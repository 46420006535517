import { Component, OnInit } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import InfoCategory from 'src/app/models/InfoCategory';
import CartFilter from 'src/app/models/cart/CartFilter';
import Product from 'src/app/models/product/product';
import Address from 'src/app/models/user/Address';
import User from 'src/app/models/user/User';
import { maskPhone } from 'src/util/mask';
import { AppInfoService } from '../../../../services/app-info.service';
import { CartService } from '../../../../services/cart.service';
import { BudgetService } from './../../../../services/budget.service';
import { ToastService } from './../../../../services/toast/toast.service';
import { BlockOperation } from './../../../../util/block-operation';
import { CartFull } from './../../../models/cart/cart';

@Component({
  selector: 'app-home-all-carts',
  template: `
    <div class="rm-ecommerce-home-menu-all-carts">
      <app-block-loader [blockOperation]="blockOperation"></app-block-loader>
      <div class="rm-ecommerce-home-header">
        <p *ngIf="subpage === 'details'">{{ getDescription() }}</p>
      </div>

      <div *ngIf="subpage === 'grid'">
        <p-table
          #tablecarts
          [columns]="cols"
          selectionMode="multiple"
          [(selection)]="selectedCarts"
          [value]="carts"
          styleClass="p-datatable-gridlines"
          [scrollable]="true"
          scrollHeight="449px"
          [globalFilterFields]="['id', 'user.name', 'user.email', 'enabled']"
        >
          <ng-template pTemplate="caption">
            <div class="table-header-search">
              <p-dropdown
                [options]="statuses"
                (onChange)="onChangeStatus($event)"
                optionLabel="description"
              ></p-dropdown>
              <div class="icons">
                <span class="p-input-icon-left ml-auto">
                  <i class="pi pi-search"></i>
                  <input
                    pTooltip="Pesquise qualquer campo do grid"
                    tooltipPosition="bottom"
                    pInputText
                    type="text"
                    (input)="
                      tablecarts.filterGlobal(
                        $any($event.target).value,
                        'contains'
                      )
                    "
                    placeholder="Pesquisar"
                  />
                </span>
                <button
                  type="button"
                  pButton
                  pRipple
                  icon="pi pi-file"
                  (click)="tablecarts.exportCSV()"
                  pTooltip="Exportar para CSV"
                  tooltipPosition="bottom"
                ></button>
              </div>
            </div>
          </ng-template>
          <ng-template pTemplate="header">
            <tr>
              <th pSortableColumn="id">
                ID<p-sortIcon field="id"></p-sortIcon>
              </th>
              <th pSortableColumn="user.name">
                Nome<p-sortIcon field="user.name"></p-sortIcon>
              </th>
              <th>Email</th>
              <th>CEP</th>
              <th>Produtos</th>
              <th>Valor</th>
              <th>Criado</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-cart>
            <tr [pSelectableRow]="cart" (dblclick)="onDblClick(cart)">
              <td>{{ cart?.id }}</td>
              <td>{{ cart?.user?.name }}</td>
              <td>{{ cart?.user?.email }}</td>
              <td>{{ cart?.user?.address[0]?.zipCode }}</td>
              <td>{{ cart?.cartProducts?.length }}</td>
              <td>{{ getTotalValue(cart) | currency }}</td>
              <td>{{ cart?.createdAt }}</td>
            </tr>
          </ng-template>
          <ng-template pTemplate="summary">
            Total: {{ carts.length }}
            {{
              cartFilter.statusCart === 'BUDGET' ? 'Orçamentos' : 'Carrinhos'
            }}
            <button
              pButton
              pRipple
              [label]="'Excluir Selecionados'"
              icon="pi pi-trash"
              class="p-button-danger"
              (click)="onClickDeleteCarts()"
              [disabled]="!carts || !carts.length"
            ></button>
          </ng-template>
        </p-table>
      </div>

      <div class="details" *ngIf="subpage === 'details'">
        <div class="rm-ecommerce-subpage-header">
          <button
            class="rm-button"
            (click)="this.subpage = 'grid'"
            title="Voltar para a listagem"
          >
            Voltar
          </button>
        </div>
        <app-datagrid
          type="cart-details"
          [rowData]="this.cartSelected.cartProducts"
          [enableEdit]="false"
          rowSelection="multiple"
          [enableDelete]="false"
        ></app-datagrid>
        <button class="rm-button" (click)="onClickDelete()">
          Excluir
          {{ cartFilter.statusCart === 'BUDGET' ? 'Orçamento' : 'Carrinho' }}
        </button>
      </div>
    </div>
  `,
})
export class AllCartsPageComponent implements OnInit {
  public blockOperation = new BlockOperation();
  public carts: Array<CartFull>;
  public cartFilter: CartFilter = new CartFilter();
  public cartSelected: CartFull;
  public categories: Array<InfoCategory> = [];
  public cols: any[] = [];
  public selectedCarts: Array<CartFull> = [];
  public statuses: any[];
  public subpage = 'grid';

  constructor(
    private appInfo: AppInfoService,
    private budgetService: BudgetService,
    private cartService: CartService,
    private confirmationService: ConfirmationService,
    private toastService: ToastService
  ) {}

  ngOnInit() {
    this.statuses = [
      { id: 'OPEN', description: 'Abertos' },
      { id: 'PREPARED_CHECKOUT', description: 'Em Checkout' },
      { id: 'CLOSED', description: 'Fechados' },
      { id: 'BUDGET', description: 'Orçamentos' },
    ];

    this.cols = [
      { field: 'id', header: 'ID' },
      { field: 'user.name', header: 'Nome' },
      { field: 'user.email', header: 'E-mail' },
      { field: 'user.getAddress(true).zipCode', header: 'CEP' },
      { field: 'cartProducts.length', header: 'Produtos' },
      { field: 'getTotalValue(cart)', header: 'Valor' },
      { field: 'createdAt', header: 'Criado' },
    ];

    this.cartFilter.statusCart = 'OPEN';
    this.getCategories();
    this.getCarts();
  }

  getCategories() {
    try {
      this.categories = this.appInfo.getCategories();
    } catch (err) {
      console.error(err);
    }
  }

  async getCarts() {
    this.carts = [];
    this.selectedCarts = [];
    this.blockOperation.setBlockOperation('', true);
    try {
      this.cartFilter.direction = 'DESC';
      this.cartFilter.order = ['id'];
      if (this.cartFilter.statusCart === 'BUDGET') {
        this.carts = await this.budgetService.list();
      } else {
        this.carts = await this.cartService.list(this.cartFilter);
      }

      this.carts.forEach((cart) => {
        cart.cartProducts.forEach((p) => {
          Product.getAllDescription(this.categories, p.product);
          p.product.valueString = this.formatValue(p.product.value);
        });
      });
    } catch (err) {
      this.toastService.warning('Não há dados para o filtro selecionado!');
    } finally {
      this.blockOperation.setBlockOperation('', false);
      this.subpage = 'grid';
      if (this.carts.length === 0) {
        this.toastService.warning('Não há dados para o filtro selecionado!');
      }
    }
  }

  onClickDelete() {
    if (
      this.cartFilter.statusCart === 'OPEN' ||
      this.cartFilter.statusCart === 'BUDGET'
    ) {
      this.confirmationService.confirm({
        message: `Deseja realmente excluir esse ${
          this.cartFilter.statusCart === 'BUDGET' ? 'orçamento' : 'carrinho'
        }?`,
        header: 'Confirmação',
        accept: () => {
          this.deleteCart();
        },
      });
    } else {
      this.toastService.error('É possível excluir apenas carrinho em aberto!');
    }
  }

  async deleteCart() {
    this.blockOperation.setBlockOperation('', true);
    try {
      if (this.cartFilter.statusCart === 'BUDGET') {
        await this.budgetService.delete(this.cartSelected.hash);
      } else {
        await this.cartService.delete(this.cartSelected.id);
      }
      this.blockOperation.setBlockOperation('', false);
      await this.getCarts();
      this.toastService.success(
        `O ${
          this.cartFilter.statusCart === 'BUDGET' ? 'orçamento' : 'carrinho'
        } selecionado foi excluído com sucesso!`
      );
    } catch (err) {
      this.blockOperation.setBlockOperation('', false);
      console.error(err);
    }
  }

  getDescription() {
    const user: User = this.cartSelected?.user;
    const address: Address = this.cartSelected?.user?.address[0];
    return `${user?.name} - ${user?.email} - ${maskPhone(
      user?.phone.toString(),
      '(##) #####-####'
    )}
        Endereço: ${address?.address}, ${address?.numberAddress} - ${
      address?.district
    } - ${address?.uf} - CEP: ${address?.zipCode}
    Criado: ${this.cartSelected?.createdAt}`;
  }

  formatValue(value) {
    if (value && value > 0) {
      return `R$ ${value.toFixed(2).split('.').join(',')}`;
    }
    return '';
  }

  async onDblClick(cart: CartFull) {
    this.cartSelected = cart;
    this.selectedCarts = this.selectedCarts.filter(
      (c) => c.id === this.cartSelected.id
    );
    this.subpage = 'details';
  }

  getTotalValue(cart) {
    let value = 0;
    cart?.cartProducts.forEach((product) => {
      value += product?.product.value * product?.amount;
    });
    return value;
  }

  onChangeStatus(evt: any) {
    this.cartFilter.statusCart = evt.value.id;
    this.getCarts();
  }

  onClickDeleteCarts() {
    if (this.selectedCarts?.length === 0) {
      this.toastService.error(
        `É necessário selecionar ao menos um ${
          this.cartFilter.statusCart === 'BUDGET' ? 'orçamento' : 'carrinho'
        }!`
      );
      return;
    }

    if (
      this.cartFilter.statusCart === 'OPEN' ||
      this.cartFilter.statusCart === 'BUDGET'
    ) {
      this.confirmationService.confirm({
        message: `Deseja realmente excluir os ${
          this.cartFilter.statusCart === 'BUDGET' ? 'orçamentos' : 'carrinhos'
        } selecionados?`,
        header: 'Confirmação',
        accept: () => {
          this.deleteCarts();
        },
      });
    } else {
      this.toastService.error('É possível excluir apenas carrinhos em aberto!');
    }
  }

  async deleteCarts() {
    this.blockOperation.setBlockOperation('', true);
    try {
      if (this.cartFilter.statusCart === 'BUDGET') {
        await this.budgetService.deleteBudgets(this.selectedCarts);
      } else {
        await this.cartService.deleteCarts(this.selectedCarts);
      }
      this.blockOperation.setBlockOperation('', false);
      await this.getCarts();
      this.toastService.success(
        `Os ${
          this.cartFilter.statusCart === 'BUDGET' ? 'orçamentos' : 'carrinhos'
        } selecionados foram excluídos com sucesso!`
      );
    } catch (error) {
      this.blockOperation.setBlockOperation('', false);
      this.toastService.error(
        error || 'Ocorreu um erro ao tentar excluir os carrinhos selecionados!'
      );
    }
  }
}
