import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DateTime } from 'luxon';
import { ConfirmationService } from 'primeng/api';
import { CartItem } from 'src/app/models/cart/cart';
import Freight from 'src/app/models/freight/Freight';
import OrderCancel from 'src/app/models/order/OrderCancel';
import OrderNFE from 'src/app/models/order/OrderNFE';
import OrderTrackingDelivery from 'src/app/models/order/OrderTrackingDelivery';
import Order, {
  PaymentData,
  ShippingCompany,
  orderStatus,
} from 'src/app/models/order/order';
import { OrderService } from 'src/services/order.service';
import { ToastService } from 'src/services/toast/toast.service';
import { TransactionsService } from 'src/services/transactions.service';
import { BlockOperation } from 'src/util/block-operation';
import { FIVE_SECONDS_IN_MS, wait } from 'src/util/wait';
import File from '../../../util/file';
import { SessionStorageService } from './../../../services/session-storage.service';
import { TWO_SECONDS_IN_MS } from './../../../util/wait';

@Component({
  selector: 'app-order',
  template: `
    <app-block-loader [blockOperation]="blockOperation"></app-block-loader>
    <app-order-status
      class="mobile-invisible"
      [statuses]="order.statuses || []"
    ></app-order-status>
    <div class="next-status" *ngIf="!isCanceled()">
      <button
        class="rm-button"
        *ngIf="isPending() && order.orderTransaction?.paymentLink"
        (click)="
          openLinkPayment(); $event.stopPropagation(); $event.preventDefault()
        "
      >
        BOLETO
      </button>
      <button
        class="rm-button"
        *ngIf="isPending() && order.orderTransaction?.pix"
        (click)="
          openQRCodePix(); $event.stopPropagation(); $event.preventDefault()
        "
      >
        REALIZAR PAGAMENTO
      </button>
      <button
        *ngIf="canCancel() && isAdmin"
        class="rm-button btn-cancel-order"
        (click)="canceling = {}"
      >
        Cancelar Pedido
      </button>
      <button
        class="rm-button"
        *ngIf="availableBtnNextStatus()"
        (click)="onBtNextStatus()"
      >
        Próximo Status
      </button>
    </div>
    <div class="order-canceled" *ngIf="isCanceled()">
      <h2>PEDIDO CANCELADO: {{ order?.statusCanceled }}</h2>
    </div>

    <table class="order-details">
      <thead>
        <tr>
          <th>Produto</th>
          <th class="price mobile-invisible">Preço</th>
          <th class="mobile-invisible">Quantidade</th>
          <th class="mobile-invisible">Total</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let cartProduct of order.cartProducts">
          <td class="order-details-product">
            <img
              src="{{ getImage(cartProduct) }}"
              [alt]="cartProduct.details.name"
              (click)="
                onClickProduct(cartProduct);
                $event.stopPropagation();
                $event.preventDefault()
              "
              width="70"
              height="70"
            />
            <div
              class="title"
              (click)="
                onClickProduct(cartProduct);
                $event.stopPropagation();
                $event.preventDefault()
              "
            >
              <h5>
                {{ cartProduct?.details?.name }} - SKU
                {{ cartProduct?.details?.id }}
              </h5>
              <small
                *ngIf="cartProduct?.details?.isPersiana()"
                class="is-blinds"
                ><span>Comando: </span>{{ cartProduct?.controlSideDescription
                }}<span> - Acionamento: </span
                >{{ cartProduct?.driveDescription }}</small
              >

              <small
                *ngIf="cartProduct?.details?.isPersiana()"
                class="is-blinds"
                ><span>Fixação: </span>{{ cartProduct?.typeFixingDescription }}

                <span *ngIf="!cartProduct?.details?.isPersianaRomana()">
                  - Acabamento: </span
                >{{
                  cartProduct?.details?.isPersianaRomana()
                    ? ''
                    : cartProduct?.panelDescription
                }}
              </small>
              <small
                >{{ cartProduct?.details?.brandDescription }} -
                {{ cartProduct?.details?.colorDescription }}</small
              >
              <small class="mobile-visible"
                ><span>Preço:&nbsp;</span>
                {{ cartProduct?.value || 0 | currency }} -
                <span>&nbsp;Qtd:&nbsp;</span> {{ cartProduct?.qt }} -
                <span>&nbsp;Total:&nbsp;</span>
                {{
                  cartProduct?.value * (cartProduct?.qt || 1) | currency
                }}</small
              >
              <small class="mobile-invisible">{{
                cartProduct?.details?.dimensions || ''
              }}</small>
            </div>
          </td>
          <td class="mobile-invisible">
            {{ cartProduct?.value || 0 | currency }}
          </td>
          <td class="mobile-invisible">{{ cartProduct?.qt }}</td>
          <td class="mobile-invisible">
            {{ cartProduct?.value * (cartProduct?.qt || 1) | currency }}
          </td>
        </tr>
      </tbody>
    </table>

    <div class="order-totals mobile-visible">
      <div class="order-total">
        <h6>Subtotal</h6>
        <h5 class="value">{{ getSubtotal() || 0 | currency }}</h5>
      </div>

      <div class="order-total" *ngIf="order?.freight">
        <h6>Frete</h6>
        <h5 class="value">{{ order?.freight || 0 | currency }}</h5>
      </div>

      <div class="order-total" *ngIf="order?.interestValue">
        <h6>Juros</h6>
        <h5 class="value">{{ order?.interestValue || 0 | currency }}</h5>
      </div>

      <div class="order-total" *ngIf="order?.discount">
        <h6>Desconto Total</h6>
        <h5 class="value discount">{{ getDiscount() || 0 | currency }}</h5>
      </div>

      <div class="order-total">
        <h6>Total</h6>
        <h5 class="value">{{ getOrderValue() || 0 | currency }}</h5>
      </div>
    </div>

    <div
      class="shipping-details mobile-block"
      *ngIf="order?.address && order?.nameUser"
    >
      <div class="shipping-detail-item mobile-block">
        <h5>Dados do Cliente</h5>
        <h6>{{ order?.orderBuyer?.name || '' }}</h6>
        <h6>Cpf: {{ order?.orderBuyer?.document || '' }}</h6>
        <h6>{{ order?.orderBuyer?.email || '' }}</h6>
        <h6>
          Fone: {{ order?.orderBuyer?.areaCode || '' }} -
          {{ order?.orderBuyer?.phone || '' }}
        </h6>
      </div>

      <div class="shipping-detail-item mobile-block">
        <h5>
          {{ storePickUp() ? 'Endereço do Cliente' : 'Endereço de Entrega' }}
          <span *ngIf="storePickUp()"> (Retirada em Loja)</span>
        </h5>
        <h6>
          Rua: {{ order?.address?.address || '' }} -
          {{ order?.address?.numberAddress }}
        </h6>
        <h6>Comp: {{ order?.address?.complement || '' }}</h6>
        <h6>Bairro: {{ order?.address?.district || '' }}</h6>
        <h6>
          Cidade: {{ order?.address?.city || '' }} -
          {{ order?.address?.uf || '' }} - {{ order?.address?.zipCode || '' }}
        </h6>
      </div>

      <div class="order-totals mobile-invisible">
        <div class="order-total">
          <h6>Subtotal</h6>
          <h5 class="value">{{ getSubtotal() || 0 | currency }}</h5>
        </div>

        <div class="order-total" *ngIf="order?.freight">
          <h6>Frete</h6>
          <h5 class="value">{{ order.freight || 0 | currency }}</h5>
        </div>

        <div class="order-total" *ngIf="order?.interestValue">
          <h6>Juros</h6>
          <h5 class="value">{{ order?.interestValue || 0 | currency }}</h5>
        </div>

        <div class="order-total" *ngIf="order?.coupon?.code">
          <h6>Cupom</h6>
          <h5 class="value discount">{{ order?.coupon?.code }}</h5>
        </div>

        <div class="order-total" *ngIf="order?.discount">
          <h6>Desconto Total</h6>
          <h5 class="value discount">{{ getDiscount() || 0 | currency }}</h5>
        </div>

        <div class="order-total">
          <h6>Total</h6>
          <h5 class="value">{{ getOrderValue() || 0 | currency }}</h5>
        </div>
      </div>
    </div>

    <div
      class="shipping-details address mobile-block"
      *ngIf="order?.address && order?.nameUser"
    >
      <div class="shipping-detail-item mobile-block">
        <h5>Dados da Entrega</h5>
        <h6>
          {{ storePickUp() ? 'Opção: ' : 'Transportadora: '
          }}<span>{{ shippingCompany?.company }}</span>
        </h6>
        <h6 *ngIf="storePickUp()">
          Endereço:
          <span
            >R. Pedro Bunn, n° 1603 - Jd. Cidade de Florianopolis, São José -
            SC, 88111-120.
          </span>
        </h6>
        <h6 *ngIf="storePickUp()">
          Horário:
          <span>Segunda à Sexta - 8h00 às 11:30 e 13:30 às 18:00</span>
        </h6>
        <h6 *ngIf="!storePickUp()">
          Rastreio: <span>{{ shippingCompany?.tracking }}</span>
        </h6>
        <h6 *ngIf="!storePickUp()">
          Data Coleta:
          <span>{{ shippingCompany?.dtCollect }}</span>
        </h6>
        <h6 *ngIf="storePickUp()">
          Liberado para retirada<span>{{ shippingCompany?.dtEstimate }}</span>
        </h6>
        <h6>
          Qtd total volumes: <span>{{ order?.totalVolumes }}</span>
        </h6>
        <h6>
          Peso total: <span>{{ order?.totalWeight + ' kg' }}</span>
        </h6>
        <h6 *ngIf="shippingCompany?.url && !storePickUp()">
          URL:
          <a href="{{ shippingCompany?.url }}" target="_blank">
            <span>{{ shippingCompany?.url }}</span></a
          >
        </h6>
        <div *ngIf="storePickUp()" class="text-right">
          <button class="" (click)="openMapsRM()">Como Chegar</button>
        </div>
        <div *ngIf="canSendTrackingDelivery()">
          <button class="rm-button" (click)="trackingDelivery = {}">
            Preencher Dados
          </button>
        </div>
      </div>

      <div class="shipping-detail-item mobile-block">
        <h5>Dados do Pagamento</h5>
        <h6>Forma de Pagamento: {{ paymentData?.form }}</h6>
        <h6>Quantidade de Parcelas: {{ paymentData?.qtdInstallments }}</h6>
        <h6>Número do Pedido: {{ paymentData?.orderNumber }}</h6>
        <h6 *ngIf="order?.orderTransaction">
          Status: {{ paymentData?.status }}
        </h6>
        <h6>ID Pedido HUB: {{ order?.orderHubId }}</h6>
      </div>
      <div class="order-totals mobile-block" *ngIf="productHaveVolume()">
        <h5>Volumes</h5>
        <div *ngFor="let cp of order?.cartProducts">
          <div *ngIf="cp?.details?.volumes?.length > 0">
            <h6>Produto: {{ cp?.details?.id }}</h6>
            <h6 *ngFor="let vol of cp?.details?.volumes">
              A={{ vol.packingHeightThickness }}cm x L={{ vol.packingWidth }}cm
              x C= {{ vol.packingLength }}cm
            </h6>
          </div>
        </div>
      </div>
      <div *ngIf="!productHaveVolume()" class="order-totals"></div>
    </div>

    <div class="nfe" *ngIf="canSendNFE()">
      <h5>Nota Fiscal</h5>
      <div class="admin" *ngIf="isAdmin">
        <div class="nfe-form">
          <div class="nfe-form-item">
            <label>Número</label>
            <input
              type="text"
              (change)="order?.orderNFE.numNFE = $event.target.value"
              [value]="order?.orderNFE?.numNFE || ''"
              [disabled]="!isOnExpedition()"
            />
          </div>
          <div class="nfe-form-item">
            <label>Série</label>
            <input
              type="text"
              (change)="order?.orderNFE.numSerie = $event.target.value"
              [value]="order?.orderNFE?.numSerie || ''"
              [disabled]="!isOnExpedition()"
            />
          </div>
          <div class="nfe-form-item nfe-form-item-key">
            <label>Chave de Acesso</label>
            <input
              type="text"
              (change)="onChangeKeyAccess($event.target.value)"
              [value]="order?.orderNFE?.accessKey || ''"
              [disabled]="!isOnExpedition()"
            />
          </div>
        </div>
        <div class="nfe-form">
          <div class="nfe-form-item nfe-form-item-xml">
            <label>XML</label>
            <div class="xml">
              <input
                type="text"
                title="order.orderNFE.xmlName || ''"
                (change)="order?.orderNFE.xmlName = $event.target.value"
                [value]="getNFENameFile()"
                [disabled]="true"
              />
              <img
                *ngIf="
                  order?.orderNFE?.attachmentOrderNFE &&
                  order?.orderNFE?.attachmentOrderNFE?.fileName
                "
                src="assets/img/download.svg"
                title="Baixar o arquivo XML da NFE"
                width="16"
                (click)="downloadXML(order?.orderNFE?.attachmentOrderNFE?.url)"
              />
              <img
                *ngIf="!order?.orderNFE?.attachmentOrderNFE?.fileName"
                src="assets/img/upload.svg"
                title="Selecione o arquivo XML para
              enviar"
                width="16"
                (click)="addXML()"
              />
            </div>
          </div>
        </div>
        <button
          class="rm-button"
          *ngIf="isOnExpedition()"
          (click)="updateOrderNFE()"
        >
          Enviar
        </button>
      </div>
      <div class="user" *ngIf="!isAdmin">
        <label>{{
          order?.orderNFE?.numNFE
            ? 'Número: ' + order?.orderNFE?.numNFE
            : 'Aguardando envio...'
        }}</label>
        <img
          src="assets/img/download.svg"
          title="Fazer o download do arquivo XML da Nota"
          width="16"
          (click)="dowloadXML()"
        />
      </div>
    </div>

    <div class="obs" *ngIf="isAdmin">
      <h5>Anotações</h5>
      <textarea
        [value]="order?.administratorNotes || ''"
        (change)="order.administratorNotes = $event.target.value"
      ></textarea>
      <div class="update" *ngIf="!isCanceled()">
        <button class="rm-button" (click)="setAdministratorNotes()">
          Salvar
        </button>
      </div>
    </div>

    <app-modal
      *ngIf="canceling"
      (closed)="canceling = null"
      class="modal-cancel-order"
    >
      <h3>Motivo do Cancelamento</h3>
      <textarea
        (change)="canceling.cancelDetails = $event.target.value"
      ></textarea>
      <div class="text-right actions">
        <button class="rm-button" (click)="onClickCancelOrder()">
          Cancelar
        </button>
      </div>
    </app-modal>

    <app-modal
      *ngIf="trackingDelivery"
      (closed)="trackingDelivery = null"
      class="modal-tracking-delivery"
    >
      <h3>Dados da Entrega</h3>
      <div class="form-item-order">
        <label>Nome da Transportadora</label>
        <input
          type="text"
          autofocus
          (change)="trackingDelivery.carrier = $event.target.value"
        />
      </div>
      <div class="form-item-order">
        <label>CNPJ da Transportadora (Apenas números)</label>
        <input
          type="number"
          (change)="trackingDelivery.carrierDocument = $event.target.value"
        />
      </div>
      <div class="form-item-order">
        <label>Data Coleta</label>
        <input
          type="text"
          mask="00/00/0000"
          [(ngModel)]="trackingDelivery.shippedDate"
        />
      </div>
      <div class="form-item-order">
        <label>Data Estimada</label>
        <input
          type="text"
          mask="00/00/0000"
          [(ngModel)]="trackingDelivery.estimateDate"
        />
      </div>
      <div class="form-item-order">
        <label>Número de Rastreio</label>
        <input
          type="text"
          (change)="trackingDelivery.number = $event.target.value"
        />
      </div>
      <div class="form-item-order">
        <label>URL Rastreamento</label>
        <input
          type="text"
          (change)="trackingDelivery.url = $event.target.value"
        />
      </div>
      <div class="text-right actions">
        <button class="rm-button" (click)="onClickTrackingDelivery()">
          Enviar
        </button>
      </div>
    </app-modal>
    <app-modal
      *ngIf="showPix"
      (closed)="showPix = false"
      class="modal-checkout-pix"
    >
      <div class="content">
        <h1>QRCDE de pagamento valor: {{ getOrderValue() || 0 | currency }}</h1>
        <h3>
          Abra o app do seu banco, acesse a área pix leia a imagem do QRCode ou
          copie e cole o link.
        </h3>
        <h3>
          Aguarde, que após o pagamento, a tela será atualizada automaticamente
        </h3>
        <img
          src="{{ order?.orderTransaction?.base64QrCode }}"
          [alt]="'QRCode'"
        />
        <button class="rm-button" (click)="onClickCopyCodePix()">
          COPIAR CÓDIGO
        </button>
      </div>
    </app-modal>
  `,
})
export class OrderComponent implements OnInit {
  @Input()
  public order: Order;

  @Input()
  public isAdmin = false;

  @Output()
  public updated: EventEmitter<Order> = new EventEmitter<Order>();

  public blockOperation = new BlockOperation();
  public canceling: OrderCancel;
  public trackingDelivery: OrderTrackingDelivery;
  public shippingCompany: ShippingCompany = {};
  public paymentData: PaymentData = {};
  public showPix = false;

  constructor(
    private confirmationService: ConfirmationService,
    private orderService: OrderService,
    public sessionStorageService: SessionStorageService,
    private toastService: ToastService,
    private transactionsService: TransactionsService
  ) {}

  ngOnInit() {
    if (!this.order.orderNFE) {
      this.order.orderNFE = new OrderNFE();
    }

    if (this.order?.orderShipping?.optionFreight === Freight.STORE_PICKUP) {
      this.order?.statuses.forEach((s) => {
        if (s.title === orderStatus.PAID_WAITING_DELIVERY) {
          s.title = 'Liberado';
        }
      });
    }

    this._setShippingCompany();
    this._setPaymentData();
  }

  private _setShippingCompany() {
    this.shippingCompany = {
      company:
        this.order?.orderShipping?.name ||
        this.order?.orderTracking?.carrier ||
        '',
    };

    if (this.order?.orderTracking) {
      this.shippingCompany.tracking = this.order?.orderTracking?.number || '';
      this.shippingCompany.url = this.order?.orderTracking?.url || '';

      if (this.order?.orderTracking?.shippedDate) {
        this.shippingCompany.dtCollect = this.order?.orderTracking?.shippedDate;
      }

      if (this.order?.orderTracking?.estimateDate) {
        this.shippingCompany.dtEstimate =
          this.order?.orderTracking?.estimateDate;
      }
    } else {
      if (this.order?.orderShipping?.dateStorePickup) {
        if (this.order?.orderShipping?.optionFreight === Freight.STORE_PICKUP) {
          this.shippingCompany.dtEstimate =
            this.order?.orderShipping?.dateStorePickup;
        }
      }
    }
  }

  private _setPaymentData() {
    this.paymentData = {};
    if (!!this.order?.orderTransaction) {
      this.paymentData.form =
        this.order?.orderTransaction?.paymentCodeDescription;
      this.paymentData.qtdInstallments =
        this.order?.orderTransaction?.installmentCount?.toString();
      this.paymentData.orderNumber = '';
      this.paymentData.status = this.order?.orderTransaction?.getStatus();
    } else {
      if (this.order?.orderPayments?.length > 0) {
        this.paymentData.form =
          this.order?.orderPayments[0]?.paymentMethodNormalized;
        this.paymentData.qtdInstallments =
          this.order?.orderPayments[0]?.installments?.toString();
      } else {
        this.paymentData.form = '';
        this.paymentData.qtdInstallments = '';
      }

      if (!!this.order.marketplaceTransaction) {
        this.paymentData.orderNumber =
          this.order?.marketplaceTransaction?.marketPlaceNumber;
      } else {
        this.paymentData.orderNumber = '';
      }
    }
  }

  storePickUp() {
    return this.order?.orderShipping?.optionFreight === Freight.STORE_PICKUP;
  }

  canSendNFE() {
    return !(this.isCanceled() || this.isPending() || this.isPaidWattingShip());
  }

  canCancel() {
    return !this.isCanceled() && !this.isConcluded();
  }

  isCanceled() {
    return this.order.currentStatus === orderStatus.CANCELED;
  }

  isPending() {
    return this.order.currentStatus === orderStatus.PENDING;
  }

  isPaidWattingShip() {
    return this.order.currentStatus === orderStatus.PAID_WAITING_SHIP;
  }

  isConcluded() {
    return this.order.currentStatus === orderStatus.CONCLUDED;
  }

  isOnExpedition() {
    return this.order.currentStatus === orderStatus.ON_EXPEDITION;
  }

  isInvoiced() {
    return this.order.currentStatus === orderStatus.INVOICED;
  }

  isMercadoLivre() {
    return (
      this.order.marketplaceTransaction &&
      this.order.marketplaceTransaction.marketPlace === 'MERCADO_LIVRE'
    );
  }

  canSendTrackingDelivery() {
    return (
      this.isAdmin &&
      this.isInvoiced() &&
      this.isMercadoLivre() &&
      !this.shippingCompany.tracking
    );
  }

  async addXML() {
    try {
      const target: any = await File.openFileXML();
      if (target) {
        this.order.orderNFE.xmlName = null;
        const file = target.files[0];
        if (file) {
          this.order.orderNFE.xmlName = file.name;
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            this.order.orderNFE.xml = reader.result.toString();
          };
        }
      }
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  downloadXML(link) {
    if (link) {
      window.open(link, '_blank');
    }
  }

  dowloadXML() {
    window.open(this.order?.orderNFE?.attachmentOrderNFE.url, '_blank');
  }

  availableBtnNextStatus() {
    const canProceed = this.orderService.canProceed(this.order);
    return (
      canProceed && this.isAdmin && !this.isCanceled() && !this.isOnExpedition()
    );
  }

  getNextStatusDescription(order: Order) {
    return this.orderService.getNextStatusDescription(order);
  }

  onBtNextStatus() {
    let nextStatus = this.getNextStatusDescription(this.order);
    nextStatus =
      nextStatus === orderStatus.PAID_WAITING_DELIVERY && this.storePickUp()
        ? 'Liberado'
        : nextStatus;

    this.confirmationService.confirm({
      message: `Deseja mesmo marcar o pedido como ${nextStatus}?`,
      header: 'Confirmação',
      accept: async () => {
        this.nextStatus();
      },
    });
  }

  async nextStatus() {
    this.blockOperation.show();
    try {
      const updated = await this.orderService.changeStatus(
        this.order,
        this.orderService.getNextStatus(this.order)
      );
      this.order = updated;
      if (!this.order.orderNFE) {
        this.order.orderNFE = new OrderNFE();
      }
      this.updated.emit(this.order);
    } catch (error) {
      this.toastService.error(error?.message || 'Erro ao mudar status.');
    } finally {
      this.blockOperation.hide();
    }
  }

  getMapUrl(address) {
    return (
      'https://maps.google.com/?q=' +
      encodeURIComponent(
        `
        ${address?.address} ${address?.numberAddress} ${address?.district} ${address?.city}
    `.trim()
      )
    );
  }

  openMapsRM() {
    const url = 'https://g.page/rmpolicarbonatos?share';
    window.open(url, '_blank');
  }

  getImage(item: CartItem) {
    if (item?.details?.photos?.length > 0) {
      return item.details.photos[0]?.url;
    }
    return '';
  }

  getSubtotal() {
    const value = this.order?.cartProducts.reduce((old, current) => {
      return old + (current.qt || 1) * (current.value || 0);
    }, 0.0);
    return value;
  }

  getOrderValue() {
    const value = this.getSubtotal() || 0;
    const freight = this.order?.freight || 0;
    const discount = this.getDiscount() || 0;
    const interest = this.order?.interestValue || 0;
    return +value + freight + interest - +discount;
  }

  getDiscount() {
    return this.order?.discount;
  }

  async onClickProduct(item: CartItem) {
    let category = item?.details?.category?.toLowerCase()?.replaceAll('_', '-');
    let subCategory = item?.details?.subCategory
      .toLowerCase()
      ?.replaceAll('_', '-');
    let name = item?.details?.name?.toLowerCase()?.replace(/ /g, '-');
    name = name?.normalize('NFD')?.replace(/[\u0300-\u036f]/g, '');
    name = name
      ?.replace(/% ?/g, '')
      ?.replaceAll('/', '')
      ?.replaceAll('(', '')
      ?.replaceAll(')', '')
      ?.replaceAll(',', '-')
      ?.replaceAll('.', '-')
      ?.replace(/\---/gi, '-')
      ?.replace(/\--/gi, '-');

    const link = `https://www.rmpolicarbonatos.com.br/${category}/${subCategory}/${item?.details?.id}/${name}`;

    window.open(link, '_blank');
  }
  async setAdministratorNotes() {
    this.blockOperation.show();
    try {
      await this.orderService.update(this.order.id, {
        administratorNotes: this.order.administratorNotes,
      });
      this.toastService.success('Anotação inserida com sucesso!');
    } catch (error) {
      this.toastService.error(error?.message || 'Erro ao inserir anotação.');
    } finally {
      this.blockOperation.hide();
    }
  }

  onChangeKeyAccess(value: string) {
    this.order.orderNFE.accessKey = value.replace(/\s/g, '');
  }

  updateOrderNFE() {
    this.confirmationService.confirm({
      message: 'Todos os dados estão corretos e foram conferidos?',
      header: 'Confirmação',
      accept: async () => {
        if (this.order?.orderNFE?.accessKey?.length > 44) {
          this.toastService.error(
            'O campo Chave de Acesso não pode ter menos de 44 caracteres.'
          );
          return;
        }
        if (this.validNFE()) {
          this.blockOperation.show();
          try {
            this.order.orderNFE.dateEmission = DateTime.now().toFormat(
              'dd/MM/yyyy HH:mm:ss'
            );
            const updated = await this.orderService.updateOrderNFE(
              this.order.id,
              this.order.orderNFE
            );
            this.order = updated;
            this.updated.emit(this.order);
            this.toastService.success('Pedido alterado para Faturado!');
          } catch (err) {
            this.toastService.error('Erro ao inserir NFE!');
          } finally {
            this.blockOperation.hide();
          }
        } else {
          this.toastService.error('Preencha todos os campos da NFE!');
        }
      },
    });
  }

  validNFE() {
    if (
      this.order?.orderNFE?.numNFE &&
      this.order?.orderNFE?.numSerie &&
      this.order?.orderNFE?.accessKey &&
      this.order?.orderNFE?.xml
    ) {
      this.order.orderNFE.accessKey = this.order?.orderNFE?.accessKey?.replace(
        /[ ,.]/g,
        ''
      );
      return true;
    } else {
      return false;
    }
  }

  onClickCancelOrder() {
    if (
      !this.canceling.cancelDetails ||
      this.canceling.cancelDetails.length < 20
    ) {
      this.toastService.error('Preencha o campo Motivo!');
      return;
    }
    this.confirmationService.confirm({
      message: 'Deseja realmente cancelar o pedido?',
      header: 'Confirmação',
      accept: async () => {
        this.cancelOrder(this.canceling.cancelDetails);
      },
    });
  }

  async cancelOrder(details) {
    this.canceling = null;
    try {
      this.blockOperation.show();
      const updated = await this.orderService.cancelOrder(this.order, details);
      this.toastService.success('Pedido cancelado com sucesso!');
      this.order = updated;
      this.updated.emit(this.order);
    } catch (error) {
      this.toastService.error(error?.message || 'Erro ao cancelar pedido!');
    } finally {
      this.blockOperation.hide();
    }
  }

  onClickTrackingDelivery() {
    if (!this.validTrackingDelivery()) {
      this.toastService.error('Preencha todos os campos!');
      return;
    }
    this.confirmationService.confirm({
      message: 'Deseja realmente enviar esses dados?',
      header: 'Confirmação',
      accept: async () => {
        this.sendTrackingDelivery();
      },
    });
  }

  async sendTrackingDelivery() {
    try {
      this.blockOperation.show();
      const updated = await this.orderService.updateTracking(
        this.order.id,
        this.trackingDelivery
      );
      this.toastService.success('Dados enviado com sucesso!');
      this.order = updated;
      this.updated.emit(this.order);
    } catch (error) {
      this.toastService.error(
        error?.message || 'Erro ao enviar dados da transportadora!'
      );
    } finally {
      this.blockOperation.hide();
    }
  }

  validTrackingDelivery() {
    if (
      !this.trackingDelivery.carrier ||
      !this.trackingDelivery.carrierDocument ||
      !this.trackingDelivery.estimateDate ||
      !this.trackingDelivery.number ||
      !this.trackingDelivery.shippedDate ||
      !this.trackingDelivery.url
    ) {
      return false;
    }
    return true;
  }

  getNFENameFile() {
    return (
      this.order?.orderNFE?.attachmentOrderNFE?.fileName ||
      this.order?.orderNFE?.xmlName ||
      ''
    );
  }

  openLinkPayment() {
    window.open(this.order?.orderTransaction?.paymentLink, '_blank');
  }

  openQRCodePix() {
    this.showPix = true;
    this.getStatusPix(this.order);
  }

  async getStatusPix(order: Order, tries = 0) {
    try {
      const res = await this.transactionsService.statusPix(order.id);
      if (res?.status === 'PAGA') {
        this.toastService.success(
          'Pagamento realizado com sucesso! Acompanhe o andamento do seu pedido em "Pedidos".'
        );
        await wait(TWO_SECONDS_IN_MS);
        window.location.reload();
      } else {
        if (tries < 24) {
          await wait(FIVE_SECONDS_IN_MS);
          await this.getStatusPix(order, ++tries);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  async onClickCopyCodePix() {
    if (this.order?.orderTransaction?.pix) {
      await navigator.clipboard
        .writeText(this.order?.orderTransaction?.pix)
        .then(() => {
          this.toastService.success(
            'Link copiado para área de transferência. Cole no seu app de pagamento!'
          );
        })
        .catch(() => {
          this.toastService.error('Ocorreu um erro ao copiar o link!');
        });
    }
  }

  productHaveVolume(): boolean {
    let have = false;
    this.order?.cartProducts.forEach((item) => {
      if (item?.details?.volumes?.length > 0) {
        have = true;
      }
    });
    return have;
  }
}
