import { Component } from '@angular/core';
@Component({
    selector: 'app-home-all-catalog',
    template: `
        <div class="rm-ecommerce-home-menu-showcase">
            <app-catalog></app-catalog>
        </div>
    `
  })

export class AllCatalogPageComponent {
    constructor() {}
}
