import { Component, OnInit } from '@angular/core';
import { DateTime } from 'luxon';
import Coupon from 'src/app/models/coupons/Coupon';
import InfoCoupon from 'src/app/models/coupons/InfoCoupon';
import InfoCategory from 'src/app/models/InfoCategory';
import User from 'src/app/models/user/User';
import ValidationCoupon from 'src/app/models/validation/ValidationCoupon';
import { AppInfoService } from 'src/services/app-info.service';
import { CouponsService } from 'src/services/coupons.service';
import { BlockOperation } from 'src/util/block-operation';
import { ToastService } from './../../../services/toast/toast.service';
import { TWO_SECONDS_IN_MS, wait } from './../../../util/wait';

@Component({
  selector: 'app-coupons',
  templateUrl: './coupons.component.html',
  styleUrls: ['./coupons.component.scss'],
})
export class CouponsComponent implements OnInit {
  CATEGORY = Coupon.CATEGORY;
  SUB_CATEGORY = Coupon.SUB_CATEGORY;
  ALL_PRODUCTS = Coupon.ALL_PRODUCTS;
  PERCENT = Coupon.PERCENT;
  FIXED = Coupon.FIXED;

  public subpage = 'grid';
  public blockOperation = new BlockOperation();
  public currentUser: User;
  public coupons: Array<Coupon> = [];
  public couponSelected: Coupon;
  public infoCoupon: InfoCoupon;
  public categories: Array<InfoCategory> = [];
  public selectedCategory: InfoCategory = new InfoCategory();
  public validation: ValidationCoupon = new ValidationCoupon();
  constructor(
    private appInfo: AppInfoService,
    private couponsService: CouponsService,
    private toastService: ToastService
  ) {}

  async ngOnInit() {
    this.list();
    this.getInfos();
  }

  getInfos() {
    try {
      this.infoCoupon = this.appInfo.getInfoCoupon();
      this.categories = this.appInfo.getCategories();
    } catch (err) {
      console.error(err);
    }
  }

  async list() {
    this.blockOperation.setBlockOperation('', true);
    try {
      this.coupons = await this.couponsService.list();
    } catch (err) {
      console.error(err);
      this.toastService.error('Erro ao buscar cupons!');
    } finally {
      this.blockOperation.setBlockOperation('', false);
    }
  }

  onClickNew() {
    this.couponSelected = new Coupon();
    this.couponSelected.amountUse = 0;
    this.subpage = 'form';
  }

  rowDoubleClicked(coupon: Coupon): void {
    if (coupon) {
      this.couponSelected = coupon;
      this.subpage = 'form';
    }
  }

  changeCategory($event) {
    this.couponSelected.category = $event.target.value;
    this.selectedCategory = this.categories
      .filter((cat) => cat.id === this.couponSelected.category)
      .slice()
      .shift();
  }

  changeDiscountTypes($event) {
    this.couponSelected.value = null;
    this.couponSelected.percentOff = null;
    this.couponSelected.discountType = $event.target.value;
  }

  changeValue($event) {
    if (this.couponSelected.discountType === this.PERCENT) {
      this.couponSelected.percentOff = $event.target.value;
    } else {
      this.couponSelected.value = $event.target.value;
    }
  }

  onClickSave() {
    if (!this.validCoupon()) {
      return;
    }
    this.save();
  }

  async save() {
    try {
      this.blockOperation.setBlockOperation('', true);

      let dtStart = DateTime.fromFormat(
        this.couponSelected.startDate,
        'ddMMyyyy'
      );
      if (!dtStart.isValid) {
        dtStart = DateTime.fromFormat(
          this.couponSelected.startDate,
          'dd/MM/yyyy'
        );
      }
      this.couponSelected.startDate = dtStart.toFormat('dd/MM/yyyy');

      let dtEnd = DateTime.fromFormat(this.couponSelected.endDate, 'ddMMyyyy');
      if (!dtEnd.isValid) {
        dtEnd = DateTime.fromFormat(this.couponSelected.endDate, 'dd/MM/yyyy');
      }
      this.couponSelected.endDate = dtEnd.toFormat('dd/MM/yyyy');

      if (this.couponSelected.id) {
        await this.couponsService.update(this.couponSelected);
      } else {
        this.couponSelected = await this.couponsService.save(
          this.couponSelected
        );
      }
      this.couponSelected = null;
      this.subpage = 'grid';
      this.toastService.success('Operação realizada com sucesso!');
      await wait(TWO_SECONDS_IN_MS);
      this.list();
    } catch (err) {
      this.toastService.error('Ocorreu um erro na operação!');
    }
  }

  validCoupon() {
    let valid = true;
    if (!this.couponSelected.description) {
      this.validation.errorDescription = true;
      valid = false;
    } else {
      this.validation.errorDescription = false;
    }

    if (!this.couponSelected.type) {
      this.validation.errorType = true;
      valid = false;
    } else {
      this.validation.errorType = false;
    }

    if (!this.couponSelected.value && !this.couponSelected.percentOff) {
      this.validation.errorValue = true;
      valid = false;
    } else {
      this.validation.errorValue = false;
    }

    if (!this.couponSelected.meanOfPayment) {
      this.validation.errorMeanOfPayment = true;
      valid = false;
    } else {
      this.validation.errorMeanOfPayment = false;
    }

    if (!this.couponSelected.code) {
      this.validation.errorCode = true;
      valid = false;
    } else {
      this.validation.errorCode = false;
    }

    if (!this.couponSelected.discountType) {
      this.validation.errorDiscountType = true;
      valid = false;
    } else {
      this.validation.errorDiscountType = false;
    }

    if (!this.couponSelected.startDate) {
      this.validation.errorStartDate = true;
      valid = false;
    } else {
      this.validation.errorStartDate = false;
    }

    if (!this.couponSelected.endDate) {
      this.validation.errorEndDate = true;
      valid = false;
    } else {
      this.validation.errorEndDate = false;
    }

    if (!this.couponSelected.status) {
      this.validation.errorStatus = true;
      valid = false;
    } else {
      this.validation.errorStatus = false;
    }

    if (!valid) {
      this.toastService.error('Preencha os campos obrigatórios!');
    }
    return valid;
  }

  async onClickBack() {
    this.subpage = 'grid';
    this.couponSelected = null;
  }
}
