import { formatCurrency } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import Budget from 'src/app/models/budget/budget';
import CartCheckoutDetails from 'src/app/models/cart/CartCheckoutDetails';
import CalculateShipping from 'src/app/models/freight/CalculateShipping';
import Carrying from 'src/app/models/freight/Carrying';
import Freight from 'src/app/models/freight/Freight';
import FreightSelected from 'src/app/models/freight/FreightSelected';
import ItemShipping from 'src/app/models/freight/ItemShipping';
import QuoteList from 'src/app/models/freight/QuoteList';
import Product from 'src/app/models/product/product';
import ProductFilter from 'src/app/models/product/product-filter';
import Address from 'src/app/models/user/Address';
import User from 'src/app/models/user/User';
import { environment } from 'src/environments/environment';
import { BudgetService } from 'src/services/budget.service';
import { CartService } from 'src/services/cart.service';
import { FreightService } from 'src/services/freight.service';
import { LocalStorageService } from 'src/services/local-storage.service';
import { InterestPercentageEnum } from 'src/services/payment.service';
import { SessionStorageService } from 'src/services/session-storage.service';
import { UserService } from 'src/services/user.service';
import { BlockOperation } from 'src/util/block-operation';
import { AddressService } from './../../../services/address.service';
import { StorageConstants } from './../../../services/constants/storage-constants';
import { ProductService } from './../../../services/product.service';
import { ToastService } from './../../../services/toast/toast.service';
import { TWO_SECONDS_IN_MS, wait } from './../../../util/wait';
import { CartStepsEnum } from './../../components/cart-steps/cart-steps.component';
import { Cart, CartItem } from './../../models/cart/cart';
import { PageResponse } from './../../models/page/page-response';

@Component({
  selector: 'app-cart-component',
  templateUrl: './cart.component.html',
})
export class CartComponent implements OnInit {
  INSTALLMENT = 'installments';

  public blockOperation = new BlockOperation();
  public budget: Budget;
  public cart: Cart = {};
  public cartCheckout: CartCheckoutDetails;
  public checkoutDone = false;
  public coupon = null;
  public currentStep = CartStepsEnum.PRODUCTS;
  public freightSelected: FreightSelected;
  public freightError: string = null;
  public loadingFreight = false;
  public loadingMain = true;
  public productsRelated: Product[] = [];
  public quotes: QuoteList;
  public requestBudget = false;
  public user: User;
  public zipCode = null;

  constructor(
    private addressService: AddressService,
    private budgetService: BudgetService,
    private cartService: CartService,
    private confirmationService: ConfirmationService,
    private freightService: FreightService,
    private localStorageService: LocalStorageService,
    private productService: ProductService,
    private sessionStorageService: SessionStorageService,
    private toastService: ToastService,
    private userService: UserService
  ) {
    (async () => {
      this.requestBudget =
        (await this.sessionStorageService.get(
          StorageConstants.RM_ECOMMERCE_REQUEST_BUDGET
        )) === 'true'
          ? true
          : false;

      this.zipCode = await this.localStorageService.get(
        StorageConstants.RM_ECOMMERCE_ZIP_CODE
      );
    })();
  }

  async ngOnInit() {
    this.blockOperation.setBlockOperation('', true);
    try {
      // Para testes
      // this.sessionStorageService.set(StorageConstants.RM_ECOMMERCE_USER, '');
      this.user = await this.userService.getUserStorage();
      const cartItems = await this.cartService.getItems();
      this.cart.items = cartItems.map((c) => ({ ...c }));

      if (this.requestBudget) {
        const localFreight = await this.localStorageService.get(
          StorageConstants.RM_ECOMMERCE_FREIGHT
        );
        if (localFreight) {
          this.freightSelected = localFreight;
          await this.localStorageService.removeItem(
            StorageConstants.RM_ECOMMERCE_FREIGHT
          );
          await this.calculateFreight(false);
        }
        await this.creatCart();
      } else {
        let cart;
        if (this.user) {
          const idCart = await this.sessionStorageService.get(
            StorageConstants.RM_ECOMMERCE_ID_CART
          );
          if (!idCart) {
            cart = await this.cartService.get();
            if (cart && cart.id > 0) {
              this.cart.id = cart.id;
            }
          }
        }
        this.getProductsRelated();
        if (this.zipCode) {
          await this.calculateFreight(false);
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
      this.blockOperation.setBlockOperation('', false);
      this.loadingMain = false;
    }
  }

  creatCart() {
    (async () => {
      await this.cartService.checkLocal();
      await this.sessionStorageService.removeItem(
        StorageConstants.RM_ECOMMERCE_PAGE
      );
      await this.sessionStorageService.removeItem(
        StorageConstants.RM_ECOMMERCE_REQUEST_BUDGET
      );
      this.onClickCheckout();
    })();
  }

  getMeasures(item: CartItem) {
    return `${item.details.heightThicknessDescription || ''} - ${
      item.details.widthDescription || ''
    } x ${item.details.lengthDescription || ''}`;
  }

  getImage(item: CartItem) {
    return item?.details?.mainPhotoS3Url || item?.details?.photos[0]?.url || '';
  }

  haveCart() {
    return this.cart.items && this.cart.items.length > 0;
  }

  removeItem(item: CartItem) {
    this.confirmationService.confirm({
      message: 'Deseja realmente remover este item?',
      header: 'Confirmação',
      accept: async () => {
        try {
          this.blockOperation.setBlockOperation('', true);
          this.cart.items.splice(this.cart.items.indexOf(item), 1);
          this.cart.items = [...this.cart.items];
          item.qt = 0;
          await this.cartService.addItem(item, false);
          if (this.cart?.items?.length > 0) {
            this.quotes = null;
            this.freightError = null;
            this.calculateFreight(false);
          }
        } catch (err) {
          this.toastService.error('Ocorreu um erro ao remover o produto!');
        } finally {
          this.blockOperation.setBlockOperation('', false);
        }
      },
    });
  }

  async setQt(item: CartItem, qt) {
    qt = parseInt(qt, 10);
    this.blockOperation.setBlockOperation('', true);
    try {
      if (
        item.details.stockBalance < qt &&
        !item.details.canBeGeneratedWithStockZero
      ) {
        return this.toastService.error('Produto sem mais itens em estoque!');
      }
      if (qt < 1) {
        this.removeItem(item);
      } else {
        item.qt = qt;
        const itemCart = this.cart?.items?.find(
          (i) => i.productId === item.productId
        );
        itemCart.qt = qt;
        await this.cartService.addItem(item, false);
        this.toastService.success('Quantidade alterada com sucesso!');
        await this.calculateFreight(false);
      }
    } catch (err) {
      this.toastService.error('Erro ao adicionar quantidade!');
    } finally {
      this.blockOperation.setBlockOperation('', false);
    }
  }

  async onClickCheckout() {
    if (this.freightSelected) {
      if (this.user) {
        this.prepareCheckout();
      } else {
        await this.sessionStorageService.set(
          StorageConstants.RM_ECOMMERCE_PAGE,
          'customer'
        );
        await this.sessionStorageService.set(
          StorageConstants.RM_ECOMMERCE_REQUEST_BUDGET,
          'true'
        );
        await this.localStorageService.set(
          StorageConstants.RM_ECOMMERCE_FREIGHT,
          this.freightSelected
        );
        window.location.reload();
      }
    } else {
      this.toastService.error('Selecione um frete para gerar seu pedido!');
    }
  }

  async prepareCheckout() {
    if (this.freightSelected) {
      try {
        this.blockOperation.setBlockOperation('', true);
        this.cartCheckout = await this.cartService.prepareCheckout(
          this.getFreightSelected()
        );

        this.sessionStorageService.set(
          StorageConstants.RM_ECOMMERCE_ZIP_CODE,
          this.zipCode
        );
        this.sessionStorageService.set(
          StorageConstants.RM_ECOMMERCE_CART_CHECKOUT,
          this.cartCheckout
        );
        window.location.pathname = '/pagamento';
      } catch (err) {
        this.toastService.error(
          'Erro ao gerar pedido! Entre em contato pelo WhatsApp.'
        );
        throw err;
      } finally {
        this.blockOperation.setBlockOperation('', false);
      }
    } else {
      this.toastService.error('Selecione um frete para gerar seu pedido!');
    }
  }

  getFreightSelected() {
    if (this.freightSelected) {
      return this.freightSelected;
    } else {
      return null;
    }
  }

  formateSubtotal() {
    let value = 0;
    if (this.cart && this.cart.items) {
      this.cart.items.forEach((c) => {
        value += c.qt * c.details.value;
      });
    }
    return value;
  }

  formateCashDiscount() {
    let value = this.getTotalValue();
    value = value - (value * environment.cashDiscount) / 100;
    return value;
  }

  format12x() {
    const value = this.getTotalValue();
    const interest: number = (InterestPercentageEnum._12X / 100) * value;
    const valueTotalWithInterest = value + interest;
    const amountWithInterest = valueTotalWithInterest / 12;
    return amountWithInterest;
  }

  getTotalValue() {
    let value = 0;
    if (this.cart?.items) {
      this.cart.items.forEach((c) => {
        value += c.qt * this.getValueWithDiscount(c.details);
      });
    }
    if (value && value > 0) {
      if (this.freightSelected) {
        value += this.freightSelected?.freight?.priceFreight;
      }
    }
    return value;
  }

  formateValueDiscount(): number {
    let value = 0;
    if (this.cart?.items) {
      this.cart.items.forEach((c) => {
        value += c.qt * ((c.details?.value * c.details?.discount) / 100);
      });
    }
    return value || 0;
  }

  formateValueFreight() {
    return this.freightSelected?.freight?.priceFreight || 0;
  }

  onClickProduct(item: CartItem) {
    let name = item.details.name.toLowerCase().replace(/ /g, '-');
    name = name.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    name = name.replace(/\---/gi, '-');
    this.sessionStorageService.set(
      StorageConstants.RM_ECOMMERCE_PAGE,
      'catalog'
    );
    const link = `${environment.linkCatalog}title=${name}&idProduct=${
      item.details.id
    }&category=${item.details.category.toLowerCase()}#sku`;
    window.open(link, '_blank');
  }

  onClickShowProducts() {
    this.sessionStorageService.set(
      StorageConstants.RM_ECOMMERCE_PAGE,
      'catalog'
    );
    window.location.href = `${environment.linkCatalog}category=coberturas_em_policarbonato#category`;
  }

  onKeyUpZipCode($event) {
    if ($event.keyCode === 13) {
      this.setZipCode($event.target.value);
    }
  }

  async setZipCode(zipCode) {
    this.zipCode = zipCode;
    if (zipCode?.length < 8) {
      this.quotes = null;
      await this.localStorageService.removeItem(
        StorageConstants.RM_ECOMMERCE_ZIP_CODE
      );
    }
  }
  async addressFound(address: Address) {
    if (address?.zipCode) {
      await this.localStorageService.set(
        StorageConstants.RM_ECOMMERCE_ZIP_CODE,
        this.zipCode
      );
      await this.calculateFreight(true);
    } else {
      this.toastService.error('CEP inválido!');
    }
  }

  async getAddress() {
    if (this?.zipCode) {
      const address: Address = await this.addressService.getAddress(
        this.zipCode
      );
      if (address?.zipCode) {
        await this.calculateFreight(true);
      } else {
        this.toastService.error('CEP inválido!');
      }
    } else {
      this.toastService.error('Digite um CEP válido para obter sua cotação!');
    }
  }

  async onClickBtcalculateFreight() {
    await this.calculateFreight(true);
  }

  async calculateFreight(showMgs) {
    this.quotes = null;
    this.freightError = null;
    if (this.zipCode) {
      this.loadingFreight = true;
      try {
        const calculate: CalculateShipping = new CalculateShipping();
        calculate.cep = this.zipCode;
        if (this.cart?.id > 0) {
          calculate.cartId = this.cart.id;
        } else {
          calculate.itens = this.cart.items.map((i) => {
            return new ItemShipping(i.qt, i.productId);
          });
        }

        if (calculate?.cartId || calculate?.itens?.length > 0) {
          const quotes: QuoteList =
            await this.freightService.calculateShippingCart(calculate);
          if (quotes && quotes.carriers.length > 0) {
            let daysOnlyOrder = 0;
            this.cart.items.forEach((i) => {
              if (i?.details?.daysOnlyOrder > daysOnlyOrder) {
                daysOnlyOrder = i.details.daysOnlyOrder;
              }
            });

            if (daysOnlyOrder > 0) {
              quotes.carriers.forEach((carrier) => {
                if (carrier.optionFreight === Freight.STORE_PICKUP) {
                  carrier.deliveryTime += daysOnlyOrder;
                }
              });
            }
            this.quotes = quotes;
          } else {
            const msg =
              'Para cotar esse frete com 6 metros, solicite via whatsapp!';
            this.toastService.error(msg);
            this.freightError = msg;
          }
        }
      } catch (err) {
        this.freightError = 'Erro ao consultar o frete!';
        console.error(err);
      } finally {
        this.loadingFreight = false;
      }
    } else {
      if (showMgs) {
        this.toastService.error('Digite um CEP válido para obter sua cotação!');
      }
    }
  }

  async setFreightSelected(carrier: Carrying) {
    this.freightSelected = new FreightSelected(carrier, this.quotes.tokenOffer);
  }

  getValueWithDiscount(product: Product) {
    if (product.discount > 0) {
      return product.value - (product.value * product.discount) / 100;
    } else {
      return product.value;
    }
  }

  async onClickBtCreateBudget() {
    if (!this.zipCode) {
      this.toastService.error('Digite um CEP válido para obter sua cotação!');
      return;
    }

    if (!this.validBudget()) {
      this.toastService.error('Preencha todos os campos!');
      return;
    }
    this.blockOperation.setBlockOperation('', true);
    try {
      this.budget.zipCode = this.zipCode;
      this.budget = await this.budgetService.save(this.budget, this.cart);
      this.blockOperation.setBlockOperation('', false);
      this.toastService.success('Orçamento criado com sucesso!');
      await wait(TWO_SECONDS_IN_MS);
      window.open(
        `${environment.linkCart}?idCart=${this.budget.hash}`,
        '_blank'
      );
      this.budget = null;
    } catch (err) {
      this.toastService.error(err?.message || 'Erro ao criar orçamento!');
      this.blockOperation.setBlockOperation('', false);
    }
  }

  validBudget() {
    if (!this.budget.email || !this.budget.name || !this.budget.phone) {
      return false;
    }
    return true;
  }

  onClickDelete() {
    this.confirmationService.confirm({
      message: 'Deseja realmente excluir esse carrinho?',
      header: 'Confirmação',
      accept: async () => {
        this.deleteCart();
      },
    });
  }

  async deleteCart() {
    this.blockOperation.setBlockOperation('', true);
    try {
      await this.cartService.delete(this.cart.id);
      this.blockOperation.setBlockOperation('', false);

      this.toastService.success('Carrinho excluído com sucesso!');
      this.cart = {};
    } catch (err) {
      this.toastService.error('Erro ao excluir o carrinho!');
      throw err;
    } finally {
      this.blockOperation.setBlockOperation('', false);
    }
  }

  haveProductDiscount(): boolean {
    let have = false;
    if (this.cart?.items) {
      this.cart.items.forEach((c) => {
        if (c.details?.discount > 0) {
          have = true;
        }
      });
    }
    return have;
  }

  selectIdProduct(product: Product) {
    window.location.href = this.getUrlProduct(product);
  }

  getUrlProduct(produto: Product) {
    let name = produto?.name?.toLowerCase().replace(/ /g, '-');
    name = name?.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    name = name?.replace(/\---/gi, '-');
    return `${environment.linkCatalog}title=${name}&idProduct=${
      produto?.id
    }&category=${produto?.category?.toLowerCase()}#sku`;
  }

  getValorSemDesconto(product: Product) {
    return `${product.value.toFixed(2).split('.').join(',')}`;
  }

  installmentFormatInterestFree(product: Product) {
    const value = this.getValueProduct(product);
    const valueFormat = formatCurrency(value, 'pt', 'R$');
    let installment =
      value / environment.minimumInstallment < 1
        ? 1
        : value / environment.minimumInstallment;
    installment = installment > 4 ? 4 : installment;
    let installmentFormat = Math.floor(installment);

    if (installmentFormat < 2) {
      return `${valueFormat} em ${installmentFormat}x de ${valueFormat} sem juros`;
    }

    const valueInstallmentFormat = formatCurrency(
      value / installmentFormat,
      'pt',
      'R$'
    );
    return `${valueFormat} em ${installmentFormat}x de ${valueInstallmentFormat} sem juros`;
  }

  getValueProduct(product: Product) {
    let value = product.value;
    if (product.discount > 0) {
      value = value - (value * product.discount) / 100;
    }
    return value;
  }

  getTotalValueProduct(product: Product) {
    let quantity = 0;
    const p = Math.floor(product.value / 5);
    quantity = p <= 1 ? p : 1;
    let value = product.value;
    if (product.discount > 0) {
      value = value - (value * product.discount) / 100;
    }

    value = value - (value * environment.cashDiscount) / 100;
    return value;
  }

  async getProductsRelated() {
    const filter: ProductFilter = new ProductFilter();
    filter.inactive = false;
    filter.ignoreMainVariation = true;
    filter.withDiscountOrFeatured = true;
    const pageRes: PageResponse = await this.productService.listV2(
      filter,
      true
    );
    if (pageRes?.totalElements > 0) {
      this.productsRelated = pageRes.content;
    }
  }
}
