export const HALF_SECOND_IN_MS = 500;
export const ONE_SECOND_IN_MS = 1000;
export const TWO_SECONDS_IN_MS = 2 * ONE_SECOND_IN_MS;
export const THREE_SECONDS_IN_MS = 3 * ONE_SECOND_IN_MS;
export const FIVE_SECONDS_IN_MS = 5 * ONE_SECOND_IN_MS;
export const EIGHT_SECONDS_IN_MS = 8 * ONE_SECOND_IN_MS;
export const TEN_SECONDS_IN_MS = 10 * ONE_SECOND_IN_MS;
export const TWENTY_SECONDS_IN_MS = 20 * ONE_SECOND_IN_MS;

export const ONE_MINUTE_IN_MS = 60 * ONE_SECOND_IN_MS;
export const FIVE_MINUTES_IN_MS = 5 * ONE_MINUTE_IN_MS;

export const ONE_HOUR_IN_MS = 60 * ONE_MINUTE_IN_MS;

export const ONE_DAY_IN_MS = 24 * ONE_HOUR_IN_MS;

export const wait = (ms: number = 0): Promise<any> => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};
