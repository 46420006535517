import ReportPrintInfo from './ReportPrintInfo';
import ReportUpdateInfo from './ReportUpdateInfo';

export default class ReportOperations {
  list?: Array<ReportPrintInfo>;
  update?: Array<ReportUpdateInfo>;

  fromJson(reportOperationsObj: any) {
    if (reportOperationsObj != null) {
      this.list = [];
      this.update = [];

      Object.keys(reportOperationsObj).forEach((key) => {
        const report = reportOperationsObj[key];
        if (report?.list?.length > 0) {
          let newItem: ReportPrintInfo;
          report.list.forEach((item) => {
            newItem = new ReportPrintInfo();
            newItem.fromJson(item);
            this.list.push(newItem);
          });
        }

        if (report?.update?.length > 0) {
          let newItem: ReportUpdateInfo;
          report.update.forEach((item) => {
            newItem = new ReportUpdateInfo();
            newItem.fromJson(item);
            this.update.push(newItem);
          });
        }
      });
    }
  }
}
