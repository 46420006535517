import AbstractInfoEnum from 'src/app/models/AbstractInfoEnum';
import { ArrayUtils } from 'src/util/array-utils';
import InfoCategory from '../InfoCategory';
import { SubCategoriesEnum } from '../InfoEnum';
import AttachmentProduct from '../attachment/AttachmentProduct';
import AttachmentProductLink from '../attachment/AttachmentProductLink';
import QuoteList from '../freight/QuoteList';
import GroupedSkusView from '../grouping/grouped-skus-view';
import InfoSubCategory from './info-sub-category';
import InformationSection from './information-section';
import ProductColorBalance from './product-color-balance';
import ProductHubDetails from './product-hub-details';
import Volume from './volume';

export default class Product {
  activeOnHub = false;
  actuationSide?: string;
  actuationSideDescription?: string;
  actuationType?: string;
  actuationTypeDescription?: string;
  aluminumWallThickness?: string;
  aluminumWallThicknessDescription?: string;
  amountFixation?: number;
  amountRafters?: number;
  attachmentsCreateOrUpdate?: Array<AttachmentProduct> = [];
  attachmentsDelete?: Array<number> = [];
  brand?: string;
  brandDescription?: string;
  canBeGeneratedWithStockZero = false;
  category?: string;
  categoryDescription?: string;
  capacity?: string;
  capacityDescription?: string;
  color?: string;
  colorDescription?: string;
  daysOnlyOrder?: number;
  description?: string;
  dimensions?: string;
  discount?: number;
  enabled = false;
  fabricComposition?: string;
  fabricCompositionDescription?: string;
  featuredBanner?: string;
  finish?: string;
  finishDescription?: string;
  groupedSkus?: Array<Product>;
  groupedSkusView?: GroupedSkusView;
  groupAttributeProduct?: string;
  groupMainProduct?: boolean;
  groupProductIdToShow?: number;
  gtin?: string;
  heightThickness?: string;
  heightThicknessDescription?: string;
  hubIntegrationCode?: string;
  id?: number;
  informationsSection?: Array<InformationSection>;
  lastSincronizedPluggtoHubDate?: string;
  lastSincronizedOmieDate?: string;
  lastSincronizedFreterapidoDate?: string;
  length?: string;
  lengthDescription?: string;
  mainPhotoS3Url?: string;
  mainVariation?: boolean;
  model?: string;
  modelDescription?: string;
  name?: string;
  nbm?: string;
  origin?: string;
  originDescription?: string;
  packingWidth?: number;
  packingLength?: number;
  packingWeight?: number;
  packingHeightThickness?: number;
  photos?: Array<AttachmentProductLink>;
  potency?: string;
  potencyDescription?: string;
  productPerSquareMeter = false;
  productType?: number;
  productHubDetails?: ProductHubDetails;
  productsColorBalance?: Array<ProductColorBalance>;
  productsIds?: Array<number>;
  qtdMaxPiecesPerVolume?: number;
  qtd = 1;
  quoteList?: QuoteList;
  stockBalance = 0;
  similarProduct: number;
  speed?: string;
  speedDescription?: string;
  subCategory?: string;
  subCategoryDescription?: string;
  synchronizedWithActiveHub = false;
  unitCommercialize?: string;
  unitCommercializeDescription?: string;
  validationMessages?: string[];
  value?: number;
  valueString?: string;
  videoUrl?: string;
  voltage?: string;
  voltageDescription?: string;
  volumes?: Array<Volume>;
  warrantyTime?: number;
  warrantyText?: string;
  weight?: string;
  width?: string;
  widthDescription?: string;

  static getAllDescription(categories: Array<InfoCategory>, product: Product) {
    const category: InfoCategory = categories.find(
      (item) => item.id === product?.category
    );
    product.categoryDescription = category?.description;

    const subCategory: InfoSubCategory = category?.subCategories?.find(
      (item) => item.id === product?.subCategory
    );
    product.subCategoryDescription = subCategory?.description;

    let info = subCategory?.heightThickness?.find(
      (item) => item.id === product?.heightThickness
    );
    if (info) {
      product.heightThicknessDescription = info?.description;
    }

    info = subCategory?.widths?.find((item) => item?.id === product?.width);
    if (info) {
      product.widthDescription = info?.description;
    }

    info = subCategory?.lengths?.find((item) => item?.id === product?.length);
    if (info) {
      product.lengthDescription = info?.description;
    }

    info = subCategory?.colors?.find((item) => item?.id === product?.color);
    if (info) {
      product.colorDescription = info?.description;
    }

    info = subCategory?.models?.find((item) => item?.id === product?.model);
    if (info) {
      product.modelDescription = info?.description;
    }

    if (subCategory?.finishes) {
      info = subCategory?.finishes?.find(
        (item) => item?.id === product?.finish
      );
      if (info) {
        product.finishDescription = info?.description;
      }
    }

    if (subCategory?.aluminumWallThickness) {
      info = subCategory?.aluminumWallThickness?.find(
        (item) => item.id === product?.aluminumWallThickness
      );
      if (info) {
        product.aluminumWallThicknessDescription = info?.description;
      }
    }

    if (subCategory?.brands) {
      info = subCategory?.brands?.find((item) => item?.id === product?.brand);
      if (info) {
        product.brandDescription = info?.description;
      }
    }

    if (subCategory?.unitsCommercialize) {
      info = subCategory?.unitsCommercialize?.find(
        (item) => item?.id === product?.unitCommercialize
      );
      if (info) {
        product.unitCommercializeDescription = info?.description;
      }
    }

    if (subCategory?.capabilities) {
      info = subCategory?.capabilities?.find(
        (item) => item.id === product?.capacity
      );
      if (info) {
        product.capacityDescription = info.description;
      }
    }

    if (subCategory?.speeds) {
      info = subCategory?.speeds?.find((item) => item.id === product.speed);
      if (info) {
        product.speedDescription = info.description;
      }
    }

    if (subCategory?.potencies) {
      info = subCategory?.potencies?.find(
        (item) => item.id === product.potency
      );
      if (info) {
        product.potencyDescription = info.description;
      }
    }

    if (subCategory?.voltages) {
      info = subCategory?.voltages?.find((item) => item.id === product.voltage);
      if (info) {
        product.voltageDescription = info.description;
      }
    }

    const heightThickness = product?.heightThicknessDescription
      ? product?.heightThicknessDescription
      : ' | ';
    const width = product?.widthDescription ? product?.widthDescription : ' | ';
    const length = product?.lengthDescription
      ? product.lengthDescription
      : ' | ';
    product.dimensions = '';
    if (
      product.heightThicknessDescription ||
      product.widthDescription ||
      product.lengthDescription
    ) {
      product.dimensions = heightThickness + ' - ' + width + ' x ' + length;
    }
  }

  static addVideoInSession(product: Product) {
    if (product.videoUrl) {
      const session = new InformationSection();
      session.id = 99;
      session.title = 'Vídeo';
      session.description = product.videoUrl;
      session.orderDisplay = product.informationsSection.length;
      product.informationsSection.push(session);
    }
  }

  fromJson?(product: any) {
    if (product != null) {
      this.amountFixation = product.amountFixation;
      this.amountRafters = product.amountRafters;
      this.id = product.id;
      this.productType = product.productType;
      this.name = product.name;
      this.description = product.description;
      this.category = product.category;
      this.categoryDescription = product.categoryDescription;
      this.subCategory = product.subCategory;
      this.subCategoryDescription = product.subCategoryDescription;
      this.color = product.color;
      this.colorDescription = product.colorDescription;
      this.finish = product.finish;
      this.aluminumWallThickness = product.aluminumWallThickness;
      this.aluminumWallThicknessDescription =
        product.aluminumWallThicknessDescription;
      this.width = product.width;
      this.widthDescription = product.widthDescription;
      this.length = product.length;
      this.lengthDescription = product.lengthDescription;
      this.weight = product.weight;
      this.brand = product.brand;
      this.brandDescription = product.brandDescription;
      this.qtdMaxPiecesPerVolume = product.qtdMaxPiecesPerVolume;
      this.stockBalance = product.stockBalance || 0;
      this.enabled = product.enabled;
      this.unitCommercialize = product.unitCommercialize;
      this.unitCommercializeDescription = product.unitCommercializeDescription;
      this.heightThickness = product.heightThickness;
      this.heightThicknessDescription = product.heightThicknessDescription;
      this.canBeGeneratedWithStockZero = product.canBeGeneratedWithStockZero;
      this.daysOnlyOrder = product.daysOnlyOrder;
      this.packingHeightThickness = product.packingHeightThickness;
      this.packingWeight = product.packingWeight;
      this.packingWidth = product.packingWidth;
      this.packingLength = product.packingLength;
      this.productHubDetails = Object.assign(
        new ProductHubDetails(),
        product.productHubDetails
      );
      this.nbm = product.nbm;
      this.origin = product.origin;
      this.originDescription = product.originDescription;
      this.model = product.model;
      this.modelDescription = product.modelDescription;
      this.mainPhotoS3Url = product.mainPhotoS3Url;
      this.mainVariation = product.mainVariation;
      this.videoUrl = product.videoUrl;
      this.warrantyTime = product.warrantyTime;
      this.warrantyText = product.warrantyText;
      this.discount = product.discount;
      this.featuredBanner = product.featuredBanner;
      this.value = product.value;
      this.synchronizedWithActiveHub = product.synchronizedWithActiveHub;
      this.lastSincronizedPluggtoHubDate =
        product.lastSincronizedPluggtoHubDate;
      this.lastSincronizedOmieDate = product.lastSincronizedOmieDate;
      this.lastSincronizedFreterapidoDate =
        product.lastSincronizedFreterapidoDate;
      this.gtin = product.gtin;
      this.activeOnHub = product.activeOnHub;
      this.similarProduct = product.similarProduct;
      this.capacity = product.capacity;
      this.potency = product.potency;
      this.speed = product.speed;
      this.voltage = product.voltage;
      this.actuationSide = product.actuationSide;
      this.actuationType = product.actuationType;
      this.fabricComposition = product.fabricComposition;
      this.groupMainProduct = product.groupMainProduct;
      this.groupProductIdToShow = product.groupProductIdToShow;
      this.groupAttributeProduct = product.groupAttributeProduct;
      this.hubIntegrationCode = product.hubIntegrationCode;
      this.productPerSquareMeter = product.productPerSquareMeter;

      if (product.photos != null) {
        let newPhoto: AttachmentProductLink;
        this.photos = [];
        product.photos.forEach((item) => {
          // DEV
          /*item.url = item.url.replace(
            'https://d20p8exwoll05u.cloudfront.net',
            'https://rm-ecommerce-homolog.s3.sa-east-1.amazonaws.com'
          );*/
          newPhoto = new AttachmentProductLink();
          newPhoto.fromJson(item);
          this.photos.push(newPhoto);
        });
        ArrayUtils.sort(this.photos, 'orderDisplay');
      }

      if (product.productsColorBalance != null) {
        let newProductColorBalance: ProductColorBalance;
        this.productsColorBalance = [];
        product.productsColorBalance.forEach((item) => {
          newProductColorBalance = new ProductColorBalance();
          newProductColorBalance.fromJson(item);
          this.productsColorBalance.push(newProductColorBalance);
        });
      }

      if (product.informationsSection != null) {
        let newSection: InformationSection;
        this.informationsSection = [];
        product.informationsSection.forEach((item) => {
          newSection = new InformationSection();
          newSection.fromJson(item);
          this.informationsSection.push(newSection);
        });
        ArrayUtils.sort(this.informationsSection, 'orderDisplay');
      }

      if (product.volumes != null) {
        let newVolumes: Volume;
        this.volumes = [];
        product.volumes.forEach((item) => {
          newVolumes = new Volume();
          newVolumes.fromJson(item);
          this.volumes.push(newVolumes);
        });
        ArrayUtils.sort(this.informationsSection, 'orderDisplay');
      }

      if (product.groupedSkus != null) {
        let newP: Product;
        this.groupedSkus = [];
        product.groupedSkus.forEach((item) => {
          newP = new Product();
          newP.fromJson(item);
          newP.color = newP?.color?.toLowerCase();
          this.groupedSkus.push(newP);
        });

        this.groupedSkusView = new GroupedSkusView();
        this.groupedSkusView.mainSKU = this.groupedSkus
          .filter((p) => p.groupMainProduct)
          .slice()
          .shift();
        this.groupedSkusView.childrenSKUs = this.groupedSkus.filter(
          (p) => !p.groupMainProduct
        );
      }
    }
  }

  getImage?(order: number): string {
    if (this.photos && this.photos.length > 0) {
      return this.photos
        .filter((img) => img.orderDisplay === order)
        .slice()
        .shift().url;
    } else {
      return '';
    }
  }

  isPersiana() {
    return (
      this.subCategory === SubCategoriesEnum.DOUBLE_VISION ||
      this.subCategory === SubCategoriesEnum.ROMANA ||
      this.subCategory === SubCategoriesEnum.ROLO
    );
  }

  isPersianaRomana() {
    return this.subCategory === SubCategoriesEnum.ROMANA;
  }

  fromExcel?(
    categories: Array<InfoCategory>,
    origins: Array<AbstractInfoEnum>,
    line: number,
    productExcel: any[]
  ) {
    this.validationMessages = [];
    this.enabled = true;

    let category: InfoCategory;
    if (productExcel[1]) {
      category = categories.find(
        (item) =>
          item?.description?.toUpperCase() === productExcel[1]?.toUpperCase()
      );

      if (category) {
        this.category = category?.id;
      } else {
        this.validationMessages.push(
          `Linha: ${line} - O campo CATEGORIA está com valor inválido!`
        );
      }
    } else {
      this.validationMessages.push(
        `Linha: ${line} - O campo CATEGORIA está em branco!`
      );
    }

    let subCategory: InfoSubCategory;
    if (productExcel[2]) {
      subCategory = category?.subCategories?.find(
        (item) =>
          item?.description?.toUpperCase() === productExcel[2]?.toUpperCase()
      );
      if (subCategory) {
        this.subCategory = subCategory?.id;
      } else {
        this.validationMessages.push(
          `Linha: ${line} - O campo SUBCATEGORIA está com valor inválido!`
        );
      }
    } else {
      this.validationMessages.push(
        `Linha: ${line} - O campo SUBCATEGORIA está em branco!`
      );
    }

    if (productExcel[0]) {
      this.name = productExcel[0];
    } else {
      this.validationMessages.push(
        `Linha: ${line} - O campo NOME está em branco!`
      );
    }

    if (productExcel[3]) {
      this.unitCommercialize = subCategory?.unitsCommercialize.find(
        (item) =>
          item?.description?.toLowerCase() === productExcel[3]?.toLowerCase()
      )?.id;

      if (!this.unitCommercialize) {
        this.validationMessages.push(
          `Linha: ${line} - O campo UNIDADE COMERCIALIZADORA está com valor inválido!`
        );
      }
    } else {
      this.validationMessages.push(
        `Linha: ${line} - O campo UNIDADE COMERCIALIZADORA está em branco!`
      );
    }

    if (productExcel[4]) {
      this.model = subCategory?.models.find(
        (item) =>
          item?.description?.toLowerCase() === productExcel[4]?.toLowerCase()
      )?.id;
      if (!this.model) {
        this.validationMessages.push(
          `Linha: ${line} - O campo MODELO está com valor inválido!`
        );
      }
    } else {
      this.validationMessages.push(
        `Linha: ${line} - O campo MODELO está em branco!`
      );
    }

    if (productExcel[5]) {
      this.value = productExcel[5]?.toFixed(2);
    } else {
      this.validationMessages.push(
        `Linha: ${line} - O campo VALOR está em branco!`
      );
    }

    if (productExcel[6]) {
      this.stockBalance = productExcel[6];
    } else {
      this.validationMessages.push(
        `Linha: ${line} - O campo ESTOQUE está em branco!`
      );
    }

    if (productExcel[7]) {
      this.canBeGeneratedWithStockZero =
        productExcel[7]?.toLowerCase() === 'sim' ? true : false;
    } else {
      this.validationMessages.push(
        `Linha: ${line} - O campo PODE VENDER SEM ESTOQUE está em branco!`
      );
    }

    if (productExcel[8]) {
      this.daysOnlyOrder = productExcel[8];
    } else {
      this.validationMessages.push(
        `Linha: ${line} - O campo PRAZO DE ENCOMENDA está em branco!`
      );
    }

    if (productExcel[9]) {
      this.heightThickness = subCategory?.heightThickness.find(
        (item) => item.description === productExcel[9]
      )?.id;

      if (!this.heightThickness) {
        this.validationMessages.push(
          `Linha: ${line} - O campo ALTURA está com valor inválido!`
        );
      }
    } else {
      this.validationMessages.push(
        `Linha: ${line} - O campo ALTURA está em branco!`
      );
    }

    if (productExcel[10]) {
      this.width = subCategory?.widths.find(
        (item) => item.description === productExcel[10]
      )?.id;
      if (!this.width) {
        this.validationMessages.push(
          `Linha: ${line} - O campo LARGURA está com valor inválido!`
        );
      }
    } else {
      this.validationMessages.push(
        `Linha: ${line} - O campo LARGURA está em branco!`
      );
    }

    if (productExcel[11]) {
      this.length = subCategory?.lengths.find(
        (item) => item.description === productExcel[11]
      )?.id;

      if (!this.length) {
        this.validationMessages.push(
          `Linha: ${line} - O campo COMPRIMENTO está com valor inválido!`
        );
      }
    } else {
      this.validationMessages.push(
        `Linha: ${line} - O campo COMPRIMENTO está em branco!`
      );
    }

    if (productExcel[12]) {
      this.color = subCategory?.colors.find(
        (item) =>
          item?.description?.toLowerCase() === productExcel[12]?.toLowerCase()
      )?.id;

      if (!this.color) {
        this.validationMessages.push(
          `Linha: ${line} - O campo COR está com valor inválido!`
        );
      }
    } else {
      this.validationMessages.push(
        `Linha: ${line} - O campo COR está em branco!`
      );
    }

    if (productExcel[13]) {
      this.finish = subCategory?.finishes.find(
        (item) =>
          item?.description?.toLowerCase() === productExcel[13]?.toLowerCase()
      )?.id;

      if (!this.finish) {
        this.validationMessages.push(
          `Linha: ${line} - O campo ACABAMENTO está com valor inválido!`
        );
      }
    } else {
      this.validationMessages.push(
        `Linha: ${line} - O campo ACABAMENTO está em branco!`
      );
    }

    if (productExcel[14]) {
      this.weight = productExcel[14]?.toFixed(2);
    } else {
      this.validationMessages.push(
        `Linha: ${line} - O campo PESO está em branco!`
      );
    }

    if (productExcel[15]) {
      this.brand = subCategory?.brands.find(
        (item) =>
          item.description?.toLowerCase() === productExcel[15]?.toLowerCase()
      )?.id;

      if (!this.brand) {
        this.validationMessages.push(
          `Linha: ${line} - O campo MARCA está com valor inválido!`
        );
      }
    } else {
      this.validationMessages.push(
        `Linha: ${line} - O campo MARCA está em branco!`
      );
    }

    if (productExcel[16]) {
      this.fabricComposition = subCategory?.fabricCompositions.find(
        (item) =>
          item.description?.toLowerCase() === productExcel[16]?.toLowerCase()
      ).id;

      if (!this.fabricComposition) {
        this.validationMessages.push(
          `Linha: ${line} - O campo COMPOSIÇÃO DO TECIDO está com valor inválido!`
        );
      }
    } else {
      this.validationMessages.push(
        `Linha: ${line} - O campo COMPOSIÇÃO DO TECIDO está em branco!`
      );
    }

    if (productExcel[17]) {
      this.actuationType = subCategory?.actuationTypes.find(
        (item) =>
          item.description?.toLowerCase() === productExcel[17]?.toLowerCase()
      )?.id;

      if (!this.actuationType) {
        this.validationMessages.push(
          `Linha: ${line} - O campo TIPO DE ACIONAMENTO está com valor inválido!`
        );
      }
    } else {
      this.validationMessages.push(
        `Linha: ${line} - O campo TIPO DE ACIONAMENTO está em branco!`
      );
    }

    if (productExcel[18]) {
      this.actuationSide = subCategory?.actuationSides.find(
        (item) =>
          item.description?.toLowerCase() === productExcel[18]?.toLowerCase()
      )?.id;

      if (!this.actuationSide) {
        this.validationMessages.push(
          `Linha: ${line} - O campo LADO DE ACIONAMENTO está com valor inválido!`
        );
      }
    } else {
      this.validationMessages.push(
        `Linha: ${line} - O campo LADO DO ACIONAMENTO está em branco!`
      );
    }

    if (productExcel[19]) {
      this.packingWidth = productExcel[19];
    } else {
      this.validationMessages.push(
        `Linha: ${line} - O campo LARGURA DA EMBALAGEM está em branco!`
      );
    }

    if (productExcel[20]) {
      this.packingLength = productExcel[20];
    } else {
      this.validationMessages.push(
        `Linha: ${line} - O campo COMPRIMENTO DA EMBALAGEM está em branco!`
      );
    }

    if (productExcel[21]) {
      this.packingHeightThickness = productExcel[21];
    } else {
      this.validationMessages.push(
        `Linha: ${line} - O campo ALTURA DA EMBALAGEM está em branco!`
      );
    }

    if (productExcel[22]) {
      this.packingWeight = productExcel[22];
    } else {
      this.validationMessages.push(
        `Linha: ${line} - O campo PESO DA EMBALAGEM está em branco!`
      );
    }

    if (productExcel[23]) {
      this.qtdMaxPiecesPerVolume = productExcel[23];
    } else {
      this.validationMessages.push(
        `Linha: ${line} - O campo MÁXIMO DE PEÇAS está em branco!`
      );
    }

    if (productExcel[24]) {
      this.origin = origins.find(
        (item) =>
          item?.description?.toLowerCase() === productExcel[24]?.toLowerCase()
      )?.id;

      if (!this.origin) {
        this.validationMessages.push(
          `Linha: ${line} - O campo ORIGEM está com valor inválido!`
        );
      }
    } else {
      this.validationMessages.push(
        `Linha: ${line} - O campo ORIGEM está em branco!`
      );
    }

    if (productExcel[25]) {
      this.nbm = productExcel[25];
    } else {
      this.validationMessages.push(
        `Linha: ${line} - O campo NCM (NBM) está em branco!`
      );
    }

    if (productExcel[26]) {
      this.warrantyTime = productExcel[26];
    } else {
      this.validationMessages.push(
        `Linha: ${line} - O campo TEMPO DE GARANTIA está em branco!`
      );
    }

    if (productExcel[27] || productExcel[27]?.length < 250) {
      this.warrantyText = productExcel[27];
    } else {
      this.validationMessages.push(
        `Linha: ${line} - O campo TEXTO DA GARANTIA está em branco ou é maior que 250 caracteres!`
      );
    }

    if (productExcel[28]) {
      this.videoUrl = productExcel[28];
    } else {
      this.validationMessages.push(
        `Linha: ${line} - O campo URL DO VÍDEO está em branco!`
      );
    }

    //Section
    this.informationsSection = [];
    let newSc: InformationSection = new InformationSection();
    newSc.title = 'Sobre';
    newSc.orderDisplay = 1;
    newSc.description = productExcel[29];
    this.informationsSection.push(newSc);

    newSc = new InformationSection();
    newSc.title = 'Aplicações';
    newSc.orderDisplay = 2;
    newSc.description = productExcel[30];
    this.informationsSection.push(newSc);

    newSc = new InformationSection();
    newSc.title = 'Vantagens';
    newSc.orderDisplay = 3;
    newSc.description = productExcel[31];
    this.informationsSection.push(newSc);

    newSc = new InformationSection();
    newSc.title = 'Observações';
    newSc.orderDisplay = 4;
    newSc.description = productExcel[32];
    this.informationsSection.push(newSc);

    newSc = new InformationSection();
    newSc.title = 'Limpeza';
    newSc.orderDisplay = 5;
    newSc.description = productExcel[33];
    this.informationsSection.push(newSc);

    newSc = new InformationSection();
    newSc.title = 'Links Úteis';
    newSc.orderDisplay = 6;
    newSc.description = productExcel[34];
    this.informationsSection.push(newSc);

    //Images
    this.photos = [];
    if (productExcel[35]) {
      this.photos.push({
        urlAPI: productExcel[35],
        url: productExcel[35],
        orderDisplay: 1,
      });
    }
    if (productExcel[36]) {
      this.photos.push({
        urlAPI: productExcel[36],
        url: productExcel[36],
        orderDisplay: 2,
      });
    }
    if (productExcel[37]) {
      this.photos.push({
        urlAPI: productExcel[37],
        url: productExcel[37],
        orderDisplay: 3,
      });
    }
    if (productExcel[38]) {
      this.photos.push({
        urlAPI: productExcel[38],
        url: productExcel[38],
        orderDisplay: 4,
      });
    }
    if (productExcel[39]) {
      this.photos.push({
        urlAPI: productExcel[39],
        url: productExcel[39],
        orderDisplay: 5,
      });
    }
  }
}
