import { Component, OnInit } from '@angular/core';
import Whatsapp from 'src/app/models/whatsapp/Whatsapp';
import WhatsappFilter from 'src/app/models/whatsapp/WhatsappFilter';
import { WhatsappService } from 'src/services/whatsapp.service';
import { BlockOperation } from 'src/util/block-operation';
import { ToastService } from './../../../services/toast/toast.service';

@Component({
  selector: 'app-whatsapp',
  templateUrl: './whatsapp.component.html',
  styleUrls: ['./whatsapp.component.scss'],
})
export class WhatsappComponent implements OnInit {
  public blockOperation = new BlockOperation();
  public whatsapps: Array<Whatsapp> = [];
  public filter: WhatsappFilter = new WhatsappFilter();

  constructor(
    private toastService: ToastService,
    private whatsappService: WhatsappService
  ) {}

  async ngOnInit() {
    this.list();
  }

  onClick() {
    this.list();
  }

  async list() {
    this.filter.direction = 'DESC';
    this.filter.order = ['id'];
    this.blockOperation.setBlockOperation('', true);
    try {
      this.whatsapps = await this.whatsappService.list(this.filter);
    } catch (err) {
      console.error(err);
      this.toastService.error('Ocorreu um erro ao buscar Contatos!');
    } finally {
      this.blockOperation.setBlockOperation('', false);
    }
  }
}
