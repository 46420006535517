export const environment = {
  production: true,
  clientId: 'rm-ecommerce',
  clientSecret: 'rmpoliauth123',
  linkCatalog: '/produtos?',
  linkCart: '/carrinho',
  maxInstallmentNoInterest: 12,
  cashDiscount: 5,
  minimumInstallment: 5,
  //authURL: 'https://homolog.rmpolicarbonatos.com.br/ecommerce/oauth/token',
  //apiUrl: 'https://homolog.rmpolicarbonatos.com.br/ecommerce/',
  authURL: 'https://api.rmpolicarbonatos.com.br/ecommerce/oauth/token',
  apiUrl: 'https://api.rmpolicarbonatos.com.br/ecommerce/',
  //authURL: 'http://localhost:8080/ecommerce/oauth/token',
  //apiUrl: 'http://localhost:8080/ecommerce/',
};
