import { Component, EventEmitter, Input, Output } from '@angular/core';
import InformationSection from 'src/app/models/product/information-section';

let dragging;

@Component({
  selector: 'app-input-section',
  template: `
    <div class="app-input-section">
      <div
        *ngFor="let section of sections"
        class="app-input-section-item"
        (drag)="drag($event, section)"
        (drop)="drop($event, section)"
        (dragenter)="dragEnter($event)"
        (dragover)="dragOver($event)"
        (dragleave)="dragLeave($event)"
        draggable="true"
      >
        <h4>{{ section.title }}</h4>
        <div class="section-controls">
          <a href="javascript:;" title="Editar seção" (click)="delete(section)">
            <img src="assets/img/delete.svg" width="18" />
          </a>
          <a
            href="javascript:;"
            title="Editar seção"
            (click)="editing = section"
          >
            <img src="assets/img/edit.svg" width="18" />
          </a>
        </div>
      </div>

      <div class="app-input-section-controls">
        <a
          href="javascript:;"
          class="text-with-icon"
          title="Adicionar seção"
          (click)="add()"
        >
          <img src="assets/img/add.svg" width="18" /> Seção
        </a>
      </div>

      <app-modal *ngIf="editing" (closed)="editing = null">
        <h3>Nova seção</h3>
        <input
          type="text"
          [value]="editing.title || ''"
          (change)="editing.title = $event.target.value"
          placeholder="Título"
          autofocus
        />
        <p-editor
          [(ngModel)]="editing.description"
          [style]="{ height: '50vh' }"
        ></p-editor>
        <div class="text-right actions">
          <button class="rm-button" (click)="saveEditing()">Salvar</button>
        </div>
      </app-modal>
    </div>
  `,
})
export class InputSectionComponent {
  constructor() {
    document.addEventListener('mouseup', () => (dragging = null));
  }

  @Input()
  public sections: Array<InformationSection> = [];

  @Output()
  public changed: EventEmitter<Array<InformationSection>> = new EventEmitter<
    Array<InformationSection>
  >();

  public editing: InformationSection;

  add() {
    this.editing = new InformationSection();
  }

  saveEditing() {
    if (this.sections.indexOf(this.editing) === -1) {
      this.editing.orderDisplay = this.sections.length + 1;
      this.sections.push(this.editing);
    }
    this.editing = null;
  }

  change($event) {
    const text = $event;
    this.editing.description = text;
  }

  delete(newSection: InformationSection) {
    this.sections.splice(this.sections.indexOf(newSection), 1);
  }

  dragOver(event) {
    event.preventDefault();
  }

  dragLeave(event) {
    event.preventDefault();
  }

  drag(event, section: InformationSection) {
    dragging = this.sections.indexOf(section);
  }

  dragEnter(event) {
    event.preventDefault();
  }

  drop(event, section: InformationSection) {
    event.preventDefault();
    if (!`${dragging}`) {
      return;
    }
    const toIndex = this.sections.indexOf(section);
    const fromIndex = dragging;
    const toSection = this.sections[toIndex];
    toSection.orderDisplay = fromIndex + 1;
    const fromSection = this.sections[fromIndex];
    fromSection.orderDisplay = toIndex + 1;
    this.sections[toIndex] = fromSection;
    this.sections[fromIndex] = toSection;
  }
}
