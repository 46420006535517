import { Component, OnInit } from '@angular/core';
import Address from 'src/app/models/user/Address';
import User from 'src/app/models/user/User';
import ValidationAddress from 'src/app/models/validation/ValidationAddress';
import { UserService } from 'src/services/user.service';
import { BlockOperation } from 'src/util/block-operation';
import { ToastService } from './../../../../services/toast/toast.service';

@Component({
  selector: 'app-home-user-address',
  template: `
    <div class="rm-ecommerce-home-menu-user-address">
      <app-block-loader [blockOperation]="blockOperation"></app-block-loader>
      <h1>Meu Endereço</h1>

      <div class="rm-ecommerce-home-address">
        <app-address
          [address]="this.addressPrincipal"
          [validation]="this.validationPrincipal"
        ></app-address>
      </div>
      <div class="rm-ecommerce-save-bottom">
        <button class="rm-button" (click)="update()">Salvar</button>
      </div>
    </div>
  `,
})
export class UserAddressPageComponent implements OnInit {
  public addressPrincipal: Address;
  public addressBilling: Address;
  public blockOperation = new BlockOperation();
  public validationPrincipal: ValidationAddress = new ValidationAddress();
  public validationBilling: ValidationAddress = new ValidationAddress();
  public user: User;

  constructor(
    private toastService: ToastService,
    public userService: UserService
  ) {}

  async ngOnInit() {
    this.user = await this.userService.getUserStorage();
    this.addressPrincipal = this.user?.getAddress(true);
  }

  async update() {
    if (!Address.validAdress(this.addressPrincipal)) {
      this.toastService.error('Preencha os campos obrigatórios!');
      return;
    }

    if (this.user?.isAdmin()) {
      if (
        Address.filledAdress(this.addressBilling) &&
        !Address.validAdress(this.addressBilling)
      ) {
        this.toastService.error('Preencha os campos obrigatórios!');
        return;
      }
    }
    this.blockOperation.setBlockOperation('', true);
    try {
      this.user.address = [];
      this.user.address.push(this.addressPrincipal);
      if (this.user.isAdmin() && Address.filledAdress(this.addressBilling)) {
        if (Address.validAdress(this.addressBilling)) {
          this.user.address.push(this.addressBilling);
        } else {
          this.toastService.error('Preencha os campos obrigatórios!');
          return;
        }
      }
      await this.userService.update(this.user);
      this.toastService.success('Endereço alterado com sucesso!');
    } catch (err) {
      console.error(err);
      this.toastService.error('Erro ao alterar endereço!');
    } finally {
      this.blockOperation.setBlockOperation('', false);
    }
  }
}
