import { Component, EventEmitter, Input, Output } from '@angular/core';

export class ProductOrder {
  id: number;
  description: string;
  attribute: string;
  orderBy: string;
}

@Component({
  selector: 'app-product-order',
  templateUrl: './product-order.component.html',
})
export class ProductOrderComponent {
  public filters: Array<ProductOrder>;
  public filterSelected: ProductOrder;

  @Output()
  public changed: EventEmitter<ProductOrder> = new EventEmitter<ProductOrder>();

  @Input()
  public set filterSelectedId(orderBy: string) {
    this.filters = [
      {
        id: 1,
        description: 'Menor Preço',
        attribute: 'value',
        orderBy: 'ASC',
      },
      {
        id: 2,
        description: 'Maior Preço',
        attribute: 'value',
        orderBy: 'DESC',
      },
    ];
    this.filterSelected = orderBy === 'ASC' ? this.filters[0] : this.filters[1];
  }

  optionSelected(event) {
    this.filterSelected = new ProductOrder();
    if (event.target.value !== 'Selecione') {
      this.filterSelected = this.filters.find(
        (f) => f.id.toString() === event.target.value
      );
    }
    this.changed.emit(this.filterSelected);
  }
}
