export default [
  { headerName: 'Id', field: 'id', sortable: true, maxWidth: 60 },
  {
    headerName: 'Nome',
    valueGetter(params) {
      return params.data?.fullName();
    },
    sortable: true,
  },
  { headerName: 'Criado', field: 'createdAt', sortable: false, maxWidth: 150 },
  { headerName: 'CPF', field: 'document', sortable: true, maxWidth: 120 },
  { headerName: 'Telefone', field: 'phone', sortable: true, maxWidth: 120 },
  { headerName: 'Email', field: 'email', sortable: true, maxWidth: 230 },
  { headerName: 'Tipo', field: 'type', sortable: true, maxWidth: 80 },
];
