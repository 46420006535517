import Address from '../user/Address';

export default class ValidationAddress {
  public errorAddress = false;
  public errorCity = false;
  public errorDistrict = false;
  public errorNumberAddress = false;
  public errorUf = false;
  public errorZipCode = false;
  public errorComplement = false;

  constructor() {}

  validAll(valid: boolean) {
    this.errorAddress = valid;
    this.errorCity = valid;
    this.errorDistrict = valid;
    this.errorComplement = valid;
    this.errorNumberAddress = valid;
    this.errorUf = valid;
    this.errorZipCode = valid;
  }

  valid(address: Address) {
    let valid = true;
    if (!address.zipCode) {
      this.validAll(true);
      valid = false;
    } else {
      this.validAll(false);
    }
    if (!address.numberAddress) {
      this.errorNumberAddress = true;
      valid = false;
    } else {
      this.errorNumberAddress = false;
    }
    if (!address.complement) {
      this.errorComplement = true;
      valid = false;
    } else {
      this.errorComplement = false;
    }
    return valid;
  }
}
