import {
  Component,
  HostListener,
  OnInit,
  Pipe,
  PipeTransform,
} from '@angular/core';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import AbstractInfoEnum from 'src/app/models/AbstractInfoEnum';
import InfoCategory from 'src/app/models/InfoCategory';
import {
  CategoriesEnum,
  FilterSubCategoriesChapasEnum,
  FilterSubCategoriesComunicacaoEnum,
  FilterSubCategoriesKitsEnum,
  FilterSubCategoriesMotoresEnum,
  FilterSubCategoriesPerfisEnum,
  FilterSubCategoriesToldosEnum,
  GroupingAttributesEnum,
} from 'src/app/models/InfoEnum';
import AttachmentProductLink from 'src/app/models/attachment/AttachmentProductLink';
import { CartItem } from 'src/app/models/cart/cart';
import CalculateShipping from 'src/app/models/freight/CalculateShipping';
import QuoteList from 'src/app/models/freight/QuoteList';
import InfoSubCategory from 'src/app/models/product/info-sub-category';
import InformationSection from 'src/app/models/product/information-section';
import Product from 'src/app/models/product/product';
import ProductColorBalance from 'src/app/models/product/product-color-balance';
import ProductFilter from 'src/app/models/product/product-filter';
import { environment } from 'src/environments/environment';
import { AppInfoService } from 'src/services/app-info.service';
import { CartService } from 'src/services/cart.service';
import { StorageConstants } from 'src/services/constants/storage-constants';
import { FreightService } from 'src/services/freight.service';
import { ProductService } from 'src/services/product.service';
import { SessionStorageService } from 'src/services/session-storage.service';
import { BlockOperation } from 'src/util/block-operation';
import { AddressService } from './../../../services/address.service';
import { LocalStorageService } from './../../../services/local-storage.service';
import { PaymentService } from './../../../services/payment.service';
import {
  FilterSubCategoriesAcessoriosEnum,
  SubCategoriesEnum,
} from './../../models/InfoEnum';
import { PageResponse } from './../../models/page/page-response';
import { ProductOrder } from './../product-filter/product-order.component';

import { formatCurrency } from '@angular/common';
import Freight from 'src/app/models/freight/Freight';
import Address from 'src/app/models/user/Address';
import { ToastService } from 'src/services/toast/toast.service';
import { HALF_SECOND_IN_MS, wait } from 'src/util/wait';
import SwiperCore, { Autoplay, Pagination } from 'swiper';

SwiperCore.use([Autoplay, Pagination]);

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
@Component({
  selector: 'app-catalog',
  templateUrl: './catalog.component.html',
})
export class CatalogComponent implements OnInit {
  public readonly SUB_PAGE_GRID = 'grid';
  public readonly SUB_PAGE_PRODUCT = 'product';
  public readonly SUB_PAGE_LOADING = 'loading';
  public readonly INSTALLMENT = 'installments';

  public readonly GROUP_BY_COLOR = GroupingAttributesEnum.GROUP_BY_COLOR;

  public activePhoto: AttachmentProductLink;
  public blockOperation = new BlockOperation();

  public categories: Array<InfoCategory> = [];
  public errorFreight = '';
  public infoSectionSelected: InformationSection;
  public installments = [];
  public loadingFreight = false;
  public loadingMain = true;
  public menuOpened = false;

  public openedSubCategory = false;
  public openedColor = false;
  public openedWidth = false;
  public openedLength = false;
  public openedFinish = false;
  public openedHeightThickness = false;
  public openedAluminumWallThickness = false;
  public openedBrand = false;
  public openedModel = false;
  public openedCapabilities = false;
  public openedPotencies = false;
  public openedSpeeds = false;
  public openedVoltages = false;

  public page: number = 0;
  public pageResponse: PageResponse;

  public products: Array<Product> = [];
  public productFilter: ProductFilter = new ProductFilter();
  public productOrderCategoryId = 1;
  public productPackage: string;
  public productsRelated: Product[] = [];
  public productSelected: Product;
  public productShowClass: string;

  public quotes: QuoteList;
  public responsiveOptions: any[] = [];

  public selectedCategory: InfoCategory = new InfoCategory();
  public selectedSubCategory: InfoSubCategory = new InfoSubCategory();
  public selectedColor: AbstractInfoEnum;
  public selectedWidth: AbstractInfoEnum;
  public selectedLength: AbstractInfoEnum;
  public selectedFinish: AbstractInfoEnum;
  public selectedHeightThickness: AbstractInfoEnum;
  public selectedAluminumWallThickness: AbstractInfoEnum;
  public selectedBrand: AbstractInfoEnum;
  public selectedModel: AbstractInfoEnum;
  public selectedCapacity: AbstractInfoEnum;
  public selectedPotency: AbstractInfoEnum;
  public selectedSpeed: AbstractInfoEnum;
  public selectedVoltage: AbstractInfoEnum;

  public showOptionsPayments = false;
  public showSimilarProduct = false;
  public similarProduct: Product;
  public subpage = this.SUB_PAGE_GRID;

  public zipCode = null;

  constructor(
    private addressService: AddressService,
    private appInfo: AppInfoService,
    private cartService: CartService,
    private freightService: FreightService,
    private localStorageService: LocalStorageService,
    private paymentService: PaymentService,
    private productService: ProductService,
    public sanitizer: DomSanitizer,
    private sessionStorageService: SessionStorageService,
    private toastService: ToastService,
    private titleService: Title,
    private metaTagService: Meta
  ) {}

  @HostListener('window:unload', ['$event'])
  unloadHandler(event) {
    event.preventDefault();
    if (!window?._RMKeepFilterSession) {
      this.removeSessionFilter();
    }
    return false;
  }

  async ngOnInit() {
    await this.getCategories();
  }

  async getCategories() {
    this.blockOperation.setBlockOperation('', true);
    try {
      this.zipCode = await this.localStorageService.get(
        StorageConstants.RM_ECOMMERCE_ZIP_CODE
      );
      this.categories = await this.appInfo.getCategoriesWithProductActive();
      const category = await this.sessionStorageService.get(
        StorageConstants.RM_ECOMMERCE_CATEGORY
      );
      const subCategory = await this.sessionStorageService.get(
        StorageConstants.RM_ECOMMERCE_SUB_CATEGORY
      );
      if (subCategory) {
        this.categories.forEach((c) => {
          const sub = c.subCategories.find(
            (s) => s.id === subCategory?.toUpperCase()
          );
          if (sub) {
            this.selectedCategory = c;
            this.selectedSubCategory = sub;
            return;
          }
        });
      } else {
        if (category && category !== CategoriesEnum.PRODUCTS_ON_OFFER) {
          this.selectedCategory = this.categories.find(
            (c) => c.id === category?.toUpperCase()
          );
          const prop = 'category';
          delete sessionStorage[prop];
        } else {
          this.selectedCategory = this.categories[0];
        }
      }
      let querySearch = await this.sessionStorageService.get(
        StorageConstants.RM_ECOMMERCE_QUERY_SEARCH
      );
      //querySearch = '254';
      this.productFilter = new ProductFilter();

      if (
        subCategory &&
        this.isSubCategoryPersianas(subCategory.toUpperCase())
      ) {
        this.selectedModel = this.selectedSubCategory.models[0];
        this.productFilter.model = this.selectedModel.id;
        this.openedModel = true;
      }

      if (querySearch) {
        this.productFilter.querySearch = querySearch;
        this.addSessionFilter(this.productFilter);
        this.selectedCategory = new InfoCategory();
      } else if (category === CategoriesEnum.PRODUCTS_ON_OFFER) {
        this.productFilter.withDiscountOrFeatured = true;
        this.addSessionFilter(this.productFilter);
        this.selectedCategory = new InfoCategory();
      } else {
        if (this.selectedCategory?.id) {
          this.productFilter.categories = [this.selectedCategory.id];
        }

        if (this.selectedSubCategory.id) {
          this.productFilter.subCategories = [this.selectedSubCategory?.id];
        }
        this.openedSubCategory = true;
      }

      const idProduct = await this.sessionStorageService.get(
        StorageConstants.RM_ECOMMERCE_ID_PRODUCT
      );
      if (idProduct) {
        this.configProducts(idProduct);
      } else {
        this.setMetaTags();
        this.loadProducts();
      }
    } catch (err) {
      console.error(err);
    } finally {
      await this.sessionStorageService.removeItem(
        StorageConstants.RM_ECOMMERCE_CATEGORY
      );
      await this.sessionStorageService.removeItem(
        StorageConstants.RM_ECOMMERCE_SUB_CATEGORY
      );
      await this.sessionStorageService.removeItem(
        StorageConstants.RM_ECOMMERCE_QUERY_SEARCH
      );
    }
  }

  async loadProducts() {
    this.blockOperation.setBlockOperation('', true);
    this.loadingMain = true;
    const filterSession: ProductFilter = await this.getSessionFilter();
    if (filterSession) {
      this.productFilter = Object.assign(new ProductFilter(), filterSession);
    }
    try {
      if (!this.productFilter.direction) {
        this.productFilter.direction = 'ASC';
      }

      this.productOrderCategoryId = 1;
      this.productFilter.order = ['value'];
      this.productFilter.page = this.page;
      this.productFilter.perPage = 24;
      this.productFilter.inactive = false;
      this.productFilter.ignoreMainVariation = true;
      this.products = [];
      this.pageResponse = await this.productService.listV2(
        this.productFilter,
        true
      );

      if (this.pageResponse?.totalElements > 0) {
        this.products = this.pageResponse.content;
        this.products = this.products.filter((p) => this.showProduct(p));
        this.products.forEach((p) => {
          Product.getAllDescription(this.categories, p);
          Product.addVideoInSession(p);
        });
        await this.setProductOrderCategoryId();
        await this.configProducts(null);
        if (
          filterSession?.categories &&
          filterSession?.categories[0] === this.productFilter?.categories[0]
        ) {
          this.productFilter = Object.assign(
            new ProductFilter(),
            filterSession
          );
          await wait(HALF_SECOND_IN_MS);
          await this.setFilterOnMenu();
        }
        this.getProductPackage();
      }
    } catch (err) {
      this.toastService.error('Não existe produto para o filtro selecionado!');
    } finally {
      this.loadingMain = false;
      if (!this.productSelected) {
        this.blockOperation.setBlockOperation('', false);
      }
    }
  }

  async getProduct(id: number) {
    this.blockOperation.setBlockOperation('', true);
    this.similarProduct = null;
    try {
      this.similarProduct = await this.productService.get(id);
      Product.getAllDescription(this.categories, this.similarProduct);
      Product.addVideoInSession(this.similarProduct);
    } catch (err) {
      console.error(err);
      throw err;
    } finally {
      this.blockOperation.setBlockOperation('', false);
    }
  }

  async setProductOrderCategoryId() {
    if (this.productOrderCategoryId === 1) {
      setTimeout(() => {
        this.productOrderCategoryId = 3;
      }, 300);
    }
  }

  private showProduct(prod: Product): boolean {
    let show = prod.enabled;
    if (
      prod.stockBalance === 0 &&
      (prod.daysOnlyOrder === 0 || !prod.daysOnlyOrder)
    ) {
      show = false;
    }
    return show;
  }

  async setFilterOnMenu() {
    this.categories.forEach((c) => {
      if (
        this.productFilter.categories &&
        this.productFilter.categories[0] === c.id
      ) {
        if (this.productFilter.subCategories) {
          const subCategory = c.subCategories.find(
            (sub) => sub.id === this.productFilter.subCategories[0]
          );
          if (subCategory) {
            this.selectedSubCategory = subCategory;

            if (this.productFilter.model) {
              const model = this.selectedSubCategory.models.find(
                (h) => h.id === this.productFilter.model
              );
              if (model) {
                this.openedModel = true;
                this.selectedModel = model;
              }
            }

            if (this.productFilter.heightThickness) {
              const heightThickness =
                this.selectedSubCategory.heightThickness.find(
                  (h) => h.id === this.productFilter.heightThickness
                );
              if (heightThickness) {
                this.openedHeightThickness = true;
                this.selectedHeightThickness = heightThickness;
              }
            }

            if (this.productFilter.width) {
              const width = this.selectedSubCategory.widths.find(
                (w) => w.id === this.productFilter.width
              );
              if (width) {
                this.openedWidth = true;
                this.selectedWidth = width;
              }
            }

            if (this.productFilter.length) {
              const length = this.selectedSubCategory.lengths.find(
                (l) => l.id === this.productFilter.length
              );
              if (length) {
                this.openedLength = true;
                this.selectedLength = length;
              }
            }

            if (this.productFilter.color) {
              const color = this.selectedSubCategory.colors.find(
                (col) => col.id === this.productFilter.color
              );
              if (color) {
                this.openedColor = true;
                this.selectedColor = color;
              }
            }

            if (this.productFilter.finish) {
              const finish = this.selectedSubCategory.finishes.find(
                (f) => f.id === this.productFilter.finish
              );
              if (finish) {
                this.openedFinish = true;
                this.selectedFinish = finish;
              }
            }

            if (this.productFilter.capacity) {
              const capacity = this.selectedSubCategory.capabilities.find(
                (f) => f.id === this.productFilter.capacity
              );
              if (capacity) {
                this.openedCapabilities = true;
                this.selectedCapacity = capacity;
              }
            }

            if (this.productFilter.potency) {
              const potency = this.selectedSubCategory.potencies.find(
                (f) => f.id === this.productFilter.potency
              );
              if (potency) {
                this.openedPotencies = true;
                this.selectedPotency = potency;
              }
            }

            if (this.productFilter.speed) {
              const speed = this.selectedSubCategory.speeds.find(
                (f) => f.id === this.productFilter.speed
              );
              if (speed) {
                this.openedSpeeds = true;
                this.selectedSpeed = speed;
              }
            }

            if (this.productFilter.voltage) {
              const voltage = this.selectedSubCategory.voltages.find(
                (f) => f.id === this.productFilter.voltage
              );
              if (voltage) {
                this.openedVoltages = true;
                this.selectedVoltage = voltage;
              }
            }
          }
        }
      }
    });
  }

  async configProducts(idProduct: string) {
    this.blockOperation.setBlockOperation('', true);
    if (idProduct) {
      const product = await this.productService.get(idProduct);
      if (product.enabled) {
        Product.getAllDescription(this.categories, product);
        Product.addVideoInSession(product);
        await this.selectProduct(product);
        const filter: ProductFilter = new ProductFilter();
        filter.inactive = false;
        filter.ignoreMainVariation = true;
        filter.perPage = 40;
        filter.subCategories = this.getEnumFilterSubcategories(product);
        const pageRes: PageResponse = await this.productService.listV2(
          filter,
          true
        );
        if (pageRes?.totalElements > 0) {
          this.productsRelated = pageRes.content;
        }
        this.blockOperation.setBlockOperation('', false);
      } else {
        this.selectedCategory = new InfoCategory();
        this.blockOperation.setBlockOperation('', false);
        this.loadingMain = false;
      }
      await this.sessionStorageService.removeItem(
        StorageConstants.RM_ECOMMERCE_ID_PRODUCT
      );
    }
  }

  getEnumFilterSubcategories(product: Product): any[] {
    let subCategories: any[] = [];
    switch (product.category) {
      case CategoriesEnum.ACESSORIOS_DE_INSTALACAO:
        subCategories = Object.values(FilterSubCategoriesAcessoriosEnum);
        break;
      case CategoriesEnum.COBERTURAS_EM_POLICARBONATO:
        subCategories = Object.values(FilterSubCategoriesChapasEnum);
        break;
      case CategoriesEnum.COMUNICACAO_VISUAL:
        subCategories = Object.values(FilterSubCategoriesComunicacaoEnum);
        break;
      case CategoriesEnum.KITS:
        subCategories = Object.values(FilterSubCategoriesKitsEnum);
        break;
      case CategoriesEnum.MOTORES:
        subCategories = Object.values(FilterSubCategoriesMotoresEnum);
        break;
      case CategoriesEnum.PERFIS_EM_ALUMINIO:
        subCategories = Object.values(FilterSubCategoriesPerfisEnum);
        break;
      case CategoriesEnum.TOLDOS:
        subCategories = Object.values(FilterSubCategoriesToldosEnum);
        break;
      case CategoriesEnum.PERSIANAS:
        subCategories = [
          product.subCategory,
          SubCategoriesEnum.ACESSORIOS_PERSIANAS,
        ];
        break;
      default:
        break;
    }
    return subCategories;
  }

  async onSelectedCategory(category: InfoCategory) {
    this.resetSelecteds();
    this.selectedSubCategory = null;
    this.selectedSubCategory = new InfoSubCategory();
    this.reseteCategories();
    this.selectedCategory = category;
    this.productFilter = new ProductFilter();
    this.productFilter.categories = [this.selectedCategory.id];
    this.openedSubCategory = true;
    this.setMetaTags();
    this.loadProducts();
  }

  async clickSelectedSubCategory(sub: InfoSubCategory) {
    this.resetSelecteds();
    if (this.selectedSubCategory.colors !== null) {
      if (this.selectedSubCategory.id === sub.id) {
        this.selectedSubCategory = null;
        this.selectedSubCategory = new InfoSubCategory();
      } else {
        this.selectedSubCategory = sub;
      }
    } else {
      this.selectedSubCategory = sub;
    }
    this.productFilter = new ProductFilter();
    this.productFilter.categories = [this.selectedCategory.id];
    if (this.selectedSubCategory.id) {
      this.productFilter.subCategories = [this.selectedSubCategory.id];
    }
    this.page = 0;
    this.addSessionFilter(this.productFilter);
    this.setMetaTags();
    this.loadProducts();
  }

  async clickSelectedColor(color: AbstractInfoEnum) {
    if (this.selectedColor) {
      if (this.selectedColor.id === color.id) {
        this.selectedColor = null;
        this.selectedColor = new AbstractInfoEnum();
        this.productFilter.color = null;
      } else {
        this.selectedColor = color;
        this.productFilter.color = this.selectedColor.id;
      }
    } else {
      this.selectedColor = color;
      this.productFilter.color = this.selectedColor.id;
    }
    this.page = 0;
    this.addSessionFilter(this.productFilter);
    this.loadProducts();
  }

  async clickSelectedThickness(thickness: AbstractInfoEnum) {
    if (this.selectedHeightThickness) {
      if (this.selectedHeightThickness.id === thickness.id) {
        this.selectedHeightThickness = null;
        this.selectedHeightThickness = new AbstractInfoEnum();
        this.productFilter.heightThickness = this.selectedHeightThickness.id;
      } else {
        this.selectedHeightThickness = thickness;
        this.productFilter.heightThickness = this.selectedHeightThickness.id;
      }
    } else {
      this.selectedHeightThickness = thickness;
      this.productFilter.heightThickness = this.selectedHeightThickness.id;
    }
    this.page = 0;
    this.addSessionFilter(this.productFilter);
    this.loadProducts();
  }

  async clickSelectedLength(lengths: AbstractInfoEnum) {
    if (this.selectedLength) {
      if (this.selectedLength.id === lengths.id) {
        this.selectedLength = null;
        this.selectedLength = new AbstractInfoEnum();
        this.productFilter.length = this.selectedLength.id;
      } else {
        this.selectedLength = lengths;
        this.productFilter.length = this.selectedLength.id;
      }
    } else {
      this.selectedLength = lengths;
      this.productFilter.length = this.selectedLength.id;
    }

    this.page = 0;
    this.addSessionFilter(this.productFilter);
    this.loadProducts();
  }

  async clickSelectedWidth(width: AbstractInfoEnum) {
    if (this.selectedWidth) {
      if (this.selectedWidth.id === width.id) {
        this.selectedWidth = null;
        this.selectedWidth = new AbstractInfoEnum();
        this.productFilter.width = this.selectedWidth.id;
      } else {
        this.selectedWidth = width;
        this.productFilter.width = this.selectedWidth.id;
      }
    } else {
      this.selectedWidth = width;
      this.productFilter.width = this.selectedWidth.id;
    }
    this.page = 0;
    this.addSessionFilter(this.productFilter);
    this.loadProducts();
  }

  async clickSelectedFinish(finish: AbstractInfoEnum) {
    if (this.selectedFinish) {
      if (this.selectedFinish.id === finish.id) {
        this.selectedFinish = null;
        this.selectedFinish = new AbstractInfoEnum();
        this.productFilter.finish = this.selectedFinish.id;
      } else {
        this.selectedFinish = finish;
        this.productFilter.finish = this.selectedFinish.id;
      }
    } else {
      this.selectedFinish = finish;
      this.productFilter.finish = this.selectedFinish.id;
    }
    this.page = 0;
    this.addSessionFilter(this.productFilter);
    this.loadProducts();
  }

  async clickSelectedModel(model: AbstractInfoEnum) {
    if (this.selectedModel) {
      if (this.selectedModel.id === model.id) {
        this.selectedModel = null;
        this.selectedModel = new AbstractInfoEnum();
        this.productFilter.model = this.selectedModel.id;
      } else {
        this.selectedModel = model;
        this.productFilter.model = this.selectedModel.id;
      }
    } else {
      this.selectedModel = model;
      this.productFilter.model = this.selectedModel.id;
    }
    this.page = 0;
    this.addSessionFilter(this.productFilter);
    this.loadProducts();
  }

  async clickSelectedCapacity(capacity: AbstractInfoEnum) {
    if (this.selectedCapacity) {
      if (this.selectedCapacity.id === capacity.id) {
        this.selectedCapacity = null;
        this.selectedCapacity = new AbstractInfoEnum();
        this.productFilter.capacity = this.selectedCapacity.id;
      } else {
        this.selectedCapacity = capacity;
        this.productFilter.capacity = this.selectedCapacity.id;
      }
    } else {
      this.selectedCapacity = capacity;
      this.productFilter.capacity = this.selectedCapacity.id;
    }
    this.page = 0;
    this.addSessionFilter(this.productFilter);
    this.loadProducts();
  }

  async clickSelectedPotency(potency: AbstractInfoEnum) {
    if (this.selectedPotency) {
      if (this.selectedPotency.id === potency.id) {
        this.selectedPotency = null;
        this.selectedPotency = new AbstractInfoEnum();
        this.productFilter.potency = this.selectedPotency.id;
      } else {
        this.selectedPotency = potency;
        this.productFilter.potency = this.selectedPotency.id;
      }
    } else {
      this.selectedPotency = potency;
      this.productFilter.potency = this.selectedPotency.id;
    }
    this.page = 0;
    this.addSessionFilter(this.productFilter);
    this.loadProducts();
  }

  async clickSelectedSpeed(speed: AbstractInfoEnum) {
    if (this.selectedSpeed) {
      if (this.selectedSpeed.id === speed.id) {
        this.selectedSpeed = null;
        this.selectedSpeed = new AbstractInfoEnum();
        this.productFilter.speed = this.selectedSpeed.id;
      } else {
        this.selectedSpeed = speed;
        this.productFilter.speed = this.selectedSpeed.id;
      }
    } else {
      this.selectedSpeed = speed;
      this.productFilter.speed = this.selectedSpeed.id;
    }
    this.page = 0;
    this.addSessionFilter(this.productFilter);
    this.loadProducts();
  }

  async clickSelectedVoltage(voltage: AbstractInfoEnum) {
    if (this.selectedVoltage) {
      if (this.selectedVoltage.id === voltage.id) {
        this.selectedVoltage = null;
        this.selectedVoltage = new AbstractInfoEnum();
        this.productFilter.voltage = this.selectedVoltage.id;
      } else {
        this.selectedVoltage = voltage;
        this.productFilter.voltage = this.selectedVoltage.id;
      }
    } else {
      this.selectedVoltage = voltage;
      this.productFilter.voltage = this.selectedVoltage.id;
    }
    this.page = 0;
    this.addSessionFilter(this.productFilter);
    this.loadProducts();
  }

  getProductPackage() {
    if (this.selectedCategory) {
      if (
        this.selectedSubCategory.colors &&
        this.selectedSubCategory.colors.length > 0
      ) {
        this.productPackage = ` / ${this.selectedSubCategory.description}`;

        if (this.selectedColor && this.selectedColor.description) {
          this.productPackage = `${this.productPackage} / ${
            this.selectedColor.description.split(':')[0]
          }`;
        }

        if (this.selectedHeightThickness) {
          this.productPackage = `${this.productPackage} / E: ${this.selectedHeightThickness.description}`;
        }

        if (this.selectedWidth) {
          this.productPackage = `${this.productPackage} / L: ${this.selectedWidth.description}`;
        }

        if (this.selectedLength) {
          this.productPackage = `${this.productPackage} / C: ${this.selectedLength.description}`;
        }

        if (this.selectedFinish) {
          this.productPackage = `${this.productPackage} / ${this.selectedFinish.description}`;
        }

        if (this.selectedAluminumWallThickness) {
          this.productPackage = `${this.productPackage} / ${this.selectedAluminumWallThickness.description}`;
        }

        if (this.selectedBrand) {
          this.productPackage = `${this.productPackage} / ${this.selectedBrand.description}`;
        }
      }
    }
  }

  resetSelecteds() {
    this.selectedColor = null;
    this.selectedWidth = null;
    this.selectedLength = null;
    this.selectedFinish = null;
    this.selectedHeightThickness = null;
    this.selectedAluminumWallThickness = null;
    this.selectedBrand = null;
    this.selectedModel = null;
    this.selectedCapacity = null;
    this.selectedPotency = null;
    this.selectedSpeed = null;
    this.selectedVoltage = null;

    this.openedAluminumWallThickness = false;
    this.openedBrand = false;
    this.openedColor = false;
    this.openedFinish = false;
    this.openedLength = false;
    this.openedHeightThickness = false;
    this.openedWidth = false;
    this.openedModel = false;
    this.openedCapabilities = false;
    this.openedPotencies = false;
    this.openedSpeeds = false;
    this.openedVoltages = false;
  }

  reseteCategories() {
    this.selectedSubCategory.colors = null;
    this.selectedSubCategory.aluminumWallThickness = null;
    this.selectedSubCategory.brands = null;
    this.selectedSubCategory.colorsCode = null;
    this.selectedSubCategory.finishes = null;
    this.selectedSubCategory.lengths = null;
    this.selectedSubCategory.heightThickness = null;
    this.selectedSubCategory.widths = null;
    this.selectedSubCategory.colors = null;
  }

  getImageHeight() {
    return window.innerHeight * 0.6 + 'px';
  }

  selectProductColor(productColor: ProductColorBalance) {
    const product: Product = Object.assign(new Product(), productColor);
    this.selectIdProduct(product);
  }

  selectIdProduct(product: Product) {
    window._RMKeepFilterSession = true;
    window.location.href = this.getUrlProduct(product);
  }

  async selectProduct(product: Product) {
    this.installments = await this.paymentService.getInstallments(
      product.value
    );
    this.blockOperation.setBlockOperation('', false);
    this.subpage = this.SUB_PAGE_PRODUCT;
    this.infoSectionSelected = null;
    this.productSelected = product;
    this.setMetaTags();
    if (this.zipCode) {
      this.calculateFreight(true);
    }
    this.activePhoto = this.productSelected.photos.find(
      (p) => p.orderDisplay === 1
    );
    if (
      this.productSelected.informationsSection &&
      this.productSelected.informationsSection.length > 0
    ) {
      let inf = this.productSelected.informationsSection.find(
        (p) => p.orderDisplay === 1
      );
      if (!inf) {
        inf = this.productSelected.informationsSection.find(
          (p) => p.orderDisplay === 2
        );
      }
      this.clickTab(inf, null);
    }
    document.body.scrollTop = 0; // Safari
    document.documentElement.scrollTop = 0; // Other browsers
    this.errorFreight = '';
  }

  selectedImage(photo: AttachmentProductLink) {
    this.activePhoto = photo;
  }

  async addToCart(product: Product = null) {
    try {
      product = product || this.productSelected;
      if (!product) {
        return;
      }

      if (!product.enabled) {
        return this.toastService.error(
          `Produto ${product.id} não pode ser adicionado ao seu carrinho pois está inativo!`
        );
      }

      if (
        product.qtd > product.stockBalance &&
        !product.canBeGeneratedWithStockZero
      ) {
        return this.toastService.error(
          `Existe apenas ${product.stockBalance} item(s) disponível(is)`
        );
      }

      this.blockOperation.setBlockOperation('', true);
      const cartItem: CartItem = {
        productId: product.id,
        qt: product.qtd || 1,
        details: product,
      };
      await this.cartService.addItem(cartItem, true);
      product = null;

      this.blockOperation.setBlockOperation('', false);
      this.toastService.success('Produto adicionado ao carrinho com sucesso!');
      window.dispatchEvent(new Event('addToCart'));
    } catch (err) {
      this.blockOperation.setBlockOperation('', false);
      this.toastService.error(
        'Ocorreu um erro ao tentar adicionar seu produto ao carrinho!'
      );
    }
  }

  clickTab(info: InformationSection, event: any) {
    info.selected = !info.selected;
    const otherMenis = this.productSelected.informationsSection.filter(
      (tab) => tab.id !== info.id
    );
    otherMenis.forEach((i) => (i.selected = false));
    this.infoSectionSelected = info;
    if (event) {
      event.scrollIntoView();
    }
  }

  onClickMoreInf() {
    document
      ?.getElementById('product-information-target')
      ?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    document
      ?.getElementById('product-information-mobile-target')
      ?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  formatTotalValue(product: Product) {
    return this.getTotalValue(product);
  }

  getTotalValue(product: Product) {
    const first = this.installments[this.installments.length - 1];
    let quantity = 0;
    if (first?.quantity <= 1) {
      quantity = first?.quantity;
    } else {
      const p = Math.floor(product.value / 5);
      quantity = p <= 1 ? p : 1;
    }
    let value = product.value;
    if (product.discount > 0) {
      value = value - (value * product.discount) / 100;
    }

    value = value - (value * environment.cashDiscount) / 100;
    return value;
  }

  installmentFormatInterestFree(product: Product) {
    const value = this.getValueProduct(product);
    const valueFormat = formatCurrency(value, 'pt', 'R$');
    const installments = this.paymentService.getTotalInstallments(value, true);
    const valueInstallmentFormat = formatCurrency(
      value / installments,
      'pt',
      'R$'
    );
    return `${valueFormat} em ${installments}x de ${valueInstallmentFormat} sem juros`;
  }

  getInstallmentValue(product: Product) {
    if (product) {
      const valueFormat = formatCurrency(
        this.installments[this.installments.length - 1],
        'pt',
        'R$'
      );
      return `${this.installments.length}x de ${valueFormat}`;
    }
    return '';
  }

  getValueProduct(product: Product) {
    let value = product.value;
    if (product.discount > 0) {
      value = value - (value * product.discount) / 100;
    }
    return value;
  }

  getParcela(product: Product) {
    const p = Math.floor(product.value / 5);
    return p > 12 ? 12 : p;
  }

  showValorParcelado(product: Product, mobile: boolean) {
    if (mobile) {
      return (
        (this.installments && this.installments.length > 0) ||
        Math.floor(product.value / 5) > 3
      );
    } else {
      return Math.floor(product.value / 5) > 3;
    }
  }

  getUrlProduct(produto: Product) {
    let name = produto?.name?.toLowerCase()?.replace(/ /g, '-');
    name = name?.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    name = name?.replace(/\---/gi, '-')?.replace(/% ?/g, '');
    return `${environment.linkCatalog}title=${name}&idProduct=${
      produto?.id
    }&category=${produto?.category?.toLowerCase()}#sku`;
  }

  getUrlCategory(cat: InfoCategory) {
    return cat?.id
      ? `${environment.linkCatalog}category=${cat?.id?.toLowerCase()}#category`
      : '';
  }

  getUrlSubCategory(sub: InfoSubCategory) {
    return sub?.id
      ? `${
          environment.linkCatalog
        }subCategory=${sub?.id?.toLowerCase()}#subCategory`
      : '';
  }

  onKeyUpZipCode($event) {
    if ($event.keyCode === 13) {
      this.setZipCode($event.target.value);
    }
  }

  async setZipCode(zipCode) {
    this.zipCode = zipCode;
    if (zipCode?.length < 8) {
      this.quotes = null;
      await this.localStorageService.removeItem(
        StorageConstants.RM_ECOMMERCE_ZIP_CODE
      );
    }
  }
  async addressFound(address: Address) {
    if (address?.zipCode) {
      await this.localStorageService.set(
        StorageConstants.RM_ECOMMERCE_ZIP_CODE,
        this.zipCode
      );
      await this.calculateFreight(true);
    } else {
      this.toastService.error('CEP não localizado!');
    }
  }

  async getAddress() {
    if (this.zipCode?.length === 8) {
      const address: Address = await this.addressService.getAddress(
        this.zipCode
      );
      if (address?.zipCode) {
        await this.calculateFreight(true);
      } else {
        this.toastService.error('CEP não localizado!');
      }
    } else {
      this.toastService.error('Digite um CEP válido para obter sua cotação!');
    }
  }

  async onBackCatalog() {
    window._RMKeepFilterSession = true;
    await this.sessionStorageService.removeItem(
      StorageConstants.RM_ECOMMERCE_ID_PRODUCT
    );
    window.location.href = this.getUrlCategory(this.selectedCategory);
  }

  async calculateFreight(showMgs) {
    this.quotes = null;
    if (this.zipCode) {
      this.loadingFreight = true;
      try {
        const calculate: CalculateShipping = new CalculateShipping();
        calculate.cep = this.zipCode;
        calculate.itens = [
          {
            amount: this.productSelected?.qtd || 1,
            productId: this.productSelected?.id,
          },
        ];
        const quotes: QuoteList = await this.freightService.calculateShipping(
          calculate
        );

        this.errorFreight = '';
        if (quotes?.carriers.length > 0) {
          if (this.productSelected?.daysOnlyOrder > 0) {
            quotes.carriers.forEach((carrier) => {
              if (carrier.optionFreight === Freight.STORE_PICKUP) {
                carrier.deliveryTime += this.productSelected.daysOnlyOrder;
              }
            });
          }
          this.quotes = quotes;
        } else {
          if (this.productSelected.similarProduct > 0) {
            this.similarProduct = this.products.find(
              (p) => p.id === this.productSelected.similarProduct
            );
            if (!this.similarProduct) {
              await this.getProduct(this.productSelected.similarProduct);
            }
            this.showSimilarProduct = true;
            this.errorFreight =
              'Para cotar esse frete com 6 metros, solicite via whatsapp!!!';
          } else {
            this.errorFreight = 'Entrega não disponível para sua região!';
          }
        }
      } catch (err) {
        this.errorFreight = 'Entrega não disponível para sua região!';
      } finally {
        this.loadingFreight = false;
      }
    } else {
      if (showMgs) {
        this.errorFreight = `CEP não localizado!`;
      }
    }
  }

  getUrlVideo(is: InformationSection) {
    return is.description;
  }

  isCategoryKit(): boolean {
    return this.selectedCategory.id === CategoriesEnum.KITS;
  }

  isCategoryToldos(): boolean {
    return this.selectedCategory.id === CategoriesEnum.TOLDOS;
  }

  isSubCategoryToldoArticulado(): boolean {
    return this.selectedSubCategory.id === SubCategoriesEnum.TOLDOS_ARTICULADO;
  }

  isSubCategoryToldosCortina(): boolean {
    return this.selectedSubCategory.id === SubCategoriesEnum.TOLDOS_CORTINA;
  }

  isCategoryPerfisFitaFixacao(): boolean {
    return this.selectedCategory.id === CategoriesEnum.PERFIS_EM_ALUMINIO;
  }

  isCategoryMotores(): boolean {
    return this.selectedCategory.id === CategoriesEnum.MOTORES;
  }

  isSubCategoryFitasSelantesParafusos(): boolean {
    return (
      this.selectedSubCategory.id === SubCategoriesEnum.FITAS_SELANTES_PARAFUSOS
    );
  }

  isSubCategoryDiscos(): boolean {
    return (
      this.selectedSubCategory.id === SubCategoriesEnum.DISCOS_CORTE_DESBASTE
    );
  }

  isCategoryPersianas(): boolean {
    return this.selectedSubCategory.id === CategoriesEnum.PERSIANAS;
  }

  isCategoryModel(): boolean {
    return (
      this.isSubCategoryToldoArticulado() ||
      this.isCategoryPerfisFitaFixacao() ||
      this.isCategoryMotores() ||
      this.isSubCategoryFitasSelantesParafusos() ||
      this.isSubCategoryDiscos()
    );
  }

  showFinishes(): boolean {
    return (
      this.selectedSubCategory?.finishes?.length > 0 &&
      (this.selectedCategory.id === CategoriesEnum.TOLDOS ||
        this.selectedCategory.id ===
          CategoriesEnum.COBERTURAS_EM_POLICARBONATO ||
        this.selectedCategory.id === CategoriesEnum.COMUNICACAO_VISUAL ||
        this.selectedCategory.id === SubCategoriesEnum.ITENS_DE_SEGURANCA) &&
      this.selectedSubCategory?.id !== SubCategoriesEnum.TOLDOS_ARTICULADO &&
      this.selectedSubCategory?.id !== SubCategoriesEnum._ACESSORIOS_TOLDOS
    );
  }

  showHeight(): boolean {
    return (
      this.selectedSubCategory?.id ===
        SubCategoriesEnum.CANTONEIRAS_BARRA_CHATA ||
      this.selectedSubCategory?.id === SubCategoriesEnum.PERFIS_ESTRUTURAIS ||
      this.selectedSubCategory?.id === SubCategoriesEnum.TOLDOS_CORTINA ||
      this.selectedCategory?.id === CategoriesEnum.PERSIANAS
    );
  }

  showHeightThickness(): boolean {
    return (
      this.selectedSubCategory.heightThickness &&
      this.selectedSubCategory.heightThickness.length > 0 &&
      this.selectedCategory?.id !== CategoriesEnum.TOLDOS &&
      this.selectedCategory?.id !== SubCategoriesEnum.ITENS_DE_SEGURANCA &&
      this.selectedCategory?.id !== CategoriesEnum.MOTORES &&
      this.selectedSubCategory?.id !==
        SubCategoriesEnum.FITAS_SELANTES_PARAFUSOS &&
      this.selectedSubCategory?.id !== SubCategoriesEnum._ACESSORIOS_TOLDOS &&
      this.selectedSubCategory?.id !==
        SubCategoriesEnum.FITAS_SELANTES_PARAFUSOS &&
      this.selectedSubCategory?.id !== SubCategoriesEnum.DISCOS_CORTE_DESBASTE
    );
  }

  showModels(): boolean {
    return (
      this.selectedSubCategory?.models?.length > 0 &&
      this.selectedCategory?.id !== CategoriesEnum.KITS &&
      this.selectedCategory?.id !==
        CategoriesEnum.COBERTURAS_EM_POLICARBONATO &&
      this.selectedCategory?.id !== CategoriesEnum.COMUNICACAO_VISUAL
    );
  }

  showWidth(): boolean {
    return (
      this.selectedSubCategory?.widths &&
      this.selectedSubCategory?.widths.length > 0 &&
      this.selectedCategory?.id !== CategoriesEnum.MOTORES
    );
  }

  showLength(): boolean {
    return (
      this.selectedSubCategory?.lengths &&
      this.selectedSubCategory?.lengths.length > 0 &&
      this.selectedCategory?.id !== CategoriesEnum.MOTORES &&
      this.selectedSubCategory?.id !==
        SubCategoriesEnum.DISCOS_CORTE_DESBASTE &&
      this.selectedCategory?.id !== CategoriesEnum.PERSIANAS
    );
  }

  getDescriptionLength(): string {
    if (this.isCategoryKit()) {
      return 'AVANÇO';
    } else if (this.isSubCategoryToldosCortina()) {
      return 'ALTURA';
    } else if (this.isCategoryPersianas()) {
      return 'PROFUNDIDADE';
    } else {
      return 'COMPRIMENTO';
    }
  }

  showColors(): boolean {
    return (
      this.selectedSubCategory?.colors?.length > 0 &&
      this.selectedSubCategory?.id !==
        SubCategoriesEnum.FITAS_SELANTES_PARAFUSOS &&
      this.selectedSubCategory?.id !== SubCategoriesEnum.DISCOS_CORTE_DESBASTE
    );

    return true;
  }

  showChildren(item: AbstractInfoEnum, field: string): boolean {
    //TODO pensar num modo do mostrar as categorias que tem produto
    /*let have = false;
    this.products.forEach((p) => {
      if (item.id === p[field]) {
        have = true;
      }
    });*/
    return true;
  }

  isKit(product: Product): boolean {
    return product.productType === 2;
  }

  moreOptionsPayments() {
    if (this.installments && this.installments.length > 0) {
      this.showOptionsPayments = true;
    }
  }

  getHeight() {
    return `${window.innerHeight * 0.8}px`;
  }

  changedOrder(filter: ProductOrder) {
    this.productFilter.direction = '';
    if (filter) {
      this.productFilter.direction = filter.orderBy;
    }
    this.setMetaTags();
    this.loadProducts();
  }

  getDescription(product: Product) {
    if (this.isKit(product)) {
      return this.getDescriptionKit(product);
    }
    return this.getDescriptionProduct(product);
  }

  getDescriptionProduct(product: Product) {
    if (this.isCategoryMotores()) {
      return this.getDescriptionProductMotores(product);
    } else {
      return this.getDescriptionProductOthers(product);
    }
  }

  getDescriptionProductMotores(product: Product) {
    const brand = product.brandDescription
      ? `Produto da marca ${product.brandDescription}`
      : ``;
    const capacity = product.capacityDescription
      ? `com capacidade de ${product.capacityDescription}`
      : ``;
    const potency = product.potencyDescription
      ? ` - Potência de ${product.potencyDescription}`
      : ``;
    const speed = product.speedDescription
      ? ` - Velocidade de ${product.speedDescription}`
      : ``;
    const voltage = product.voltageDescription
      ? ` - Voltagem ${product.voltageDescription}`
      : ``;

    return `${brand} ${capacity} ${potency} ${speed} ${voltage} - sku ${product.id}`;
  }

  getDescriptionProductOthers(product: Product) {
    const brand = product.brandDescription
      ? `Produto da marca ${product.brandDescription}`
      : ``;
    const width = product.widthDescription
      ? `com ${product.widthDescription} de largura`
      : ``;
    const length = product.lengthDescription
      ? `por ${product.lengthDescription} de comprimento`
      : ``;
    const heightThickness = product.heightThicknessDescription
      ? `e ${product.heightThicknessDescription} de altura/espessura`
      : ``;
    const color = product.colorDescription
      ? `na cor ${product.colorDescription}`
      : ``;
    const weight = product.weight ? `com peso de ${product.weight} kg` : ``;
    const finish = product.finishDescription
      ? `e acabamento ${product.finishDescription}`
      : ``;
    return `${brand} ${width} ${length} ${heightThickness} ${color} ${weight} ${finish} - sku ${product.id}`;
  }

  getDescriptionKit(product: Product) {
    const name = `Kit pronto para ${product.name}, `;
    const fixation = `com ${product.amountFixation} pontos de fixação. `;
    const info = `Para que possa aplica-lo, a sua estrutura deve seguir o padrão de: `;
    const width = `Largura: ${product.widthDescription} x `;
    const length = `${product.lengthDescription} Avanço/Caída da água. `;
    const rafters = `Quantidade de cáibros: ${product.amountRafters}. `;
    const weight = `O peso total deste kit é de ${product.weight} kg`;
    return `${name}${fixation}${info}${width}${length}${rafters}${weight} - sku ${product.id}`;
  }

  async removeSessionFilter() {
    await this.sessionStorageService.removeItem(
      StorageConstants.RM_ECOMMERCE_PRODUCT_FILTER
    );
  }

  async addSessionFilter(value) {
    await this.sessionStorageService.set(
      StorageConstants.RM_ECOMMERCE_PRODUCT_FILTER,
      value
    );
  }

  async getSessionFilter() {
    return await this.sessionStorageService.get(
      StorageConstants.RM_ECOMMERCE_PRODUCT_FILTER
    );
  }

  changeBodyOverlay() {
    const bodyElement = document.body;
    if (bodyElement) {
      if (this.menuOpened) {
        bodyElement.classList.add('p-overflow-hidden');
      } else {
        bodyElement.classList.remove('p-overflow-hidden');
      }
    }
  }

  onPageChange(event: any) {
    this.page = event.page;
    this.setMetaTags();
    this.loadProducts();
  }

  isSubCategoryPersianas(subCategory: string): boolean {
    switch (subCategory) {
      case SubCategoriesEnum.DOUBLE_VISION:
        return true;
      case SubCategoriesEnum.HORIZONTAL:
        return true;
      case SubCategoriesEnum.PAINEL:
        return true;
      case SubCategoriesEnum.ROLO:
        return true;
      case SubCategoriesEnum.ROMANA:
        return true;
      case SubCategoriesEnum.ROMANA_DE_TETO:
        return true;
      default:
        return false;
    }
  }

  setMetaTags() {
    let title: string = '';
    let h1: string = '';
    let h2: string = '';

    if (this.productSelected) {
      title = this.productSelected?.name?.substring(0, 50);
      h1 = this.selectedCategory?.description;
      h2 = this.selectedCategory?.subCategories
        .filter((c) => c.id === this.productSelected?.subCategory)
        ?.slice()
        ?.shift()?.description;
    } else if (this.selectedSubCategory?.colors?.length > 0) {
      title = this.selectedSubCategory?.description;
      h1 = this.selectedCategory.description;
      h2 = this.selectedSubCategory.description;
    } else if (this.selectedCategory) {
      title = this.selectedCategory.description;
      h1 = this.selectedCategory.description;
    }
    this.titleService.setTitle(title + ' - RM Policarbonatos');

    this.metaTagService.addTags([
      {
        name: 'description',
        content: 'Descrição de: ' + title,
      },
      {
        property: 'og:description',
        content: 'Descrição de: ' + title,
      },
    ]);

    let h1SiteMain = document.getElementById('h1SitMain');
    if (h1SiteMain) {
      h1SiteMain.innerHTML = h1;
    }

    let h2SiteMain = document.getElementById('h2SitMain');
    if (h2SiteMain) {
      h2SiteMain.innerHTML = h2;
    }
  }

  getDescriptionToMetaTag(subCategory: string): boolean {
    switch (subCategory) {
      case SubCategoriesEnum.DOUBLE_VISION:
        return true;
      case SubCategoriesEnum.HORIZONTAL:
        return true;
      case SubCategoriesEnum.PAINEL:
        return true;
      case SubCategoriesEnum.ROLO:
        return true;
      case SubCategoriesEnum.ROMANA:
        return true;
      case SubCategoriesEnum.ROMANA_DE_TETO:
        return true;
      default:
        return false;
    }
  }
}
