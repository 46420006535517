import { Injectable } from '@angular/core';
import Address from 'src/app/models/user/Address';
import extractNumbers from '../util/extract-numbers';

@Injectable({ providedIn: 'root' })
export class AddressService {
  async getAddress(cep: string): Promise<Address> {
    try {
      const response = await window.fetch(
        `https://viacep.com.br/ws/${extractNumbers(cep)}/json/`
      );
      if (response.status !== 200) {
        throw response;
      }
      const json = await response.json();
      const address = new Address();
      address.city = json.localidade || '';
      address.zipCode = json.cep || '';
      address.district = json.bairro || '';
      address.address = json.logradouro || '';
      // address.complement = json.complemento || '';
      address.uf = json.uf || '';
      address.principal = true;
      return address;
    } catch (err) {
      throw err;
    }
  }
}
