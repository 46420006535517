import { Injectable } from '@angular/core';
import Coupon from 'src/app/models/coupons/Coupon';
import { AppInfoService } from './app-info.service';
import fetch from './fetch';
@Injectable({
  providedIn: 'root',
})
export class CouponsService {
  constructor(private appInfo: AppInfoService) {}

  async save(coupon: Coupon) {
    try {
      return await fetch('POST', `coupons/`, coupon);
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  async get(id: number) {
    try {
      return await fetch('GET', `coupons/${id}`);
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  async update(coupon: Coupon) {
    try {
      return await fetch('PUT', `coupons/${coupon.id}/`, coupon);
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  async delete(id: number) {
    try {
      return await fetch('DELETE', `coupons/${id}`);
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  public async list(): Promise<Array<Coupon>> {
    const coupons: Array<Coupon> = [];
    try {
      const response = await fetch('GET', 'coupons/pageable/list/');
      let newCoupon: Coupon;
      response.content.forEach((couponBanco) => {
        newCoupon = Object.assign(new Coupon(), couponBanco);
        coupons.push(newCoupon);
      });
      return coupons;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
}
