import ReportOperations from './ReportOperations';

export default class Report {
  reports: ReportOperations;

  fromJson(reportObj: any) {
    if (reportObj != null) {
      this.reports = new ReportOperations();
      this.reports.fromJson(reportObj);
    }
  }
}
