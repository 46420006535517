import { Injectable } from '@angular/core';
import Budget from 'src/app/models/budget/budget';
import CartCheckoutDetails from 'src/app/models/cart/CartCheckoutDetails';
import CartFilter from 'src/app/models/cart/CartFilter';
import PrepareCheckoutCart from 'src/app/models/cart/PrepareCheckoutCart';
import { Cart, CartFull, CartItem } from 'src/app/models/cart/cart';
import Coupon from 'src/app/models/coupons/Coupon';
import CouponDiscount from 'src/app/models/coupons/CouponDiscount';
import Freight from 'src/app/models/freight/Freight';
import FreightSelected from 'src/app/models/freight/FreightSelected';
import Product from 'src/app/models/product/product';
import { StorageConstants } from 'src/services/constants/storage-constants';
import { AppInfoService } from './app-info.service';
import { BudgetService } from './budget.service';
import Fetch from './fetch';
import { LocalStorageService } from './local-storage.service';
import { SessionStorageService } from './session-storage.service';

@Injectable({
  providedIn: 'root',
})
export class CartService {
  constructor(
    private appInfo: AppInfoService,
    private budgetService: BudgetService,
    private localStorageService: LocalStorageService,
    public sessionStorageService: SessionStorageService
  ) {}

  async get() {
    try {
      const res = await Fetch('GET', 'cart/');
      return res;
    } catch (err) {
      console.error(err);
    }
  }

  async save(items) {
    try {
      return await Fetch(
        'POST',
        'cart/',
        {
          cartProducts: items?.map((i) => ({
            amount: i?.qt || i?.amount,
            idProduct: i?.productId || i?.product.id,
          })),
        },
        null,
        'text'
      );
    } catch (err) {
      console.error(err);
    }
  }

  async getOrCreateCart(items: Array<CartItem>) {
    try {
      let cart = await this.get();
      if (!cart) {
        await this.save(items);
        cart = await this.get();
      }
      return cart;
    } catch (err) {
      console.error(err);
    }
  }

  async saveItem(item, cart: Cart) {
    const cartItem = await Fetch(
      'POST',
      `cart/add/product/`,
      {
        amount: item?.qt || item?.amount,
        idProduct: item?.productId || item?.product?.id,
        id: cart?.id,
      },
      null,
      'text'
    );
    return cartItem;
  }

  async checkLocal() {
    try {
      const user = await this.sessionStorageService.get(
        StorageConstants.RM_ECOMMERCE_USER
      );
      const itens = await this.getItemsLocalStorage();
      const haveItens = user && !!itens ? true : false;
      if (haveItens) {
        let items: Array<CartItem>;
        try {
          items = [...itens];
        } catch (err) {
          console.warn(err);
          items = [];
        }

        let cart = await this.get();
        if (!cart) {
          await this.save(items);
        } else {
          await Promise.all(
            items.map(async (item) => await this.saveItem(item, cart))
          );
        }
        cart = await this.get();
        await this.localStorageService.removeItem(
          StorageConstants.RM_ECOMMERCE_CART_ITEMS
        );
        return cart;
      }
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  async getItems() {
    try {
      let itens;
      const cartItens: Array<CartItem> = [];
      const idCart = await this.sessionStorageService.get(
        StorageConstants.RM_ECOMMERCE_ID_CART
      );
      if (idCart) {
        itens = await this.prepareBudget(idCart);
        await this.sessionStorageService.removeItem(
          StorageConstants.RM_ECOMMERCE_ID_CART
        );
      } else {
        let cart = await this.checkLocal();
        const user = await this.sessionStorageService.get(
          StorageConstants.RM_ECOMMERCE_USER
        );
        if (user) {
          if (!cart) {
            cart = await this.get();
          }
          itens = cart?.cartProducts;
        } else {
          try {
            itens = (await this.getItemsLocalStorage()) || '[]';
          } catch (err) {
            console.warn(err);
            itens = [];
          }
        }
      }
      if (itens) {
        let product: Product;
        itens.forEach((element) => {
          product = new Product();
          product.fromJson(element?.details || element?.product);
          Product.getAllDescription(this.appInfo.getCategories(), product);
          const cartItem: CartItem = {
            details: product,
            productId: product?.id,
            qt: element?.qt || element?.amount,
            id: element?.id,
          };
          cartItens.push(cartItem);
        });
      }
      return cartItens;
    } catch (err) {
      if (err.status === 404) {
        return [];
      } else {
        console.error(err);
        throw err;
      }
    }
  }

  async addItem(item: CartItem, setQuantity: boolean) {
    try {
      // Para testes
      // await this.sessionStorageService.set(StorageConstants.RM_ECOMMERCE_USER, '');
      const user = await this.sessionStorageService.get(
        StorageConstants.RM_ECOMMERCE_USER
      );
      let itensLocalStorage = [];
      let currentCart;
      if (!!user) {
        try {
          currentCart = await this.getOrCreateCart([item]);
        } catch (error) {
          console.error(error);
        }
        const exist = (await this.getItems()).find(
          (i) => i?.productId === item?.productId
        );
        if (exist) {
          const index = currentCart?.cartProducts?.findIndex(
            (p) => p?.product?.id === item?.productId
          );

          if (setQuantity) {
            item.qt = exist.qt + 1;
          }
          currentCart?.cartProducts?.splice(index, 1);
          exist.qt = item.qt;
          if (exist.id) {
            await this.deleteProduct(exist.id);
            if (exist.qt) {
              await this.saveItem(exist, currentCart);
            }
          } else {
            await this.saveItem(item, currentCart);
          }
        } else {
          await this.saveItem(item, currentCart);
        }
      } else {
        const itens = await this.getItemsLocalStorage();
        if (itens) {
          itensLocalStorage = [...itens];
        }

        const exist = itensLocalStorage.find(
          (i) => i?.productId === item?.productId
        );

        if (exist) {
          const index = itensLocalStorage?.findIndex(
            (i) => i?.productId === item?.productId
          );

          if (setQuantity) {
            item.qt = exist.qt + 1;
          }
          itensLocalStorage.splice(index, 1);
        }
        if (item.qt !== 0) {
          itensLocalStorage.push(item);
        }
        await this.localStorageService.set(
          StorageConstants.RM_ECOMMERCE_CART_ITEMS,
          itensLocalStorage
        );
      }
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  async checkout(freightSelected: FreightSelected) {
    try {
      const cart: Cart = await this.getOrCreateCart([]);
      const cartCheckout: PrepareCheckoutCart = new PrepareCheckoutCart();
      cartCheckout.cartId = cart.id;
      cartCheckout.freightSelected = freightSelected;
      await Fetch('POST', `cart/checkout/`, cartCheckout);
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  async prepareCheckout(freightSelected: FreightSelected) {
    try {
      const cart: Cart = await this.getOrCreateCart([]);
      const cartCheckout: PrepareCheckoutCart = new PrepareCheckoutCart();
      cartCheckout.cartId = cart?.id;
      cartCheckout.freightSelected = freightSelected;
      if (
        cartCheckout?.freightSelected?.optionFreight === Freight.STORE_PICKUP
      ) {
        cartCheckout.freightSelected.freight = null;
      }
      const res = await Fetch('POST', `cart/prepare-checkout/`, cartCheckout);
      return new CartCheckoutDetails(res);
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  async list(filter: CartFilter): Promise<Array<CartFull>> {
    try {
      const response = await Fetch('POST', 'cart/pageable/list/', filter);
      return response?.content;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  async delete(id: number) {
    try {
      const res = await Fetch('DELETE', `cart/${id}`, null, null, 'text');
      return res;
    } catch (err) {
      console.error(err);
    }
  }

  async coupon(idCart: number, coupon: string): Promise<CouponDiscount> {
    try {
      const response = await Fetch(
        'POST',
        `cart/${idCart}/store/coupon/${coupon}/`
      );
      const cd: CouponDiscount = Object.assign(new CouponDiscount(), response);
      cd.coupon = Object.assign(new Coupon(), cd.coupon);
      return cd;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  async prepareBudget(hash) {
    try {
      let items;
      const budget: Budget = await this.budgetService.get(hash);
      items = budget?.cart?.cartProducts;
      await this.localStorageService.set(
        StorageConstants.RM_ECOMMERCE_ZIP_CODE,
        budget.zipCode
      );
      await this.localStorageService.removeItem(
        StorageConstants.RM_ECOMMERCE_CART_ITEMS
      );
      const user = await this.sessionStorageService.get(
        StorageConstants.RM_ECOMMERCE_USER
      );
      if (user) {
        let cart = await this.get();
        if (cart) {
          await Promise.all(
            cart.cartProducts.map((item) => this.deleteProduct(item.id))
          );
          await Promise.all(items.map((item) => this.saveItem(item, cart)));
        } else {
          cart = await this.save(items);
        }
      } else {
        await this.localStorageService.set(
          StorageConstants.RM_ECOMMERCE_CART_ITEMS,
          items
        );
      }
      return items;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  async deleteProduct(id) {
    await Fetch('DELETE', `cart/remove/product/${id}`, null, null, 'text');
  }

  async getItemsLocalStorage() {
    const carts = await this.localStorageService.get(
      StorageConstants.RM_ECOMMERCE_CART_ITEMS
    );
    return carts || [];
  }

  async deleteCarts(carts: Array<CartFull>) {
    try {
      await Promise.all(carts.map((c) => this.delete(c.id)));
    } catch (err) {
      console.error(err);
    }
  }
}
