export default class InformationSection {
    id?: number;
    title?: string;
    description?: string;
    orderDisplay?: number;
    selected?: boolean;

    fromJson(section: any) {
        if (section) {
            this.id = section.id;
            this.title = section.title;
            this.description = section.description;
            this.orderDisplay = section.orderDisplay;
        }
    }
}
