import { Component, OnInit } from '@angular/core';
import { StorageConstants } from 'src/services/constants/storage-constants';
import { SessionStorageService } from 'src/services/session-storage.service';
import { UserService } from 'src/services/user.service';
import { BlockOperation } from 'src/util/block-operation';
import { validPassword } from 'src/util/validators';
import { ToastService } from './../../../services/toast/toast.service';
import { TWO_SECONDS_IN_MS, wait } from './../../../util/wait';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss'],
})
export class ForgotComponent implements OnInit {
  public blockOperation = new BlockOperation();
  public emailForgot = '';
  public tokenForgot = '';
  public password = '';
  public confirmPassword = '';
  public showPassword = false;
  public showConfirmPassword = false;
  public errorPassword = false;
  public errorConfirmPassword = false;

  constructor(
    public userService: UserService,
    private toastService: ToastService,
    public sessionStorageService: SessionStorageService
  ) {}

  async ngOnInit() {
    this.emailForgot = await this.sessionStorageService.get(
      StorageConstants.RM_ECOMMERCE_EMAIL_FORGOT
    );
    this.tokenForgot = await this.sessionStorageService.get(
      StorageConstants.RM_ECOMMERCE_TOKEN_FORGOT
    );

    if (!this.emailForgot && !this.tokenForgot) {
      this.toastService.error(
        'Token inválido! Solicite novamente uma nova senha!'
      );
      await wait(TWO_SECONDS_IN_MS);
      this.userService.goToLogin();
    }
  }

  async onChange() {
    if (!this.validationUser()) {
      this.toastService.error('Informe sua senha e confirmação!');
      return;
    }

    const msg = validPassword(this.password, this.confirmPassword);
    if (msg) {
      this.toastService.error(msg);
      return;
    }

    try {
      this.blockOperation.setBlockOperation('', true);
      await this.userService.changePassword(
        this.emailForgot,
        this.tokenForgot,
        this.password
      );
      this.toastService.success('Sua senha foi alterada com sucesso!');
      await wait(TWO_SECONDS_IN_MS);
      this.userService.goToLogin();
    } catch (error) {
      this.toastService.error(error.message);
      await wait(TWO_SECONDS_IN_MS);
      this.userService.goToLogin();
    } finally {
      this.blockOperation.setBlockOperation('', false);
    }
  }

  validationUser() {
    let valid = true;
    if (!this.password) {
      this.errorPassword = true;
      valid = false;
    } else {
      this.errorPassword = false;
    }

    if (!this.confirmPassword) {
      this.errorConfirmPassword = true;
      valid = false;
    } else {
      this.errorConfirmPassword = false;
    }
    return valid;
  }
}
