import { Injectable } from '@angular/core';
import { OrderStatusItem } from 'src/app/components/order-status/order-status.component';
import { BlindsOptionsEnum } from 'src/app/models/InfoEnum';
import MarketplaceTransaction from 'src/app/models/MarketplaceTransaction';
import { CartItem } from 'src/app/models/cart/cart';
import Freight from 'src/app/models/freight/Freight';
import OrderBuyer from 'src/app/models/order/OrderBuyer';
import OrderCancel from 'src/app/models/order/OrderCancel';
import OrderFilter from 'src/app/models/order/OrderFilter';
import OrderGrid from 'src/app/models/order/OrderGrid';
import OrderNFE from 'src/app/models/order/OrderNFE';
import OrderShipping from 'src/app/models/order/OrderShipping';
import OrderTracking from 'src/app/models/order/OrderTracking';
import OrderTrackingDelivery from 'src/app/models/order/OrderTrackingDelivery';
import OrderTransaction from 'src/app/models/order/OrderTransaction';
import Order, { OrderEvent, orderStatus } from 'src/app/models/order/order';
import Product from 'src/app/models/product/product';
import { AppInfoService } from './app-info.service';
import Fetch from './fetch';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  constructor(private appInfo: AppInfoService) {}

  canProceed(order: Order) {
    const noNext = ['CANCELED', 'CONCLUDED'];
    const status: OrderStatusItem = order.statuses
      .filter((s) => s.id)
      .slice()
      .pop();

    return noNext.indexOf(status ? status.status : '') === -1;
  }

  getNextStatus(order: Order) {
    const status = order.statuses
      .filter((s) => s.id)
      .slice()
      .pop().status;
    const keys = Object.keys(orderStatus);
    const next = keys.indexOf(status) + 1;

    return keys[next];
  }

  getStatusCanceled() {
    return 'CANCELED';
  }

  getItemStatusCanceled(orderHistories: Array<OrderEvent>) {
    const status: OrderEvent = orderHistories?.find(
      (s) => s.updated === 'CANCELED'
    );
    return status?.createdAt || '';
  }

  getNextStatusDescription(order: Order) {
    if (this.canProceed(order)) {
      const next = this.getNextStatus(order);
      return orderStatus[next];
    }

    return null;
  }

  getStatusName(statusId) {
    return orderStatus[statusId] || 'Operação';
  }

  getOrderStatuses(orderHistories: Array<OrderEvent>): Array<OrderStatusItem> {
    orderHistories = orderHistories || [];
    return orderStatus.order.map((currentStatus, index) => {
      const happened = orderHistories.find(
        (event) => event.updated === currentStatus
      );
      const isFirst = index === 0;
      let date;
      if (isFirst) {
        // Se for o primeiro status, ele pega a data do próximo.
        const firstOrderHist = orderHistories.find(
          (h) => h.updated === 'PENDING'
        ); // FIXME
        date = firstOrderHist ? firstOrderHist.createdAt : null;
      } else {
        // Não é o primeiro item, tem data.
        date = happened ? happened.createdAt : null;
      }
      return {
        id: happened ? happened.id : null,
        title: this.getStatusName(currentStatus),
        status: happened?.updated,
        date,
      };
    });
  }

  async wsToOrder(item) {
    const order: Order = {
      ...item,
      createdAt: item?.createdAt,
      updatedAt:
        item?.updatedAt || item?.item?.updatedAt
          ? item?.updatedAt || item?.item?.updatedAt
          : null,

      orderHistories: item.orderHistories,
      statuses: this.getOrderStatuses(item.orderHistories),
      statusCanceled: this.getItemStatusCanceled(item.orderHistories),
      orderTransaction: item.orderTransaction
        ? Object.assign(new OrderTransaction(), item.orderTransaction)
        : null,
      orderNFE: item.orderNFE
        ? Object.assign(new OrderNFE(), item.orderNFE)
        : null,
      marketplaceTransaction: item.marketplaceTransaction
        ? Object.assign(
            new MarketplaceTransaction(),
            item.marketplaceTransaction
          )
        : null,
      orderTracking: item.orderTracking
        ? Object.assign(new OrderTracking(), item.orderTracking)
        : null,
      orderCanceled: item.orderCanceled
        ? Object.assign(new OrderCancel(), item.orderCanceled)
        : null,
      orderBuyer: item.orderBuyer
        ? Object.assign(new OrderBuyer(), item.orderBuyer)
        : null,
      orderShipping: item.orderShipping
        ? Object.assign(new OrderShipping(), item.orderShipping)
        : null,
      cartProducts: item.cartProducts.map((itemCart) => {
        let product: Product;
        product = new Product();
        product.fromJson(itemCart.details || itemCart.product);
        Product.getAllDescription(this.appInfo.getCategories(), product);
        const cartItem: CartItem = {
          details: product,
          qt: itemCart.amount,
          productId: product.id,
          id: itemCart.id,
          value: itemCart.value,
          discount: itemCart.discount,
          total: itemCart.total,
          controlSide: itemCart?.controlSide,
          controlSideDescription:
            itemCart?.controlSide === BlindsOptionsEnum.CONTROL_SIDE_LEFT
              ? 'Esquerdo'
              : itemCart?.controlSide === BlindsOptionsEnum.CONTROL_SIDE_RIGHT
              ? 'Direito'
              : '',
          drive: itemCart?.drive,
          driveDescription:
            itemCart?.drive === BlindsOptionsEnum.DRIVE_MOTOR
              ? 'Motorizado'
              : itemCart?.drive === BlindsOptionsEnum.DRIVE_MANUAL
              ? 'Manual'
              : '',
          panel: itemCart?.panel,
          panelDescription:
            itemCart?.panel === BlindsOptionsEnum.WITH_PANEL
              ? 'Com Bandô'
              : itemCart?.panel === BlindsOptionsEnum.WITHOUT_PANEL
              ? 'Sem Bandô'
              : '',
          typeFixing: itemCart?.typeFixing,
          typeFixingDescription:
            itemCart?.typeFixing === BlindsOptionsEnum.TYPE_FIXING_WALL
              ? 'Na parede'
              : itemCart?.typeFixing === BlindsOptionsEnum.TYPE_FIXING_CEILING
              ? 'No teto'
              : '',
        };
        return cartItem;
      }),
    };
    await this.formatOrder(order);
    return order;
  }

  wsToOrderGrid(item) {
    const orderGrid: OrderGrid = Object.assign(new OrderGrid(), item);
    orderGrid.marketPlace = orderGrid?.marketPlace?.toLowerCase();
    const mkts = orderGrid.marketPlace.split('_');
    orderGrid.marketPlace =
      mkts?.length === 1 ? mkts[0] : `${mkts[0]} ${mkts[1]}`;
    orderGrid.shippingCompany = orderGrid?.shippingCompany?.toLowerCase();
    orderGrid.nameUser = orderGrid?.nameUser?.toLowerCase();
    orderGrid.currentStatus = this.getStatusName(item.currentStatus);
    orderGrid.daysToDelivery = this.getDifferenceInDays(orderGrid);
    return orderGrid;
  }

  getDifferenceInDays(order: OrderGrid) {
    if (
      order.currentStatus !== orderStatus.CONCLUDED &&
      order.currentStatus !== orderStatus.CANCELED &&
      order?.estimateDate
    ) {
      let dateString = order?.estimateDate.substring(0, 10);
      let [day, month, year] = dateString?.split('/');
      const deliveryDate = new Date(+year, +month - 1, +day);

      const nowDate = new Date();
      nowDate.setHours(0, 0, 0, 0);
      const diffInMilliseconds = deliveryDate.getTime() - nowDate.getTime();
      const differenceInDays = Math.floor(
        diffInMilliseconds / (1000 * 60 * 60 * 24)
      );

      return differenceInDays;
    } else {
      return 100;
    }
  }

  async getOrdersNew(filter: OrderFilter): Promise<Array<OrderGrid>> {
    try {
      const res = await Fetch('POST', `order/pageable/grid/list`, filter);
      return res.content.map((item) => this.wsToOrderGrid(item));
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  async getOrders(filter: OrderFilter): Promise<Array<Order>> {
    try {
      const res = await Fetch('POST', `order/pageable/list`, filter);
      return res.content.map((item) => this.wsToOrder(item));
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  async getOrder(id): Promise<Order> {
    try {
      const res = await Fetch('GET', `order/${id}/`);
      return this.wsToOrder(res);
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  async update(orderId: number, { administratorNotes }) {
    try {
      await Fetch('PUT', `order/${orderId}`, { administratorNotes });
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  async updateOrderNFE(orderId: number, nfe: OrderNFE): Promise<Order> {
    try {
      const updated = await Fetch('PUT', `order/${orderId}/nfe/send/`, nfe);
      return this.wsToOrder(updated);
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  async invoiceOLD(orderId: number, nfe: OrderNFE) {
    try {
      const updated = await Fetch('PUT', `order/${orderId}/nfe/`, nfe);
      return this.wsToOrder(updated);
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
  async sendXMLOLD(orderId: number, xml: string) {
    try {
      const updated = await Fetch('PUT', `order/${orderId}/nfe/xml/`, xml);
      return this.wsToOrder(updated);
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  public async changeStatus(order: Order, status: string): Promise<Order> {
    try {
      const updated = await Fetch('PUT', `order/${order.id}/status/`, {
        currentStatus: status,
      });
      return this.wsToOrder(updated);
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  public async cancelOrder(order: Order, details: string): Promise<Order> {
    try {
      const updated = await Fetch('PUT', `order/${order.id}/cancel/`, {
        cancelDetails: details,
      });
      return this.wsToOrder(updated);
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  public async updateTracking(
    idOrder: number,
    orderTracking: OrderTrackingDelivery
  ): Promise<Order> {
    try {
      const updated = await Fetch(
        'PUT',
        `order/${idOrder}/tracking/`,
        orderTracking
      );
      return this.wsToOrder(updated);
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  private formatOrder(order: Order) {
    if (order?.orderCanceled) {
      order.administratorNotes = `Motivo do cancelamento: ${
        order.orderCanceled?.cancelDetails
      }\nData do Cancelamento: ${order?.orderCanceled?.cancelDate}
      \n${order?.administratorNotes || ''}`;
    }
    this.getDtEstimate(order);
  }

  private getDtEstimate(order: Order) {
    if (order?.orderTracking) {
      if (order?.orderTracking?.estimateDate) {
        order.dtEstimate = order?.orderTracking?.estimateDate;
      }
    } else {
      if (order?.orderShipping) {
        if (order?.orderShipping?.optionFreight === Freight.STORE_PICKUP) {
          if (order?.orderShipping?.dateStorePickup) {
            order.dtEstimate = order?.orderShipping?.dateStorePickup;
          }
        }
      }
    }
  }
}
