import { Component, Input } from '@angular/core';
import { BlockOperation } from 'src/util/block-operation';

@Component({
    selector: 'app-block-loader',
    template: `
      <div *ngIf="blockOperation.blocked" class="block-loader" tabindex="-1" role="dialog">
          <div>
              <img src="assets/img/loading.svg" alt="Carregando..." *ngIf="!progress" width="150px">
              <div class="progress" *ngIf="progress">
                  <div class="progress-bar progress-bar-striped"
                       role="progressbar"
                       [ngStyle]="{width: progress+'%'}"></div>
              </div>
              <h1>{{blockOperation.message}}</h1>
          </div>
      </div>
    `
  })

  export class BlockLoaderComponent {

    public message = 'Carregando...';

    @Input()
    public progress = null;

    @Input()
    public blockOperation = new BlockOperation();
}
