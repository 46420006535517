export class StorageConstants {
  public static readonly RM_ECOMMERCE_CART_CHECKOUT = 'rmEcommerceCartCheckout';
  public static readonly RM_ECOMMERCE_CART_ITEMS = 'rmEcommerceCartItems';
  public static readonly RM_ECOMMERCE_CATEGORY = 'rmEcommerceCategory';
  public static readonly RM_ECOMMERCE_EMAIL_FORGOT = 'rmEcommerceEmailForgot';
  public static readonly RM_ECOMMERCE_FREIGHT = 'rmEcommerceFreight';
  public static readonly RM_ECOMMERCE_ID_CART = 'rmEcommerceIdCart';
  public static readonly RM_ECOMMERCE_ID_PRODUCT = 'rmEcommerceIdProduct';
  public static readonly RM_ECOMMERCE_PAGE = 'rmEcommerceFrontendPage';
  public static readonly RM_ECOMMERCE_PRODUCT_FILTER =
    'rmEcommerceProductFilter';
  public static readonly RM_ECOMMERCE_QUERY_SEARCH = 'rmEcommerceQuerySearch';
  public static readonly RM_ECOMMERCE_REQUEST_BUDGET =
    'rmEcommerceRequestBudget';
  public static readonly RM_ECOMMERCE_SUB_CATEGORY = 'rmEcommerceSubCategory';
  public static readonly RM_ECOMMERCE_TOKEN = 'rmEcommerceToken';
  public static readonly RM_ECOMMERCE_TOKEN_FORGOT = 'rmEcommerceTokenForgot';
  public static readonly RM_ECOMMERCE_USER = 'rmEcommerceUser';
  public static readonly RM_ECOMMERCE_ZIP_CODE = 'rmEcommerceZipCode';
}
