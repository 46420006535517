<div class="rm-ecommerce-home-menu-all-products">
  <app-block-loader [blockOperation]="blockOperation"></app-block-loader>
  <div class="rm-ecommerce-home-header">
    <h1 *ngIf="subpage === 'form'">{{ model.name || 'Novo produto' }}</h1>
  </div>

  <div *ngIf="subpage === 'grid'">
    <p-table
      #tableProducts
      [value]="visibleProducts"
      [(selection)]="selectedProducts"
      [metaKeySelection]="true"
      [columns]="cols"
      [scrollable]="true"
      [virtualScroll]="true"
      selectionMode="multiple"
      dataKey="id"
      styleClass="p-datatable-gridlines"
      scrollHeight="80vh"
      [globalFilterFields]="[
        'id',
        'name',
        'lastSincronizedPluggtoHubDate',
        'color',
        'value',
        'term',
        'brand',
        'model',
        'discount',
        'stockBalance',
        'featured',
        'dimensions',
        'category',
        'subcategory',
        'lastSincronizedOmieDate',
        'lastSincronizedFreterapidoDate'
      ]"
    >
      <ng-template pTemplate="caption">
        <div class="table-header-search">
          <div class="icons">
            <span class="p-input-icon-left ml-auto">
              <i class="pi pi-search"></i>
              <input
                #filterGridInput
                pTooltip="Pesquise qualquer campo do grid"
                tooltipPosition="bottom"
                pInputText
                type="text"
                [(ngModel)]="filterGrid"
                (input)="tableProducts.filterGlobal(filterGrid, 'contains')"
                placeholder="Pesquisar"
              />
            </span>
          </div>
          <p-dropdown
            [options]="categories"
            (onChange)="keyUpFilter($event?.value, 'categories')"
            [(ngModel)]="productFilterScreen?.categories[0]"
            optionLabel="description"
            optionValue="id"
            inputId="float-label"
            placeholder="Selecione uma categoria"
          ></p-dropdown>
          <p-dropdown
            [options]="selectedSegmento?.subCategories"
            (onChange)="keyUpFilter($event?.value, 'subCategories')"
            [(ngModel)]="productFilterScreen?.subCategories[0]"
            optionLabel="description"
            optionValue="id"
            inputId="float-label"
            placeholder="Selecione uma subcategoria"
            [showClear]="true"
          ></p-dropdown>
          <p-button
            label="FILTRO"
            severity="warning"
            [ngClass]="{ 'btn-filter-with-data': withDataFilter() }"
            (click)="showFilter = true"
          ></p-button>
          <button
            type="button"
            pButton
            pRipple
            icon="pi pi-download"
            (click)="tableProducts.exportCSV()"
            pTooltip="Exportar para CSV"
            tooltipPosition="bottom"
          ></button>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th style="min-width: 70px" pSortableColumn="id">
            SKU<p-sortIcon field="id"></p-sortIcon>
          </th>
          <th
            style="min-width: 130px"
            pSortableColumn="lastSincronizedPluggtoHubDate"
          >
            Sinc Hub<p-sortIcon
              field="lastSincronizedPluggtoHubDate"
            ></p-sortIcon>
          </th>
          <th
            style="min-width: 130px"
            pSortableColumn="lastSincronizedOmieDate"
          >
            Sinc Omie<p-sortIcon field="lastSincronizedOmieDate"></p-sortIcon>
          </th>
          <th
            style="min-width: 130px"
            pSortableColumn="lastSincronizedFreterapidoDate"
          >
            Sinc FR<p-sortIcon
              field="lastSincronizedFreterapidoDate"
            ></p-sortIcon>
          </th>
          <th style="min-width: 330px" pSortableColumn="name">
            Título<p-sortIcon field="name"></p-sortIcon>
          </th>
          <th style="min-width: 100px" pSortableColumn="value">
            Valor<p-sortIcon field="value"></p-sortIcon>
          </th>
          <th style="min-width: 90px" pSortableColumn="stockBalance">
            Estoque<p-sortIcon field="stockBalance"></p-sortIcon>
          </th>
          <th style="min-width: 100px" pSortableColumn="discount">
            Desconto<p-sortIcon field="discount"></p-sortIcon>
          </th>
          <th style="min-width: 80px" pSortableColumn="term">
            Prazo<p-sortIcon field="term"></p-sortIcon>
          </th>
          <th style="min-width: 100px" pSortableColumn="featured">
            Destaque<p-sortIcon field="featured"></p-sortIcon>
          </th>
          <th style="min-width: 150px" pSortableColumn="dimensions">
            Dimensão<p-sortIcon field="dimensions"></p-sortIcon>
          </th>
          <th style="min-width: 160px" pSortableColumn="category">
            Categoria<p-sortIcon field="category"></p-sortIcon>
          </th>
          <th style="min-width: 160px" pSortableColumn="subcategory">
            Subcategoria<p-sortIcon field="subcategory"></p-sortIcon>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-product let-rowIndex="rowIndex">
        <tr
          [pSelectableRow]="product"
          [pSelectableRowIndex]="rowIndex"
          (dblclick)="rowDoubleClicked(product, rowIndex)"
        >
          <td>{{ product?.id }}</td>
          <td>{{ product?.lastSincronizedPluggtoHubDate }}</td>
          <td>{{ product?.lastSincronizedOmieDate }}</td>
          <td>{{ product?.lastSincronizedFreterapidoDate }}</td>
          <td>{{ product?.name }}</td>
          <td>{{ product?.value | currency }}</td>
          <td>{{ product?.stockBalance }}</td>
          <td>{{ product?.discount }}</td>
          <td>
            {{ product?.daysOnlyOrder < 1 ? '' : product?.daysOnlyOrder }}
          </td>

          <td>{{ product?.featured ? 'Sim' : 'Não' }}</td>
          <td>{{ product?.dimensions }}</td>
          <td>{{ product?.categoryDescription }}</td>
          <td>{{ product?.subCategoryDescription }}</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="summary">
        Total: {{ visibleProducts.length }} produtos | Selecionados:
        {{ selectedProducts?.length }} produtos
        <div class="products-footer-btn">
          <!--button
            pButton
            pRipple
            [label]="'IMAGENS S3'"
            icon="pi pi-plus-circle"
            class="p-button-danger"
            (click)="onClickUpdateImgS3()"
          ></button-->
          <button
            pButton
            pRipple
            [label]="'NOVO'"
            icon="pi pi-plus-circle"
            class="p-button-success"
            (click)="onClickNew()"
          ></button>
          <button
            pButton
            pRipple
            [label]="'SINC FRETE'"
            icon="pi pi-arrow-circle-up"
            class="p-button-secondary"
            (click)="onClickSynchronizeWithFR()"
          ></button>
          <button
            pButton
            pRipple
            [label]="'SINC PLUG'"
            icon="pi pi-arrow-circle-up"
            class="p-button-secondary"
            (click)="onClickSynchronizeWithHubIntegration(false)"
          ></button>
          <button
            pButton
            pRipple
            [label]="'SINC OMIE'"
            icon="pi pi-arrow-circle-up"
            class="p-button-secondary"
            (click)="onClickSynchronizeWithOmie()"
          ></button>
          <label for="file-products" pButton pRipple icon="pi pi-file-import"
            >PRODUTOS EM LOTE</label
          >
          <input
            type="file"
            (change)="onFileChange($event)"
            name="file-products"
            id="file-products"
            multiple="false"
            #fileInput
          />
        </div>
      </ng-template>
    </p-table>
  </div>

  <div *ngIf="subpage === 'form'">
    <div class="rm-ecommerce-subpage-header">
      <button
        class="rm-button"
        (click)="clickBack()"
        title="Voltar para a listagem"
      >
        VOLTAR
      </button>
    </div>

    <div class="register-products">
      <div class="d-flex">
        <div class="inf-basica">
          <label>Informações Básicas</label>
          <div class="d-flex">
            <div class="item item-15">
              <label>ID (SKU)</label>
              <input
                type="text"
                autofocus
                (change)="model.id = $event.target.value"
                [value]="model.id || ''"
                [disabled]="true"
              />
            </div>
            <div
              class="item item-85"
              [ngClass]="{
                'more-than-60-characters': model?.name?.length > 60
              }"
            >
              <label>{{
                'Nome - ' + (model?.name || '')?.substring(0, 59)
              }}</label>
              <input
                type="text"
                [maxLength]="100"
                name="name"
                [(ngModel)]="model.name"
                [ngClass]="{ error: !!validation.errorName }"
              />
            </div>
          </div>
          <div class="d-flex">
            <div class="item">
              <label>Categoria</label>
              <app-combo
                [emptyLabel]="'Selecione a Categoria'"
                [options]="categories"
                (change)="changeCategory($event)"
                [value]="model.category"
              ></app-combo>
            </div>

            <div class="item">
              <label>Subcategoria</label>
              <app-combo
                [emptyLabel]="'Selecione a Subcategoria'"
                [options]="selectedSegmento?.subCategories"
                (change)="changeSubCategory($event)"
                [value]="model.subCategory"
              ></app-combo>
            </div>
          </div>
          <div class="d-flex">
            <div class="item">
              <label>Un. Comercializada</label>
              <app-combo
                [options]="selectedSubCategory?.unitsCommercialize"
                (change)="model.unitCommercialize = $event.target.value"
                [value]="model.unitCommercialize"
              ></app-combo>
            </div>
            <div class="item">
              <label>Modelo</label>
              <app-combo
                [options]="selectedSubCategory?.models"
                (change)="model.model = $event.target.value"
                [value]="model.model"
              ></app-combo>
            </div>
          </div>
          <div class="d-flex">
            <div class="item">
              <label>Valor</label>
              <input
                type="number"
                step="0.01"
                (change)="model.value = $event.target.value"
                [value]="model.value || ''"
              />
            </div>
            <div class="item">
              <label>Saldo Estoque</label>
              <input
                type="text"
                appNumberOnly
                (change)="model.stockBalance = $event.target.value"
                [value]="model.stockBalance"
                [ngClass]="{ error: !!validation.errorStockBalance }"
              />
            </div>
          </div>
          <div class="d-flex">
            <div class="item">
              <label>Desconto em %</label>
              <input
                type="number"
                step="0.01"
                (change)="model.discount = $event.target.value"
                [value]="model.discount || ''"
              />
            </div>
            <div class="item">
              <label>Faixa Destaque</label>
              <input
                type="text"
                [maxLength]="20"
                (change)="model.featuredBanner = $event.target.value"
                [value]="model.featuredBanner || ''"
              />
            </div>
            <div class="item">
              <label>SKU Similar</label>
              <input
                appNumberOnly
                type="text"
                [maxLength]="6"
                (change)="model.similarProduct = $event.target.value"
                [value]="model.similarProduct || ''"
              />
            </div>
            <div class="item">
              <label>Código de Integração</label>
              <input
                type="text"
                (change)="model.hubIntegrationCode = $event.target.value"
                [value]="model.hubIntegrationCode || ''"
                [disabled]="model?.id > 0"
              />
            </div>
          </div>
        </div>
        <div class="visibilidade">
          <label>Visibilidade</label>
          <div class="d-flex chk">
            <div
              class="item item-100"
              (click)="model.enabled = !model.enabled; $event.stopPropagation()"
            >
              <input
                type="checkbox"
                [checked]="model.enabled"
                (click)="
                  model.enabled = !model.enabled; $event.stopPropagation()
                "
              />
              <label>Ativo</label>
            </div>
          </div>
          <div class="d-flex chk">
            <div
              class="item item-100"
              (click)="
                model.productPerSquareMeter = !model.productPerSquareMeter;
                $event.stopPropagation()
              "
            >
              <input
                type="checkbox"
                [checked]="model.productPerSquareMeter"
                (click)="
                  model.productPerSquareMeter = !model.productPerSquareMeter;
                  $event.stopPropagation()
                "
              />
              <label>Produto por Metro Quadrado</label>
            </div>
          </div>
          <div class="d-flex chk">
            <div
              class="item item-100"
              (click)="
                model.activeOnHub = !model.activeOnHub; $event.stopPropagation()
              "
            >
              <input
                type="checkbox"
                [checked]="model.activeOnHub"
                (click)="
                  model.activeOnHub = !model.activeOnHub;
                  $event.stopPropagation()
                "
              />
              <label>Ativo no Hub</label>
            </div>
          </div>
          <div class="d-flex chk">
            <div
              class="item item-100"
              (click)="
                model.mainVariation = !model.mainVariation;
                $event.stopPropagation()
              "
            >
              <input
                type="checkbox"
                [checked]="model.mainVariation"
                (click)="
                  model.mainVariation = !model.mainVariation;
                  $event.stopPropagation()
                "
              />
              <label>Principal</label>
            </div>
          </div>
          <div class="d-flex chk">
            <div
              class="item item-100"
              (click)="
                model.canBeGeneratedWithStockZero =
                  !model.canBeGeneratedWithStockZero;
                $event.stopPropagation()
              "
            >
              <input
                type="checkbox"
                [checked]="model.canBeGeneratedWithStockZero"
                (click)="
                  model.canBeGeneratedWithStockZero =
                    !model.canBeGeneratedWithStockZero;
                  $event.stopPropagation()
                "
              />
              <label>Vender Sem Estoque</label>
            </div>
          </div>
          <div class="d-flex">
            <div class="item item-100">
              <label>Prazo Encomenda (Dias)</label>
              <input
                type="number"
                step="1"
                (change)="model.daysOnlyOrder = $event.target.value"
                [value]="model.daysOnlyOrder || ''"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="variacoes">
        <label>Características do Produto</label>
        <div class="d-flex">
          <label (click)="model.productType = 1">
            <input type="radio" [checked]="model.productType === 1" />PRODUTO
          </label>
          <label (click)="model.productType = 2">
            <input type="radio" [checked]="model.productType === 2" />KIT
          </label>
        </div>
        <div class="d-flex">
          <div class="item item-25">
            <label>Altura/Espessura (em cm)</label>
            <app-combo
              [options]="selectedSubCategory?.heightThickness"
              (change)="model.heightThickness = $event.target.value"
              [value]="model.heightThickness"
            ></app-combo>
          </div>

          <div class="item item-25">
            <label>Largura (em cm)</label>
            <app-combo
              [options]="selectedSubCategory?.widths"
              (change)="model.width = $event.target.value"
              [value]="model.width"
            ></app-combo>
          </div>

          <div class="item item-25">
            <label
              >{{ model.productType === 1 ? 'Comprimento' : 'Avanço' }} (em
              cm)</label
            >
            <app-combo
              [options]="selectedSubCategory?.lengths"
              (change)="model.length = $event.target.value"
              [value]="model.length"
            ></app-combo>
          </div>

          <div class="item item-25">
            <label>Cor</label>
            <app-combo
              [options]="selectedSubCategory?.colors"
              (change)="model.color = $event.target.value"
              [value]="model.color"
            ></app-combo>
          </div>
        </div>

        <div class="d-flex">
          <div class="item item-25">
            <label>Acabamento</label>
            <app-combo
              [options]="selectedSubCategory?.finishes"
              (change)="model.finish = $event.target.value"
              [value]="model.finish"
            ></app-combo>
          </div>

          <div class="item item-25">
            <label>Espessura da Parede do Alumínio</label>
            <app-combo
              [options]="selectedSubCategory?.aluminumWallThickness"
              (change)="model.aluminumWallThickness = $event.target.value"
              [value]="model.aluminumWallThickness"
            ></app-combo>
          </div>

          <div class="item item-25">
            <label>Peso</label>
            <input
              type="number"
              step="0.01"
              (change)="model.weight = $event.target.value"
              [value]="model.weight || ''"
            />
          </div>

          <div class="item item-25">
            <label>Marca</label>
            <app-combo
              [options]="selectedSubCategory?.brands"
              (change)="model.brand = $event.target.value"
              [value]="model.brand"
            ></app-combo>
          </div>
        </div>

        <div *ngIf="model.category === 'MOTORES'" class="d-flex">
          <div class="item item-25">
            <label>Voltagem</label>
            <app-combo
              [options]="selectedSubCategory?.voltages"
              (change)="model.voltage = $event.target.value"
              [value]="model.voltage"
            ></app-combo>
          </div>

          <div class="item item-25">
            <label>Potência/Torque</label>
            <app-combo
              [options]="selectedSubCategory?.potencies"
              (change)="model.potency = $event.target.value"
              [value]="model.potency"
            ></app-combo>
          </div>

          <div class="item item-25">
            <label>Capacidade em KG</label>
            <app-combo
              [options]="selectedSubCategory?.capabilities"
              (change)="model.capacity = $event.target.value"
              [value]="model.capacity"
            ></app-combo>
          </div>

          <div class="item item-25">
            <label>Velocidade</label>
            <app-combo
              [options]="selectedSubCategory?.speeds"
              (change)="model.speed = $event.target.value"
              [value]="model.speed"
            ></app-combo>
          </div>
        </div>

        <div *ngIf="showFieldsActuation()" class="d-flex">
          <div class="item item-25">
            <label>Composição do Tecido</label>
            <app-combo
              [options]="selectedSubCategory?.fabricCompositions"
              (change)="model.fabricComposition = $event.target.value"
              [value]="model.fabricComposition"
            ></app-combo>
          </div>

          <div class="item item-25">
            <label>Tipo de Acionamento</label>
            <app-combo
              [options]="selectedSubCategory?.actuationTypes"
              (change)="model.actuationType = $event.target.value"
              [value]="model.actuationType"
            ></app-combo>
          </div>

          <div class="item item-25">
            <label>Lado do Acionamento</label>
            <app-combo
              [options]="selectedSubCategory?.actuationSides"
              (change)="model.actuationSide = $event.target.value"
              [value]="model.actuationSide"
            ></app-combo>
          </div>
        </div>

        <div *ngIf="model.productType === 2" class="d-flex">
          <div class="item item-25">
            <label>Quantidade de caibros</label>
            <input
              type="text"
              appNumberOnly
              (change)="model.amountRafters = $event.target.value"
              [value]="model.amountRafters || ''"
            />
          </div>

          <div class="item item-25">
            <label>Quantidade de fixação</label>
            <input
              type="text"
              appNumberOnly
              (change)="model.amountFixation = $event.target.value"
              [value]="model.amountFixation || ''"
            />
          </div>
        </div>
      </div>

      <div class="envio">
        <label>Dados de Envio (Embalagem)</label>
        <div *ngIf="model.productType === 1" class="d-flex">
          <div class="item item-20">
            <label>Largura (em cm)</label>
            <input
              type="number"
              step="0.01"
              (change)="model.packingWidth = $event.target.value"
              [value]="model.packingWidth || ''"
            />
          </div>

          <div class="item item-20">
            <label>Comprimento (em cm)</label>
            <input
              type="number"
              step="0.01"
              (change)="model.packingLength = $event.target.value"
              [value]="model.packingLength || ''"
            />
          </div>

          <div class="item item-20">
            <label>Altura (em cm)</label>
            <input
              type="number"
              step="0.01"
              (change)="model.packingHeightThickness = $event.target.value"
              [value]="model.packingHeightThickness || ''"
            />
          </div>

          <div class="item item-20">
            <label>Peso (em kg)</label>
            <input
              type="number"
              step="0.01"
              (change)="model.packingWeight = $event.target.value"
              [value]="model.packingWeight || ''"
            />
          </div>

          <div class="item item-20">
            <label>Máx. Peças/Volume</label>
            <input
              type="number"
              step="1"
              (change)="model.qtdMaxPiecesPerVolume = $event.target.value"
              [value]="model.qtdMaxPiecesPerVolume || ''"
              [ngClass]="{
                error: !!validation.errorQtdMaxPiecesPerVolume
              }"
            />
          </div>
        </div>

        <div *ngIf="model.productType === 2">
          <div
            *ngFor="let volume of model.volumes; let i = index"
            class="d-flex align-end"
          >
            <div class="item item-20">
              <label>Altura (em cm)</label>
              <input
                type="number"
                step="0.01"
                (change)="volume.packingHeightThickness = $event.target.value"
                [value]="volume.packingHeightThickness || ''"
              />
            </div>

            <div class="item item-20">
              <label>Largura (em cm)</label>
              <input
                type="number"
                step="0.01"
                (change)="volume.packingWidth = $event.target.value"
                [value]="volume.packingWidth || ''"
              />
            </div>

            <div class="item item-20">
              <label>Comprimento (em cm)</label>
              <input
                type="number"
                step="0.01"
                (change)="volume.packingLength = $event.target.value"
                [value]="volume.packingLength || ''"
              />
            </div>

            <div class="item item-20">
              <label>Peso (em kg)</label>
              <input
                type="number"
                step="0.01"
                [disabled]="true"
                (change)="volume.packingWeight = $event.target.value"
                [value]="volume.packingWeight || ''"
              />
            </div>
            <div class="item item-20">
              <button class="rm-button" (click)="removeVolume(i)">
                Excluir
              </button>
            </div>
          </div>
          <div class="d-flex justify-end p-r-10">
            <button class="rm-button" (click)="newVolume()">Novo Volume</button>
          </div>
        </div>
      </div>

      <div class="fiscais">
        <label>Dados Fiscais e Garantia</label>
        <div class="d-flex">
          <div class="item item-33">
            <label>Origem</label>
            <app-combo
              [options]="origins"
              (change)="model.origin = $event.target.value"
              [value]="model.origin"
            ></app-combo>
          </div>

          <div class="item item-22">
            <label>NCM (NBM)</label>
            <input
              type="text"
              (change)="model.nbm = $event.target.value"
              [value]="model.nbm || ''"
            />
          </div>

          <div class="item item-22">
            <label>GTIN</label>
            <input
              type="text"
              appNumberOnly
              (change)="model.gtin = $event.target.value"
              [value]="model.gtin || ''"
            />
          </div>

          <div class="item item-22">
            <label>Tempo de Garantia (Meses)</label>
            <input
              type="text"
              appNumberOnly
              (change)="model.warrantyTime = $event.target.value"
              [value]="model.warrantyTime || ''"
            />
          </div>
        </div>
        <div class="d-flex">
          <div class="item item-100">
            <label>Texto da Garantia</label>
            <textarea
              [rows]="5"
              [maxLength]="250"
              (change)="model.warrantyText = $event.target.value"
              [value]="model.warrantyText || ''"
            ></textarea>
          </div>
        </div>
      </div>

      <div class="midias">
        <label>Mídias</label>
        <div class="d-flex">
          <div class="item item-100">
            <label>URL do Vídeo</label>
            <input
              type="text"
              (change)="model.videoUrl = $event.target.value"
              [value]="model.videoUrl || ''"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="form-item form-item-50-p m-t-20">
      <label class="label-bold">Seções</label>
      <app-input-section [sections]="sections"></app-input-section>
    </div>

    <div class="form-item form-item-xl m-t-20">
      <label class="label-bold">Imagens</label>
      <app-image-gallery-selection
        [images]="images"
      ></app-image-gallery-selection>
    </div>

    <div class="form-controls form-btn">
      <button class="rm-button" (click)="onClickClone(false)">
        Clonar SEM Imagens
      </button>
      <button class="rm-button" (click)="onClickClone(true)">
        Clonar COM Imagens
      </button>
      <button class="rm-button" (click)="onClickSave()">Salvar</button>
    </div>
  </div>
</div>
<app-modal
  *ngIf="showFilter"
  (closed)="showFilter = false"
  class="modal-all-products-filter"
>
  <h3>Selecione o filtro desejado:</h3>
  <div class="content">
    <div class="form-item">
      <input
        type="checkbox"
        [checked]="productFilterScreen.active"
        (click)="keyUpFilter($event.target.checked, 'active')"
      />
      <label for="field_name">Ativos</label>
    </div>
    <div class="form-item">
      <input
        type="checkbox"
        [checked]="productFilterScreen.inactive"
        (click)="keyUpFilter($event.target.checked, 'inactive')"
      />
      <label for="field_name">Inativos</label>
    </div>
    <div class="form-item">
      <input
        type="checkbox"
        [checked]="productFilterScreen.discount"
        (click)="keyUpFilter($event.target.checked, 'discount')"
      />
      <label for="field_name">Com desconto</label>
    </div>
    <div class="form-item">
      <input
        type="checkbox"
        [checked]="productFilterScreen.featuredBanner"
        (click)="keyUpFilter($event.target.checked, 'featuredBanner')"
      />
      <label for="field_name">Com Faixa Destaque</label>
    </div>
  </div>
</app-modal>
