import { Component } from '@angular/core';
import User from '../../models/user/User';
@Component({
  selector: 'app-customer-page',
  template: `
    <app-customer-login
      *ngIf="session === 'customer-login'"
      (registerClick)="registerNewUser($event)"
    ></app-customer-login>

    <app-sign-up
      *ngIf="session === 'customer-signup'"
      [user]="user"
    ></app-sign-up>
  `,
})
export class CustomerPageComponent {
  public session: string;
  public user: User;

  constructor() {
    this.session = 'customer-login';
  }

  registerNewUser(user: User) {
    this.user = user;
    this.session = 'customer-signup';
  }
}
