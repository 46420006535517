import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import User from 'src/app/models/user/User';
import { StorageConstants } from 'src/services/constants/storage-constants';
import { SessionStorageService } from 'src/services/session-storage.service';
import { UserService } from 'src/services/user.service';
import { BlockOperation } from 'src/util/block-operation';
import { ToastService } from './../../../services/toast/toast.service';
import { CartStepsEnum } from './../cart-steps/cart-steps.component';

@Component({
  selector: 'app-customer-login',
  template: `
    <div class="rm-ecommerce-frontend-customer-login">
      <app-block-loader [blockOperation]="blockOperation"></app-block-loader>
      <app-cart-steps [currentStep]="currentStep"></app-cart-steps>

      <div class="rm-ecommerce-frontend-customer-login-content">
        <div class="rm-ecommerce-frontend-customer-login-content-signin">
          <h3 class="rm-frontend-images-iconconfirm">IDENTIFIQUE-SE</h3>
          <div>
            <input
              type="email"
              autofocus
              placeholder="Digite seu email"
              (keyup)="onKeyUpEmail($event)"
              [ngClass]="{ error: errorEmail }"
            />
          </div>
          <div class="password">
            <input
              [type]="showPassword ? 'text' : 'password'"
              placeholder="Digite sua senha"
              (keyup)="onKeyUpPass($event)"
              [ngClass]="{ error: errorPassword }"
            />
            <img
              src="assets/img/pwshow.png"
              alt="Mostrar senha"
              *ngIf="!showPassword"
              (click)="showPassword = !showPassword"
            />
            <img
              src="assets/img/pwhide.png"
              alt="Esconder senha"
              *ngIf="showPassword"
              (click)="showPassword = !showPassword"
            />
          </div>
          <div
            class="rm-ecommerce-frontend-customer-login-content-signin-bottom"
          >
            <a
              href="javascript:;"
              (click)="forgot()"
              title="Clique para recuperar a senha"
              >Esqueceu sua senha?</a
            >
            <button class="rm-button" (click)="login()">ENTRAR</button>
          </div>
        </div>

        <div class="rm-ecommerce-frontend-customer-login-content-signup">
          <h3 class="rm-frontend-images-iconedit">CADASTRE-SE</h3>
          <form (submit)="onClickRegister($event)">
            <input
              type="email"
              name="field_email"
              placeholder="Digite seu email"
              [ngClass]="{ error: !!errorNewUser }"
            />
            <div class="bt-cadastro">
              <button class="rm-button">CADASTRAR</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  `,
})
export class CustomerLoginComponent implements OnInit {
  public blockOperation = new BlockOperation();
  public user: User = new User();
  public showPassword = false;
  public errorEmail: boolean;
  public errorPassword: boolean;
  public errorNewUser: boolean;
  public currentStep = CartStepsEnum.IDENTIFICATION;
  public requestBudget = false;

  @Output()
  public registerClick: EventEmitter<User> = new EventEmitter<User>();

  @Output()
  public logged: EventEmitter<User> = new EventEmitter<User>();

  constructor(
    private confirmationService: ConfirmationService,
    public userService: UserService,
    public sessionStorageService: SessionStorageService,
    private toastService: ToastService
  ) {}

  async ngOnInit() {
    this.requestBudget = false;
    const rb = await this.sessionStorageService.get(
      StorageConstants.RM_ECOMMERCE_REQUEST_BUDGET
    );
    if (rb === 'true') {
      this.requestBudget = true;
    }
  }

  async login() {
    this.errorEmail = false;
    this.errorPassword = false;
    if (!this.user.email) {
      this.errorEmail = true;
    }

    if (!this.user.password) {
      this.errorPassword = true;
    }

    if (this.errorEmail || this.errorPassword) {
      this.toastService.error('Preencha os campos obrigatórios!');
      return;
    }

    try {
      this.blockOperation.setBlockOperation('', true);
      const authUser = await this.userService.login(this.user);
      if (authUser) {
        if (this.requestBudget) {
          await this.sessionStorageService.set(
            StorageConstants.RM_ECOMMERCE_PAGE,
            'cart'
          );
        }
        window.location.reload();
      }
    } catch (error) {
      this.toastService.error(error.message);
    } finally {
      this.blockOperation.setBlockOperation('', false);
    }
  }

  onKeyUpEmail($event) {
    this.user.email = $event.target.value;
    if ($event.keyCode === 13) {
      this.login();
    }
  }

  onKeyUpPass($event) {
    this.user.password = $event.target.value;
    if ($event.keyCode === 13) {
      this.login();
    }
  }

  async onClickRegister(event) {
    try {
      this.blockOperation.setBlockOperation('', true);
      this.errorNewUser = false;
      event.preventDefault();
      const user: User = new User();
      user.email = event.target.field_email.value;
      if (!user.email) {
        this.errorNewUser = true;
        this.toastService.error('Informe o e-mail corretamente!');
        return;
      } else {
        await this.userService.validate(encodeURIComponent(user.email));
        this.register(user);
      }
    } catch (e) {
      this.toastService.error(
        'Seu e-mail já está cadastrado! Tente recuperar a sua senha!'
      );
    } finally {
      this.blockOperation.setBlockOperation('', false);
    }
  }

  register(user: User) {
    this.registerClick.emit(user);
  }

  async forgot() {
    if (!this.user.email) {
      this.errorEmail = true;
      this.toastService.error('Informe seu email para recuperar a senha!');
      return;
    }

    this.confirmationService.confirm({
      message: 'Deseja mesmo solicitar uma nova senha?',
      header: 'Confirmação',
      accept: async () => {
        try {
          this.blockOperation.setBlockOperation('', true);
          await this.userService.forgot(this.user.email);
          this.toastService.success(
            'Você receberá um e-mail com as instruções para gerar uma nova senha!'
          );
        } catch (error) {
          this.toastService.error(error.message);
        } finally {
          this.blockOperation.setBlockOperation('', false);
        }
      },
    });
  }
}
