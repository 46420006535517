<div class="content">
  <article>
    <a class="logo" href="/">
      <img src="../../../assets/img/logo.png" alt="" />
    </a>
    <ul class="steps-cart">
      <li
        [ngClass]="{
          active:
            currentStep === enumStepProducts ||
            currentStep === enumStepIdentification ||
            currentStep === enumStepPayment ||
            currentStep === enumStepOrderDone
        }"
      >
        <i class="fa-solid fa-cart-shopping"></i>
        Produtos
      </li>
      <li
        [ngClass]="{
          active:
            currentStep === enumStepIdentification ||
            currentStep === enumStepPayment ||
            currentStep === enumStepOrderDone
        }"
      >
        <i class="fa-regular fa-user"> </i>Identificação
      </li>
      <li
        [ngClass]="{
          active:
            currentStep === enumStepPayment || currentStep === enumStepOrderDone
        }"
      >
        <i class="fa-solid fa-barcode"></i>Pagamento
      </li>
      <li
        [ngClass]="{
          active: currentStep === enumStepOrderDone
        }"
      >
        <i class="fa-solid fa-check"></i>Pedido Efetuado
      </li>
    </ul>
    <span><i class="fa-solid fa-lock"></i>100% Seguro</span>
  </article>
</div>
