<div
  class="app-catalog-component"
  *ngIf="subpage === SUB_PAGE_GRID && !loadingMain"
>
  <aside
    class="app-catalog-aside"
    [ngClass]="{ opened: menuOpened }"
    *ngIf="selectedCategory?.subCategories"
  >
    <div class="app-catalog-aside-content">
      <div class="item">
        <a
          href="javascript:;"
          title="Clique para abrir"
          class="title"
          (click)="openedSubCategory = !openedSubCategory"
        >
          <span>SUBCATEGORIAS</span>
          <img
            src="assets/img/plus.svg"
            alt="Abrir"
            *ngIf="!openedSubCategory"
          />
          <img
            src="assets/img/minus.svg"
            alt="Fechar"
            *ngIf="openedSubCategory"
          />
        </a>
        <div class="children" [ngClass]="{ opened: openedSubCategory }">
          <a
            *ngFor="let item of selectedCategory?.subCategories"
            href="{{ getUrlSubCategory(item) }}"
            title="Clique para abrir"
            (click)="clickSelectedSubCategory(item); $event.preventDefault()"
            [ngClass]="{
              active:
                selectedSubCategory && selectedSubCategory?.id === item?.id
            }"
            >{{ item?.description }}</a
          >
        </div>
      </div>

      <div class="item" *ngIf="showModels()">
        <a
          href="javascript:;"
          title="Clique para abrir"
          class="title"
          (click)="openedModel = !openedModel"
        >
          <span>MODELO</span>
          <img src="assets/img/plus.svg" alt="Abrir" *ngIf="!openedModel" />
          <img src="assets/img/minus.svg" alt="Fechar" *ngIf="openedModel" />
        </a>
        <div class="children" [ngClass]="{ opened: openedModel }">
          <div *ngFor="let item of selectedSubCategory?.models">
            <a
              *ngIf="showChildren(item, 'model')"
              href="javascript:;"
              title="Clique para abrir"
              (click)="clickSelectedModel(item)"
              [ngClass]="{
                active: selectedModel && selectedModel?.id === item?.id
              }"
              >{{ item?.description }}</a
            >
          </div>
        </div>
      </div>

      <div
        class="item"
        *ngIf="showWidth() && selectedCategory?.id === 'PERSIANAS'"
      >
        <a
          href="javascript:;"
          title="Clique para abrir"
          class="title"
          (click)="openedWidth = !openedWidth"
        >
          <span>LARGURAS</span>
          <img src="assets/img/plus.svg" alt="Abrir" *ngIf="!openedWidth" />
          <img src="assets/img/minus.svg" alt="Fechar" *ngIf="openedWidth" />
        </a>
        <div class="children" [ngClass]="{ opened: openedWidth }">
          <div *ngFor="let item of selectedSubCategory?.widths">
            <a
              *ngIf="showChildren(item, 'width')"
              href="javascript:;"
              title="Clique para abrir"
              (click)="clickSelectedWidth(item)"
              [ngClass]="{
                active: selectedWidth && selectedWidth?.id === item?.id
              }"
              >{{ item?.description }}</a
            >
          </div>
        </div>
      </div>

      <div class="item" *ngIf="showHeightThickness()">
        <a
          href="javascript:;"
          title="Clique para abrir"
          class="title"
          (click)="openedHeightThickness = !openedHeightThickness"
        >
          <span *ngIf="showHeight()">ALTURAS</span>
          <span *ngIf="!showHeight()">ESPESSURAS</span>
          <img
            src="assets/img/plus.svg"
            alt="Abrir"
            *ngIf="!openedHeightThickness"
          />
          <img
            src="assets/img/minus.svg"
            alt="Fechar"
            *ngIf="openedHeightThickness"
          />
        </a>
        <div class="children" [ngClass]="{ opened: openedHeightThickness }">
          <div *ngFor="let item of selectedSubCategory?.heightThickness">
            <a
              *ngIf="showChildren(item, 'heightThickness')"
              href="javascript:;"
              title="Clique para abrir"
              (click)="clickSelectedThickness(item)"
              [ngClass]="{
                active:
                  selectedHeightThickness &&
                  selectedHeightThickness?.id === item?.id
              }"
              >{{ item?.description }}</a
            >
          </div>
        </div>
      </div>

      <div
        class="item"
        *ngIf="showWidth() && selectedCategory?.id !== 'PERSIANAS'"
      >
        <a
          href="javascript:;"
          title="Clique para abrir"
          class="title"
          (click)="openedWidth = !openedWidth"
        >
          <span>LARGURAS</span>
          <img src="assets/img/plus.svg" alt="Abrir" *ngIf="!openedWidth" />
          <img src="assets/img/minus.svg" alt="Fechar" *ngIf="openedWidth" />
        </a>
        <div class="children" [ngClass]="{ opened: openedWidth }">
          <div *ngFor="let item of selectedSubCategory?.widths">
            <a
              *ngIf="showChildren(item, 'width')"
              href="javascript:;"
              title="Clique para abrir"
              (click)="clickSelectedWidth(item)"
              [ngClass]="{
                active: selectedWidth && selectedWidth?.id === item?.id
              }"
              >{{ item?.description }}</a
            >
          </div>
        </div>
      </div>

      <div class="item" *ngIf="showLength()">
        <a
          href="javascript:;"
          title="Clique para abrir"
          class="title"
          (click)="openedLength = !openedLength"
        >
          <span>{{ getDescriptionLength() }}</span>
          <img src="assets/img/plus.svg" alt="Abrir" *ngIf="!openedLength" />
          <img src="assets/img/minus.svg" alt="Fechar" *ngIf="openedLength" />
        </a>
        <div class="children" [ngClass]="{ opened: openedLength }">
          <div *ngFor="let item of selectedSubCategory?.lengths">
            <a
              *ngIf="showChildren(item, 'length')"
              href="javascript:;"
              title="Clique para abrir"
              (click)="clickSelectedLength(item)"
              [ngClass]="{
                active: selectedLength && selectedLength?.id === item?.id
              }"
              >{{ item?.description }}</a
            >
          </div>
        </div>
      </div>

      <div class="item" *ngIf="showFinishes()">
        <a
          href="javascript:;"
          title="Clique para abrir"
          class="title"
          (click)="openedFinish = !openedFinish"
        >
          <span>ACABAMENTOS</span>
          <img src="assets/img/plus.svg" alt="Abrir" *ngIf="!openedFinish" />
          <img src="assets/img/minus.svg" alt="Fechar" *ngIf="openedFinish" />
        </a>
        <div class="children" [ngClass]="{ opened: openedFinish }">
          <div *ngFor="let item of selectedSubCategory?.finishes">
            <a
              *ngIf="showChildren(item, 'finish')"
              href="javascript:;"
              title="Clique para abrir"
              (click)="clickSelectedFinish(item)"
              [ngClass]="{
                active: selectedFinish && selectedFinish?.id === item?.id
              }"
              >{{ item?.description }}</a
            >
          </div>
        </div>
      </div>

      <div class="item" *ngIf="showColors()">
        <a
          href="javascript:;"
          title="Clique para abrir"
          class="title"
          (click)="openedColor = !openedColor"
        >
          <span>CORES</span>
          <img src="assets/img/plus.svg" alt="Abrir" *ngIf="!openedColor" />
          <img src="assets/img/minus.svg" alt="Fechar" *ngIf="openedColor" />
        </a>
        <div class="children" [ngClass]="{ opened: openedColor }">
          <div *ngFor="let item of selectedSubCategory.colors">
            <a
              href="javascript:;"
              title="Clique para abrir"
              (click)="clickSelectedColor(item)"
              [ngClass]="{
                active: selectedColor && selectedColor?.id === item?.id
              }"
              >{{ item?.description }}</a
            >
          </div>
        </div>
      </div>

      <div
        class="item"
        *ngIf="
          selectedSubCategory?.capabilities &&
          selectedSubCategory?.capabilities?.length > 0
        "
      >
        <a
          href="javascript:;"
          title="Clique para abrir"
          class="title"
          (click)="openedCapabilities = !openedCapabilities"
        >
          <span>CAPACIDADE</span>
          <img
            src="assets/img/plus.svg"
            alt="Abrir"
            *ngIf="!openedCapabilities"
          />
          <img
            src="assets/img/minus.svg"
            alt="Fechar"
            *ngIf="openedCapabilities"
          />
        </a>
        <div class="children" [ngClass]="{ opened: openedCapabilities }">
          <div *ngFor="let item of selectedSubCategory?.capabilities">
            <a
              *ngIf="showChildren(item, 'capacity')"
              href="javascript:;"
              title="Clique para abrir"
              (click)="clickSelectedCapacity(item)"
              [ngClass]="{
                active: selectedCapacity && selectedCapacity?.id === item?.id
              }"
              >{{ item?.description }}</a
            >
          </div>
        </div>
      </div>

      <div
        class="item"
        *ngIf="
          selectedSubCategory?.potencies &&
          selectedSubCategory?.potencies?.length > 0
        "
      >
        <a
          href="javascript:;"
          title="Clique para abrir"
          class="title"
          (click)="openedPotencies = !openedPotencies"
        >
          <span>POTÊNCIA</span>
          <img src="assets/img/plus.svg" alt="Abrir" *ngIf="!openedPotencies" />
          <img
            src="assets/img/minus.svg"
            alt="Fechar"
            *ngIf="openedPotencies"
          />
        </a>
        <div class="children" [ngClass]="{ opened: openedPotencies }">
          <div *ngFor="let item of selectedSubCategory?.potencies">
            <a
              *ngIf="showChildren(item, 'potency')"
              href="javascript:;"
              title="Clique para abrir"
              (click)="clickSelectedPotency(item)"
              [ngClass]="{
                active: selectedPotency && selectedPotency?.id === item?.id
              }"
              >{{ item?.description }}</a
            >
          </div>
        </div>

        <div
          class="item"
          *ngIf="
            selectedSubCategory?.speeds &&
            selectedSubCategory?.speeds?.length > 0
          "
        >
          <a
            href="javascript:;"
            title="Clique para abrir"
            class="title"
            (click)="openedSpeeds = !openedSpeeds"
          >
            <span>VELOCIDADE</span>
            <img src="assets/img/plus.svg" alt="Abrir" *ngIf="!openedSpeeds" />
            <img src="assets/img/minus.svg" alt="Fechar" *ngIf="openedSpeeds" />
          </a>
          <div class="children" [ngClass]="{ opened: openedSpeeds }">
            <div *ngFor="let item of selectedSubCategory?.speeds">
              <a
                *ngIf="showChildren(item, 'speed')"
                href="javascript:;"
                title="Clique para abrir"
                (click)="clickSelectedSpeed(item)"
                [ngClass]="{
                  active: selectedSpeed && selectedSpeed?.id === item?.id
                }"
                >{{ item?.description }}</a
              >
            </div>
          </div>
        </div>
      </div>

      <div
        class="item"
        *ngIf="
          selectedSubCategory?.voltages &&
          selectedSubCategory?.voltages?.length > 0
        "
      >
        <a
          href="javascript:;"
          title="Clique para abrir"
          class="title"
          (click)="openedVoltages = !openedVoltages"
        >
          <span>VOLTAGEM</span>
          <img src="assets/img/plus.svg" alt="Abrir" *ngIf="!openedVoltages" />
          <img src="assets/img/minus.svg" alt="Fechar" *ngIf="openedVoltages" />
        </a>
        <div class="children" [ngClass]="{ opened: openedVoltages }">
          <div *ngFor="let item of selectedSubCategory?.voltages">
            <a
              *ngIf="showChildren(item, 'voltage')"
              href="javascript:;"
              title="Clique para abrir"
              (click)="clickSelectedVoltage(item)"
              [ngClass]="{
                active: selectedVoltage && selectedVoltage?.id === item?.id
              }"
              >{{ item?.description }}</a
            >
          </div>
        </div>
      </div>
    </div>
  </aside>
  <article class="app-catalog-content" *ngIf="!blockOperation.blocked">
    <p-carousel
      class="mobile-visible"
      [value]="selectedCategory?.subCategories"
      [autoplayInterval]="2000"
      [circular]="true"
      [numVisible]="2"
      [showIndicators]="false"
      [responsiveOptions]="responsiveOptions"
    >
      <ng-template let-sub pTemplate="item">
        <button
          class="btn-toggle btn-sub-categories mobile-visible"
          (click)="clickSelectedSubCategory(sub); $event.preventDefault()"
          [ngClass]="{
            'btn-sub-categories-active':
              selectedSubCategory && selectedSubCategory?.id === sub.id
          }"
        >
          {{ sub.description }}
        </button>
      </ng-template>
    </p-carousel>
    <div class="form form-catalog">
      <div class="count-products">
        <h3 *ngIf="products?.length > 0">
          {{ pageResponse?.totalElements }} PRODUTO(S)
        </h3>
        <div
          class="filter"
          [ngClass]="{ 'filter-no-products': products?.length === 0 }"
        >
          <app-product-order
            *ngIf="products?.length > 0"
            [filterSelectedId]="productFilter.direction"
            (changed)="changedOrder($event)"
          ></app-product-order>

          <div [ngClass]="{ 'opened-menu': menuOpened }">
            <button
              class="btn-toggle mobile-visible"
              (click)="menuOpened = !menuOpened; changeBodyOverlay()"
              title="Abre todos os filtros disponíveis"
            >
              {{ menuOpened ? 'VOLTAR' : 'FILTROS' }}
            </button>
          </div>
        </div>
      </div>
      <h3 class="no-products" *ngIf="products?.length === 0">
        Não existem produtos para o filtro selecionado!
      </h3>
    </div>
    <div class="content" *ngIf="selectedSubCategory && products">
      <a
        href="{{ getUrlProduct(product) }}"
        class="product-item"
        *ngFor="let product of products"
        (click)="selectIdProduct(product); $event.preventDefault()"
      >
        <div
          *ngIf="product?.featuredBanner || product?.discount > 0"
          class="featured-banner"
        >
          {{ product.featuredBanner || product?.discount + '% DE DESCONTO' }}
        </div>
        <img
          src="{{ product.mainPhotoS3Url }}"
          [alt]="'Foto: ' + product.name"
        />
        <h3>
          {{ product.name }} <span>- {{ product.brandDescription }}</span>
        </h3>
        <div class="value">
          <p *ngIf="product.discount > 0" class="desconto">
            de <span>{{ product.value | currency }}</span> por
          </p>
          <p class="total">
            {{ installmentFormatInterestFree(product) }}
          </p>
        </div>
        <p *ngIf="product.discount <= 0" class="description">
          ou {{ formatTotalValue(product) | currency }} à vista
        </p>

        <ul
          href="javascript:;"
          class="add-to-cart"
          (click)="
            addToCart(product);
            $event.stopPropagation();
            $event.preventDefault()
          "
          title="Adicionar ao Carrinho"
        >
          <li>
            <a><img src="assets/img/cart.svg" alt="Carrinho" /></a>
          </li>
        </ul>
      </a>
    </div>
    <p-paginator
      *ngIf="products?.length > 0"
      [rows]="24"
      [first]="page * 24"
      [totalRecords]="pageResponse?.totalElements || 0"
      (onPageChange)="onPageChange($event)"
    ></p-paginator>
  </article>
</div>

<article
  class="product-show rodape-mobile-show"
  *ngIf="subpage === SUB_PAGE_PRODUCT"
>
  <button (click)="onBackCatalog()" title="Voltar para os produtos">
    VOLTAR
  </button>
  <div class="product-details mobile-block">
    <div class="image" [ngStyle]="{ height: getImageHeight() }">
      <div class="vertical-gallery">
        <app-vertical-gallery
          [images]="productSelected.photos"
          [selectedImage]="activePhoto"
          (selected)="selectedImage($event)"
        ></app-vertical-gallery>
      </div>
      <img
        src="{{ activePhoto?.url }}"
        [alt]="'Foto: ' + productSelected.description"
        class="main"
      />
    </div>
    <div class="details mobile-block">
      <h2 class="name">{{ productSelected.name }}</h2>

      <h3 class="desconto" *ngIf="productSelected.discount > 0">
        de <span>{{ productSelected.value | currency }}</span> por
      </h3>

      <h3 class="value">
        {{ installmentFormatInterestFree(productSelected) }}
      </h3>

      <h4 *ngIf="!productSelected.discount">
        {{ formatTotalValue(productSelected) | currency }} à vista
      </h4>

      <h3 class="value">
        <span *ngIf="showValorParcelado(productSelected, false)"
          >ou em até
          <span> {{ getInstallmentValue(productSelected) }}</span></span
        >
      </h3>

      <h3 class="description">{{ getDescription(productSelected) }}</h3>
      <div class="more-inf">
        <button (click)="onClickMoreInf()" title="Ver mais informações">
          VER MAIS INFORMAÇOES
        </button>
      </div>

      <div
        *ngIf="
          productSelected?.groupedSkusView?.mainSKU?.groupAttributeProduct ===
          GROUP_BY_COLOR
        "
        class="products-grouped-color"
      >
        <h3>Cores disponíveis:</h3>
        <div class="products-grouped-color-main">
          <div
            *ngFor="let item of productSelected?.groupedSkus"
            class="products-grouped-color-item"
          >
            <div *ngIf="!item.groupMainProduct">
              <a href="{{ getUrlProduct(item) }}">
                <img
                  [ngClass]="{
                    'item-selected':
                      item.id.toString() === productSelected.id.toString()
                  }"
                  src="{{ item?.mainPhotoS3Url }}"
                />
              </a>
              <h3 class="description">{{ item.color }}</h3>
            </div>
          </div>
        </div>
      </div>

      <div class="products-freight mobile-block">
        <div class="products-freight-header">
          <div class="products-freight-qtd">
            <p>Quantidade</p>
            <div class="products-freight-qtd-button">
              <button
                class="btn-left"
                (click)="
                  productSelected.qtd = productSelected.qtd - 1;
                  $event.stopPropagation();
                  $event.preventDefault()
                "
              >
                -
              </button>
              <input
                type="text"
                appNumberOnly
                [value]="productSelected.qtd"
                (focusout)="productSelected.qtd = $event.target.value"
              />
              <button
                class="btn-right"
                (click)="
                  productSelected.qtd = productSelected.qtd + 1;
                  $event.stopPropagation();
                  $event.preventDefault()
                "
              >
                +
              </button>
            </div>
          </div>

          <div class="products-freight-calculate">
            <p>Calcular frete e prazo</p>
            <div *ngIf="loadingFreight" class="loading-freight">
              <img src="assets/img/loading.svg" alt="Carregando..." />
            </div>
            <div *ngIf="!loadingFreight" class="form">
              <div class="form-item">
                <app-cep-input
                  placeholder="Seu CEP"
                  [value]="zipCode || ''"
                  (changed)="setZipCode($event)"
                  (keyup)="onKeyUpZipCode($event)"
                  (addressFound)="addressFound($event)"
                ></app-cep-input>
              </div>
              <button (click)="getAddress()" title="Calcular frete e prazo">
                CALCULAR
              </button>
              <a
                href="https://buscacepinter.correios.com.br/app/endereco/index.php"
                target="_blank"
                >Não sabe o CEP?</a
              >
            </div>
          </div>
        </div>
        <div class="products-quote" *ngIf="quotes && quotes.carriers">
          <div class="carrying-row" *ngFor="let carrying of quotes.carriers">
            <p class="carrying-row-name">
              {{ carrying?.displayName?.toLowerCase() }}
            </p>
            <p>
              {{ carrying.deliveryTime }}
              {{ carrying.deliveryTime === 1 ? 'dia' + ' útil' : 'dias úteis' }}
            </p>
            <p>{{ carrying.priceFreight | currency }}</p>
          </div>
        </div>
        <div class="products-error-freght" *ngIf="!quotes">
          <p>{{ errorFreight }}</p>
        </div>
      </div>

      <div
        class="add-to-cart mobile-invisible-cart"
        *ngIf="
          productSelected.stockBalance > 0 ||
          productSelected.canBeGeneratedWithStockZero
        "
      >
        <button
          (click)="addToCart()"
          title="Adicionar ao carrinho"
          [disabled]="errorFreight !== ''"
        >
          <label>ADICIONAR AO CARRINHO</label>
          <img src="assets/img/cart.svg" alt="Carrinho" />
        </button>

        <p
          *ngIf="
            productSelected.stockBalance === 0 &&
            !productSelected.canBeGeneratedWithStockZero
          "
        >
          Produto sem estoque!
        </p>
      </div>

      <div class="products-border mobile-block"></div>

      <div
        *ngIf="
          !productSelected?.groupedSkus &&
          productSelected?.productsColorBalance?.length > 0
        "
        class="products-color-balance mobile-block"
      >
        <p>Outras Opções:</p>
        <div class="products-color-balance-content">
          <img
            *ngFor="let item of productSelected.productsColorBalance"
            href="javascript:;"
            src="{{ item?.urlFirstImage }}"
            (click)="selectProductColor(item)"
          />
        </div>
      </div>
    </div>
  </div>
  <div
    id="product-information-target"
    class="product-information mobile-invisible"
  >
    <div *ngIf="infoSectionSelected" class="catalog-tab-information">
      <div
        *ngFor="let info of productSelected.informationsSection"
        [ngClass]="info.selected && 'active'"
        (click)="clickTab(info, null)"
      >
        {{ info.title }}
      </div>
    </div>
    <div *ngIf="infoSectionSelected" class="catalog-tab-information-content">
      <div
        *ngIf="infoSectionSelected.title !== 'Vídeo'"
        [innerHTML]="infoSectionSelected.description"
      ></div>

      <div class="text-center" *ngIf="infoSectionSelected.title === 'Vídeo'">
        <iframe
          width="640"
          height="350"
          frameborder="0"
          allowfullscreen="allowfullscreen"
          [src]="getUrlVideo(infoSectionSelected) | safe"
        >
        </iframe>
      </div>
    </div>
  </div>

  <div
    #infoSectionTarget
    id="product-information-mobile-target"
    class="product-information mobile-visible"
  >
    <div *ngIf="infoSectionSelected" class="catalog-tab-information">
      <div
        class="catalog-tab-information-title"
        *ngFor="let info of productSelected.informationsSection"
        [ngClass]="info.selected && 'active'"
        (click)="clickTab(info, infoSectionTarget)"
      >
        <h1>{{ info.title }}</h1>
        <div
          *ngIf="info.selected && info.title !== 'Vídeo'"
          class="catalog-tab-information-content"
          [innerHTML]="info.description"
        ></div>
      </div>
      <div
        class="catalog-tab-information-video"
        *ngIf="infoSectionSelected.title === 'Vídeo'"
      >
        <iframe
          frameborder="0"
          allowfullscreen="allowfullscreen"
          [src]="getUrlVideo(infoSectionSelected) | safe"
        >
        </iframe>
      </div>
    </div>
  </div>

  <div class="products-related" *ngIf="productsRelated?.length > 0">
    <div class="products-related-title">
      <h1>Veja também</h1>
    </div>
    <swiper
      [spaceBetween]="20"
      [slidesPerView]="5"
      [slidesPerGroup]="5"
      [loop]="true"
      [loopFillGroupWithBlank]="true"
      [pagination]="{
        clickable: true
      }"
      [autoplay]="{
        delay: 3000,
        disableOnInteraction: false
      }"
      [breakpoints]="{
        '320': {
          slidesPerView: 2,
          slidesPerGroup: 2,
          spaceBetween: 10
        },
        '768': {
          slidesPerView: 3,
          slidesPerGroup: 3,
          spaceBetween: 20
        },
        '1024': {
          slidesPerView: 5,
          slidesPerGroup: 5,
          spaceBetween: 20
        }
      }"
      class="carousel-products-related"
    >
      <ng-template swiperSlide *ngFor="let product of productsRelated">
        <div
          class="product-item"
          (click)="
            selectIdProduct(product);
            $event.stopPropagation();
            $event.preventDefault()
          "
        >
          <div class="product-item-content">
            <img
              src="{{ product.mainPhotoS3Url }}"
              [alt]="product.name"
              class="product-image"
            />
            <div class="product-item-content-text">
              <h4>{{ product.name }}</h4>
              <div class="value">
                <p *ngIf="product.discount > 0" class="discount">
                  de
                  <span>{{ product.value | currency }}</span> por
                </p>
                <p class="total">
                  {{ installmentFormatInterestFree(product) }}
                </p>
              </div>
              <p *ngIf="product.discount <= 0" class="description">
                ou {{ formatTotalValue(product) | currency }} à vista
              </p>
              <ul
                href="javascript:;"
                class="add-to-cart"
                (click)="selectIdProduct(product)"
                title="Adicionar ao Carrinho"
              >
                <li>
                  <a><img src="assets/img/cart.svg" alt="Carrinho" /></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </ng-template>
    </swiper>
  </div>

  <div class="rodape-mobile">
    <p
      *ngIf="
        !productSelected.stockBalance &&
        !productSelected.canBeGeneratedWithStockZero
      "
    >
      Fora de estoque
    </p>
    <div
      *ngIf="
        productSelected.stockBalance ||
        productSelected.canBeGeneratedWithStockZero
      "
      class="purchase"
    >
      <button
        (click)="addToCart()"
        [disabled]="errorFreight !== ''"
        title="Adicionar ao Carrinho"
      >
        <label>ADICIONAR AO CARRINHO</label>
        <img src="assets/img/cart.svg" alt="Carrinho" />
      </button>
    </div>
  </div>
</article>
<app-block-loader [blockOperation]="blockOperation"></app-block-loader>
<app-modal
  *ngIf="showOptionsPayments"
  (closed)="showOptionsPayments = null"
  class="modal-options-payments"
>
  <h3>Cartão de Crédito</h3>
  <div class="content">
    <div *ngFor="let item of installments">
      <h1>{{ item.quantity }}x</h1>
      <h2>de {{ item.installmentAmount | currency }}</h2>
      <h2 *ngIf="!item.interestFree">com juros</h2>
      <h2 *ngIf="item.interestFree">sem juros</h2>
      <h2>total {{ item.totalAmount | currency }}</h2>
    </div>
  </div>
</app-modal>

<app-modal
  *ngIf="showSimilarProduct"
  (closed)="showSimilarProduct = null"
  class="modal-similar-product"
>
  <h3>Produto não disponível para sua região!!!</h3>
  <h3>Mas não fique triste.</h3>
  <h3>Para sua região, possuímos o mesmo produto com uma medida menor:</h3>
  <div class="content">
    <a
      href="{{ getUrlProduct(similarProduct) }}"
      class="product-item"
      (click)="selectIdProduct(similarProduct); $event.preventDefault()"
    >
      <div *ngIf="similarProduct.featuredBanner" class="featured-banner">
        {{ similarProduct.featuredBanner }}
      </div>
      <img
        src="{{ similarProduct.getImage(1) }}"
        [alt]="'Foto: ' + similarProduct.name"
      />
      <h3>
        {{ similarProduct.name }}
        <span>- {{ similarProduct.brandDescription }}</span>
      </h3>
      <div class="value">
        <p *ngIf="similarProduct.discount > 0" class="desconto">
          de
          <span>{{ similarProduct.value | currency }}</span> por
        </p>
        <p class="total">
          {{ formatTotalValue(similarProduct) | currency }} à vista
        </p>
      </div>
      <p *ngIf="showValorParcelado(similarProduct, false)" class="description">
        ou parcele em até {{ getParcela(similarProduct) }}x
      </p>
    </a>
    <div class="add-to-cart">
      <button (click)="addToCart(similarProduct)" title="Adicionar ao carrinho">
        <label>ADICIONAR AO CARRINHO</label>
        <img src="assets/img/cart.svg" alt="Carrinho" />
      </button>
    </div>
  </div>
</app-modal>
