import { Injectable } from '@angular/core';
import Budget from 'src/app/models/budget/budget';
import { Cart, CartFull } from 'src/app/models/cart/cart';
import Address from 'src/app/models/user/Address';
import User from 'src/app/models/user/User';
import fetch from './fetch';

@Injectable({
  providedIn: 'root',
})
export class BudgetService {
  constructor() {}

  async save(budget: Budget, cart: Cart) {
    try {
      const itens = cart.items.map((i) => ({
        amount: i.qt,
        idProduct: i.productId,
      }));
      const res = await fetch('POST', `budget/`, {
        email: budget.email,
        name: budget.name,
        phone: budget.phone,
        zipCode: budget.zipCode,
        cart: { cartProducts: itens },
      });
      return Object.assign(new Budget(), res);
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  async get(hash: string) {
    try {
      const res = await fetch('GET', `budget/${hash}`);
      return Object.assign(new Budget(), res);
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  async delete(hash: string) {
    try {
      const res = await fetch('DELETE', `budget/${hash}`, null, null, 'text');
      return res;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  public async list(): Promise<Array<CartFull>> {
    const carts: Array<CartFull> = [];
    try {
      const response = await fetch('POST', 'budget/pageable/list/', {
        status: `GENERATED`,
      });
      let newBudget: CartFull;
      response.content.forEach((budgetBanco) => {
        newBudget = {};
        newBudget.cartProducts = budgetBanco.cart.cartProducts;
        newBudget.createdAt = budgetBanco.createdAt;
        newBudget.hash = budgetBanco.hash;
        newBudget.id = budgetBanco.cart.id;
        newBudget.user = new User();
        newBudget.user.name = budgetBanco.name;
        newBudget.user.phone = budgetBanco.phone;
        newBudget.user.email = budgetBanco.email;
        newBudget.user.address = [];
        const newAddress: Address = new Address();
        newAddress.fromJson(budgetBanco);
        newBudget.user.address.push(newAddress);
        carts.push(newBudget);
      });
      return carts;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  async deleteBudgets(carts: Array<CartFull>) {
    try {
      await Promise.all(carts.map((c) => this.delete(c.hash)));
    } catch (err) {
      console.error(err);
    }
  }
}
