<div class="rm-ecommerce-frontend-forgot">
  <app-block-loader [blockOperation]="blockOperation"></app-block-loader>

  <div *ngIf="tokenForgot" class="rm-ecommerce-frontend-forgot-content">
    <h2>Informe sua nova senha!</h2>
    <div class="password">
      <input
        [type]="showPassword ? 'text' : 'password'"
        title="Sua senha deve conter no mínimo 8 caracteres, letras maiúsculas, minúsculas e números!"
        placeholder="Senha"
        [value]="password || ''"
        (change)="password = $event.target.value"
        [ngClass]="{ error: !!errorPassword }"
      />
      <img
        src="assets/img/pwshow.png"
        alt="Mostrar senha"
        *ngIf="!showPassword"
        (click)="showPassword = !showPassword"
      />
      <img
        src="assets/img/pwhide.png"
        alt="Esconder senha"
        *ngIf="showPassword"
        (click)="showPassword = !showPassword"
      />
    </div>

    <div class="password">
      <input
        [type]="showConfirmPassword ? 'text' : 'password'"
        title="Sua senha deve conter no mínimo 8 caracteres, letras maiúsculas, minúsculas e números!"
        placeholder="Confirme a Senha"
        [value]="confirmPassword || ''"
        (change)="confirmPassword = $event.target.value"
        [ngClass]="{ error: !!errorConfirmPassword }"
      />
      <img
        src="assets/img/pwshow.png"
        alt="Mostrar senha"
        *ngIf="!showConfirmPassword"
        (click)="showConfirmPassword = !showConfirmPassword"
      />
      <img
        src="assets/img/pwhide.png"
        alt="Esconder senha"
        *ngIf="showConfirmPassword"
        (click)="showConfirmPassword = !showConfirmPassword"
      />
    </div>
    <p>
      Sua senha deve conter no mínimo 8 caracteres, letras maiúsculas,
      minúsculas e números!
    </p>

    <div class="rm-ecommerce-frontend-forgot-btn">
      <button (click)="onChange()">Alterar</button>
    </div>
  </div>
</div>
