import { Injectable } from '@angular/core';
import Whatsapp from 'src/app/models/whatsapp/Whatsapp';
import WhatsappFilter from 'src/app/models/whatsapp/WhatsappFilter';
import fetch from './fetch';
@Injectable({
  providedIn: 'root',
})
export class WhatsappService {
  constructor() {}

  public async list(filter: WhatsappFilter): Promise<Array<Whatsapp>> {
    const coupons: Array<Whatsapp> = [];
    try {
      const response = await fetch(
        'POST',
        'request-contact-whatsapp/pageable/list/',
        filter
      );
      let newWhats: Whatsapp;
      response.content.forEach((whatsBanco) => {
        newWhats = Object.assign(new Whatsapp(), whatsBanco);
        coupons.push(newWhats);
      });
      return coupons;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
}
