import { Input, Component, OnInit } from '@angular/core';

export interface OrderStatusItem {
    id: number;
    title: string;
    date?: string;
    status?: string;
}

@Component({
    selector: 'app-order-status',
    template: `
    <div class="app-order-status" *ngIf="statuses.length">
        <div class="app-order-status-item" *ngFor="let status of statuses; let i=index;">
            <div class="text">
                <div class="title">{{ status.title }}</div>
                <div class="date" *ngIf="status.date">{{ status.date }}</div>
            </div>
            <div class="dot" [ngClass]="{active: status.date || i === 0}"></div>
            <div class="link" [ngClass]="{active: status.date || i === 0}" *ngIf="i !== statuses.length-1"></div>
        </div>
    </div>
    `
})
export class OrderStatusComponent {

    @Input()
    public statuses: Array<OrderStatusItem> = [];

}
