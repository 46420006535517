<app-block-loader [blockOperation]="blockOperation"></app-block-loader>
<h1>Contatos cadastrados via WhatsApp</h1>

<div class="form">
  <div class="form-item m-b-20">
    <label for="field_name">Nome</label>
    <input type="text" (keyup)="filter.name = $event.target.value" autofocus />
  </div>
  <div class="form-item m-b-20">
    <label for="field_name">E-mail</label>
    <input type="text" (keyup)="filter.email = $event.target.value" />
  </div>
  <div class="form-item m-b-20">
    <label for="field_name">Telefone</label>
    <input type="text" (keyup)="filter.phone = $event.target.value" />
  </div>
  <div class="form-item m-b-20">
    <label for="field_name">Data (DD/MM/AAAA)</label>
    <input
      type="text"
      (keyup)="
        filter.startDate = $event.target.value;
        filter.endDate = $event.target.value
      "
    />
  </div>
  <div class="form-item m-b-20">
    <label for="field_name"></label>
    <button class="rm-button" (click)="onClick()" title="Buscar contatos">
      Buscar
    </button>
  </div>
</div>

<app-datagrid
  type="whatsapps"
  [rowData]="whatsapps"
  [enableCellTextSelection]="true"
></app-datagrid>
