import { Component, OnInit } from '@angular/core';
import AbstractInfoEnum from 'src/app/models/AbstractInfoEnum';
import OrderFilter from 'src/app/models/order/OrderFilter';
import OrderGrid from 'src/app/models/order/OrderGrid';
import Order, { orderStatus } from 'src/app/models/order/order';
import User from 'src/app/models/user/User';
import { AppInfoService } from 'src/services/app-info.service';
import { UserService } from 'src/services/user.service';
import { BlockOperation } from 'src/util/block-operation';
import { OrderService } from '../../../services/order.service';

@Component({
  selector: 'app-orders',
  template: `
    <h1>Meus Pedidos</h1>
    <app-block-loader [blockOperation]="blockOperation"></app-block-loader>
    <div>
      <div class="form form-filters">
        <div class="form-item" *ngIf="this.isAdmin">
          <label for="field_name">Nome do Cliente</label>
          <input
            type="text"
            autofocus
            placeholder="Informe o Nome do Cliente..."
            (keyup)="keyUpNmCliente($event.target.value)"
            [value]="nmCliente || ''"
          />
        </div>
        <div class="form-item status">
          <label for="field_name">Status do Pedido</label>
          <app-combo
            [options]="orderStatus"
            (change)="keyUpStatus($event.target.value)"
          ></app-combo>
        </div>

        <div class="form-item status" *ngIf="this.isAdmin">
          <label for="field_name">Marketplaces</label>
          <app-combo
            [options]="marketplaces"
            (change)="keyUpMarketplaces($event.target.value)"
          ></app-combo>
        </div>
        <div class="form-item shipping" *ngIf="this.isAdmin">
          <label for="field_name">Transportadora</label>
          <input
            type="text"
            autofocus
            placeholder="Informe a Transportadora..."
            (keyup)="filter.shipping = $event.target.value"
            [value]="filter.shipping || ''"
          />
        </div>
        <div class="form-item btn-filter">
          <button class="rm-button" (click)="onClickSearch()">Buscar</button>
        </div>
      </div>
      <p *ngIf="!orders.length">
        Nenhum pedido em aberto. Selecione outro status para verificar pedido
        antigos.
      </p>
      <div *ngIf="orders.length">
        <table class="order-list order-list-header">
          <tbody>
            <tr>
              <th class="pedido">Pedido</th>
              <th class="nm-cliente">Cliente</th>
              <th class="marketplace">Marketplace</th>
              <th class="marketplace">Transportadora</th>
              <th class="value">Valor</th>
              <th class="dt-pedido">Data</th>
              <th class="dt-pedido">Previsão</th>
              <th class="status-pedido">Status</th>
            </tr>
          </tbody>
        </table>

        <div *ngFor="let order of orders">
          <table
            class="order-list"
            [ngClass]="{
              active: orderSelected.id === order.id,
              'error-late-delivery': order?.daysToDelivery <= 0,
              'warning-late-delivery':
                order?.daysToDelivery > 0 && order?.daysToDelivery <= 2
            }"
          >
            <tbody>
              <tr class="order order-header" (click)="onClickOrder(order)">
                <td class="pedido">#{{ order?.id }}</td>
                <td class="nm-cliente">{{ order?.nameUser }}</td>
                <td class="marketplace">
                  {{ order?.marketPlace || 'RM ECOMMERCE' }}
                </td>
                <td class="marketplace">
                  {{ order?.shippingCompany || '' }}
                </td>
                <td class="value">
                  {{ (order?.totalValue | currency) || 'Em avaliação' }}
                </td>
                <td class="dt-pedido">
                  {{ order?.createdAt | mask : '00/00/0000 00:00' }}
                </td>
                <td class="dt-pedido">
                  {{ order?.estimateDate | mask : '00/00/0000' }}
                </td>
                <td
                  class="status-pedido"
                  [ngClass]="ngClassStatus(order?.currentStatus)"
                >
                  <button class="rm-button">{{ order?.currentStatus }}</button>
                </td>
              </tr>
            </tbody>
          </table>
          <div
            class="order order-detail"
            *ngIf="orderSelected?.id === order?.id"
          >
            <app-order
              [order]="orderSelected"
              [isAdmin]="isAdmin"
              (updated)="changeOrder($event)"
            ></app-order>
          </div>
        </div>
      </div>
    </div>
  `,
})
export class OrdersComponent implements OnInit {
  public blockOperation = new BlockOperation();
  public pageLoaded = 0;
  public orders: Array<OrderGrid> = [];
  public ordersCache: Array<Order> = [];
  public orderSelected: Order = new Order();
  public currentUser: User;
  public isAdmin = false;
  public nmCliente = null;
  public orderStatus: Array<AbstractInfoEnum>;
  public marketplaces: Array<AbstractInfoEnum>;
  public filter: OrderFilter = {};

  constructor(
    private orderService: OrderService,
    private userService: UserService,
    private appInfo: AppInfoService
  ) {}

  async ngOnInit() {
    this.filter.status = await this.getStatusDefault();
    await this.getOrderStatus();
    await this.loadOrders();
  }

  async getOrderStatus() {
    try {
      this.orderStatus = await this.appInfo.getOrderStatus();
      this.marketplaces = await this.appInfo.getMarketplaces();
    } catch (err) {
      console.error(err);
    }
  }

  async loadOrders() {
    try {
      this.orders = [];
      this.blockOperation.setBlockOperation('', true);
      this.currentUser = await this.userService.getUserStorage();
      this.isAdmin = this.currentUser?.isAdmin();
      this.filter.direction = 'DESC';
      if (!this.isAdmin) {
        this.filter.idUser = this.currentUser?.id;
      }
      this.orders = await this.orderService.getOrdersNew(this.filter);
    } catch (err) {
      console.error(err);
      throw err;
    } finally {
      this.blockOperation.setBlockOperation('', false);
    }
  }

  async getOrder(idOrder) {
    try {
      this.blockOperation.setBlockOperation('', true);
      return await this.orderService.getOrder(idOrder);
    } catch (err) {
      console.error(err);
      throw err;
    } finally {
      this.blockOperation.setBlockOperation('', false);
    }
  }

  onClickSearch() {
    this.loadOrders();
  }

  async onClickOrder(orderGrid: OrderGrid) {
    if (this.orderSelected?.id === orderGrid?.id) {
      this.orderSelected = new Order();
    } else {
      const orderCache = this.ordersCache.find(
        (order) => order.id === orderGrid.id
      );
      if (orderCache) {
        this.orderSelected = orderCache;
      } else {
        this.orderSelected = await this.getOrder(orderGrid?.id);
        this.ordersCache.push(this.orderSelected);
      }
    }
  }

  async changeOrder(order: Order) {
    const onList = this.orders?.find((o) => o.id === order.id);
    if (onList) {
      await onList.updateOrderGridFromOrder(order);
      /* const index = this.orders?.indexOf(onList);
      this.orders[index] = order; */
      this.orders = [...this.orders];
    }
  }

  keyUpNmCliente(value) {
    this.filter.nameUser = value;
  }

  keyUpStatus(value) {
    if (value && value !== 'Selecione') {
      this.filter.status = [value];
    } else {
      this.filter.status = this.getStatusDefault();
    }
  }

  keyUpMarketplaces(value) {
    if (value && value !== 'Selecione') {
      this.filter.marketplaces = [value];
    } else {
      this.filter.marketplaces = null;
    }
  }

  ngClassStatus(status) {
    switch (status) {
      case orderStatus.PENDING:
        return 'status-pending';
      case orderStatus.PAID_WAITING_SHIP:
        return 'status-paid';
      case orderStatus.ON_EXPEDITION:
        return 'status-on-expedition';
      case orderStatus.INVOICED:
        return 'status-invoiced';
      case orderStatus.PAID_WAITING_DELIVERY:
        return 'status-sent';
      case orderStatus.CONCLUDED:
        return 'status-concluded';
      case orderStatus.CANCELED:
        return 'status-canceled';
      default:
        return '';
    }
  }

  getStatusDefault(): Array<string> {
    return [
      'PENDING',
      'PAID_WAITING_SHIP',
      'ON_EXPEDITION',
      'INVOICED',
      'PAID_WAITING_DELIVERY',
    ];
  }
}
