import { Injectable } from '@angular/core';
import User from 'src/app/models/user/User';
import ValidationException from 'src/app/models/validation/ValidationException';
import fetch from 'src/services/fetch';
import { StorageConstants } from './constants/storage-constants';
import { HttpService } from './http.service';
import { SessionStorageService } from './session-storage.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private httpService: HttpService,
    private sessionStorageService: SessionStorageService
  ) {}

  public async login(user: User): Promise<User> {
    return new Promise((resolve, reject) => {
      this.httpService.login(user).subscribe({
        next: async (res: any) => {
          if (res) {
            await this.sessionStorageService.set(
              StorageConstants.RM_ECOMMERCE_TOKEN,
              res.access_token
            );
            await this.sessionStorageService.set(
              StorageConstants.RM_ECOMMERCE_PAGE,
              'home'
            );
            const userLogged = await this.logged();
            await this.sessionStorageService.set(
              StorageConstants.RM_ECOMMERCE_USER,
              userLogged
            );
            resolve(userLogged);
          }
        },
        error: (error: any) => {
          let msg =
            error?.error?.message ||
            error?.error?.error ||
            'Ocorreu um erro ao realizar seu login!';
          if (msg === 'invalid_grant') {
            msg = 'Usuário ou senha inválidos!';
          }
          reject(msg);
        },
      });
    });
  }

  public async logged(): Promise<User> {
    try {
      const user = new User();
      const res = await fetch('GET', 'user/logged/');
      user.fromJson(res);
      return user;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  public async getUserStorage(): Promise<User> {
    const newUser = new User();
    const userStorage = await this.sessionStorageService.get(
      StorageConstants.RM_ECOMMERCE_USER
    );
    if (userStorage) {
      newUser.fromJson(userStorage);
      return newUser;
    }
    return null;
  }

  public async logout() {
    await this.sessionStorageService.clear();
    await this.sessionStorageService.set(
      StorageConstants.RM_ECOMMERCE_PAGE,
      'customer'
    );
    window.location.reload();
  }

  public async save(user: User): Promise<User> {
    if (!user) {
      throw new ValidationException('User is null!');
    }
    user.roles = [{ name: 'ROLE_USER' }];
    try {
      const res = await fetch('POST', 'user/', user, null, 'text');
      return res;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  public async list(): Promise<Array<User>> {
    const users: Array<User> = [];
    try {
      const response = await fetch('GET', 'user/list/');
      let newUser: User;
      response.content.forEach((userBanco) => {
        newUser = new User();
        newUser.fromJson(userBanco);
        users.push(newUser);
      });
      return users;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  public async update(user: User): Promise<User> {
    if (!user) {
      throw new ValidationException('User is null!');
    }
    try {
      return await fetch('PUT', 'user/' + user.id + '/', user, null, 'text');
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  async delete(id: number) {
    try {
      const res = await fetch('DELETE', `user/${id}`, null, null, 'text');
      return res;
    } catch (err) {
      throw err;
    }
  }

  public async forgot(email) {
    try {
      return await fetch('GET', `user/password-recovery/${email}/`);
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  public async changePassword(email: string, token: string, password: string) {
    try {
      return await fetch(
        'POST',
        `user/password-recovery/${email}/`,
        { token, password, confirmPassword: password },
        null,
        'text'
      );
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  public async goToLogin() {
    await this.sessionStorageService.clear();
    await this.sessionStorageService.set(
      StorageConstants.RM_ECOMMERCE_PAGE,
      'customer'
    );
    window.location.hash = '';
    history.pushState('', document.title, window.location.pathname);
    window.location.reload();
  }

  public async validate(email: string) {
    try {
      return await fetch('POST', `user/validate/${email}/`, null, null, 'text');
    } catch (err) {
      throw err;
    }
  }
}
