export default class ValidationProduct {

    public errorName = false;
    public errorQtdMaxPiecesPerVolume = false;
    public errorStockBalance = false;

    constructor() {
    }

    validAll(valid: boolean) {
        this.errorName = valid;
        this.errorQtdMaxPiecesPerVolume = valid;
        this.errorStockBalance = valid;
    }
}
