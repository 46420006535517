export default {
  openFile: (multiple = false) =>
    new Promise((resolve, reject) => {
      const accept = 'image/jpeg';
      const inputFile = document.createElement('input');
      inputFile.type = 'file';
      inputFile.style.position = 'absolute';
      inputFile.style.top = '-100px';
      inputFile.multiple = multiple;
      document.body.appendChild(inputFile);
      inputFile.addEventListener('change', async (e: any) => {
        if (multiple) {
          try {
            const files = await Promise.all(
              Array.from(e.target.files).map(
                (f: any) =>
                  new Promise((singleResolve) => {
                    const singleReader = new FileReader();
                    singleReader.onload = (e2: any) =>
                      singleResolve(e2.target.result);
                    if (f.type === 'image/jpeg') {
                      if (f.size <= 500000) {
                        singleReader.readAsDataURL(f);
                      } else {
                        return reject(
                          `Imagem excedeu o tamanho máximo de 500KB. Tamanho da imagem: ${(
                            f.size / 1000
                          ).toFixed(0)}KB!`
                        );
                      }
                    } else {
                      return reject('Selecione apenas imagens do tipo: jpeg!');
                    }
                  })
              )
            );
            return resolve(files);
          } catch (error) {
            return reject(error);
          }
        }
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (e2: any) => resolve(e2.target.result);
        reader.readAsDataURL(file);
      });
      inputFile.accept = accept || '';
      inputFile.click();
    }),

  openFileXML: () =>
    new Promise((resolve) => {
      const accept = 'application/xml';
      const inputFile = document.createElement('input');
      inputFile.type = 'file';
      inputFile.style.position = 'absolute';
      inputFile.style.top = '-100px';
      inputFile.multiple = false;
      document.body.appendChild(inputFile);
      inputFile.addEventListener('change', (e) => {
        resolve(e.target);
      });
      inputFile.accept = accept || '';
      inputFile.click();
    }),

  openFileXLS: () =>
    new Promise((resolve) => {
      const accept = 'application/xls';
      const inputFile = document.createElement('input');
      inputFile.type = 'file';
      inputFile.style.position = 'absolute';
      inputFile.style.top = '-100px';
      inputFile.multiple = false;
      document.body.appendChild(inputFile);
      inputFile.addEventListener('change', (e) => {
        resolve(e.target);
      });
      inputFile.accept = accept || '';
      inputFile.click();
    }),
};
