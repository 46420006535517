export default class AttachmentProductLink {
  id?: number;
  url?: string;
  urlAPI?: string;
  orderDisplay?: number;

  fromJson?(attachment: any) {
    if (attachment != null) {
      this.id = attachment.id;
      this.url = attachment.url;
      this.orderDisplay = attachment.orderDisplay;
    }
  }
}
