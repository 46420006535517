export default class AbstractInfoEnum {
  id: string;
  description: string;
  hasActiveProduct: boolean;

  fromJson(infObj: any) {
    this.id = infObj.id;
    this.description = infObj.description;
    this.hasActiveProduct = infObj.hasActiveProduct;
  }
}
