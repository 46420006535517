import { Component, OnInit } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { ImageGalleryImage } from 'src/app/components/image-gallery/image-gallery.component';
import AbstractInfoEnum from 'src/app/models/AbstractInfoEnum';
import InfoCategory from 'src/app/models/InfoCategory';
import InfoSubCategory from 'src/app/models/product/info-sub-category';
import Product from 'src/app/models/product/product';
import ProductFilter from 'src/app/models/product/product-filter';
import { AppInfoService } from 'src/services/app-info.service';
import { ProductService } from 'src/services/product.service';
import { ToastService } from 'src/services/toast/toast.service';
import { BlockOperation } from 'src/util/block-operation';
import { THREE_SECONDS_IN_MS, wait } from './../../../util/wait';
import { GroupingAttributesEnum } from './../../models/InfoEnum';

@Component({
  selector: 'app-grouped',
  templateUrl: './grouped.component.html',
  styleUrls: ['./grouped.component.scss'],
})
export class GroupedComponent implements OnInit {
  public readonly GROUP_BY_COLOR = GroupingAttributesEnum.GROUP_BY_COLOR;

  public allSubCategories: InfoSubCategory = new InfoSubCategory();
  public blockOperation = new BlockOperation();
  public categories: Array<InfoCategory> = [];
  public cols: any[] = [];
  public colsGrouped: any[] = [];
  public groupingAttributes: Array<AbstractInfoEnum> = [];
  public model: Product;
  public images: Array<ImageGalleryImage> = [];
  public origins: Array<AbstractInfoEnum> = [];
  public products: Array<Product> = [];
  public productFilter: ProductFilter = new ProductFilter();
  public productFilterGrouped: ProductFilter = new ProductFilter();
  public productsSearch: Array<Product>;
  public productsSelected: Array<Product> = [];
  public productsVisible: Array<Product> = [];
  public selectedCaregory: InfoCategory = null;
  public selectedGroupeds: Array<Product> = [];
  public selectedSubcategory: InfoSubCategory = null;
  public showModalProductGroup = false;
  public subpage = 'grid';

  constructor(
    private appInfo: AppInfoService,
    private confirmationService: ConfirmationService,
    private toastService: ToastService,
    public productService: ProductService
  ) {}

  ngOnInit() {
    this.getCategories();
    this.getProductOrigin();
    this.getGroupingAttributes();
    this.cols = [
      { field: 'id', header: 'SKU' },
      { field: 'name', header: 'Nome' },
      { field: 'gtin', header: 'GTIN' },
      { field: 'enabled', header: 'Ativo' },
    ];

    this.colsGrouped = [
      { field: 'id', header: 'SKU' },
      { field: 'name', header: 'Nome' },
      { field: 'brandDescription', header: 'Marca' },
      { field: 'value', header: 'Valor' },
      { field: 'stockBalance', header: 'Estoque' },
    ];
  }

  getCategories() {
    try {
      this.categories = this.appInfo.getCategories();
      this.allSubCategories = this.appInfo.getAllSubCategories();
      this.loadProducts();
    } catch (err) {
      console.error(err);
    }
  }

  getProductOrigin() {
    try {
      this.origins = this.appInfo.getProductOrigin();
    } catch (err) {
      console.error(err);
    }
  }

  getGroupingAttributes() {
    try {
      this.groupingAttributes = this.appInfo.getGroupingAttributes();
    } catch (err) {
      console.error(err);
    }
  }

  async loadProducts() {
    this.blockOperation.setBlockOperation('', true);
    try {
      this.productFilter.direction = 'DESC';
      this.productFilter.order = ['id'];
      this.products = await this.productService.groupPageableList(
        this.productFilter
      );
      this.products.forEach((p) => {
        Product.getAllDescription(this.categories, p);
      });
      this.productsVisible = [...this.products];
    } catch (error) {
      this.toastService.error(error);
    } finally {
      this.blockOperation.setBlockOperation('', false);
    }
  }

  onClickRemoveSkus() {
    if (this.selectedGroupeds?.length === 0) {
      this.toastService.error('É necessário selecionar ao menos um produto!');
      return;
    }

    this.confirmationService.confirm({
      message: 'Deseja realmente excluir os produtos selecionados?',
      header: 'Confirmação',
      accept: () => {
        if (this.model.groupedSkus.length > 0) {
          this.selectedGroupeds.forEach((p1) => {
            this.model.groupedSkus = this.model.groupedSkus.filter(
              (p2) => p1.id !== p2.id
            );
          });

          this.selectedGroupeds = [];
        }
      },
    });
  }

  onClickAddSkuSelectedOnList() {
    this.toastService.error('Ainda não inplementado. Adicione todos! ');
    return;
    this.addSKUsOnList(this.productsSelected);
  }

  onClickAddAllSkuOnList() {
    this.addSKUsOnList(this.productsSearch);
  }

  addSKUsOnList(products: Product[]) {
    if (!this.model.groupedSkus) {
      this.model.groupedSkus = [];
    }
    let lsSkuExisting = '';
    let exist;
    products.forEach((product) => {
      exist = this.model.groupedSkus.find((p) => product.id === p.id);
      if (exist) {
        lsSkuExisting += ' | ' + product.id;
      } else {
        this.model.groupedSkus.push(product);
      }
    });

    if (lsSkuExisting) {
      this.toastService.error('SKUs Filho já cadastrado: ' + lsSkuExisting);
    }
  }

  async onClickSearchProductGroup() {
    this.productsSearch = [];
    this.blockOperation.setBlockOperation('', true);
    try {
      this.productFilterGrouped.direction = 'DESC';
      this.productFilterGrouped.order = ['id'];
      this.productFilterGrouped.color = this.model.color;
      this.productFilterGrouped.categories = [this.model.category];
      this.productFilterGrouped.subCategories = [this.model.subCategory];
      this.productsSearch = await this.productService.groupList(
        this.productFilterGrouped
      );
    } catch (error) {
      this.toastService.error(
        error || 'Ocorreu um erro ao buscar os produtos!'
      );
    } finally {
      this.blockOperation.setBlockOperation('', false);
    }
  }

  async onRowSelectProduct(event) {
    this.blockOperation.setBlockOperation('', true);
    try {
      this.model = await this.productService.getGroup(event.data.id);
      this.blockOperation.setBlockOperation('', false);
      this.changeCategory(this.model?.category);
      this.changeSubCategory(this.model?.subCategory);
      this.subpage = 'form';
    } catch (error) {
      this.toastService.error(error || 'Ocorreu um erro ao buscar o produto!');
    }
  }

  formatValue(value) {
    if (value && value > 0) {
      return `R$ ${value.toFixed(2).split('.').join(',')}`;
    }
    return '';
  }

  new() {
    this.model = new Product();
    this.subpage = 'form';
  }

  clickBack() {
    this.confirmationService.confirm({
      message: 'Deseja realmente sair sem salvar o produto?',
      header: 'Confirmação',
      accept: async () => {
        this.subpage = 'grid';
      },
    });
  }

  async clickSave() {
    if (!this.validProducts()) {
      return;
    }

    if (this.model.id) {
      this.update();
    } else {
      this.save();
    }
  }

  async save() {
    try {
      this.blockOperation.setBlockOperation('', true);
      this.model = await this.productService.saveGroup(this.model);
      this.blockOperation.setBlockOperation('', false);
      this.subpage = 'grid';
      this.toastService.success('Cadastro realizado com sucesso!');
      await wait(THREE_SECONDS_IN_MS);
      this.loadProducts();
    } catch (err) {
      this.blockOperation.setBlockOperation('', false);
      const msg = (err || {}).message || 'Erro ao cadastrar produto!';
      this.toastService.error(msg);
    }
  }

  async update() {
    try {
      this.blockOperation.setBlockOperation('', true);
      await this.productService.updateGroup(this.model);
      this.blockOperation.setBlockOperation('', false);
      this.subpage = 'grid';
      this.toastService.success('Produto alterado com sucesso!');
      await wait(THREE_SECONDS_IN_MS);
      this.loadProducts();
    } catch (err) {
      this.blockOperation.setBlockOperation('', false);
      console.error(err);
      this.toastService.error(err?.message || 'Erro ao alterar produto!');
    }
  }

  validProducts() {
    if (
      !this.model?.name ||
      !this.model?.value ||
      !this.model?.gtin ||
      !this.model?.category ||
      !this.model?.subCategory ||
      !this.model?.color ||
      !this.model?.groupAttributeProduct ||
      !this.model?.groupProductIdToShow ||
      !this.model?.groupedSkus ||
      this.model?.groupedSkus?.length === 0
    ) {
      this.toastService.error('Preencha todos os campos!');
      return false;
    }

    const productToShow = this.model?.groupedSkus?.find(
      (product) =>
        product?.id?.toString() === this.model?.groupProductIdToShow.toString()
    );

    if (!productToShow) {
      this.toastService.error(
        'O SKU que será mostrado na vitrine precisa estar na lista de SKUs Filhos!'
      );
      return false;
    }

    return true;
  }

  changeCategory(value) {
    if (value) {
      this.model.category = value;
      this.selectedCaregory = this.categories
        .filter((cat) => cat.id === this.model.category)
        .slice()
        .shift();
    } else {
      this.selectedCaregory = null;
    }
  }

  changeSubCategory(value) {
    if (value) {
      this.model.subCategory = value;
      this.selectedSubcategory = this.selectedCaregory.subCategories
        .filter((sub) => sub.id === this.model.subCategory)
        .slice()
        .shift();
    } else {
      this.selectedSubcategory = null;
    }
  }
}
