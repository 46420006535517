export const maskCPF = (cpf: string, mask: string): string => {
  if (cpf) {
    let i = 0;
    const value = mask.replace(/#/g, () => cpf[i++] || '');
    return value;
  }
  return cpf;
};

export const maskPhone = (phone: string, mask: string): string => {
  if (phone) {
    let i = 0;
    const value = mask.replace(/#/g, () => phone[i++] || '');
    return value;
  }
  return phone;
};
