import InfoEnum from './InfoEnum';
import Report from './reports/Report';

export default class AppInfo {
  enumeretions: InfoEnum;
  reports: Report;

  fromJson(appInfoObj: any) {
    if (appInfoObj != null) {
      this.enumeretions = new InfoEnum();
      this.enumeretions?.fromJson(appInfoObj?.enumeretions);

      this.reports = new Report();
      this.reports.fromJson(appInfoObj?.reports);
    }
  }
}
