import { Component, EventEmitter, Input, Output } from '@angular/core';

interface ComboOption {
  id: string;
  description: string;
}

@Component({
  selector: 'app-combo',
  template: `
    <select
      (change)="change($event)"
      [disabled]="disabled"
      [ngClass]="{ error: error }"
    >
      <option [selected]="!value">{{ emptyLabel }}</option>
      <option
        *ngFor="let option of options"
        [value]="option.id"
        [selected]="value === option.id"
      >
        {{ option.description }}
      </option>
    </select>
  `,
})
export class ComboComponent {
  @Output()
  public changed: EventEmitter<string> = new EventEmitter<string>();

  @Input()
  public options: Array<ComboOption> = [];

  @Input()
  public value: string;

  @Input()
  public disabled: false;

  @Input()
  public error: false;

  @Input()
  public emptyLabel = 'Selecione';

  change($event) {
    this.changed.emit($event.target.value);
  }
}
