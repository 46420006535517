export class BlockOperation {

    public blocked = false;
    public message = '';

    public setBlockOperation(message: string, statusBlock: boolean) {
        this.blocked = statusBlock;
        this.message = message || this.message;
    }

    public hide(): void {
        this.setBlockOperation('', false);
    }

    public show(): void {
        this.setBlockOperation('', true);
    }
}
