import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import coldefs from './cols/index';
import renderers from './renderers/index';
const w: any = window;

const randomId = () => 'el-id-' + `${Math.random()}`.substr(4, 5);

interface DataRow {
  index: number;
  data: any;
}

@Component({
  selector: 'app-datagrid',
  template: `
    <div class="app-datagrid" *ngIf="visible">
      <h3 *ngIf="rows && !rows.length">
        Não existe registro para o filtro selecionado!
      </h3>
      <ag-grid-angular
        *ngIf="columnDefs && rows && rows.length"
        [ngStyle]="{ height: gridHeight }"
        [rowData]="rows"
        class="ag-theme-alpine"
        [components]="gridComponents"
        [columnDefs]="columnDefs"
        [rowSelection]="rowSelection"
        [enableCellTextSelection]="enableCellTextSelection"
        [overlayNoRowsTemplate]="overlayNoRowsTemplate"
        (rowDoubleClicked)="doubleClicked($event)"
        (selectionChanged)="onSelectionChanged($event)"
        (sortChanged)="onSortChanged($event)"
        (gridReady)="onReady($event)"
        (rowDataChanged)="onRowDataChanged($event)"
        (cellValueChanged)="onCellValueChanged($event)"
      ></ag-grid-angular>
    </div>
  `,
})
export class DataGridComponent implements OnInit {
  randomId = randomId();

  gridComponents = { ...renderers };

  @Input()
  public visible = true;

  @Input()
  public height;

  @Input()
  public enableDelete = false;

  @Input()
  public enableEdit = false;

  @Input()
  public enableCellTextSelection = false;

  @Input()
  public type: string;

  @Input()
  public rowSelection: string;

  @Input()
  public customColDef: Array<any>;

  @Input()
  public gridHeight: string;

  @Input()
  public rows: Array<object> = [];

  @Output()
  public doubleClick: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public cellValueChanged: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  public selectionChanged: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  public sortChanged: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  public gridReady: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  public rowDataChanged: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  public configColumnsClicked: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  public edited: EventEmitter<DataRow> = new EventEmitter<DataRow>();

  @Output()
  public deleted: EventEmitter<DataRow> = new EventEmitter<DataRow>();

  @Input()
  set rowData(rows: Array<any>) {
    if (rows && rows.length > 0) {
      this.rows = rows.map((row, index) => {
        if (this.enableEdit) {
          row._onEdit = () => this.edited.emit({ index, data: row });
        }
        if (this.enableDelete) {
          row._onDelete = () => this.deleted.emit({ index, data: row });
        }
        return row;
      });
    }
  }

  @Input()
  set overlayNoRowsMessage(message: string) {
    const finalMessage =
      message === undefined || message === null
        ? 'Ainda não foram gerados registros.'
        : message;

    this.overlayNoRowsTemplate =
      '<span class="border-default" style="padding: 10px;">' +
      finalMessage +
      '</span>';
  }

  public overlayNoRowsTemplate;
  public columnDefs: Array<object> = null;
  public gridApi: any;
  public colApi: any;

  constructor() {
    this.visible = false;
    this.gridHeight = `${window.innerHeight * 0.6}px`;
    setTimeout(() => (this.visible = true), 100);
  }

  onReady($event) {
    this.gridApi = $event.api;
    this.colApi = $event.columnApi;
    this.gridApi.sizeColumnsToFit();
    this.gridReady.emit($event);
  }

  onRowDataChanged($event) {
    this.rowDataChanged.emit($event);
  }

  onSelectionChanged($event) {
    this.selectionChanged.emit($event);
  }

  onSortChanged($event) {
    this.sortChanged.emit($event);
  }

  ngOnInit() {
    this.columnDefs = this.customColDef || this.getColumnDef(this.type);
    if (!this.columnDefs) {
      console.error(`Nenhuma coluna definida`);
    }
    if (
      this.overlayNoRowsTemplate === undefined ||
      this.overlayNoRowsTemplate === null
    ) {
      this.overlayNoRowsMessage = null;
    }
  }

  doubleClicked($event) {
    this.doubleClick.emit($event.data);
  }

  onCellValueChanged($event) {
    this.cellValueChanged.emit($event);
  }

  getColumnDef(type: string): Array<object> {
    const coldef = coldefs.find((c) => c.type === type);
    if (this.enableDelete || this.enableEdit) {
      return [
        ...coldef.cols,
        {
          headerName: '',
          field: 'id',
          sortable: true,
          width: 130,
          cellRenderer: 'controlsRenderer',
        },
      ];
    }

    return coldef.cols;
  }
}
