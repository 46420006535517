import { Injectable } from '@angular/core';
import ReportOptions from 'src/app/models/reports/ReportOptions';
import Fetch from './fetch';

@Injectable({
  providedIn: 'root',
})
export class ReportstService {
  constructor() {}

  public async generate(reportOption: ReportOptions) {
    try {
      const resp = await Fetch(
        'POST',
        `reports/generate/`,
        reportOption,
        null,
        'blob'
      );
      return resp;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  public async updateProducts(xls: string, idReport: string) {
    try {
      const resp = await Fetch('PUT', `reports/product/update/`, {
        file: xls,
        reportName: idReport,
      });
      return resp;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
}
