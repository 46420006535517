import { CartFull } from 'src/app/models/cart/cart';
export default class Budget {
  cart?: CartFull;
  createdAt?: string;
  email?: string;
  hash?: string;
  id?: number;
  name?: string;
  phone?: string;
  status?: string;
  zipCode?: string;
}
