import { Component, Input } from '@angular/core';
import User from 'src/app/models/user/User';
import ValidationUser from 'src/app/models/validation/ValidationUser';
@Component({
  selector: 'app-personal-data',
  template: `
    <div class="rm-ecommerce-personal-data-component">
      <h3>{{ title }}</h3>

      <label>Nome:</label>
      <input
        type="text"
        autofocus
        [value]="user?.name || ''"
        (change)="user.name = $event.target.value"
        [disabled]="readOnly"
        [ngClass]="{ error: !!validation.errorName }"
      />

      <label>Sobrenome:</label>
      <input
        type="text"
        autofocus
        [value]="user?.lastName || ''"
        (change)="user.lastName = $event.target.value"
        [disabled]="readOnly"
        [ngClass]="{ error: !!validation.errorLastName }"
      />

      <label>CPF:</label>
      <input
        type="text"
        maxlength="14"
        mask="CPF_CNPJ"
        [disabled]="readOnly"
        [ngClass]="{
          error: !!validation.errorDocument && user?.document?.length < 14
        }"
        [(ngModel)]="user.document"
      />

      <label>Telefone:</label>
      <input
        type="text"
        mask="(00) 00000-0000"
        [disabled]="readOnly"
        [ngClass]="{ error: !!validation.errorPhone }"
        [(ngModel)]="user.phone"
      />

      <label>Data de nascimento:</label>
      <input
        type="text"
        mask="00/00/0000"
        [disabled]="readOnly"
        [ngClass]="{ error: !!validation.errorBirthDate }"
        [(ngModel)]="user.birthDate"
      />

      <label>E-mail:</label>
      <input
        type="email"
        [value]="user?.email || ''"
        disabled
        (change)="user.email = $event.target.value"
        [disabled]="readOnly"
        [ngClass]="{ error: !!validation.errorEmail }"
      />

      <div class="password" *ngIf="!readOnly && !hidePassword">
        <label>Senha:</label>
        <input
          [type]="showPassword ? 'text' : 'password'"
          title="Sua senha deve conter no mínimo 8 caracteres, letras maiúsculas, minúsculas e números!"
          [value]="user?.password || ''"
          (change)="user.password = $event.target.value"
          [ngClass]="{ error: !!validation.errorPassword }"
        />
        <img
          src="assets/img/pwshow.png"
          alt="Mostrar senha"
          *ngIf="!showPassword"
          (click)="showPassword = !showPassword"
        />
        <img
          src="assets/img/pwhide.png"
          alt="Esconder senha"
          *ngIf="showPassword"
          (click)="showPassword = !showPassword"
        />
      </div>

      <div
        class="password"
        *ngIf="(editing && !hidePassword) || (!readOnly && !hidePassword)"
      >
        <label>Confirme a Senha:</label>
        <input
          [type]="showConfirmPassword ? 'text' : 'password'"
          title="Sua senha deve conter no mínimo 8 caracteres, letras maiúsculas, minúsculas e números!"
          [value]="user?.confirmPassword || ''"
          (change)="user.confirmPassword = $event.target.value"
          [ngClass]="{ error: !!validation.errorConfirmPassword }"
        />
        <img
          src="assets/img/pwshow.png"
          alt="Mostrar senha"
          *ngIf="!showConfirmPassword"
          (click)="showConfirmPassword = !showConfirmPassword"
        />
        <img
          src="assets/img/pwhide.png"
          alt="Esconder senha"
          *ngIf="showConfirmPassword"
          (click)="showConfirmPassword = !showConfirmPassword"
        />
      </div>

      <p>
        Sua senha deve conter no mínimo 8 caracteres, letras maiúsculas,
        minúsculas e números!
      </p>
    </div>
  `,
})
export class PersonalDataComponent {
  @Input()
  public user: User;

  @Input()
  public editing: boolean;

  @Input()
  public readOnly: boolean;

  @Input()
  public title: string;

  @Input()
  public validation: ValidationUser;

  @Input()
  public hidePassword: boolean = false;

  public showPassword = false;
  public showConfirmPassword = false;

  constructor() {}
}
