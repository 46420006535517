export const validCPF = (cpf: string): boolean => {
  cpf = cpf.replace(/\D/g, '');
  let rest: number;
  let sum = 0;

  if (cpf.length !== 11) {
    return false;
  }

  const firstChar = cpf[0];
  if (cpf.split(firstChar).length === 12) {
    return false;
  }

  for (let i = 1; i <= 9; i++) {
    sum = sum + +cpf.substring(i - 1, i) * (11 - i);
  }
  rest = (sum * 10) % 11;

  if (rest === 10 || rest === 11) {
    rest = 0;
  }

  if (rest !== +cpf.substring(9, 10)) {
    return false;
  }

  sum = 0;
  for (let i = 1; i <= 10; i++) {
    sum = sum + +cpf.substring(i - 1, i) * (12 - i);
  }
  rest = (sum * 10) % 11;

  if (rest === 10 || rest === 11) {
    rest = 0;
  }

  if (rest !== +cpf.substring(10, 11)) {
    return false;
  }

  return true;
};

export const formatCPF = (cpf: string): string => {
  if (cpf.length <= 14) {
    cpf = cpf.replace(/\D/g, '');
    cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2');
    cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2');
    cpf = cpf.replace(/(\d{3})(\d{1,2})/, '$1-$2');
    cpf = cpf.replace(/(-\d{2})\d+?$/, '$1');
  }
  return cpf.slice(0, 14);
};

export const hideNumbersCPF = (cpf: string): string => {
  if (
    cpf &&
    cpf.length === 14 &&
    cpf.charAt(3) === '.' &&
    cpf.charAt(7) === '.' &&
    cpf.charAt(11) === '-'
  ) {
    return cpf.replace(cpf.substring(3, 11), '.***.***');
  } else {
    return cpf;
  }
};
