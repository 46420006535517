import { Component } from '@angular/core';
import { AppInfoService } from 'src/services/app-info.service';
import { StorageConstants } from 'src/services/constants/storage-constants';
import { SessionStorageService } from './../services/session-storage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  public session: string;

  constructor(
    private appInfo: AppInfoService,
    public sessionStorageService: SessionStorageService
  ) {
    (async () => {
      await this.appInfo.getAppInfo();
      // this.session = 'catalog';

      const page = await this.sessionStorageService?.get(
        StorageConstants.RM_ECOMMERCE_PAGE
      );

      if (!page) {
        const token = await sessionStorageService?.get(
          StorageConstants.RM_ECOMMERCE_TOKEN
        );
        if (token) {
          this.session = 'home';
        } else {
          this.session = 'customer';
        }
      } else {
        this.session = page;
      }

      await this.sessionStorageService.set(
        StorageConstants.RM_ECOMMERCE_PAGE,
        ''
      );
    })();
  }
}
