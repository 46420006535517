import { Injectable } from '@angular/core';
import Checkout from 'src/app/models/checkout/Checkout';
import Fetch from './fetch';

@Injectable({
  providedIn: 'root',
})
export class TransactionsService {
  constructor() {}

  async checkout(checkout: Checkout) {
    try {
      const res = await Fetch('POST', `transactions/checkout/`, checkout);
      return res;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  async statusPix(idOrder: number) {
    try {
      const res = await Fetch('GET', `transactions/status?orderId=${idOrder}`);
      return res;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
}
