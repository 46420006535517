import { Component, OnInit } from '@angular/core';
import { DateTime } from 'luxon';
import User from 'src/app/models/user/User';
import ValidationUser from 'src/app/models/validation/ValidationUser';
import { UserService } from 'src/services/user.service';
import { BlockOperation } from 'src/util/block-operation';
import { ToastService } from './../../../../services/toast/toast.service';

@Component({
  selector: 'app-home-user-configs',
  template: `
    <div class="rm-ecommerce-home-menu-user-configs">
      <app-block-loader [blockOperation]="blockOperation"></app-block-loader>
      <h1>Meus Dados</h1>

      <div class="rm-ecommerce-home-configs">
        <app-personal-data
          [user]="user"
          [editing]="true"
          [validation]="validationUser"
          [hidePassword]="true"
        ></app-personal-data>
      </div>
      <div class="rm-ecommerce-save-bottom">
        <button class="rm-button" (click)="update()">Salvar</button>
      </div>
    </div>
  `,
})
export class UserConfigsPageComponent implements OnInit {
  public blockOperation = new BlockOperation();
  public user: User;
  public validationUser: ValidationUser = new ValidationUser();

  constructor(
    public userService: UserService,
    private toastService: ToastService
  ) {}

  async ngOnInit() {
    this.user = await this.userService.getUserStorage();
  }

  async update() {
    const haveError = !this.validationUser.valid(this.user, true);
    if (haveError) {
      this.toastService.error('Preencha os campos obrigatórios!');

      return;
    }

    try {
      this.blockOperation.setBlockOperation('', true);
      this.user.birthDate = this.user?.birthDate.replace(/\D/g, '');
      const dtBirt = DateTime.fromFormat(this.user?.birthDate, 'ddMMyyyy');
      if (!dtBirt.isValid) {
        this.toastService.error('Data incorreta!');
        return;
      }

      this.user.birthDate = dtBirt?.toFormat('dd/MM/yyyy');
      this.user.document = this.user?.document.replace(/\D/g, '');
      this.user.phone = this.user?.phone.replace(/\D/g, '');
      await this.userService.update(this.user);
      this.toastService.success('Dados alterados com sucesso!');
    } catch (error) {
      this.toastService.error(error.message || 'Erro ao alterar seus dados!');
    } finally {
      this.blockOperation.setBlockOperation('', false);
    }
  }
}
