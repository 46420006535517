<app-block-loader [blockOperation]="blockOperation"></app-block-loader>
<div class="rm-ecommerce-cart-page" *ngIf="!loadingMain">
  <app-cart-steps [currentStep]="currentStep"></app-cart-steps>

  <div class="cart-page-content">
    <div
      class="dont-cart"
      *ngIf="!haveCart() && !checkoutDone && !requestBudget"
    >
      <div class="dont-cart-content">
        <p>SEU CARRINHO ESTÁ VAZIO!</p>
        <button (click)="onClickShowProducts()">COMPRAR PRODUTOS</button>
      </div>
    </div>
    <table
      class="cart-details"
      *ngIf="haveCart() && !checkoutDone && !requestBudget"
    >
      <thead>
        <tr>
          <th class="mobile-invisible">Produto</th>
          <th class="mobile-invisible">Preço</th>
          <th class="mobile-invisible">Quantidade</th>
          <th class="mobile-invisible">Total</th>
          <th class="mobile-invisible">Remover</th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let item of cart.items">
          <td class="cart-details-product">
            <img
              src="{{ getImage(item) }}"
              [alt]="item.details.name"
              width="85"
              height="85"
              (click)="
                onClickProduct(item);
                $event.stopPropagation();
                $event.preventDefault()
              "
            />
            <div
              class="title"
              (click)="
                onClickProduct(item);
                $event.stopPropagation();
                $event.preventDefault()
              "
            >
              <h5>{{ item.details.name }}</h5>
              <small
                ><span>Marca: </span>{{ item.details.brandDescription || '' }}
              </small>
              <small><span>SKU: </span>{{ item.details.id }}</small>

              <div class="cart-list-qt mobile-visible">
                <div class="qt">
                  <button
                    class="btn-left"
                    (click)="
                      setQt(item, item.qt - 1);
                      $event.stopPropagation();
                      $event.preventDefault()
                    "
                  >
                    -
                  </button>
                  <input type="text" appNumberOnly [value]="item.qt" />
                  <button
                    class="btn-right"
                    (click)="
                      setQt(item, item.qt + 1);
                      $event.stopPropagation();
                      $event.preventDefault()
                    "
                  >
                    +
                  </button>
                </div>
                <h5 class="product-price">
                  {{ item.details.value * item.qt | currency }}
                </h5>
              </div>
            </div>
          </td>
          <td class="cart-price mobile-invisible">
            <h5 *ngIf="!item.details?.discount">
              {{ item.details.value | currency }}
            </h5>

            <h5 class="desconto" *ngIf="item.details?.discount > 0">
              {{ item.details.value | currency }}
            </h5>
            <h5 *ngIf="item.details?.discount > 0">
              {{ getValueWithDiscount(item.details) | currency }}
            </h5>
          </td>
          <td class="mobile-invisible">
            <div class="cart-list-qt">
              <button
                class="btn-left"
                (click)="
                  setQt(item, item.qt - 1);
                  $event.stopPropagation();
                  $event.preventDefault()
                "
              >
                -
              </button>
              <input
                type="text"
                appNumberOnly
                [value]="item.qt"
                (focusout)="setQt(item, $event.target.value)"
              />
              <button
                class="btn-right"
                (click)="
                  setQt(item, item.qt + 1);
                  $event.stopPropagation();
                  $event.preventDefault()
                "
              >
                +
              </button>
            </div>
          </td>
          <td class="cart-price mobile-invisible">
            <h5 *ngIf="!item.details?.discount">
              {{ item.details.value * item.qt | currency }}
            </h5>

            <h5 class="desconto" *ngIf="item.details?.discount > 0">
              {{ item.details.value * item.qt | currency }}
            </h5>
            <h5 *ngIf="item.details?.discount > 0">
              {{ getValueWithDiscount(item.details) * item.qt | currency }}
            </h5>
          </td>
          <td class="mobile-invisible">
            <a
              href="javascript:;"
              title="Remover do carrinho"
              (click)="removeItem(item)"
              ><img
                src="assets/img/delete.svg"
                alt="Remover produto"
                width="18"
            /></a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="btn-create-budget" *ngIf="user?.isAdmin() && haveCart()">
    <button (click)="onClickDelete()" title="Remover o carrinho">LIMPAR</button>
    <button (click)="budget = {}" title="Gerar orçamento para cliente">
      GERAR ORÇAMENTO
    </button>
  </div>

  <div class="content" *ngIf="haveCart() && !checkoutDone">
    <div class="freight">
      <div class="coupon_code">
        <h3>FRETE</h3>
        <div class="coupon_inner">
          <div *ngIf="loadingFreight" class="loading-freight">
            <img src="assets/img/loading.svg" alt="Carregando..." />
          </div>
          <div *ngIf="!loadingFreight" class="form">
            <div class="form-item">
              <app-cep-input
                placeholder="Seu CEP"
                [value]="zipCode || ''"
                (changed)="setZipCode($event)"
                (keyup)="onKeyUpZipCode($event)"
                (addressFound)="addressFound($event)"
              ></app-cep-input>
            </div>
            <button (click)="getAddress()" title="Calcular frete e prazo">
              CALCULAR
            </button>
            <span *ngIf="freightError">{{ freightError }}</span>
          </div>
          <div class="quotes-details" *ngIf="quotes && quotes.carriers">
            <div
              class="carrying-row"
              *ngFor="let carrying of quotes.carriers"
              (click)="setFreightSelected(carrying)"
              [ngClass]="{
                'freight-selected':
                  freightSelected?.freight?.offer === carrying.offer
              }"
            >
              <p class="carrying-row-name">
                {{ carrying?.displayName?.toLowerCase() }}
              </p>
              <p>
                {{ carrying.deliveryTime }}
                {{
                  carrying.deliveryTime === 1 ? 'dia' + ' útil' : 'dias úteis'
                }}
              </p>
              <p>{{ carrying.priceFreight | currency }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="total">
      <div class="coupon_code">
        <h3>RESUMO DO PEDIDO</h3>
        <div class="coupon_inner">
          <div class="cart_subtotal">
            <p>Subtotal</p>
            <p class="cart_amount">{{ formateSubtotal() | currency }}</p>
          </div>
          <div *ngIf="haveProductDiscount()" class="cart_subtotal">
            <p>Desconto produto</p>
            <p class="cart_amount">{{ formateValueDiscount() | currency }}</p>
          </div>
          <div class="cart_subtotal">
            <p>Frete</p>
            <p class="cart_amount">{{ formateValueFreight() | currency }}</p>
          </div>
          <div class="border-separator"></div>
          <div class="cart_subtotal">
            <p class="cart_subtotal_first">Total</p>
            <div class="cart_subtotal_pay">
              <p class="cart_amount">
                4x sem juros {{ getTotalValue() | currency }}
              </p>
              <p *ngIf="!haveProductDiscount()" class="cart_amount">
                à vista {{ formateCashDiscount() | currency }}
              </p>
              <p class="cart_amount">ou em até 12x vezes</p>
            </div>
          </div>
          <div class="border-separator"></div>
          <p class="text-center">
            cupons e outras opções de pagamentos na próxima etapa
          </p>
          <div class="checkout_btn">
            <button (click)="onClickShowProducts()">CONTINUAR COMPRANDO</button>
            <button (click)="onClickCheckout()">FINALIZAR COMPRA</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="cart-products-related" *ngIf="productsRelated.length > 0">
    <div class="cart-products-related-title">
      <h1>APROVEITE E LEVE TAMBÉM</h1>
    </div>
    <div class="cart-products-related-carousel">
      <swiper
        [spaceBetween]="20"
        [slidesPerView]="5"
        [slidesPerGroup]="5"
        [loop]="true"
        [loopFillGroupWithBlank]="true"
        [pagination]="{
          clickable: true
        }"
        [autoplay]="{
          delay: 3000,
          disableOnInteraction: false
        }"
        [breakpoints]="{
          '320': {
            slidesPerView: 2,
            slidesPerGroup: 2,
            spaceBetween: 10
          },
          '768': {
            slidesPerView: 3,
            slidesPerGroup: 3,
            spaceBetween: 20
          },
          '1024': {
            slidesPerView: 5,
            slidesPerGroup: 5,
            spaceBetween: 20
          }
        }"
        class="carousel-products-related"
      >
        <ng-template swiperSlide *ngFor="let product of productsRelated">
          <div
            class="product-item"
            (click)="
              selectIdProduct(product);
              $event.stopPropagation();
              $event.preventDefault()
            "
          >
            <div class="product-item-content">
              <img
                src="{{ product.mainPhotoS3Url }}"
                [alt]="product.name"
                class="product-image"
              />
              <div class="product-item-content-text">
                <h4>{{ product.name }}</h4>
                <div class="value">
                  <p *ngIf="product.discount > 0" class="discount">
                    de <span>{{ getValorSemDesconto(product) }}</span> por
                  </p>
                  <p class="total">
                    {{ installmentFormatInterestFree(product) }}
                  </p>
                </div>
                <p *ngIf="product.discount <= 0" class="description">
                  ou {{ getTotalValueProduct(product) | currency }} à vista
                </p>
                <ul
                  href="javascript:;"
                  class="add-to-cart"
                  (click)="selectIdProduct(product)"
                  title="Adicionar ao Carrinho"
                >
                  <li>
                    <a><img src="assets/img/cart.svg" alt="Carrinho" /></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </ng-template>
      </swiper>
    </div>
  </div>

  <div *ngIf="checkoutDone">
    <p>
      Seu pedido foi enviado para orçamento. Em breve, entraremos em contato com
      você.
    </p>
    <p>Obrigado pela preferência!</p>
  </div>
</div>
<app-modal *ngIf="budget" (closed)="budget = null" class="modal-budget">
  <h3>Preencha os dados</h3>
  <div class="form-item">
    <label>Nome</label>
    <input type="text" autofocus (change)="budget.name = $event.target.value" />
  </div>
  <div class="form-item">
    <label>E-mail</label>
    <input type="email" (change)="budget.email = $event.target.value" />
  </div>
  <div class="form-item">
    <label>Telefone</label>
    <input
      type="text"
      appNumberOnly
      (change)="budget.phone = $event.target.value"
      maxlength="11"
    />
  </div>
  <div class="text-right actions">
    <button (click)="onClickBtCreateBudget()">Gerar Orçamento</button>
  </div>
</app-modal>
