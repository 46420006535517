import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import Address from 'src/app/models/user/Address';
import ValidationAddress from 'src/app/models/validation/ValidationAddress';
import states from '../../repos/states';
import { ToastService } from './../../../services/toast/toast.service';

@Component({
  selector: 'app-address',
  template: `
    <div class="rm-ecommerce-address-component">
      <h3 *ngIf="title">{{ title }}</h3>
      <div class="radio-wrp"></div>
      <label>CEP:</label>
      <app-cep-input
        [value]="address?.zipCode || ''"
        (changed)="changeCep($event)"
        [error]="validation?.errorZipCode"
        (addressFound)="addressFound($event)"
        [readOnly]="readOnly"
      ></app-cep-input>

      <label>Endereço:</label>
      <input
        type="text"
        [disabled]="disabled"
        [value]="address?.address || ''"
        [disabled]="disabledAddress"
        [ngClass]="{ error: !!validation?.errorAddress }"
        (change)="address.address = $event.target.value"
      />

      <div class="rm-ecommerce-double-input-wrp">
        <div>
          <label>Número:</label>
          <input
            type="text"
            class="number"
            [disabled]="disabledNumber || readOnly"
            [value]="address?.numberAddress || ''"
            [ngClass]="{ error: !!validation?.errorNumberAddress }"
            (change)="address.numberAddress = $event.target.value"
          />
        </div>

        <div>
          <label>Complemento:</label>
          <input
            type="text"
            class="complemento"
            [disabled]="disabledComplement || readOnly"
            [value]="address?.complement || ''"
            [ngClass]="{ error: !!validation?.errorComplement }"
            (change)="address.complement = $event.target.value"
            [maxLength]="20"
          />
        </div>
      </div>

      <label>Bairro:</label>
      <input
        type="text"
        [disabled]="disabled"
        [value]="address?.district || ''"
        [disabled]="disableddistrict"
        [ngClass]="{ error: !!validation?.errorDistrict }"
        (change)="address.district = $event.target.value"
      />

      <div class="rm-ecommerce-double-input-wrp">
        <div>
          <label>Estado:</label>
          <select
            (change)="address.uf = $event.target.value"
            [disabled]="disabled"
            [ngClass]="{ error: !!validation?.errorUf }"
          >
            <option value="" [selected]="!address?.uf">Estado</option>
            <option
              *ngFor="let uf of states"
              [value]="uf?.value"
              [selected]="address?.uf === uf?.value"
            >
              {{ uf?.label }}
            </option>
          </select>
        </div>

        <div>
          <label>Cidade:</label>
          <input
            type="text"
            [disabled]="disabled"
            [value]="address?.city || ''"
            [ngClass]="{ error: !!validation?.errorCity }"
            (change)="address.city = $event.target.value"
          />
        </div>
      </div>
    </div>
  `,
})
export class AddressComponent implements OnInit {
  states = states;

  @Input()
  public address: Address;

  @Input()
  public title: string;

  @Input()
  public validation: ValidationAddress;

  @Input()
  public readOnly: string;

  @Output()
  public clearedZip: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  public foundZip: EventEmitter<boolean> = new EventEmitter<boolean>();

  public disabled = true;
  public disabledComplement = true;
  public disabledNumber = true;
  public disabledAddress = true;
  public disableddistrict = true;

  constructor(private toastService: ToastService) {}

  ngOnInit() {
    if (this.address && this.address.zipCode) {
      this.disabledComplement = false;
      this.disabledNumber = false;
      this.disabledAddress = !!this.address.address;
      this.disableddistrict = !!this.address.district;
    }
  }

  changeCep(cep) {
    if (cep && cep.length === 8) {
      this.address.zipCode = cep;
    } else {
      this.address.address = null;
      this.address.city = null;
      this.address.complement = null;
      this.address.district = null;
      this.address.numberAddress = null;
      this.address.uf = null;
      this.clearedZip.emit(true);
    }
  }

  addressFound(address: Address) {
    if (address.zipCode) {
      this.address.fromJson(address);
      this.disabledComplement = false;
      this.disabledNumber = false;
      this.disabledAddress = !!this.address.address;
      this.disableddistrict = !!this.address.district;
      this.validation.validAll(false);
      this.foundZip.emit(true);
    } else {
      this.validation.errorZipCode = true;
      this.toastService.error('CEP inválido!');
    }
  }
}
