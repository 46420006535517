export const validPhone = (phone: string): boolean => {
  phone = phone.replace(/\D/g, '');
  let valid = false;
  if (phone?.length > 9) {
    valid = true;
  }
  return valid;
};

export const validBirthDate = (dt: string): boolean => {
  dt = dt.replace(/\D/g, '');
  let valid = false;
  if (dt?.length === 8) {
    valid = true;
  }
  return valid;
};

export const validPassword = (
  password: string,
  confirmPassword: string
): string => {
  let msg = '';
  if (password && confirmPassword) {
    const valid = new RegExp('^^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])').test(
      password
    );
    if (password.length < 8 || !valid) {
      msg =
        'Sua senha deve conter no mínimo 8 caracteres, letras maiúsculas, minúsculas e números';
    } else if (password !== confirmPassword) {
      msg = 'Senha e Confirmação estão diferente!';
    }
  } else {
    msg = 'Favor informar a senha e confirmação';
  }
  return msg;
};

export const validEmail = (email: string): boolean => {
  const valid = new RegExp(
    "([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|\"([]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|[[\t -Z^-~]*])"
  ).test(email);
  return valid;
};
