import AttachmentNFELink from '../attachment/AttachmentNFELink';

export default class OrderNFE {
  accessKey?: string;
  numSerie?: string;
  numNFE?: string;
  xml?: string;
  xmlName?: string;
  xmlSynchronizedWithHub?: string;
  dateEmission?: string;
  dateSynchronizedWithHub?: string;
  dateXmlSynchronizedWithHub?: string;
  synchronizedWithActiveHub = false;
  synchronizedWithHub = false;
  attachmentOrderNFE?: AttachmentNFELink;
}
