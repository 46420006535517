export default class ProductColorBalance {
  id?: number;
  category?: string;
  color?: string;
  name?: string;
  stockBalance?: number;
  urlFirstImage?: string;

  fromJson(product: any) {
    if (product) {
      this.id = product.id;
      this.category = product.category;
      this.color = product.color;
      this.name = product.name;
      this.stockBalance = product.stockBalance;
      this.urlFirstImage = product.urlFirstImage;
    }
  }
}
