import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import Address from 'src/app/models/user/Address';
import { AddressService } from 'src/services/address.service';

@Component({
  selector: 'app-cep-input',
  template: `
    <input
      type="text"
      (keyup)="keyup()"
      [(ngModel)]="value"
      [placeholder]="placeholder"
      [maxLength]="9"
      [ngClass]="{ error: !!error }"
      [disabled]="this.readOnly"
      mask="00000-000"
    />
  `,
})
export class CepInputComponent implements OnInit {
  @Output()
  public changed: EventEmitter<string> = new EventEmitter<string>();

  @Input()
  public placeholder: string = '';

  @Input()
  public value: string;

  @Input()
  public error: boolean;

  @Input()
  public readOnly: string;

  @Output()
  public addressFound: EventEmitter<Address> = new EventEmitter<Address>();

  constructor(private addressService: AddressService) {}

  ngOnInit() {
    if (this.value) {
      this.cep = this.value;
    }
  }

  @Input()
  set cep(cep: string) {
    this.value = cep || '';
  }

  keyup() {
    if (this.value?.length === 8) {
      this.addressService
        .getAddress(this.value)
        .then((address) => this.addressFound.emit(address));
      return this.changed.emit(this.value);
    } else {
      this.changed.emit(this.value);
    }
  }
}
