import Product from '../product/product';

export default class CartProductCheckout {
  public amount: number;
  public discount: number;
  public discountPercent: number;
  public gross: number;
  public id: number;
  public insufficientStock: boolean;
  public product: Product;
  public total: number;
  public value: number;
}
