import { Injectable } from '@angular/core';
import { environment } from './../environments/environment';

export enum InterestPercentageEnum {
  _5X = 5.28,
  _6X = 5.73,
  _7X = 6.19,
  _8X = 6.63,
  _9X = 7.08,
  _10X = 7.52,
  _11X = 7.96,
  _12X = 8.4,
}
@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  constructor() {}

  async getInstallments(value): Promise<any[]> {
    const installmentsValue = [];
    const installments = this.getTotalInstallments(value, false);

    for (let index = 1; index <= installments; index++) {
      if (index <= 4) {
        installmentsValue.push(value / index);
      } else {
        const interest: number = (this.getInterest(index) / 100) * value;
        const valueTotalWithInterest = value + interest;
        const amountWithInterest = valueTotalWithInterest / index;
        installmentsValue.push(amountWithInterest);
      }
    }
    return installmentsValue;
  }

  getTotalInstallments(value: number, free: boolean) {
    let installment =
      value / environment.minimumInstallment < 1
        ? 1
        : value / environment.minimumInstallment;
    if (free) {
      installment = installment > 4 ? 4 : installment;
    } else {
      installment = installment > 12 ? 12 : installment;
    }
    return Math.floor(installment);
  }

  getInterest(installment: number): number {
    switch (installment) {
      case 5:
        return InterestPercentageEnum._5X;
      case 6:
        return InterestPercentageEnum._6X;
      case 7:
        return InterestPercentageEnum._7X;
      case 8:
        return InterestPercentageEnum._8X;
      case 9:
        return InterestPercentageEnum._9X;
      case 10:
        return InterestPercentageEnum._10X;
      case 11:
        return InterestPercentageEnum._11X;
      case 12:
        return InterestPercentageEnum._12X;
      default:
        return 1;
    }
  }
}
