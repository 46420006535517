export default class AttachmentProduct {
    id?: number;
    photo?: string;
    orderDisplay?: number;

    fromJson(attachment: any) {
        if (attachment != null) {
            this.id = attachment.id ;
            this.photo = attachment.photo;
            this.orderDisplay = attachment.orderDisplay;
        }
    }
}
