<p-toast position="top-center" [baseZIndex]="9999"></p-toast>
<app-confirm-dialog></app-confirm-dialog>
<div class="rm-ecommerce-frontend">
  <app-customer-page *ngIf="session === 'customer'"></app-customer-page>
  <app-home-page *ngIf="session === 'home'"></app-home-page>
  <app-catalog *ngIf="session === 'catalog'"></app-catalog>
  <app-cart-component *ngIf="session === 'cart'"></app-cart-component>
  <app-orders *ngIf="session === 'orders'"></app-orders>
  <app-forgot *ngIf="session === 'forgot'"></app-forgot>
</div>
