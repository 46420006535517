import Carrying from './Carrying';
import Freight from './Freight';
export default class FreightSelected {
  public freight: Freight;
  public optionFreight?: string;

  constructor(carrier: Carrying, tokenOffer: string) {
    if (!carrier) {
      this.freight = null;
      this.optionFreight = Freight.STORE_PICKUP;
    } else {
      this.freight = new Freight(carrier, tokenOffer);
      this.optionFreight = carrier?.optionFreight;
    }
  }
}
