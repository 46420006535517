import { Component, ElementRef, Output, EventEmitter, Input } from '@angular/core';
import AttachmentProductLink from 'src/app/models/attachment/AttachmentProductLink';

@Component({
    selector: 'app-vertical-gallery',
    templateUrl: './vertical-gallery.component.html'
})
export class VerticalGalleryComponent {

    @Input()
    public images: Array<AttachmentProductLink> = [];

    @Input()
    public selectedImage: AttachmentProductLink;

    @Output()
    public selected: EventEmitter<AttachmentProductLink> = new EventEmitter<AttachmentProductLink>();

    private itemsWrp: any;

    constructor(el: ElementRef) {
        setTimeout(() => {
            this.itemsWrp = el.nativeElement.querySelector('.items-wrp');
            this.itemsWrp.style.transition = '0.2s';
        }, 50);
    }

    selectImage(selected: AttachmentProductLink) {
        this.selectedImage = selected;
        this.selected.emit(selected);
    }

}
