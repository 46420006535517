import { OrderStatusItem } from '../../components/order-status/order-status.component';
import MarketplaceTransaction from '../MarketplaceTransaction';
import Coupon from '../coupons/Coupon';
import Address from '../user/Address';
import { CartItem } from './../cart/cart';
import OrderBuyer from './OrderBuyer';
import OrderCancel from './OrderCancel';
import OrderNFE from './OrderNFE';
import OrderPayment from './OrderPayment';
import OrderShipping from './OrderShipping';
import OrderTracking from './OrderTracking';
import OrderTransaction from './OrderTransaction';

export interface OrderEvent {
  id?: number;
  previous?: string;
  updated?: string;
  createdAt: string;
}

export interface ShippingCompany {
  company?: string;
  tracking?: string;
  url?: string;
  dtCollect?: Date;
  dtEstimate?: Date;
}

export interface PaymentData {
  form?: string;
  qtdInstallments?: string;
  orderNumber?: string;
  status?: string;
}

export const orderStatus = {
  PENDING: 'Pendente',
  PAID_WAITING_SHIP: 'Pago',
  ON_EXPEDITION: 'Em Expedição',
  INVOICED: 'Faturado',
  PAID_WAITING_DELIVERY: 'Enviado',
  CONCLUDED: 'Entregue',
  CANCELED: 'Cancelado',
  order: [
    'PENDING',
    'PAID_WAITING_SHIP',
    'ON_EXPEDITION',
    'INVOICED',
    'PAID_WAITING_DELIVERY',
    'CONCLUDED',
  ],
};

export default class Order {
  id?: number;
  createdAt: string;
  cartProducts?: Array<CartItem>;
  currentStatus?: string;
  coupon?: Coupon;
  address?: Address;
  idUser?: string;
  nameUser?: string;
  emailUser?: string;
  phoneUser?: string;
  documentUser?: string;
  orderHistories?: Array<OrderEvent>;
  administratorNotes?: string;
  freight?: number;
  gross?: number;
  interestValue?: number;
  totalValue?: number;
  discount?: number;
  statuses?: Array<OrderStatusItem>;
  statusCanceled?: OrderStatusItem;
  orderNFE?: OrderNFE;
  marketplaceTransaction?: MarketplaceTransaction;
  orderTracking?: OrderTracking;
  orderPayments?: Array<OrderPayment>;
  orderBuyer?: OrderBuyer;
  orderCanceled?: OrderCancel;
  orderHubId?: string;
  orderShipping?: OrderShipping;
  orderTransaction?: OrderTransaction;
  totalVolumes?: number;
  totalWeight?: number;
  dtEstimate?: Date;
}
