import cartDetails from './cart-details';
import coupons from './coupons';
import customers from './customers';
import whatsapps from './whatsapps';

export default [
  { type: 'customers', cols: customers },
  { type: 'cart-details', cols: cartDetails },
  { type: 'coupons', cols: coupons },
  { type: 'whatsapps', cols: whatsapps },
];
