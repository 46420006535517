import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Observable, Observer, switchMap } from 'rxjs';
import AttachmentProduct from 'src/app/models/attachment/AttachmentProduct';
import Product from 'src/app/models/product/product';
import ProductFilter from 'src/app/models/product/product-filter';
import ValidationException from 'src/app/models/validation/ValidationException';
import Fetch from 'src/services/fetch';
import { ArrayUtils } from 'src/util/array-utils';
import { ImageGalleryImage } from './../app/components/image-gallery/image-gallery.component';
import { PageResponse } from './../app/models/page/page-response';

@Injectable({ providedIn: 'root' })
export class ProductService {
  constructor(private http: HttpClient) {}
  public async save(product: Product): Promise<Product> {
    try {
      if (product.productType === 1) {
        return await Fetch('POST', 'product/', product);
      } else {
        return await Fetch('POST', 'product/kit/', product);
      }
    } catch (err) {
      if (err?.message) {
        err.message = err.message.split('[').join('').split(']').join('');
        err.message = err.message
          .split(',')
          .map((str) => str.trim())
          .join('\n');
      }
      throw err;
    }
  }

  public async saveV2(
    product: Product,
    attachments: Array<AttachmentProduct>
  ): Promise<Product> {
    try {
      if (attachments && attachments.length > 0) {
        product.attachmentsCreateOrUpdate = attachments;
      }
      if (product.productType === 1) {
        return await Fetch('POST', 'v2/product/', product);
      } else {
        return await Fetch('POST', 'v2/product/kit/', product);
      }
    } catch (err) {
      if (err?.message) {
        err.message = err.message.split('[').join('').split(']').join('');
        err.message = err.message
          .split(',')
          .map((str) => str.trim())
          .join('\n');
      }
      throw err;
    }
  }

  public async updateV2(
    product: Product,
    attachmentsCreateOrUpdate: Array<AttachmentProduct>,
    attachmentsDelete: Array<number>
  ) {
    if (attachmentsCreateOrUpdate && attachmentsCreateOrUpdate.length > 0) {
      product.attachmentsCreateOrUpdate = attachmentsCreateOrUpdate;
    }

    if (attachmentsDelete && attachmentsDelete.length > 0) {
      product.attachmentsDelete = attachmentsDelete;
    }

    try {
      if (product.productType === 1) {
        await Fetch('PUT', `v2/product/${product.id}/`, product);
      } else {
        await Fetch('PUT', `v2/product/kit/${product.id}/`, product);
      }
    } catch (err) {
      if (err?.message) {
        err.message = err.message.split('[').join('').split(']').join('');
        err.message = err.message
          .split(',')
          .map((str) => str.trim())
          .join('\n');
      }
      throw err;
    }
  }

  public async update(product: Product) {
    try {
      if (product.productType === 1) {
        await Fetch('PUT', `product/${product.id}/`, product, null, 'text');
      } else {
        await Fetch('PUT', `product/kit/${product.id}/`, product, null, 'text');
      }
    } catch (err) {
      throw err;
    }
  }

  public async list(filter: ProductFilter): Promise<Array<Product>> {
    const products: Array<Product> = [];
    try {
      const response = await Fetch('POST', 'product/pageable/list/', filter);
      let newProduct: Product;
      response.content.forEach((productBanco) => {
        newProduct = new Product();
        newProduct.fromJson(productBanco);
        products.push(newProduct);
      });
      ArrayUtils.sort(products, 'value');
      return products;
    } catch (err) {
      throw err;
    }
  }

  public async listV2(
    filter: ProductFilter,
    orderByValue: boolean
  ): Promise<PageResponse> {
    let response: PageResponse = new PageResponse();
    try {
      response = await Fetch('POST', 'v2/product/pageable/list/', filter);
      if (response?.content) {
        const products: Array<Product> = [];
        let newProduct: Product;
        response.content.forEach((productBanco) => {
          newProduct = new Product();
          newProduct.fromJson(productBanco);
          products.push(newProduct);
        });
        if (orderByValue) {
          ArrayUtils.sort(products, 'value');
        }
        response.content = products;
      }
      return response;
    } catch (err) {
      throw err;
    }
  }

  public async get(id): Promise<Product> {
    try {
      const response = await Fetch('GET', `product/${id}`);
      let newProduct: Product;
      newProduct = new Product();
      newProduct.fromJson(response);
      return newProduct;
    } catch (err) {
      throw err;
    }
  }

  public async saveAttachment(
    images: Array<AttachmentProduct>,
    idProduct: number
  ) {
    try {
      if (idProduct) {
        return await Promise.all(
          images.map((image) =>
            Fetch(
              'POST',
              `product/${idProduct}/attachment/`,
              image,
              null,
              'text'
            )
          )
        );
      }
    } catch (err) {
      err.message =
        'Ocorreu um erro ao salvar/alterar as imagens do produto: ' + idProduct;
      throw err;
    }
  }

  public async deleteAttachment(
    idsAttachments: Array<number>,
    idProduct: number
  ) {
    try {
      return await Promise.all(
        idsAttachments.map((id) =>
          Fetch(
            'DELETE',
            `product/${idProduct}/attachment/${id}`,
            null,
            null,
            'text'
          )
        )
      );
    } catch (err) {
      err.message = 'Ocorreu um erro ao deletar as imagens!';
      throw err;
    }
  }

  public async syncronizedWithHubIntegration(idsProducts: Array<number>) {
    try {
      const resp = await Fetch(
        'POST',
        `product/hub-integration/syncronized/`,
        { ids: idsProducts },
        null,
        'text'
      );
      return resp;
    } catch (err) {
      throw err;
    }
  }

  public async syncronizedWithOmie(productsIds: Array<number>) {
    try {
      const resp = await Fetch(
        'POST',
        'v1/omie/product/batch/update/',
        { productsIds },
        null,
        'text'
      );
      return resp;
    } catch (err) {
      throw err;
    }
  }

  public async synchronizeWithFR(productsIds: Array<number>) {
    try {
      const resp = await Fetch(
        'POST',
        'v1/freterapido/product/batch/update/',
        { productsIds },
        null,
        'text'
      );
      return resp;
    } catch (err) {
      throw err;
    }
  }

  public async updateImgS3(idsProducts: Array<number>) {
    try {
      const resp = await Fetch(
        'POST',
        `product/update-image-extension`,
        { ids: idsProducts },
        null,
        'text'
      );
      return resp;
    } catch (err) {
      throw err;
    }
  }

  public async activateOnHubIntegration(idsProducts: Array<number>, activate) {
    try {
      const resp = await Fetch(
        'POST',
        `product/hub-integration/syncronized/${
          activate ? 'activate' : 'inactivate'
        }/`,
        { ids: idsProducts },
        null,
        'text'
      );
      return resp;
    } catch (err) {
      throw err;
    }
  }

  public async importXLS(xls: string) {
    try {
      const resp = await Fetch('PUT', `product/update/with/file/`, {
        file: xls,
      });
      return resp;
    } catch (err) {
      throw err;
    }
  }

  public async groupPageableList(
    filter: ProductFilter
  ): Promise<Array<Product>> {
    const products: Array<Product> = [];
    try {
      const response = await Fetch(
        'POST',
        'product/group/pageable/list/',
        filter
      );
      let newProduct: Product;
      response.content.forEach((productBanco) => {
        newProduct = new Product();
        newProduct.fromJson(productBanco);
        products.push(newProduct);
      });
      return products;
    } catch (err) {
      throw err;
    }
  }

  public async groupList(filter: ProductFilter): Promise<Array<Product>> {
    const products: Array<Product> = [];
    try {
      const response = await Fetch('POST', 'product/group/list/', filter);
      let newProduct: Product;
      response.content.forEach((productBanco) => {
        newProduct = new Product();
        newProduct.fromJson(productBanco);
        products.push(newProduct);
      });
      return products;
    } catch (err) {
      throw err;
    }
  }

  public async saveGroup(product: Product): Promise<Product> {
    if (!product) {
      throw new ValidationException('Product is null!');
    }
    try {
      return await Fetch('POST', 'product/group/', {
        category: product.category,
        subCategory: product.subCategory,
        color: product.color,
        name: product.name,
        value: product.value,
        gtin: product.gtin,
        groupAttributeProduct: product.groupAttributeProduct,
        groupProductIdToShow: product.groupProductIdToShow,
        productsIds: product.groupedSkus.map((p) => {
          return p.id;
        }),
      });
    } catch (err) {
      throw err;
    }
  }

  public async updateGroup(product: Product) {
    if (!product) {
      throw new ValidationException('Product is null!');
    }
    try {
      await Fetch(
        'PUT',
        `product/group/${product.id}/`,
        {
          category: product.category,
          subCategory: product.subCategory,
          color: product.color,
          name: product.name,
          value: product.value,
          gtin: product.gtin,
          groupAttributeProduct: product.groupAttributeProduct,
          groupProductIdToShow: product.groupProductIdToShow,
          productsIds: product.groupedSkus.map((p) => {
            return p.id;
          }),
        },
        null,
        'text'
      );
    } catch (err) {
      throw err;
    }
  }

  public async getGroup(id): Promise<Product> {
    try {
      const response = await Fetch('GET', `product/group/${id}`);
      let newProduct: Product;
      newProduct = new Product();
      newProduct.fromJson(response);
      return newProduct;
    } catch (err) {
      throw err;
    }
  }

  getBase64FromUrlOld = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  };
  async getBase64FromUrl(image: ImageGalleryImage): Promise<Observable<any>> {
    try {
      let defaultHeaders = new HttpHeaders()
        .set('Access-Control-Allow-Origin', '*')
        .set('Access-Control-Allow-Methods', 'POST, GET, PUT')
        .set('Access-Control-Allow-Headers', 'Content-Type')
        .set('Accept', '*/*')
        .set('Content-Type', 'image/jpeg')
        .set('Accept-Encondig', 'gzip, deflate, br');

      return this.http
        .get(image.url, {
          headers: defaultHeaders,
          responseType: 'blob',
          withCredentials: false,
        })
        .pipe(switchMap((blob) => this.convertBlobToBase64(blob)));
    } catch (error) {
      throw error;
    }
  }

  convertBlobToBase64(blob: Blob) {
    return new Observable((observer: Observer<any>) => {
      const reader = new FileReader();
      const binaryString = reader.readAsDataURL(blob);
      reader.onload = (event: any) => {
        observer.next(event.target.result);
        observer.complete();
      };

      reader.onerror = (event: any) => {
        observer.next(event.target.error.code);
        observer.complete();
      };
    });
  }

  public async saveProductsBatch(
    products: Array<Product>
  ): Promise<Array<Product>> {
    try {
      try {
        const newProducts: Array<Product> = await Fetch(
          'POST',
          'product/batch',
          products
        );
        return newProducts;
      } catch (error) {
        throw error;
      }
    } catch (error) {
      error.message = error.message || 'Ocorreu um erro ao salvar os Produtos!';
      throw error;
    }
  }

  public async saveAttachmentBatch(products: Array<Product>) {
    try {
      let newProducts = _.chunk(products, 100);
      for (let lsProducts of newProducts) {
        await Fetch(
          'POST',
          `product/attachment-batch/`,
          lsProducts.map((p) => ({
            id: p.id,
            photos: p.photos,
          })),
          null,
          'text'
        );
      }
    } catch (err) {
      throw err;
    }
  }

  private async saveAttachmentPromiseEach(
    images: AttachmentProduct[],
    idProduct: number
  ) {
    for (let image of images) {
      await Fetch(
        'POST',
        `product/${idProduct}/attachment/`,
        image,
        null,
        'text'
      );
    }
  }
}
