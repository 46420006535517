function ControlsRenderer() {
    const el = document.createElement('div');
    el.className = 'rm-ecommerce-controls-col';
    this.eGui = el;
}

ControlsRenderer.prototype.init = function(params) {
    this.rendererImage = params.rendererImage;
    this.value = params.value;
    this.refresh(params);
};

ControlsRenderer.prototype.getGui = function getGui() {
    return this.eGui;
};

ControlsRenderer.prototype.refresh = function(params) {
    const controls = {
        onEdit: params.data.__onEdit,
        onDelete: params.data.__onDelete
    };
    this.value = params.value;

    // Edit icon
    this.eGui.appendChild((() => {
        const editIcon = document.createElement('img');
        editIcon.src = 'assets/img/edit.svg';
        editIcon.height = editIcon.width = 24;
        editIcon.addEventListener('click', () => controls.onEdit && controls.onEdit());
        return editIcon;
    })());

    this.eGui.appendChild((() => {
        const editIcon = document.createElement('img');
        editIcon.src = 'assets/img/delete.svg';
        editIcon.height = editIcon.width = 28;
        editIcon.addEventListener('click', () => controls.onDelete && controls.onDelete());
        return editIcon;
    })());
};

export default ControlsRenderer;
