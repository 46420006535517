import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import CalculateShipping from 'src/app/models/freight/CalculateShipping';
import Carrying from 'src/app/models/freight/Carrying';
import Freight from 'src/app/models/freight/Freight';
import QuoteList from 'src/app/models/freight/QuoteList';
import Fetch from './fetch';

@Injectable({
  providedIn: 'root',
})
export class FreightService {
  constructor() {}

  public static addStorePickup() {
    const c = new Carrying();
    c.displayName = 'RETIRAR NA LOJA';
    c.priceFreight = 0;
    c.costFreight = 0;
    c.optionFreight = Freight.STORE_PICKUP;
    c.deliveryTime = 1;

    let dtEstimada = DateTime.now().plus({ days: 1 });
    const now = DateTime.now();
    const h = now.hour;
    if (h >= 17) {
      dtEstimada = dtEstimada.plus({ days: 1 });
      c.deliveryTime = 2;
    }
    c.estimatedDelivery = dtEstimada.toFormat('dd/MM/yyyy');
    return c;
  }

  async calculateShipping(calculate: CalculateShipping, tries = true) {
    try {
      const res = await Fetch('POST', `freight/calculate/shipping/`, calculate);
      const quote: QuoteList = Object.assign(new QuoteList(), res);
      const newCarries = [];

      if (quote?.carriers?.length > 0) {
        newCarries.push(FreightService.addStorePickup());
      }

      quote.carriers.map((c) => {
        c.name = c.name.toLowerCase();
        if (c.priceFreight === 0.01 || c.costFreight === 0.01) {
          c.priceFreight = 0;
          c.costFreight = 0;
        }
        newCarries.push(Object.assign(new Carrying(), c));
      });
      quote.carriers = newCarries.slice(0, 5);
      return quote;
    } catch (err) {
      if (tries) {
        return await this.calculateShipping(calculate, false);
      } else {
        throw err;
      }
    }
  }

  async calculateShippingCart(calculate: CalculateShipping, tries = true) {
    try {
      let res;
      if (calculate.cartId) {
        res = await Fetch(
          'POST',
          `freight/calculate/shipping/cart/`,
          calculate
        );
      } else {
        res = await Fetch('POST', `freight/calculate/shipping/`, calculate);
      }

      const quote: QuoteList = Object.assign(new QuoteList(), res);
      const newCarries = [];

      if (quote?.carriers?.length > 0) {
        newCarries.push(FreightService.addStorePickup());
      }

      quote.carriers.map((c) => {
        if (c.priceFreight === 0.01 || c.costFreight === 0.01) {
          c.priceFreight = 0;
          c.costFreight = 0;
        }
        newCarries.push(Object.assign(new Carrying(), c));
      });
      quote.carriers = newCarries.slice(0, 5);
      return quote;
    } catch (err) {
      if (tries) {
        return await this.calculateShipping(calculate, false);
      } else {
        throw err;
      }
    }
  }
}
