import { Component, Input } from '@angular/core';

export enum CartStepsEnum {
  PRODUCTS = 1,
  IDENTIFICATION = 2,
  PAYMENT = 3,
  ORDER_DONE = 4,
}

@Component({
  selector: 'app-cart-steps',
  templateUrl: './cart-steps.component.html',
  styleUrls: ['./cart-steps.component.scss'],
})
export class CartStepsComponent {
  enumStepProducts = CartStepsEnum.PRODUCTS;
  enumStepIdentification = CartStepsEnum.IDENTIFICATION;
  enumStepPayment = CartStepsEnum.PAYMENT;
  enumStepOrderDone = CartStepsEnum.ORDER_DONE;

  @Input()
  public currentStep = CartStepsEnum.PRODUCTS;
}
