import { Component, Input, OnInit } from '@angular/core';
import { DateTime } from 'luxon';
import Address from 'src/app/models/user/Address';
import User from 'src/app/models/user/User';
import ValidationAddress from 'src/app/models/validation/ValidationAddress';
import ValidationUser from 'src/app/models/validation/ValidationUser';
import { UserService } from 'src/services/user.service';
import { BlockOperation } from 'src/util/block-operation';
import { validEmail, validPhone } from 'src/util/validators';
import { ToastService } from './../../../services/toast/toast.service';
import { validCPF } from './../../../util/cpf';
import { TWO_SECONDS_IN_MS, wait } from './../../../util/wait';
import { CartStepsEnum } from './../cart-steps/cart-steps.component';

@Component({
  selector: 'app-sign-up',
  template: `
    <div class="rm-ecommerce-signup">
      <app-block-loader [blockOperation]="blockOperation"></app-block-loader>
      <app-cart-steps [currentStep]="currentStep"></app-cart-steps>

      <div class="rm-ecommerce-register">
        <div class="rm-ecommerce-personal">
          <app-personal-data
            [user]="user"
            [title]="'DADOS PESSOAIS'"
            [validation]="validationUser"
          ></app-personal-data>
        </div>

        <div class="rm-ecommerce-shipping">
          <app-address
            [address]="address"
            [title]="'ENDEREÇO'"
            [validation]="validationAddress"
          ></app-address>
          <div class="rm-ecommerce-signup-bottom">
            <button (click)="register()">CADASTRAR</button>
          </div>
        </div>
      </div>
    </div>
  `,
})
export class SignUpComponent implements OnInit {
  @Input()
  public user: User = new User();

  public address: Address;
  public blockOperation = new BlockOperation();
  public currentStep = CartStepsEnum.IDENTIFICATION;
  public validationAddress: ValidationAddress = new ValidationAddress();
  public validationUser: ValidationUser = new ValidationUser();

  constructor(
    public userService: UserService,
    private toastService: ToastService
  ) {
    this.user = new User();
    this.address = new Address();
  }

  ngOnInit() {
    this.user.address = [];
    this.user.isCompany = false;
    this.address.principal = true;
  }

  async register() {
    if (!this.validationUser.valid(this.user, false)) {
      this.toastService.error('Preencha os campos obrigatórios do usuário!');
      return;
    }

    if (!validCPF(this.user?.document)) {
      this.validationUser.errorDocument = true;
      this.toastService.error('Digite um CPF válido.');
      return;
    }

    if (!validEmail(this.user?.email)) {
      this.validationUser.errorEmail = true;
      this.toastService.error('Digite um E-mail válido.');
      return;
    }

    if (!validPhone(this.user?.phone)) {
      this.validationUser.errorPhone = true;
      this.toastService.error('Digite um telefone válido com DDD.');
      return;
    }

    this.user.birthDate = this.user?.birthDate.replace(/\D/g, '');
    const dtBirt = DateTime.fromFormat(this.user?.birthDate, 'ddMMyyyy');
    if (!dtBirt.isValid) {
      this.toastService.error(
        'Digite uma data de nascimento válida (DD/MM/AAAA)'
      );
      return;
    }

    const msg = this.validationUser.validPassword(this.user);
    if (msg) {
      this.toastService.error(msg);
      return;
    }

    if (!this.validationAddress.valid(this.address)) {
      this.toastService.error('Preencha os campos obrigatórios do endereço!');
      return;
    }

    try {
      this.blockOperation.setBlockOperation('', true);
      if (this.user.address.length === 0) {
        this.user.address.push(this.address);
      }
      this.user.confirmPassword = this.user?.password;
      this.user.document = this.user?.document.replace(/\D/g, '');
      this.user.phone = this.user?.phone.replace(/\D/g, '');
      this.user.birthDate = dtBirt?.toFormat('dd/MM/yyyy');
      await this.userService.save(this.user);
      this.toastService.success('Cadastro realizado com sucesso!');
      await wait(TWO_SECONDS_IN_MS);
      window.location.reload();
    } catch (error) {
      this.toastService.error(error?.message || 'Erro ao cadastrar usuário!');
    } finally {
      this.blockOperation.setBlockOperation('', false);
    }
  }
}
