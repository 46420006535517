import { Component } from '@angular/core';
import { UserService } from 'src/services/user.service';
@Component({
  selector: 'app-home-user-requests',
  template: `
    <div class="rm-ecommerce-home-menu-user-requests">
      <app-orders></app-orders>
    </div>
  `,
})
export class UserRequestsPageComponent {
  constructor(public userService: UserService) {}
}
