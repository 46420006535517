import { Component } from '@angular/core';

@Component({
  selector: 'app-home-all-requests',
  template: `
    <div class="rm-ecommerce-home-menu-user-requests">
      <h1>Pedidos</h1>
    </div>
  `,
})
export class AllRequestsPageComponent {
  constructor() {}
}
