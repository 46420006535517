export default class Address {
    id?: number;
    uf?: string;
    city?: string;
    district?: string;
    address?: string;
    numberAddress?: string;
    zipCode?: string;
    complement?: string;
    principal?: boolean;

    static validAdress(address: Address): boolean {
        const valid = true;
        return valid;
    }

    static filledAdress(address: Address): boolean {
        if (address.uf != null || address.city != null || address.district != null || address.address != null ||
            address.numberAddress != null || address.zipCode != null || address.complement != null || address.uf) {
                return true;
        }
        return false;
    }

    fromJson(address: any) {
        if (address) {
            this.id = address.id || '';
            this.uf = address.uf || '';
            this.city = address.city || '';
            this.district = address.district || '';
            this.address = address.address || '';
            this.numberAddress = address.numberAddress || '';
            this.zipCode = address.zipCode || '';
            this.complement = address.complement || '';
            this.principal = address.principal || '';
        }
    }
}
