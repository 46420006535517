export default class WhatsappFilter {
  cep?: string;
  direction?: string;
  email?: string;
  endDate?: string;
  name?: string;
  order?: any;
  phone?: string;
  startDate?: string;
}
