<div class="rm-ecommerce-home-menu-all-products product-grouped">
  <app-block-loader [blockOperation]="blockOperation"></app-block-loader>
  <div class="rm-ecommerce-home-header">
    <h1 *ngIf="subpage === 'form'">{{ model.name || 'Novo Agrupador' }}</h1>
  </div>

  <div class="grid" *ngIf="subpage === 'grid'">
    <h1 *ngIf="products.length === 0">
      NÃO EXISTE PRODUTO PARA O FILTRO SELECIONADO
    </h1>

    <p-table
      [columns]="cols"
      #tableuProducts
      selectionMode="single"
      [value]="products"
      styleClass="p-datatable-gridlines"
      [scrollable]="true"
      scrollHeight="500px"
      (onRowSelect)="onRowSelectProduct($event)"
      [globalFilterFields]="[
        'name',
        'id',
        'gtin',
        'enabled',
        'value',
        'groupAttributeProduct',
        'category',
        'subcategory',
        'color'
      ]"
    >
      <ng-template pTemplate="caption">
        <div class="table-header-search">
          <div class="icons">
            <span class="p-input-icon-left ml-auto">
              <i class="pi pi-search"></i>
              <input
                pTooltip="Pesquise qualquer campo do grid"
                tooltipPosition="bottom"
                pInputText
                type="text"
                (input)="
                  tableuProducts.filterGlobal(
                    $any($event.target).value,
                    'contains'
                  )
                "
                placeholder="Pesquisar"
              />
            </span>
            <button
              type="button"
              pButton
              pRipple
              icon="pi pi-file"
              (click)="tableuProducts.exportCSV()"
              pTooltip="Exportar para CSV"
              tooltipPosition="bottom"
            ></button>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="id">SKU<p-sortIcon field="id"></p-sortIcon></th>
          <th pSortableColumn="name">
            Nome<p-sortIcon field="name"></p-sortIcon>
          </th>
          <th pSortableColumn="value">
            Valor<p-sortIcon field="value"></p-sortIcon>
          </th>
          <th style="min-width: 160px" pSortableColumn="category">
            Categoria<p-sortIcon field="category"></p-sortIcon>
          </th>
          <th style="min-width: 160px" pSortableColumn="subcategory">
            Subcategoria<p-sortIcon field="subcategory"></p-sortIcon>
          </th>
          <th pSortableColumn="color">
            Cor<p-sortIcon field="color"></p-sortIcon>
          </th>
          <th pSortableColumn="groupAttributeProduct">
            Atributo<p-sortIcon field="groupAttributeProduct"></p-sortIcon>
          </th>
          <th pSortableColumn="gtin">
            GTIN<p-sortIcon field="gtin"></p-sortIcon>
          </th>
          <th pSortableColumn="enabled">
            Ativo<p-sortIcon field="enabled"></p-sortIcon>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-product>
        <tr [pSelectableRow]="product">
          <td>{{ product?.id }}</td>
          <td>{{ product?.name }}</td>
          <td>{{ product?.value | currency }}</td>
          <td>{{ product?.categoryDescription }}</td>
          <td>{{ product?.subCategoryDescription }}</td>
          <td>{{ product?.colorDescription }}</td>
          <td>{{ product?.groupAttributeProduct }}</td>
          <td>{{ product?.gtin }}</td>
          <td>
            <p-tag
              [value]="product?.enabled ? 'Sim' : 'Não'"
              [severity]="product?.enabled ? 'success' : 'danger'"
            ></p-tag>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="summary">
        TOTAL: {{ products.length }} PRODUTOS
      </ng-template>
    </p-table>

    <div class="btn-rodape-grid">
      <button
        pButton
        pRipple
        [label]="'NOVO'"
        icon="pi pi-plus-circle"
        class="p-button-success"
        (click)="new()"
      ></button>
    </div>
  </div>

  <div *ngIf="subpage === 'form'">
    <div class="rm-ecommerce-subpage-header">
      <button
        class="rm-button"
        (click)="clickBack()"
        title="Voltar para a listagem"
      >
        VOLTAR
      </button>
    </div>
    <div class="register-products">
      <div class="inf-basica inf-basica-grouped">
        <label>INFORMAÇÕES BÁSICAS</label>
        <div class="d-flex">
          <div class="item item-25">
            <label>CATEGORIA</label>
            <app-combo
              [emptyLabel]="'Selecione uma Categoria'"
              [options]="categories"
              (change)="changeCategory($event.target.value)"
              [value]="model.category"
              [disabled]="model?.id && model?.category"
              [ngClass]="{ 'error-combo': !model?.category }"
            ></app-combo>
          </div>
          <div class="item item-25">
            <label>SUBCATEGORIA</label>
            <app-combo
              [emptyLabel]="'Selecione uma Subategoria'"
              [options]="selectedCaregory?.subCategories"
              (change)="changeSubCategory($event.target.value)"
              [value]="model.subCategory"
              [disabled]="
                !selectedCaregory || (model?.id && model?.subCategory)
              "
              [ngClass]="{ 'error-combo': !model?.subCategory }"
            ></app-combo>
          </div>
          <div class="item item-25">
            <label>ATRIBUTO</label>
            <app-combo
              [emptyLabel]="'Selecione um Atributo'"
              [options]="groupingAttributes"
              (change)="model.groupAttributeProduct = $event.target.value"
              [value]="model.groupAttributeProduct"
              [disabled]="model?.id || (!model.id && !selectedSubcategory)"
              [ngClass]="{ 'error-combo': !model?.groupAttributeProduct }"
            ></app-combo>
          </div>
          <div class="item item-25">
            <label>COR</label>
            <app-combo
              [emptyLabel]="'Selecione uma Cor'"
              [options]="selectedSubcategory?.colors"
              (change)="model.color = $event.target.value"
              [value]="model.color"
              [disabled]="
                !model?.groupAttributeProduct || (model?.id && model?.color)
              "
              [ngClass]="{ 'error-combo': !model?.color }"
            ></app-combo>
          </div>
        </div>
        <div class="d-flex">
          <div class="item item-10">
            <label>ID (SKU)</label>
            <input
              type="text"
              autofocus
              (change)="model.id = $event.target.value"
              [value]="model.id || ''"
              [disabled]="true"
            />
          </div>
          <div class="item item-50">
            <label>NOME</label>
            <input
              type="text"
              [maxLength]="60"
              (change)="model.name = $event.target.value"
              [value]="model.name || ''"
              [ngClass]="{ error: !model?.name }"
            />
          </div>
          <div class="item item-20">
            <label>SKU EXIBIDO NA VITRINE</label>
            <input
              type="text"
              pattern="[0-9]*"
              inputmode="numeric"
              oninput="this.value = this.value.replace(/[^0-9]/g, '')"
              [maxLength]="6"
              (change)="model.groupProductIdToShow = $event.target.value"
              [value]="model.groupProductIdToShow || ''"
              [ngClass]="{ error: !model?.groupProductIdToShow }"
            />
          </div>
          <div class="item item-10">
            <label>VALOR M²</label>
            <input
              type="number"
              step="0.01"
              (change)="model.value = $event.target.value"
              [value]="model.value || ''"
              [ngClass]="{ error: !model?.value }"
            />
          </div>
          <div class="item item-10">
            <label>GTIN</label>
            <input
              type="text"
              appNumberOnly
              (change)="model.gtin = $event.target.value"
              [value]="model.gtin || ''"
              [ngClass]="{ error: !model?.gtin }"
            />
          </div>
        </div>
      </div>

      <div
        class="product-group"
        [ngClass]="{
          'error-grid':
            !this.model?.groupedSkus || this.model?.groupedSkus?.length === 0
        }"
      >
        <p-table
          [columns]="colsGrouped"
          #tableGrouped
          selectionMode="multiple"
          [(selection)]="selectedGroupeds"
          [value]="model.groupedSkus"
          [scrollable]="true"
          scrollHeight="400px"
          [globalFilterFields]="[
            'id',
            'name',
            'brandDescription',
            'stockBalance',
            'value'
          ]"
        >
          <ng-template pTemplate="caption">
            <div class="table-header-search">
              <span class="title-table-header">SKUS FILHOS</span>
              <div class="icons">
                <span class="p-input-icon-left ml-auto">
                  <i class="pi pi-search"></i>
                  <input
                    pTooltip="Pesquise qualquer campo do grid"
                    tooltipPosition="bottom"
                    pInputText
                    type="text"
                    (input)="
                      tableGrouped.filterGlobal(
                        $any($event.target).value,
                        'contains'
                      )
                    "
                    placeholder="Pesquisar"
                  />
                </span>
                <button
                  type="button"
                  pButton
                  pRipple
                  icon="pi pi-file"
                  (click)="tableGrouped.exportCSV()"
                  pTooltip="Exportar para CSV"
                  tooltipPosition="bottom"
                ></button>
              </div>
            </div>
          </ng-template>
          <ng-template pTemplate="header">
            <tr>
              <th></th>
              <th pSortableColumn="id">
                ID<p-sortIcon field="id"></p-sortIcon>
              </th>
              <th pSortableColumn="name">
                Nome<p-sortIcon field="name"></p-sortIcon>
              </th>
              <th pSortableColumn="brandDescription">
                Marca<p-sortIcon field="brandDescription"></p-sortIcon>
              </th>
              <th pSortableColumn="value">
                Valor<p-sortIcon field="value"></p-sortIcon>
              </th>
              <th pSortableColumn="stockBalance">
                Estoque<p-sortIcon field="stockBalance"></p-sortIcon>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-grouped>
            <tr [pSelectableRow]="grouped">
              <td>
                <img
                  [src]="grouped.mainPhotoS3Url"
                  [alt]="grouped.name"
                  width="60"
                  class="shadow-4"
                />
              </td>
              <td>{{ grouped?.id }}</td>
              <td>{{ grouped?.name }}</td>
              <td>{{ grouped?.brandDescription }}</td>
              <td>{{ grouped?.value | currency }}</td>
              <td>{{ grouped?.stockBalance }}</td>
            </tr>
          </ng-template>
          <ng-template pTemplate="summary">
            Total: {{ model?.groupedSkus?.length }} produtos

            <div>
              <button
                pButton
                pRipple
                [label]="'ADICIONAR SKU'"
                icon="pi pi-plus-circle"
                class="p-button-success"
                (click)="
                  showModalProductGroup = true; onClickSearchProductGroup()
                "
                [disabled]="!selectedSubcategory"
              ></button>
              <button
                pButton
                pRipple
                [label]="'EXCLUIR SELECIONADOS'"
                icon="pi pi-trash"
                class="p-button-danger"
                (click)="onClickRemoveSkus()"
                [disabled]="!model.groupedSkus || !model.groupedSkus.length"
              ></button>
            </div>
          </ng-template>
        </p-table>
      </div>
    </div>

    <div class="form-controls form-btn">
      <button
        pButton
        pRipple
        [label]="'SALVAR'"
        icon="pi pi-check"
        class="p-button"
        (click)="clickSave()"
      ></button>
    </div>
  </div>
</div>

<app-modal
  *ngIf="showModalProductGroup"
  (closed)="showModalProductGroup = false; productsSearch = []"
  class="modal-products-group"
>
  <div class="main">
    <div class="filter">
      <input
        type="text"
        placeholder="Digite o filtro desejado..."
        (change)="productFilterGrouped.querySearch = $event.target.value"
      />
      <button class="rm-button" (click)="onClickSearchProductGroup()">
        BUSCAR
      </button>
    </div>
    <div class="result">
      <div class="result-item" *ngFor="let product of productsSearch">
        <div>
          <img
            src="{{ product.mainPhotoS3Url }}"
            [alt]="product.name"
            width="70"
            height="70"
          />
          <p>
            {{
              product.id +
                ' - ' +
                product.name +
                ' - ' +
                product.brandDescription +
                ' - Estoque: ' +
                product.stockBalance +
                ' - Valor: '
            }}
            {{ product.value | currency }}
          </p>
        </div>
      </div>
    </div>
    <div class="buttons">
      <button
        pButton
        pRipple
        [label]="'ADICIONAR'"
        icon="pi pi-plus-circle"
        class="p-button-success"
        (click)="onClickAddSkuSelectedOnList()"
      ></button>
      <button
        pButton
        pRipple
        [label]="'ADICIONAR TODOS'"
        icon="pi pi-plus-circle"
        class="p-button-help"
        (click)="onClickAddAllSkuOnList()"
      ></button>
    </div>
  </div>
</app-modal>
