import AbstractInfoEnum from './AbstractInfoEnum';
import InfoSubCategory from './product/info-sub-category';

export default class InfoCategory extends AbstractInfoEnum {
  subCategories: Array<InfoSubCategory>;

  fromJson(categoriesObj: any) {
    if (categoriesObj != null) {
      super.fromJson(categoriesObj);

      if (
        categoriesObj.subCategories != null &&
        categoriesObj.subCategories.length > 0
      ) {
        this.subCategories = [];
        let newcSubCategory: InfoSubCategory;
        categoriesObj.subCategories.forEach((item) => {
          item.main = categoriesObj.id;
          newcSubCategory = new InfoSubCategory();
          newcSubCategory.fromJson(item);
          this.subCategories.push(newcSubCategory);
        });
      }
    }
  }
}
