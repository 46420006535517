export default [
  {
    headerName: 'Sku',
    valueGetter(params) {
      return params.data?.product?.id;
    },
    sortable: true,
    maxWidth: 80,
  },
  {
    headerName: 'Nome',
    valueGetter(params) {
      return params.data?.product?.name;
    },
    sortable: true,
  },
  {
    headerName: 'Marca',
    valueGetter(params) {
      return params.data?.product?.brandDescription;
    },
    sortable: true,
    maxWidth: 230,
  },
  {
    headerName: 'Valor Un.',
    valueGetter(params) {
      return params.data?.product?.valueString;
    },
    sortable: true,
    maxWidth: 120,
  },
  { headerName: 'Quantidade', field: 'amount', sortable: true, maxWidth: 110 },
  {
    headerName: 'Valor Total',
    valueGetter(params) {
      return getTotalValue(params);
    },
    sortable: true,
    maxWidth: 120,
  },
];

function getTotalValue(params) {
  let value = 0;
  value += params?.data?.product?.value * params?.data?.amount;
  return `R$ ${value.toFixed(2).split('.').join(',')}`;
}
