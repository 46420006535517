<app-block-loader [blockOperation]="blockOperation"></app-block-loader>
<h1 *ngIf="!couponSelected">Cupons de Desconto</h1>
<h1 *ngIf="couponSelected">{{ couponSelected.code }}</h1>

<div *ngIf="subpage === 'grid'">
  <app-datagrid
    type="coupons"
    *ngIf="!couponSelected"
    [rowData]="coupons"
    [enableCellTextSelection]="true"
    (doubleClick)="rowDoubleClicked($event)"
  ></app-datagrid>
  <div class="btn-footer">
    <button class="rm-button" (click)="onClickNew()">Novo Cupom</button>
  </div>
</div>

<div class="form" *ngIf="subpage === 'form'">
  <div class="rm-ecommerce-subpage-header">
    <button
      class="rm-button"
      (click)="onClickBack()"
      title="Voltar para a listagem"
    >
      Voltar
    </button>
  </div>

  <div class="register-coupons">
    <div class="d-flex">
      <div class="item item-15">
        <label>ID</label>
        <input
          type="text"
          (change)="couponSelected.id = $event.target.value"
          [value]="couponSelected.id || ''"
          [disabled]="true"
        />
      </div>
      <div class="item item-85">
        <label>Descrição</label>
        <input
          type="text"
          [maxLength]="60"
          (change)="couponSelected.description = $event.target.value"
          [value]="couponSelected.description || ''"
          [ngClass]="{ error: !!this.validation.errorDescription }"
        />
      </div>
    </div>
    <div class="d-flex">
      <div class="item item-25">
        <label>Código</label>
        <input
          type="text"
          (change)="couponSelected.code = $event.target.value?.toUpperCase()"
          [value]="couponSelected.code || ''"
          [ngClass]="{ error: !!this.validation.errorCode }"
          [maxLength]="15"
        />
      </div>
      <div class="item item-25">
        <label>Tipo do Cupom</label>
        <app-combo
          [options]="infoCoupon.types"
          (change)="couponSelected.type = $event.target.value"
          [value]="couponSelected.type"
          [error]="this.validation.errorType"
        ></app-combo>
      </div>
      <div class="item item-25">
        <label>Categoria</label>
        <app-combo
          [options]="categories"
          (change)="changeCategory($event)"
          [value]="couponSelected.category"
          [error]="this.validation.errorCategory"
          [disabled]="
            couponSelected.type !== this.CATEGORY &&
            couponSelected.type !== this.SUB_CATEGORY
          "
        ></app-combo>
      </div>
      <div class="item item-25">
        <label>Subcategoria</label>
        <app-combo
          [options]="selectedCategory.subCategories"
          (change)="couponSelected.subCategory = $event.target.value"
          [value]="couponSelected.subCategory"
          [error]="this.validation.errorSubCategory"
          [disabled]="couponSelected.type !== this.SUB_CATEGORY"
        ></app-combo>
      </div>
    </div>
    <div class="d-flex">
      <div class="item item-25">
        <label>Tipo do Desconto</label>
        <app-combo
          [options]="infoCoupon.discountTypes"
          (change)="changeDiscountTypes($event)"
          [value]="couponSelected.discountType"
          [error]="this.validation.errorDiscountType"
        ></app-combo>
      </div>
      <div class="item item-25">
        <label>Forma de Pagamento</label>
        <app-combo
          [options]="infoCoupon.meansOfPayment"
          (change)="couponSelected.meanOfPayment = $event.target.value"
          [value]="couponSelected.meanOfPayment"
          [error]="this.validation.errorMeanOfPayment"
        ></app-combo>
      </div>
      <div class="item item-25">
        <label>Valor</label>
        <input
          type="number"
          step="0.01"
          (change)="changeValue($event)"
          [value]="couponSelected.value || couponSelected.percentOff || ''"
          [ngClass]="{ error: !!this.validation.errorValue }"
        />
      </div>
      <div class="item item-25">
        <label>Cupom Disponível (0 = livre)</label>
        <input
          type="text"
          appNumberOnly
          (change)="couponSelected.amountUse = $event.target.value"
          [value]="couponSelected.amountUse"
        />
      </div>
    </div>
    <div class="d-flex">
      <div class="item item-25">
        <label>Início</label>
        <input
          type="text"
          mask="00/00/0000"
          [ngClass]="{ error: !!validation.errorStartDate }"
          [(ngModel)]="couponSelected.startDate"
        />
      </div>
      <div class="item item-25">
        <label>Fim</label>
        <input
          type="text"
          mask="00/00/0000"
          [ngClass]="{ error: !!validation.errorEndDate }"
          [(ngModel)]="couponSelected.endDate"
        />
      </div>
      <div class="item item-25">
        <label>Status</label>
        <app-combo
          [options]="infoCoupon.status"
          (change)="couponSelected.status = $event.target.value"
          [value]="couponSelected.status"
          [error]="this.validation.errorStatus"
        ></app-combo>
      </div>
    </div>
  </div>
  <div class="btn-footer">
    <button class="rm-button" (click)="onClickSave()">Salvar</button>
  </div>
</div>
