import Freight from 'src/app/models/freight/Freight';
export default class Carrying {
  public cnpj: string;
  public costFreight: number;
  public deliveryTime: number;
  public displayName: string;
  public estimatedDelivery: string;
  public logo: string;
  public name: string;
  public offer: number;
  public priceFreight: number;
  public service: string;
  public serviceDescription: string;
  public validity: string;
  public optionFreight = Freight.CARRYING;
}
