export default class ValidationCoupon {
  public errorCategory = false;
  public errorCode = false;
  public errorDescription = false;
  public errorDiscountType = false;
  public errorEndDate = false;
  public errorMeanOfPayment = false;
  public errorStartDate = false;
  public errorStatus = false;
  public errorSubCategory = false;
  public errorType = false;
  public errorValue = false;

  constructor() {}
}
