import { Component, OnInit } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import Address from 'src/app/models/user/Address';
import User from 'src/app/models/user/User';
import ValidationAddress from 'src/app/models/validation/ValidationAddress';
import ValidationUser from 'src/app/models/validation/ValidationUser';
import { UserService } from 'src/services/user.service';
import { BlockOperation } from 'src/util/block-operation';
import { ToastService } from './../../../../services/toast/toast.service';
import { TWO_SECONDS_IN_MS, wait } from './../../../../util/wait';

@Component({
  selector: 'app-home-all-customers',
  template: `
    <div class="menu-all-customers">
      <app-block-loader [blockOperation]="blockOperation"></app-block-loader>
      <div class="rm-ecommerce-home-header">
        <h1 *ngIf="!this.userSelected">Usuários</h1>
        <h1 *ngIf="this.userSelected">
          {{ this.userSelected.fullName() }} - {{ this.userSelected.createdAt }}
        </h1>
      </div>

      <div class="form m-b-20" *ngIf="!this.userSelected">
        <div class="form-item">
          <label for="field_name">Nome</label>
          <input
            type="text"
            placeholder="Procure pelo nome ou CPF..."
            (keyup)="keyUpFilter($event.target.value)"
            autofocus
          />
        </div>
      </div>
      <app-datagrid
        type="customers"
        *ngIf="!this.userSelected"
        [rowData]="visibleUsers"
        [enableCellTextSelection]="true"
        (gridReady)="onGridReady($event)"
        (doubleClick)="rowDoubleClicked($event)"
      ></app-datagrid>

      <div class="menu-all-customers-selected" *ngIf="this.userSelected">
        <div class="rm-ecommerce-subpage-header">
          <button
            class="rm-button"
            (click)="onClickVoltar()"
            title="Voltar para a listagem"
          >
            Voltar
          </button>
        </div>

        <div class="informations">
          <app-personal-data
            [user]="this.userSelected"
            [title]="'DadosPessoais'"
            [editing]="false"
            [readOnly]="true"
            [validation]="validationUser"
          ></app-personal-data>
          <app-address
            [address]="this.addressPrincipal"
            [title]="'Entrega'"
            [validation]="this.validationAddress"
            [readOnly]="true"
          ></app-address>
        </div>
        <button class="rm-button" (click)="onClickChangeToAdmin()">
          {{ userSelected?.isAdmin() ? 'Remover' : 'Tornar' }} Admin
        </button>
        <button
          class="rm-button"
          (click)="onClickDelete()"
          title="Excluir usuário"
        >
          Excluir
        </button>
      </div>
    </div>
  `,
})
export class AllCustomersPageComponent implements OnInit {
  public blockOperation = new BlockOperation();
  public allUsers: Array<User> = [];
  public visibleUsers: Array<User> = [];
  public userSelected: User;
  public addressPrincipal: Address;
  public addressBilling: Address;
  public validationAddress: ValidationAddress = new ValidationAddress();
  public validationUser: ValidationUser = new ValidationUser();
  public gridApi;

  constructor(
    private confirmationService: ConfirmationService,
    public userService: UserService,
    private toastService: ToastService
  ) {}

  ngOnInit() {
    this.list();
  }

  async list() {
    this.userSelected = null;
    this.blockOperation.setBlockOperation('', true);
    try {
      this.allUsers = await this.userService.list();
      this.visibleUsers = [...this.allUsers];
    } catch (err) {
      console.error(err);
      this.toastService.error('Erro ao buscar usuário!');
    } finally {
      this.blockOperation.setBlockOperation('', false);
    }
  }

  rowDoubleClicked(user: User) {
    if (user) {
      this.userSelected = user;
      this.addressPrincipal = this.userSelected.getAddress(true);
      if (this.userSelected.isAdmin()) {
        this.addressBilling = new Address();
        const ad = this.userSelected.getAddress(false);
        if (ad) {
          this.addressBilling = ad;
        }
      }
    }
  }

  keyUpFilter(value: string) {
    this.visibleUsers = [];
    if (value) {
      const newUsers = [];
      this.allUsers.forEach((user) => {
        const t1 = user.name
          ? user.name.toUpperCase().indexOf(value.toUpperCase()) !== -1
          : false;
        const t2 = user.document ? user?.document.indexOf(value) !== -1 : false;
        if (t1 || t2) {
          newUsers.push(user);
        }
      });
      this.visibleUsers = [...newUsers];
    }
  }

  onClickVoltar() {
    this.userSelected = null;
  }

  onClickDelete() {
    this.confirmationService.confirm({
      message: 'Deseja realmente excluir esse cliente?',
      header: 'Confirmação',
      accept: () => {
        this.deleteUser();
      },
    });
  }

  onClickChangeToAdmin() {
    this.confirmationService.confirm({
      message: 'Deseja realmente alterar esse cliente?',
      header: 'Confirmação',
      accept: () => {
        this.changeToAdmin();
      },
    });
  }

  async changeToAdmin() {
    this.blockOperation.setBlockOperation('', true);
    try {
      this.userSelected.roles = this.userSelected.isAdmin()
        ? [{ name: User.ROLE_USER }]
        : [{ name: User.ROLE_ADMIN }];
      await this.userService.update(this.userSelected);
      this.blockOperation.setBlockOperation('', false);
      this.toastService.success('Usuário alterado com sucesso!');
      await wait(TWO_SECONDS_IN_MS);
      this.list();
    } catch (error) {
      this.toastService.error(error?.message || 'Erro ao atualizar usuário!');
    } finally {
      this.blockOperation.setBlockOperation('', false);
    }
  }

  async deleteUser() {
    this.blockOperation.setBlockOperation('', true);
    try {
      await this.userService.delete(this.userSelected.id);
      this.blockOperation.setBlockOperation('', false);
      this.toastService.success('Cliente excluído com sucesso!');
      await wait(TWO_SECONDS_IN_MS);
      this.list();
    } catch (error) {
      this.toastService.error(
        error?.message || 'Erro ao excluir esse cliente!'
      );
    } finally {
      this.blockOperation.setBlockOperation('', false);
    }
  }

  onGridReady(params) {
    this.gridApi = params.api;
  }
}
