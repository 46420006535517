import { Component } from '@angular/core';

@Component({
  selector: 'app-home-all-coupons',
  template: `
    <div class="rm-ecommerce-home-menu-all-coupons">
      <app-coupons></app-coupons>
    </div>
  `,
})
export class AllCouponsPageComponent {
  constructor() {}
}
