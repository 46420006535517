import { Component, OnInit } from '@angular/core';
import Report from 'src/app/models/reports/Report';
import ReportPrintInfo from 'src/app/models/reports/ReportPrintInfo';
import ReportUpdateInfo from 'src/app/models/reports/ReportUpdateInfo';
import { AppInfoService } from 'src/services/app-info.service';
import { ReportstService } from 'src/services/reports.service';
import { UserService } from 'src/services/user.service';
import { BlockOperation } from 'src/util/block-operation';
import File from 'src/util/file';
import { ToastService } from './../../../../services/toast/toast.service';

@Component({
  selector: 'app-home-reports',
  template: `
    <div class="rm-ecommerce-home-menu-reports">
      <app-block-loader [blockOperation]="blockOperation"></app-block-loader>
      <h1>Relatórios</h1>

      <div class="form m-t-35">
        <div class="form-item">
          <label for="field_name">Relatórios Disponível para Download</label>
          <app-combo
            [options]="this.reports.reports.list"
            (change)="changeReportPrint($event.target.value)"
          ></app-combo>
        </div>
      </div>
      <div class="button-reports">
        <button class="rm-button" (click)="donwloadReport()">Download</button>
      </div>

      <div class="form m-t-50">
        <div class="form-item">
          <label for="field_name">Relatórios Disponível para Atualização</label>
          <app-combo
            [options]="this.reports.reports.update"
            (change)="changeReportUpdate($event.target.value)"
          ></app-combo>
        </div>
      </div>
      <div class="button-reports">
        <button class="rm-button" (click)="updateFromReport()">
          Atualizar
        </button>
      </div>
    </div>
  `,
})
export class ReportsPageComponent implements OnInit {
  public blockOperation = new BlockOperation();
  public reports: Report;
  public reportPrintSelect: ReportPrintInfo;
  public reportUpdateSelect: ReportUpdateInfo;
  public xls: string;

  constructor(
    private appInfo: AppInfoService,
    public reportsService: ReportstService,
    private toastService: ToastService,
    public userService: UserService
  ) {}

  ngOnInit() {
    this.getReports();
  }

  getReports() {
    try {
      this.reports = this.appInfo.getReports();
    } catch (err) {
      console.error(err);
    }
  }

  changeReportPrint(value) {
    if (value && value !== 'Selecione') {
      this.reportPrintSelect = this.reports.reports.list.find(
        (r) => r.id === value
      );
    } else {
      this.reportPrintSelect = null;
    }
  }

  changeReportUpdate(value) {
    if (value && value !== 'Selecione') {
      this.reportUpdateSelect = this.reports.reports.update.find(
        (r) => r.id === value
      );
    } else {
      this.reportUpdateSelect = null;
    }
  }

  async donwloadReport() {
    if (this.reportPrintSelect) {
      this.blockOperation.setBlockOperation('', true);
      try {
        const resp = await this.reportsService.generate({
          reportName: this.reportPrintSelect.id,
          reportType: this.reportPrintSelect.availableFormats.slice().shift(),
        });
        const url = window.URL.createObjectURL(resp);
        window.open(url, '_blank');
        const link = document.createElement('a');
        document.body.appendChild(link);
        link.setAttribute('style', 'display: none');
        link.href = url;
        link.download = `${this.reportPrintSelect.id}_${Date.now()}.xlsx`;
        link.click();
        window.URL.revokeObjectURL(url);
        link.remove();
      } catch (err) {
        this.toastService.error('Erro ao gerar o relatório!');
      } finally {
        this.blockOperation.setBlockOperation('', false);
      }
    } else {
      this.toastService.error('Selecione um relatório!');
    }
  }

  async updateFromReport() {
    if (this.reportUpdateSelect) {
      try {
        const target: any = await File.openFileXLS();
        this.xls = null;
        if (target) {
          const file = target.files[0];
          if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
              this.xls = reader.result.toString();
              this.importXLS();
            };
          }
        }
      } catch (err) {
        console.error(err);
        throw err;
      }
    } else {
      this.toastService.error('Selecione um relatório!');
    }
  }

  async importXLS() {
    if (this.xls) {
      this.blockOperation.setBlockOperation('', true);
      try {
        const resp = await this.reportsService.updateProducts(
          this.xls,
          this.reportUpdateSelect.id
        );
        if (resp.progress.status === 'ERROR') {
          this.toastService.error(
            'Erro ao importar a planilha. Verifique se está com a ordem das colunas corretamente e não possui valor vazio!'
          );
        } else {
          this.toastService.success('Planilha importada com sucesso!');
        }
      } catch (error) {
        this.toastService.error(
          error?.message || 'Erro ao atualizar produtos!'
        );
      } finally {
        this.blockOperation.setBlockOperation('', false);
      }
    }
  }
}
