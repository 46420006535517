import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-modal',
  template: `
    <div class="app-modal-overlay" [ngClass]="clazz">
      <div class="app-modal-content" [ngClass]="innerClass">
        <button
          class="rm-button app-modal-close"
          (click)="close()"
          *ngIf="closable"
        >
          <img src="assets/img/close.svg" />
        </button>
        <ng-content></ng-content>
      </div>
    </div>
  `,
})
export class ModalComponent {
  public clazz = '';

  @Input()
  public innerClass = '';

  @Input()
  public closable = true;

  constructor() {
    setTimeout(() => (this.clazz = 'opened'), 10);
  }

  @Output()
  public closed: EventEmitter<any> = new EventEmitter<any>();

  close() {
    this.clazz = 'closing';
    setTimeout(() => this.closed.emit(0), 300);
  }
}
