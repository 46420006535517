import { Component, OnInit } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { SessionStorageService } from 'src/services/session-storage.service';
import { UserService } from 'src/services/user.service';
import { BlockOperation } from 'src/util/block-operation';
import User from '../../models/user/User';
import { ToastService } from './../../../services/toast/toast.service';

export class MenuItem {
  constructor(
    public id,
    public title,
    public selected,
    public subMenus: Array<MenuItem>
  ) {}
}
@Component({
  selector: 'app-home-page',
  template: `
    <div class="rm-ecommerce-home">
      <app-block-loader [blockOperation]="blockOperation"></app-block-loader>
      <aside class="rm-ecommerce-home-menu">
        <div
          class="rm-ecommerce-home-menu-sidebar"
          [ngClass]="{ admin: user?.isAdmin() }"
        >
          <div class="rm-ecommerce-home-menu-sidebar-top">
            <img src="assets/img/no-avatar.png" width="32" />
            <h1>{{ this.user?.name }}</h1>
          </div>
          <a
            href="javascript:;"
            *ngFor="let menuItem of menuItems"
            [ngClass]="{
              active: menuItem.selected,
              'main-menu': menuItem?.subMenus,
              'menu-logout': menuItem?.id === MENU_LOGOUT
            }"
            (click)="clickMenu(menuItem)"
            >{{ menuItem.title }} {{ menuItem?.subMenus ? '+ ' : '' }}

            <div *ngIf="subMenuListOpened && menuItem?.subMenus">
              <a
                href="javascript:;"
                *ngFor="let subMenu of menuItem.subMenus"
                [ngClass]="{ active: subMenu.selected }"
                (click)="clickMenu(subMenu)"
                >{{ subMenu.title }}
              </a>
            </div>
          </a>
        </div>
      </aside>

      <div class="rm-ecommerce-home-main">
        <div class="rm-ecommerce-home-menu-content">
          <app-home-user-requests
            *ngIf="subSession === MENU_USER_REQUESTS"
          ></app-home-user-requests>
          <app-home-user-configs
            *ngIf="subSession === MENU_USER_CONFIGS"
          ></app-home-user-configs>
          <app-home-user-address
            *ngIf="subSession === MENU_USER_ADDRESS"
          ></app-home-user-address>
          <app-home-all-catalog
            *ngIf="subSession === MENU_CATALOG"
          ></app-home-all-catalog>
          <app-home-all-customers
            *ngIf="subSession === MENU_ALL_CUSTOMERS"
          ></app-home-all-customers>
          <app-home-all-products
            *ngIf="subSession === MENU_ALL_PRODUCTS"
          ></app-home-all-products>
          <app-home-all-requests
            *ngIf="subSession === MENU_ALL_REQUESTS"
          ></app-home-all-requests>
          <app-home-all-carts
            *ngIf="subSession === MENU_ALL_CARTS"
          ></app-home-all-carts>
          <app-home-all-coupons
            *ngIf="subSession === MENU_ALL_COUPONS"
          ></app-home-all-coupons>
          <app-home-all-whatsapp
            *ngIf="subSession === MENU_ALL_WHATSAPP"
          ></app-home-all-whatsapp>
          <app-home-reports
            *ngIf="subSession === MENU_REPORTS"
          ></app-home-reports>
          <app-grouped *ngIf="subSession === MENU_ALL_GROUPED"> </app-grouped>
        </div>
      </div>
    </div>
  `,
})
export class HomePageComponent implements OnInit {
  public MENU_USER_REQUESTS = 'user-requests';
  public MENU_USER_CONFIGS = 'user-configs';
  public MENU_USER_ADDRESS = 'user-address';
  public MENU_ALL_CUSTOMERS = 'all-customers';
  public MENU_ALL_PRODUCTS = 'all-products';
  public MENU_ALL_GROUPED = 'all-grouped';
  public MENU_ALL_REQUESTS = 'all-requests';
  public MENU_ALL_CARTS = 'all-carts';
  public MENU_ALL_COUPONS = 'all-coupons';
  public MENU_ALL_WHATSAPP = 'all-whatsapp';
  public MENU_CATALOG = 'catalog';
  public MENU_LOGOUT = 'logout';
  public MENU_REPORTS = 'reports';
  public MENU_LISTS = 'lists';

  public blockOperation = new BlockOperation();
  public menuItems: Array<MenuItem> = [];
  public user: User;
  public subSession: string;
  public subPageProducts = 'teste';
  public subMenuListOpened = false;

  constructor(
    private confirmationService: ConfirmationService,
    public sessionStorageService: SessionStorageService,
    private toastService: ToastService,
    public userService: UserService
  ) {}

  async ngOnInit() {
    this.user = await this.userService.getUserStorage();
    if (this.user?.isAdmin()) {
      this.getMenuAdmin();
    } else {
      this.getMenuUser();
    }

    this.menuItems.push(
      new MenuItem(this.MENU_USER_CONFIGS, 'Meus Dados', false, null)
    );
    this.menuItems.push(
      new MenuItem(this.MENU_USER_ADDRESS, 'Meu Endereço', false, null)
    );
    this.menuItems.push(new MenuItem(this.MENU_LOGOUT, 'Sair', false, null));
  }

  getMenuAdmin() {
    this.menuItems.push(
      new MenuItem(this.MENU_USER_REQUESTS, 'Todos Pedidos', true, null)
    );
    this.menuItems.push(
      new MenuItem(this.MENU_ALL_PRODUCTS, 'Produtos', false, null)
    );
    this.menuItems.push(
      new MenuItem(this.MENU_ALL_GROUPED, 'Agrupadores', false, null)
    );
    this.menuItems.push(
      new MenuItem(this.MENU_LISTS, 'Listas', false, this.getSubMenuList())
    );
    this.subSession = this.MENU_USER_REQUESTS;
  }

  getMenuUser() {
    this.menuItems.push(
      new MenuItem(this.MENU_USER_REQUESTS, 'Meus Pedidos', true, null)
    );
    this.subSession = this.MENU_USER_REQUESTS;
  }

  getSubMenuList() {
    const subMenu: Array<MenuItem> = [];
    subMenu.push(
      new MenuItem(this.MENU_ALL_CARTS, 'Carrinhos Abertos', false, null)
    );
    subMenu.push(new MenuItem(this.MENU_REPORTS, 'Relatórios', false, null));
    subMenu.push(
      new MenuItem(this.MENU_ALL_CUSTOMERS, 'Usuários', false, null)
    );
    subMenu.push(new MenuItem(this.MENU_ALL_COUPONS, 'Cupons', false, null));
    subMenu.push(new MenuItem(this.MENU_ALL_WHATSAPP, 'WhatsApp', false, null));
    return subMenu;
  }

  clickMenu(menuItemSelected: MenuItem) {
    if (menuItemSelected.id === this.MENU_LISTS) {
      this.subMenuListOpened = !this.subMenuListOpened;
      return;
    }
    this.subMenuListOpened = false;
    if (
      this.subSession === this.MENU_ALL_PRODUCTS &&
      this.subPageProducts === 'form'
    ) {
      this.confirmationService.confirm({
        message: 'Deseja realmente sair sem salvar o produto?',
        header: 'Confirmação',
        accept: () => {
          this.selectMenu(menuItemSelected);
        },
      });
    } else {
      this.selectMenu(menuItemSelected);
    }
  }

  selectMenu(menuItemSelected: MenuItem) {
    menuItemSelected.selected = true;
    if (menuItemSelected.id === this.MENU_LOGOUT) {
      this.logout();
    } else {
      this.subSession = menuItemSelected.id;
    }
    this.activeMenus(menuItemSelected);
  }

  activeMenus(menuItemSelected: MenuItem) {
    const otherMenus = this.menuItems.filter(
      (menu) => menu.id !== menuItemSelected.id
    );
    otherMenus.forEach((menu) => {
      menu.selected = false;
      if (menu?.subMenus) {
        menu.subMenus.forEach((sub) => {
          if (sub.id !== menuItemSelected.id) {
            sub.selected = false;
          }
        });
      }
    });
  }

  logout() {
    this.blockOperation.setBlockOperation('', true);
    try {
      this.userService.logout();
    } catch (err) {
      console.error(err);
      this.toastService.error('Ocorreu um erro ao tentar sair do sistema!');
    } finally {
      this.blockOperation.setBlockOperation('', false);
    }
  }
}
