import FreightSelected from '../freight/FreightSelected';
import CartCheckout from './CartCheckout';

export default class CartCheckoutDetails {
  public cart: CartCheckout;
  public discount: number;
  public freightSelected: FreightSelected;
  public gross: number;
  public total: number;

  constructor(obj) {
    this.cart = new CartCheckout(obj?.cart);
    this.discount = obj?.discount;
    this.freightSelected = new FreightSelected(
      obj?.freightSelected?.freight,
      obj?.freightSelected?.freight?.tokenOffer
    );
    this.freightSelected.optionFreight = obj?.freightSelected?.optionFreight;
    this.gross = obj?.gross;
    this.total = obj?.total;
  }
}
