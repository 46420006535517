import User from '../user/User';

export default class ValidationUser {
  public errorName = false;
  public errorLastName = false;
  public errorDocument = false;
  public errorPhone = false;
  public errorBirthDate = false;
  public errorEmail = false;
  public errorPassword = false;
  public errorConfirmPassword = false;

  constructor() {}

  validPassword(user: User) {
    let msg = '';
    if (user?.password && user?.confirmPassword) {
      const valid = new RegExp('^^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])').test(
        user?.password
      );
      if (user?.password.length < 8 || !valid) {
        msg =
          'Sua senha deve conter no mínimo 8 caracteres, letras maiúsculas, minúsculas e números';
      } else if (user?.password !== user?.confirmPassword) {
        msg = 'Senha e Confirmação estão diferente!';
      }

      if (msg) {
        this.errorPassword = true;
        this.errorConfirmPassword = true;
        return msg;
      } else {
        this.errorPassword = false;
        this.errorConfirmPassword = false;
      }
    } else {
      msg = 'Favor informar a senha e confirmação';
      this.errorPassword = true;
      this.errorConfirmPassword = true;
    }
    return msg;
  }

  valid(user: User, ignorPassword: boolean) {
    let valid = true;
    if (!user?.name || user?.name.length < 3) {
      this.errorName = true;
      valid = false;
    } else {
      this.errorName = false;
    }

    if (!user?.lastName || user?.lastName.length < 3) {
      this.errorLastName = true;
      valid = false;
    } else {
      this.errorLastName = false;
    }

    if (!user?.document) {
      this.errorDocument = true;
      valid = false;
    } else {
      this.errorDocument = false;
    }

    if (!user?.phone) {
      this.errorPhone = true;
      valid = false;
    } else {
      this.errorPhone = false;
    }

    if (!user?.birthDate) {
      this.errorBirthDate = true;
      valid = false;
    } else {
      this.errorBirthDate = false;
    }

    if (!user?.email) {
      this.errorEmail = true;
      valid = false;
    } else {
      this.errorEmail = false;
    }

    if (!ignorPassword) {
      if (!user?.password) {
        this.errorPassword = true;
        valid = false;
      } else {
        this.errorPassword = false;
      }

      if (!user?.confirmPassword) {
        this.errorConfirmPassword = true;
        valid = false;
      } else {
        this.errorConfirmPassword = false;
      }
    }

    return valid;
  }
}
