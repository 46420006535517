import Coupon from 'src/app/models/coupons/Coupon';

export default [
  { headerName: 'Id', field: 'id', sortable: true, maxWidth: 50 },
  { headerName: 'Código', field: 'code', sortable: true, maxWidth: 100 },
  { headerName: 'Descrição', field: 'description', sortable: true },
  { headerName: 'Tipo', field: 'type', sortable: true, maxWidth: 130 },
  {
    headerName: 'Tipo Desconto',
    field: 'discountType',
    sortable: true,
    maxWidth: 130,
  },
  {
    headerName: 'Valor',
    valueGetter(params) {
      return getValue(params);
    },
    sortable: true,
    maxWidth: 80,
  },
  { headerName: 'Início', field: 'startDate', sortable: true, maxWidth: 100 },
  { headerName: 'Fim', field: 'endDate', sortable: true, maxWidth: 100 },
  { headerName: 'Status', field: 'status', sortable: true, maxWidth: 80 },
];

function getValue(params) {
  if (params.data?.discountType === Coupon.FIXED) {
    return params.data?.value;
  } else {
    return params.data?.percentOff;
  }
}
