import { CategoriesEnum } from 'src/app/models/InfoEnum';
import AbstractInfoEnum from '../AbstractInfoEnum';
import { AttributeEnum, SubCategoriesEnum } from '../InfoEnum';

export default class InfoSubCategory extends AbstractInfoEnum {
  actuationSides: Array<AbstractInfoEnum> = [];
  actuationTypes: Array<AbstractInfoEnum> = [];
  aluminumWallThickness: Array<AbstractInfoEnum>;
  brands: Array<AbstractInfoEnum> = [];
  capabilities: Array<AbstractInfoEnum> = [];
  colors: Array<AbstractInfoEnum> = [];
  colorsCode: Array<AbstractInfoEnum> = [];
  fabricCompositions: Array<AbstractInfoEnum> = [];
  finishes: Array<AbstractInfoEnum> = [];
  heightThickness: Array<AbstractInfoEnum> = [];
  lengths: Array<AbstractInfoEnum> = [];
  models: Array<AbstractInfoEnum> = [];
  potencies: Array<AbstractInfoEnum> = [];
  speeds: Array<AbstractInfoEnum> = [];
  unitsCommercialize: Array<AbstractInfoEnum> = [];
  voltages: Array<AbstractInfoEnum> = [];
  widths: Array<AbstractInfoEnum> = [];

  categoy?: string;

  fromJson(subCategoriesObj: any) {
    const category = subCategoriesObj.main;
    if (subCategoriesObj != null) {
      super.fromJson(subCategoriesObj);

      if (
        subCategoriesObj.aluminumWallThickness != null &&
        subCategoriesObj.aluminumWallThickness.length > 0
      ) {
        this.aluminumWallThickness = this.newAbstractInfo(
          subCategoriesObj.aluminumWallThickness,
          true
        );
      }

      if (
        subCategoriesObj.colors != null &&
        subCategoriesObj.colors.length > 0
      ) {
        this.colors = this.newAbstractInfo(subCategoriesObj.colors, false);
      }

      if (
        subCategoriesObj.colorsCode != null &&
        subCategoriesObj.colorsCode.length > 0
      ) {
        this.colorsCode = this.newAbstractInfo(
          subCategoriesObj.colorsCode,
          false
        );
      }

      if (
        subCategoriesObj.finishes != null &&
        subCategoriesObj.finishes.length > 0
      ) {
        this.finishes = this.newAbstractInfo(subCategoriesObj.finishes, false);
      }

      if (
        subCategoriesObj.heightThickness != null &&
        subCategoriesObj.heightThickness.length > 0
      ) {
        this.heightThickness = this.newAbstractInfo(
          subCategoriesObj.heightThickness,
          true
        );
      }

      if (
        subCategoriesObj.widths != null &&
        subCategoriesObj.widths.length > 0
      ) {
        this.widths = this.newAbstractInfo(subCategoriesObj.widths, true);
      }

      if (
        subCategoriesObj.lengths != null &&
        subCategoriesObj.lengths.length > 0
      ) {
        this.lengths = this.newAbstractInfo(subCategoriesObj.lengths, true);
      }

      if (
        subCategoriesObj.brands != null &&
        subCategoriesObj.brands.length > 0
      ) {
        this.brands = this.newAbstractInfo(subCategoriesObj.brands, false);
      }

      if (
        subCategoriesObj.unitsCommercialize != null &&
        subCategoriesObj.unitsCommercialize.length > 0
      ) {
        this.unitsCommercialize = this.newAbstractInfo(
          subCategoriesObj.unitsCommercialize,
          false
        );
      }

      if (
        subCategoriesObj.capabilities != null &&
        subCategoriesObj.capabilities.length > 0
      ) {
        this.capabilities = this.newAbstractInfo(
          subCategoriesObj.capabilities,
          false
        );
      }

      if (
        subCategoriesObj.models != null &&
        subCategoriesObj.models.length > 0
      ) {
        this.models = this.newAbstractInfo(subCategoriesObj.models, false);
      }

      if (
        subCategoriesObj.potencies != null &&
        subCategoriesObj.potencies.length > 0
      ) {
        this.potencies = this.newAbstractInfo(
          subCategoriesObj.potencies,
          false
        );
      }

      if (
        subCategoriesObj.speeds != null &&
        subCategoriesObj.speeds.length > 0
      ) {
        this.speeds = this.newAbstractInfo(subCategoriesObj.speeds, false);
      }

      if (
        subCategoriesObj.voltages != null &&
        subCategoriesObj.voltages.length > 0
      ) {
        this.voltages = this.newAbstractInfo(subCategoriesObj.voltages, false);
      }

      if (
        subCategoriesObj.fabricCompositions != null &&
        subCategoriesObj.fabricCompositions.length > 0
      ) {
        this.fabricCompositions = this.newAbstractInfo(
          subCategoriesObj.fabricCompositions,
          false
        );
      }

      if (
        subCategoriesObj.actuationTypes != null &&
        subCategoriesObj.actuationTypes.length > 0
      ) {
        this.actuationTypes = this.newAbstractInfo(
          subCategoriesObj.actuationTypes,
          false
        );
      }

      if (
        subCategoriesObj.actuationSides != null &&
        subCategoriesObj.actuationSides.length > 0
      ) {
        this.actuationSides = this.newAbstractInfo(
          subCategoriesObj.actuationSides,
          false
        );
      }
    }
  }

  newAbstractInfo(subCategoriesObj: Array<any>, metreage: boolean) {
    let itens: Array<AbstractInfoEnum> = [];
    let abstractInfo: AbstractInfoEnum;
    itens = [];
    subCategoriesObj.forEach((item) => {
      abstractInfo = new AbstractInfoEnum();
      abstractInfo.fromJson(item);

      let value = parseFloat(abstractInfo.description.replace(',', '.'));

      if (metreage) {
        let sufix = 'cm';
        value = parseFloat(value.toFixed(4));
        abstractInfo.description = value.toString().concat(sufix);
      }
      itens.push(abstractInfo);
    });
    return itens;
  }

  categoryUseMM(category: string): boolean {
    return (
      category === CategoriesEnum.KITS ||
      category === CategoriesEnum.COBERTURAS_EM_POLICARBONATO ||
      category === CategoriesEnum.COMUNICACAO_VISUAL ||
      category === CategoriesEnum.PERFIS_EM_ALUMINIO
    );
  }

  useMetersOrCentimeter(category: string, subCategory: string): boolean {
    return (
      category === CategoriesEnum.PERFIS_EM_ALUMINIO ||
      subCategory === SubCategoriesEnum.ITENS_DE_SEGURANCA ||
      subCategory === SubCategoriesEnum.CANTONEIRAS_BARRA_CHATA ||
      subCategory === SubCategoriesEnum.DISCOS_CORTE_DESBASTE ||
      subCategory === SubCategoriesEnum.FITAS_SELANTES_PARAFUSOS ||
      subCategory === SubCategoriesEnum.PLACAS_SINALIZACAO ||
      subCategory === SubCategoriesEnum.PLASTICOS_EMBALAGENS ||
      subCategory === SubCategoriesEnum._ACESSORIOS_TOLDOS
    );
  }

  subCategoryUseMM(subCategory: string): boolean {
    return (
      subCategory === SubCategoriesEnum.PLACAS_SINALIZACAO ||
      subCategory === SubCategoriesEnum.PLASTICOS_EMBALAGENS
    );
  }

  attributeUseMM(attribute: string): boolean {
    return attribute === AttributeEnum.HEIGHT_THICKNESS;
  }
}
