export default class OrderBuyer {
  areCode?: string;
  cellPhone?: string;
  document?: string;
  documentType?: string;
  email?: string;
  integrationType?: string;
  name?: string;
  phone?: string;
}
