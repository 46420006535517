import { Component } from '@angular/core';

@Component({
  selector: 'app-home-all-whatsapp',
  template: `
    <div class="rm-ecommerce-home-menu-all-whatsapp">
      <app-whatsapp></app-whatsapp>
    </div>
  `,
})
export class AllWhatsappPageComponent {
  constructor() {}
}
