import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { ImageGalleryImage } from 'src/app/components/image-gallery/image-gallery.component';
import AbstractInfoEnum from 'src/app/models/AbstractInfoEnum';
import InfoCategory from 'src/app/models/InfoCategory';
import { CategoriesEnum } from 'src/app/models/InfoEnum';
import AttachmentProduct from 'src/app/models/attachment/AttachmentProduct';
import InfoSubCategory from 'src/app/models/product/info-sub-category';
import InformationSection from 'src/app/models/product/information-section';
import Product from 'src/app/models/product/product';
import ProductFilter from 'src/app/models/product/product-filter';
import Volume from 'src/app/models/product/volume';
import ValidationProduct from 'src/app/models/validation/ValidationProduct';
import { AppInfoService } from 'src/services/app-info.service';
import { ProductService } from 'src/services/product.service';
import { ToastService } from 'src/services/toast/toast.service';
import { BlockOperation } from 'src/util/block-operation';
import File from 'src/util/file';
import {
  ONE_SECOND_IN_MS,
  TWO_SECONDS_IN_MS,
  wait,
} from './../../../../util/wait';
import { PageResponse } from './../../../models/page/page-response';

import { Table } from 'primeng/table';
import AttachmentProductLink from 'src/app/models/attachment/AttachmentProductLink';
import * as XLSX from 'xlsx';

type AOA = any[][];

@Component({
  selector: 'app-home-all-products',
  templateUrl: './all.products.component.html',
})
export class AllProductsPageComponent implements OnInit {
  @ViewChild('filterGridInput') filterGridInput: ElementRef;

  @ViewChild('tableProducts')
  tableproducts: Table;

  @ViewChild('fileInput')
  fileInputVariable: ElementRef;
  batchProducts: AOA = [
    [1, 2],
    [3, 4],
  ];
  wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };

  public allProducts: Array<Product>;
  public allSubCategories: InfoSubCategory = new InfoSubCategory();
  public blockOperation = new BlockOperation();
  public categories: Array<InfoCategory> = [];
  public cols: any[] = [];
  public filterGrid: string;
  public images: Array<ImageGalleryImage> = [];
  public model: Product;
  public origins: Array<AbstractInfoEnum> = [];
  public productFilter: ProductFilter = new ProductFilter();
  public productFilterScreen: ProductFilter = new ProductFilter();
  public sections: Array<InformationSection> = [];
  public selectedProducts: Array<Product> = null;
  public selectedRowIndex: number = 0;
  public selectedSegmento: InfoCategory = new InfoCategory();
  public selectedSubCategory: InfoSubCategory = new InfoSubCategory();
  public showFilter = false;
  public subpage = 'grid';
  public validation: ValidationProduct = new ValidationProduct();
  public visibleProducts: Array<Product> = [];
  public xls: string;

  constructor(
    private appInfo: AppInfoService,
    private confirmationService: ConfirmationService,
    public productService: ProductService,
    private toastService: ToastService
  ) {}

  ngOnInit() {
    this.getCategories();
    this.getProductOrigin();
    this.productFilterScreen.active = true;

    this.cols = [
      { field: 'id', header: 'SKU' },
      { field: 'name', header: 'Título' },
      { field: 'value', header: 'Preço' },
      { field: 'enabled', header: 'Ativo' },
    ];
  }

  getCategories() {
    try {
      this.categories = this.appInfo.getCategories();
      this.allSubCategories = this.appInfo.getAllSubCategories();
      this.keyUpFilter(this.categories[0].id, 'categories');
    } catch (error) {
      this.toastService.error(error);
    }
  }

  getProductOrigin() {
    try {
      this.origins = this.appInfo.getProductOrigin();
    } catch (error) {
      this.toastService.error(error);
    }
  }

  async loadProducts() {
    this.blockOperation.setBlockOperation('', true);
    try {
      this.productFilter.direction = 'DESC';
      this.productFilter.order = ['id'];
      this.productFilter.ignoreMainVariation = true;
      this.productFilter.ignoreGrouped = true;
      const pageResponde: PageResponse = await this.productService.listV2(
        this.productFilter,
        false
      );

      if (pageResponde?.totalElements > 0) {
        this.allProducts = pageResponde.content;
        this.allProducts.forEach((p) => {
          Product.getAllDescription(this.categories, p);
        });
        this.visibleProducts = [...this.allProducts];
        this.filterProducts();
      }
    } catch (error) {
      this.toastService.error(error?.message);
    } finally {
      this.blockOperation.setBlockOperation('', false);
    }
  }

  formatValue(value) {
    if (value && value > 0) {
      return `R$ ${value.toFixed(2).split('.').join(',')}`;
    }
    return '';
  }

  onClickNew() {
    this.model = new Product();
    this.model.productType = 1;
    this.newVolume();
    this.images = [];
    this.sections = [];
    this.subpage = 'form';
  }

  newVolume() {
    if (!this.model.volumes) {
      this.model.volumes = [];
    }
    this.model.volumes.push(new Volume());
    this.calcVolumeWeight();
  }

  calcVolumeWeight() {
    if (this.model.volumes?.length > 0) {
      this.model.volumes.forEach((vl) => {
        vl.packingWeight =
          parseFloat(this.model.weight) / this.model.volumes.length;
      });
    }
  }

  removeVolume(index) {
    if (this.model.volumes.length > 1) {
      this.model.volumes.splice(index, 1);
    }
    this.calcVolumeWeight();
  }

  keyUpFilter(value, name) {
    this.productFilterScreen[name] = value;
    if (name === 'categories' || name === 'subCategories') {
      this.model = null;
      this.productFilterScreen[name] = [value];
    }

    if (name === 'categories') {
      this.productFilter.categories = [value];
      this.productFilterScreen.subCategories = [];
      if (this.productFilterScreen?.categories[0] !== 'Selecione') {
        this.selectedSegmento = this.categories
          .filter((cat) => cat.id === this.productFilterScreen.categories[0])
          .slice()
          .shift();
        this.loadProducts();
      } else {
        this.selectedSegmento = new InfoCategory();
        this.selectedSubCategory = new InfoSubCategory();
        this.visibleProducts = [];
        this.allProducts = [];
      }
    }
    this.filterProducts();
  }

  filterProducts() {
    if (this.allProducts) {
      this.visibleProducts = [...this.allProducts];
    }

    if (this.productFilterScreen.inactive) {
      this.visibleProducts = this.visibleProducts.filter(
        (prod) => prod.enabled === false
      );
    }

    if (this.productFilterScreen.active) {
      this.visibleProducts = this.visibleProducts.filter(
        (prod) => prod.enabled === true
      );
    }

    if (this.productFilterScreen.discount) {
      this.visibleProducts = this.visibleProducts.filter(
        (prod) => prod.discount > 0
      );
    }

    if (this.productFilterScreen?.querySearch) {
      const q = this.productFilterScreen?.querySearch.toUpperCase();
      const newProducts = [];
      this.visibleProducts.forEach((prod) => {
        const t1 = prod.name
          ? prod.name.toUpperCase().indexOf(q) !== -1
          : false;
        const t2 = prod.dimensions
          ? prod.dimensions.toUpperCase().indexOf(q) !== -1
          : false;
        const t3 = prod.colorDescription
          ? prod.colorDescription.toUpperCase().indexOf(q) !== -1
          : false;
        const t4 = prod.id ? prod.id.toString().indexOf(q) !== -1 : false;
        if (t1 || t2 || t3 || t4) {
          newProducts.push(prod);
        }
      });
      this.visibleProducts = newProducts;
    }

    if (this.productFilterScreen?.categories[0]) {
      this.visibleProducts = this.visibleProducts.filter(
        (prod) => prod.category === this.productFilterScreen.categories[0]
      );
    }

    if (this.productFilterScreen.subCategories[0]) {
      this.visibleProducts = this.visibleProducts.filter(
        (prod) => prod.subCategory === this.productFilterScreen.subCategories[0]
      );
    }

    if (this.productFilterScreen?.featuredBanner) {
      this.visibleProducts = this.visibleProducts.filter(
        (prod) => prod?.featuredBanner?.length > 0
      );
    }
    this.setFilterOnGrid();
  }

  async rowDoubleClicked(product: Product, rowIndex: number) {
    this.blockOperation.setBlockOperation('', true);
    try {
      if (product) {
        this.selectedProducts = [];
        this.selectedRowIndex = rowIndex;
        this.model = await this.productService.get(product.id);
        this.sections = this.model.informationsSection || [];
        this.images = this.formatPhotosToImagesGallery(this.model.photos);
        this.selectedSegmento = this.categories
          .filter((cat) => cat.id === this.model.category)
          .slice()
          .shift();
        this.selectedSubCategory = this.selectedSegmento.subCategories
          .filter((sub) => sub.id === this.model.subCategory)
          .slice()
          .shift();
        this.subpage = 'form';
        this.calcVolumeWeight();
      }
    } catch (error) {
      this.toastService.error(error);
    } finally {
      this.blockOperation.setBlockOperation('', false);
    }
  }

  validProduct() {
    if (!this.model.name) {
      this.validation.errorName = true;
      this.toastService.error('Preencha os campos obrigatórios!');
      return false;
    } else {
      this.validation.errorName = false;
    }

    if (!this.images || this.images.length === 0) {
      this.toastService.error('É obrigatório adicionar ao menos uma imagem!');
      return false;
    }

    if (
      this.model.canBeGeneratedWithStockZero &&
      this.model.daysOnlyOrder < 1
    ) {
      this.toastService.error(
        'É obrigatório preencher o Prazo de Encomenda (Dias)!'
      );
      return false;
    }

    if (
      this.model.daysOnlyOrder > 0 &&
      !this.model.canBeGeneratedWithStockZero
    ) {
      this.toastService.error(
        'É obrigatório selecionar Pode Vender Sem Estoque!'
      );
      return false;
    }

    if (this.isKit(this.model)) {
      if (!this.haveVolume()) {
        this.toastService.error(
          'É obrigatório preencher os volumes da embalagem!'
        );
        return false;
      }
    } else {
      if (
        !this.model.packingHeightThickness ||
        !this.model.packingLength ||
        !this.model.packingWidth ||
        !this.model.packingWeight
      ) {
        this.toastService.error(
          'É obrigatório preencher os dados da embalagem!'
        );
        return false;
      }
    }

    if (!this.model.enabled && this.model.mainVariation) {
      this.toastService.error(
        'Produto inativo não pode ser configurado como principal!'
      );
      return false;
    }

    if (!this.model.hubIntegrationCode) {
      this.toastService.error('Código de integração é campo obrigatório!');
      return false;
    }

    return true;
  }

  haveVolume(): boolean {
    if (!this.model?.volumes || this.model?.volumes?.length === 0) {
      return false;
    }

    let have = true;
    this.model?.volumes.forEach((volume) => {
      if (
        !volume.packingHeightThickness ||
        !volume.packingLength ||
        !volume.packingWeight ||
        !volume.packingWidth
      ) {
        have = false;
      }
    });

    return have;
  }

  async onClickSave() {
    if (!this.validProduct()) {
      return;
    }

    if (this.model.id) {
      this.updateV2();
    } else {
      this.saveV2();
    }
  }

  async saveV2() {
    this.blockOperation.setBlockOperation('', true);
    try {
      this.model.informationsSection = this.sections;
      const attachments: Array<AttachmentProduct> =
        this.formatImagesGalleryToPhotos(this.images);

      this.model = await this.productService.saveV2(this.model, attachments);

      this.blockOperation.setBlockOperation('', false);
      this.toastService.success('Cadastro realizado com sucesso!');
      await wait(ONE_SECOND_IN_MS);
      this.loadProducts();
      this.subpage = 'grid';
    } catch (err) {
      this.blockOperation.setBlockOperation('', false);
      const msg = (err || {}).message || 'Erro ao cadastrar produto!';
      this.toastService.error(msg);
    }
  }

  async updateV2() {
    this.blockOperation.setBlockOperation('', true);
    try {
      this.model.informationsSection = this.sections;
      const attachmentsCreateOrUpdate = this.formatImagesGalleryToPhotos(
        this.images
      );

      const attachmentsDelete = this.getImagesToDelete(this.model.photos);

      await this.productService.updateV2(
        this.model,
        attachmentsCreateOrUpdate,
        attachmentsDelete
      );

      this.blockOperation.setBlockOperation('', false);
      this.toastService.success('Produto alterado com sucesso!');
      await wait(ONE_SECOND_IN_MS);
      this.loadProducts();
      this.subpage = 'grid';
    } catch (err) {
      this.blockOperation.setBlockOperation('', false);
      this.toastService.error(err?.message || 'Erro ao alterar produto!');
    }
  }

  getImagesToDelete(images: Array<AttachmentProductLink>): Array<number> {
    const idsImagesToDelete: Array<number> = [];
    if (images && images.length > 0) {
      images.forEach((photo) => {
        const item: ImageGalleryImage = this.images?.find(
          (img) => img.id === photo.id
        );
        if (!item) {
          idsImagesToDelete.push(photo.id);
        }
      });
    }
    return idsImagesToDelete;
  }

  formatImagesGalleryToPhotos(
    images: Array<ImageGalleryImage>
  ): Array<AttachmentProduct> {
    const newImages: Array<AttachmentProduct> = [];
    let attachment: AttachmentProduct;
    let count = 1;
    images.forEach((image) => {
      attachment = new AttachmentProduct();
      if (image.id) {
        attachment.id = image.id;
      }
      attachment.photo = image.src;
      attachment.orderDisplay = count;
      newImages.push(attachment);
      count++;
    });
    return newImages;
  }

  formatPhotosToImagesGallery(
    photos: Array<AttachmentProductLink>
  ): Array<ImageGalleryImage> {
    const newImages: Array<ImageGalleryImage> = [];
    if (photos && photos.length > 0) {
      let image: ImageGalleryImage;
      photos.forEach((photo) => {
        image = {};
        image.id = photo.id;
        image.src = photo.url;
        newImages.push(image);
      });
    }
    return newImages;
  }

  changeCategory($event) {
    this.model.category = $event.target.value;
    this.selectedSegmento = this.categories
      .filter((cat) => cat.id === this.model.category)
      .slice()
      .shift();
  }

  changeSubCategory($event) {
    this.model.subCategory = $event.target.value;
    this.selectedSubCategory = this.selectedSegmento.subCategories
      .filter((sub) => sub.id === this.model.subCategory)
      .slice()
      .shift();
  }

  clickBack() {
    this.confirmationService.confirm({
      message: 'Deseja realmente sair sem salvar o produto?',
      header: 'Confirmação',
      accept: () => {
        this.subpage = 'grid';
        this.setFilterOnGrid();
      },
    });
  }

  setFilterOnGrid() {
    if (this.model) {
      this.selectedProducts = [this.model];

      if (this.filterGrid) {
        setTimeout(() => {
          const event = new Event('input', {
            bubbles: true,
            cancelable: true,
          });
          this.filterGridInput?.nativeElement?.dispatchEvent(event);
        }, 100);
      }

      if (this.selectedRowIndex > 0) {
        setTimeout(() => {
          this.tableproducts?.scrollToVirtualIndex(this.selectedRowIndex + 1);
        }, 500);
      }
    }
  }

  onClickClone(withImage: boolean) {
    if (!this.model.id) {
      this.toastService.error('É possível clonar apenas produtos existentes!');
      return;
    }

    this.confirmationService.confirm({
      message: 'Deseja realmente clonar esse produto?',
      header: 'Confirmação',
      accept: () => {
        this.model.id = null;
        this.model.gtin = null;
        this.model.hubIntegrationCode = null;
        if (this.sections?.length > 0) {
          this.sections.forEach((sec) => {
            sec.id = null;
          });
        }

        if (withImage) {
          if (this.images?.length > 0) {
            this.images.map(async (image) => {
              image.id = null;
              image.url = image.src.replace(
                'https://d20p8exwoll05u.cloudfront.net',
                'https://rm-ecommerce-prod.s3.sa-east-1.amazonaws.com'
              );
              (await this.productService.getBase64FromUrl(image)).subscribe(
                async (base64ImageUrl) => {
                  image.base64 = base64ImageUrl;
                  image.src = base64ImageUrl;

                  if (this.loadAllUrlsClone(this.images)) {
                    this.toastService.success('Produto clonado com sucesso!');
                    window.scroll(0, 0);
                  }
                }
              );
            });
          }
        } else {
          this.images = [];
          this.toastService.success('Produto clonado com sucesso!');
          window.scroll(0, 0);
        }
      },
    });
  }
  onClickUpdateImgS3() {
    if (this.selectedProducts?.length > 0) {
      this.confirmationService.confirm({
        message: 'Deseja realmente atualizar as imagens desse(s) produto(s)?',
        header: 'Confirmação',
        accept: () => {
          this.updateImgS3();
        },
      });
    } else {
      this.toastService.error('Selecione ao menos um produto!');
    }
  }

  async updateImgS3() {
    try {
      this.blockOperation.setBlockOperation('', true);
      const ids: Array<number> = [];
      this.visibleProducts.forEach((p) => {
        ids.push(p.id);
      });
      this.selectedProducts = [];
      await this.productService.updateImgS3(ids);
      this.blockOperation.setBlockOperation('', false);
      this.toastService.success('Imagens atualizadas com sucesso!');
      await wait(TWO_SECONDS_IN_MS);
      this.loadProducts();
    } catch (err) {
      this.blockOperation.setBlockOperation('', false);
      this.toastService.error('Erro ao atualizar imagens com o S3!');
    }
  }

  onClickSynchronizeWithHubIntegration(sincAllProducts: boolean) {
    if (sincAllProducts || this.selectedProducts?.length > 0) {
      this.confirmationService.confirm({
        message: `Deseja realmente sincronizar os ${this.selectedProducts?.length} produto(s) com a PluggTO?`,
        header: 'Confirmação',
        accept: () => {
          this.synchronizeWithHubIntegration(sincAllProducts);
        },
      });
    } else {
      this.toastService.error('Selecione ao menos um produto!');
    }
  }

  async synchronizeWithHubIntegration(sincAllProducts: boolean) {
    try {
      this.blockOperation.setBlockOperation('', true);
      let productsToSinc: Array<Product>;
      if (sincAllProducts) {
        productsToSinc = this.visibleProducts;
      } else {
        productsToSinc = this.selectedProducts;
      }
      const ids: Array<number> = [];
      productsToSinc.forEach((p) => {
        ids.push(p.id);
      });
      this.selectedProducts = [];
      await this.productService.syncronizedWithHubIntegration(ids);
      this.blockOperation.setBlockOperation('', false);
      this.toastService.success(
        'Produto(s) sincronizado(s) com o Hub de Integração!'
      );
      await wait(TWO_SECONDS_IN_MS);
      this.loadProducts();
    } catch (err) {
      this.blockOperation.setBlockOperation('', false);
      this.toastService.error(
        'Erro sincronizar produto com o Hub de Integração!'
      );
    }
  }

  onClickSynchronizeWithOmie() {
    if (this.selectedProducts?.length > 0) {
      this.confirmationService.confirm({
        message: `Deseja realmente sincronizar os ${this.selectedProducts?.length} produto(s) com a OMIE?`,
        header: 'Confirmação',
        accept: () => {
          this.synchronizeWithOmie();
        },
      });
    } else {
      this.toastService.error('Selecione ao menos um produto!');
    }
  }

  async synchronizeWithOmie() {
    try {
      this.blockOperation.setBlockOperation('', true);
      const ids: Array<number> = [];
      this.selectedProducts.forEach((p) => {
        ids.push(p.id);
      });
      this.selectedProducts = [];
      await this.productService.syncronizedWithOmie(ids);
      this.blockOperation.setBlockOperation('', false);
      this.toastService.success('Produto(s) sincronizado(s) com a OMIE!');
      await wait(TWO_SECONDS_IN_MS);
      this.loadProducts();
    } catch (err) {
      this.blockOperation.setBlockOperation('', false);
      this.toastService.error('Erro sincronizar produto com a OMIE!');
    }
  }

  onClickSynchronizeWithFR() {
    if (this.selectedProducts?.length > 0) {
      this.confirmationService.confirm({
        message: `Deseja realmente sincronizar os ${this.selectedProducts?.length} produto(s) com a Frete Rápido?`,
        header: 'Confirmação',
        accept: () => {
          this.synchronizeWithFR();
        },
      });
    } else {
      this.toastService.error('Selecione ao menos um produto!');
    }
  }

  async synchronizeWithFR() {
    try {
      this.blockOperation.setBlockOperation('', true);
      const ids: Array<number> = [];
      this.selectedProducts.forEach((p) => {
        ids.push(p.id);
      });
      this.selectedProducts = [];
      await this.productService.synchronizeWithFR(ids);
      this.blockOperation.setBlockOperation('', false);
      this.toastService.success('Produto(s) sincronizado(s) com a OMIE!');
      await wait(TWO_SECONDS_IN_MS);
      this.loadProducts();
    } catch (err) {
      this.blockOperation.setBlockOperation('', false);
      this.toastService.error('Erro sincronizar produto com a OMIE!');
    }
  }

  async activateWithHubIntegration(activate) {
    if (this.selectedProducts?.length > 0) {
      this.confirmationService.confirm({
        message: `Deseja realmente ${
          activate ? 'ativar' : 'desativar'
        } esse(s) produto(s) no Hub de Integração?`,
        header: 'Confirmação',
        accept: () => {
          this.activate(activate);
        },
      });
    } else {
      this.toastService.error('Selecione ao menos um produto!');
    }
  }

  async activate(activate) {
    try {
      this.blockOperation.setBlockOperation('', true);
      const ids: Array<number> = [];
      this.selectedProducts.forEach((p) => {
        ids.push(p.id);
      });
      this.selectedProducts = null;
      await this.productService.activateOnHubIntegration(ids, activate);
      this.toastService.success(
        `Produto(s) ${
          activate ? 'ativado(s)' : 'desativado(s)'
        } no Hub de Integração!`
      );
      await wait(TWO_SECONDS_IN_MS);
      this.loadProducts();
    } catch (err) {
      this.blockOperation.setBlockOperation('', false);
      this.toastService.error(
        `Erro ao ${
          activate ? 'ativar' : 'desativar'
        } produto(s) com o Hub de Integração`
      );
    }
  }

  async onClickImportXLS() {
    try {
      this.confirmationService.confirm({
        message:
          'Verificou se a planilha está no padrão da PLANILHA MODELO,com as colunas na mesma posição e não possui valores vazios?',
        header: 'Confirmação',
        accept: async () => {
          const target: any = await File.openFileXLS();
          this.xls = null;
          if (target) {
            const file = target.files[0];
            if (file) {
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = () => {
                this.xls = reader.result.toString();
                this.importXLS();
              };
            }
          }
        },
      });
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  async importXLS() {
    if (this.xls) {
      this.blockOperation.setBlockOperation('', true);
      try {
        const resp = await this.productService.importXLS(this.xls);
        this.blockOperation.setBlockOperation('', false);
        if (resp.status === 'ERROR') {
          this.toastService.error(
            'Erro ao importar a planilha. Verifique se está com a ordem das colunas corretamente e não possui valor vazio!'
          );
        } else {
          this.toastService.success('Planilha importada com sucesso!');
          await wait(TWO_SECONDS_IN_MS);
          this.loadProducts();
        }
      } catch (err) {
        this.blockOperation.setBlockOperation('', false);
        this.toastService.error('Erro ao atualizar produtos!');
      }
    }
  }

  onClickBaixarModelo() {
    try {
      window.open(
        `https://rm-public-files.s3-sa-east-1.amazonaws.com/PLANILHA_MODELO.xls`,
        '_blank'
      );
    } catch (err) {
      this.toastService.error('Erro ao baixar a imagem!');
    }
  }

  isKit(product: Product): boolean {
    return product.productType === 2;
  }

  withDataFilter(): boolean {
    return (
      this.productFilterScreen.active ||
      this.productFilterScreen.inactive ||
      this.productFilterScreen.discount ||
      this.productFilterScreen.featuredBanner
    );
  }

  onFileChange(evt: any) {
    this.blockOperation.setBlockOperation('', true);
    const target: DataTransfer = <DataTransfer>evt.target;
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      this.batchProducts = <AOA>XLSX.utils.sheet_to_json(ws, { header: 1 });
      this.export();
      this.fileInputVariable.nativeElement.value = '';
    };
    reader.readAsBinaryString(target.files[0]);
  }

  export(): void {
    const products: Array<Product> = [];
    let newProduct: Product;
    let line = 1;

    this.batchProducts.forEach((product) => {
      if (line !== 1) {
        newProduct = new Product();
        newProduct.fromExcel(this.categories, this.origins, line, product);
        products.push(newProduct);
      }
      line++;
    });

    if (products) {
      this.getBase64FromUrlProduct(products);
    }
  }

  async getBase64FromUrlProduct(products: Product[]) {
    try {
      let allImagesUrls: AttachmentProductLink[] = [];
      products.map(async (product) => {
        product.photos.map(async (image) => {
          allImagesUrls.push(image);
        });
      });
      allImagesUrls = Array.from(
        allImagesUrls.reduce((m, t) => m.set(t.url, t), new Map()).values()
      );

      allImagesUrls.map(async (image) => {
        let haveError = false;
        (await this.productService.getBase64FromUrl(image)).subscribe({
          next: (base64ImageUrl: any) => {
            image.url = base64ImageUrl;
            if (!haveError) {
              if (this.loadAllUrls(allImagesUrls)) {
                this.saveProductsBatch(products, allImagesUrls);
              }
            }
          },
          error: () => {
            haveError = true;
            this.blockOperation.setBlockOperation('', false);
            this.toastService.error(
              'Não foi possível baixar a imagem: ' + image.url
            );
          },
        });
      });
    } catch (error) {
      this.blockOperation.setBlockOperation('', false);
      this.toastService.error(error);
    }
  }

  async saveProductsBatch(
    products: Array<Product>,
    allImagesUrls: AttachmentProductLink[]
  ) {
    const validationMessages: string[] = this.validProductsBatch(products);
    if (validationMessages?.length === 0) {
      try {
        const newProducts = await this.productService.saveProductsBatch(
          products
        );

        if (newProducts?.length > 0) {
          newProducts.map((product) => {
            product.photos.map((image) => {
              const img = allImagesUrls.find(
                (newImage) => image.url === newImage.urlAPI
              );
              if (img) {
                image.url = img.url;
              }
            });
          });

          this.continueBatchProcess(newProducts);
        }
      } catch (error) {
        this.blockOperation.setBlockOperation('', false);
        const msg = error?.message
          .split(',')
          .map((str) => str.trim())
          .join('\n');
        this.toastService.error(msg || error?.error, 10000);
        throw error;
      }
    } else {
      this.blockOperation.setBlockOperation('', false);
      const msg = validationMessages
        .toString()
        .split(',')
        .map((str) => str.trim())
        .join('\n');
      this.toastService.error(msg);
    }
  }

  async continueBatchProcess(products: any[]) {
    try {
      await this.setImagesProductsBatch(products);
      this.blockOperation.setBlockOperation('', false);
      this.toastService.success('Cadastro realizado com sucesso!');
      this.subpage = 'grid';
      this.loadProducts();
    } catch (error) {
      this.blockOperation.setBlockOperation('', false);
      this.toastService.error(
        'Ocorreu um erro ao cadastrar as imagens. Entre em contado com o Desenvolvimento!'
      );
      throw error;
    }
  }

  async setImagesProductsBatch(products: any[]) {
    try {
      await this.productService.saveAttachmentBatch(products);
    } catch (error) {
      throw error;
    }
  }

  loadAllUrlsClone(allImagesUrls: ImageGalleryImage[]) {
    let loadAll = true;
    const findImage = allImagesUrls.find((image) => image.base64 === undefined);
    if (findImage) {
      loadAll = false;
    }
    return loadAll;
  }

  public loadAllUrls(allImagesUrls: AttachmentProductLink[]) {
    let loadAll = true;
    const findImage = allImagesUrls.find((image) => {
      const indexOf = image.url.indexOf(
        'https://rm-ecommerce-prod.s3.sa-east-1.amazonaws.com'
      );
      return indexOf === 0;
    });
    if (findImage) {
      loadAll = false;
    }
    return loadAll;
  }

  validProductsBatch(products: Array<Product>): string[] {
    let msg: string[] = [];
    products.map(async (product) => {
      if (product?.validationMessages.length > 0) {
        msg = msg.concat(product.validationMessages);
      }
    });
    return msg;
  }

  showFieldsActuation(): boolean {
    return (
      this.model.category === CategoriesEnum.PERSIANAS ||
      this.model.category === CategoriesEnum.TOLDOS
    );
  }
}
