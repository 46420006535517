import { Injectable } from '@angular/core';
import AbstractInfoEnum from 'src/app/models/AbstractInfoEnum';
import AppInfo from 'src/app/models/AppInfo';
import InfoCoupon from 'src/app/models/coupons/InfoCoupon';
import InfoCategory from 'src/app/models/InfoCategory';
import InfoSubCategory from 'src/app/models/product/info-sub-category';
import Report from 'src/app/models/reports/Report';
import fetch from 'src/services/fetch';
import { ArrayUtils } from 'src/util/array-utils';

@Injectable({ providedIn: 'root' })
export class AppInfoService {
  public appInfo: AppInfo;

  public getCategories(): Array<InfoCategory> {
    return this.appInfo?.enumeretions?.categories;
  }

  public getCategoriesWithProductActive(): Array<InfoCategory> {
    const categoriesWithProductActive =
      this.appInfo?.enumeretions?.categories.filter(
        (cat) => cat.hasActiveProduct
      );
    categoriesWithProductActive.forEach((cat) => {
      cat.subCategories = cat.subCategories.filter(
        (sub) => sub.hasActiveProduct
      );
    });
    return categoriesWithProductActive;
  }

  public getOrderStatus(): Array<AbstractInfoEnum> {
    return this.appInfo?.enumeretions?.orderStatus;
  }

  public getMarketplaces(): Array<AbstractInfoEnum> {
    return this.appInfo?.enumeretions?.marketplaces;
  }

  public getGroupingAttributes(): Array<AbstractInfoEnum> {
    return this.appInfo?.enumeretions?.groupingAttributes;
  }

  public getProductOrigin(): Array<AbstractInfoEnum> {
    return this.appInfo?.enumeretions?.productOrigins;
  }

  public getReports(): Report {
    return this.appInfo?.reports;
  }

  public getInfoCoupon(): InfoCoupon {
    return this.appInfo?.enumeretions?.coupon;
  }

  public getAllSubCategories(): InfoSubCategory {
    const newSubs: InfoSubCategory = new InfoSubCategory();
    this.appInfo?.enumeretions?.categories?.forEach((cat) => {
      cat.subCategories.forEach((subs) => {
        subs.heightThickness.forEach((thicknes) => {
          if (
            !newSubs.heightThickness.find((item) => item.id === thicknes.id)
          ) {
            newSubs.heightThickness.push(thicknes);
          }
        });
        subs.widths.forEach((width) => {
          if (!newSubs.widths.find((item) => item.id === width.id)) {
            newSubs.widths.push(width);
          }
        });
        subs.lengths.forEach((length) => {
          if (!newSubs.lengths.find((item) => item.id === length.id)) {
            newSubs.lengths.push(length);
          }
        });
        subs.colors.forEach((color) => {
          if (!newSubs.colors.find((c) => c.id === color.id)) {
            newSubs.colors.push(color);
          }
        });
      });
    });
    ArrayUtils.sort(newSubs.heightThickness, 'description');
    ArrayUtils.sort(newSubs.widths, 'description');
    ArrayUtils.sort(newSubs.lengths, 'description');
    ArrayUtils.sort(newSubs.colors, 'description');
    return newSubs;
  }

  public async getAppInfo() {
    if (!this.appInfo) {
      this.appInfo = await this.getApp();
    }
    return this.appInfo;
  }

  public async getApp(): Promise<AppInfo> {
    const appInfo: AppInfo = new AppInfo();
    try {
      const response = await fetch('GET', 'app-info/');
      appInfo.fromJson(response);
      return appInfo;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
}
