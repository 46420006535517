import Product from '../product/product';
import CartProductCheckout from './CartProductCheckout';

export default class CartCheckout {
  public id: number;
  public cartProducts: Array<CartProductCheckout>;

  constructor(cart: any) {
    if (cart) {
      this.id = cart?.id;
      if (cart.cartProducts) {
        this.cartProducts = [];
        cart.cartProducts.forEach((cp) => {
          cp = Object.assign(new CartProductCheckout(), cp);
          cp.product = Object.assign(new Product(), cp.product);
          this.cartProducts.push(cp);
        });
      }
    }
  }
}
