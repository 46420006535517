export default class ProductFilter {
  active?: boolean;
  aluminumWallThickness?: string;
  brand?: string;
  categories?: string[];
  capacity?: string;
  color?: string;
  direction?: string;
  inactive?: boolean;
  featuredBanner?: boolean;
  finish?: string;
  filterExcluded?: string;
  heightThickness?: string;
  ignoreGrouped?: boolean;
  ignoreMainVariation?: boolean;
  discount?: boolean;
  length?: string;
  model?: string;
  name?: string;
  order?: any;
  perPage?: number;
  page?: number;
  potency?: string;
  productPerSquareMeter?: boolean;
  querySearch?: string;
  speed?: string;
  subCategories?: string[];
  voltage?: string;
  width?: string;
  withDiscountOrFeatured?: boolean;
}
